import React from 'react'

function CopyRightArea() {
  return (
    <div className="copyright-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <div className="copy-right-text">
              <p>
                © {new Date().getFullYear()} K4D. All Rights Reserved by{' '}
                <a
                  href="https://zoltraafrika.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Zoltra Afrika
                </a>
              </p>
              <ul className="copy-right-list">
                <li>
                  <a
                    href="terms-condition.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="privacy-policy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="copy-right-social">
              <ul className="social-link">
                <li>
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ri-instagram-fill"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ri-linkedin-fill"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CopyRightArea
