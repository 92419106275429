import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearErrors, forgotPassword } from '../../actions/userAction'
import { useSnackbar } from 'notistack'
import BackdropLoader from '../Layouts/BackdropLoader'
import MetaData from '../Layouts/MetaData'
import FormSidebar from './FormSidebar'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { error, message, loading } = useSelector(
    (state) => state.forgotPassword
  )

  const [email, setEmail] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.set('email', email)
    dispatch(forgotPassword(formData))
    setEmail('')
  }

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' })
      dispatch(clearErrors())
    }
    if (message) {
      enqueueSnackbar(message, { variant: 'success' })
    }
  }, [dispatch, error, message, enqueueSnackbar])

  return (
    <>
      <MetaData title="Forgot Password" />

      {loading && <BackdropLoader />}
      <main className="mt-12 flex min-h-screen w-full items-center justify-center  sm:mt-0 sm:pt-20">
        {/* <!-- row --> */}
        <div className="m-auto mb-7 flex bg-gray-200 shadow-lg sm:mt-4 sm:w-4/6">
          <FormSidebar
            title="Forgot Your Password?"
            tag="Enter the email address associated with your account."
          />

          {/* <!-- login column --> */}
          <div className="flex-1 overflow-hidden">
            <h2 className="mt-6 text-center text-2xl font-medium text-gray-800">
              Forgot Password
            </h2>

            {/* <!-- edit info container --> */}
            <div className="px-4 py-10 text-center sm:px-14">
              {/* <!-- input container --> */}
              <form onSubmit={handleSubmit}>
                <div className="flex w-full flex-col gap-4">
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                  {/* <!-- button container --> */}
                  <div className="mb-32 mt-2 flex flex-col gap-2.5">
                    <p className="text-left text-xs text-primary-grey">
                      By continuing, you agree to Kibanda4Devs's{' '}
                      <a
                        href="https://www.Kibanda4Devs.com/pages/terms"
                        className="text-primary-blue"
                      >
                        {' '}
                        Terms of Use
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://www.Kibanda4Devs.com/pages/privacypolicy"
                        className="text-primary-blue"
                      >
                        {' '}
                        Privacy Policy.
                      </a>
                    </p>
                    <button
                      type="submit"
                      className="w-full rounded-sm bg-[#DB7904] py-3 font-medium text-white shadow"
                    >
                      Submit
                    </button>
                  </div>
                  {/* <!-- button container --> */}
                </div>
              </form>
              {/* <!-- input container --> */}

              <Link
                to="/register"
                className="text-sm font-medium text-primary-blue"
              >
                New to Kibanda4Devs? Create an account
              </Link>
            </div>
            {/* <!-- edit info container --> */}
          </div>
          {/* <!-- login column --> */}
        </div>
        {/* <!-- row --> */}
      </main>
    </>
  )
}

export default ForgotPassword
