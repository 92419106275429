import React from 'react'

function CollectionsArea() {
  return (
    <div className="collections-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>Hot Collections</h2>
        </div>
        <div className="row pt-45">
          <div className="col-lg-3 col-6">
            <div className="collections-card">
              <a href="collection.html">
                <img
                  src="assets/images/collections/collections-img1.png"
                  alt="Images"
                />
              </a>
              <div className="content">
                <div className="collections-user">
                  <img
                    src="assets/images/collections/collections-user1.png"
                    alt="Images"
                  />
                </div>
                <h3>
                  <a href="collection.html">Wrapped Cryptopunks</a>
                </h3>
                <span>ETH - 11,256</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="collections-card">
              <a href="collection.html">
                <img
                  src="assets/images/collections/collections-img2.png"
                  alt="Images"
                />
              </a>
              <div className="content">
                <div className="collections-user">
                  <img
                    src="assets/images/collections/collections-user2.png"
                    alt="Images"
                  />
                </div>
                <h3>
                  <a href="collection.html">Art Blocks</a>
                </h3>
                <span>ETH - 12,256</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="collections-card">
              <a href="collection.html">
                <img
                  src="assets/images/collections/collections-img3.png"
                  alt="Images"
                />
              </a>
              <div className="content">
                <div className="collections-user">
                  <img
                    src="assets/images/collections/collections-user3.png"
                    alt="Images"
                  />
                </div>
                <h3>
                  <a href="collection.html">Hashmasks</a>
                </h3>
                <span>ETH - 11,764</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="collections-card">
              <a href="collection.html">
                <img
                  src="assets/images/collections/collections-img4.png"
                  alt="Images"
                />
              </a>
              <div className="content">
                <div className="collections-user">
                  <img
                    src="assets/images/collections/collections-user4.png"
                    alt="Images"
                  />
                </div>
                <h3>
                  <a href="collection.html">Inventory</a>
                </h3>
                <span>ETH - 13,264</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollectionsArea
