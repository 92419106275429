import React from 'react'
import MissionImage from '../../../assets/images/adj desk (2).jpeg'
import Consumer from '../../../assets/images/consumer.png'
import Business from '../../../assets/images/business.jpeg'
import Employee1 from '../../../assets/images/employee4.avif'
import Employee2 from '../../../assets/images/employee2.avif'
import Employee3 from '../../../assets/images/employee3.avif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const AboutUsPage = () => {
  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-200">
      <div className="container relative z-10 mx-auto">
        {/* Header Section */}
        <div className="mb-12 mt-4">
          <h1
            className="relative mx-auto mb-4 text-center font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            Welcome to Kibanda4devs
            <span
              className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            ></span>
          </h1>
        </div>

        <div className="relative z-10 mx-auto rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}

          <section className="relative z-10 mb-12 flex flex-col items-center md:flex-row">
            <div className="md:w-1/2 md:pr-8">
              <h1
                className="font-semi-bold mb-4 font-sans text-3xl"
                style={{ color: '#DB7A03' }}
              >
                Our Mission
              </h1>

              <p className="mb-4 font-sans text-gray-600">
                Empowering developers through personalized workspaces, designed
                to supercharge productivity and well-being.
              </p>
            </div>
            <div className="md:w-1/2">
              <img
                src={MissionImage}
                alt="Welcome Image"
                className="rounded-lg shadow-md"
              />
            </div>
          </section>
        </div>

        {/* What We Do Section */}

        <div className="mb-12 mt-4">
          <h1
            className="relative mx-auto mb-4 text-center font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            What We Do
            <span
              className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            ></span>
          </h1>
        </div>

        <div className="relative z-10 mx-auto rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <section className="relative z-10 mb-12 flex flex-col items-center md:flex-row">
            <div className="md:w-1/2 md:pr-8">
              <img
                src={Consumer}
                alt="Customer Experience"
                className="rounded-lg shadow-md"
              />
              <h1
                className="font-semi-bold mb-4 mt-4 font-sans text-2xl"
                style={{ color: '#DB7A03' }}
              >
                Enhancing Customer Experience
              </h1>
              <p className="mb-4 font-sans text-gray-600">
                At Kibanda4devs, we prioritize the needs of our customers by
                offering tailored solutions that enhance usability and
                efficiency. Our team is dedicated to providing top-notch support
                and continuously improving our services to meet the evolving
                demands of the tech industry.
              </p>
              {/* Link for Consumers - Futuristic style with arrow */}
              <a
                href="/about-us/consumer-services"
                className="flex items-center font-sans hover:text-gray-300"
                style={{ color: '#DB7A03' }}
              >
                Discover Consumer Services
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
            <div className="md:w-1/2 md:pr-8">
              <img
                src={Business}
                alt="Business Growth"
                className="rounded-lg shadow-md"
              />
              <h1
                className="font-semi-bold mb-4 mt-4 font-sans text-2xl"
                style={{ color: '#DB7A03' }}
              >
                Empowering Business Growth
              </h1>
              <p className="mb-4 font-sans text-gray-600">
                We partner with businesses to provide a platform where they can
                showcase and sell their products. Acting as a B2B facilitator,
                we help companies/businesses reach new markets and grow their
                sales by connecting them with a wider audience.
              </p>
              {/* Link for Businesses - Futuristic style with arrow */}
              <a
                href="/about-us/business-services"
                className="flex items-center font-sans hover:text-gray-300"
                style={{ color: '#DB7A03' }}
              >
                Discover Business Services
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </section>
        </div>

        {/* Leadership Section */}

        <div className="mb-12 mt-4">
          <h1
            className="relative mx-auto mb-4 text-center font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            Leadership
            <span
              className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            ></span>
          </h1>
        </div>

        <div className="relative z-10 mx-auto mb-6 rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <section>
            <div className="relative z-10 grid grid-cols-1 gap-8 lg:grid-cols-3">
              {/* Example Leader Card */}
              <div className="rounded-lg bg-white p-6 shadow-md">
                <img
                  src={Employee1}
                  alt="Leader Image"
                  className="mb-4 w-full rounded-lg"
                />
                <h3 className="mb-2 text-lg font-semibold">John Doe</h3>
                <p className="text-gray-600">CEO</p>
              </div>
              <div className="rounded-lg bg-white p-6 shadow-md">
                <img
                  src={Employee2}
                  alt="Leader Image"
                  className="mb-4 w-full rounded-lg"
                />
                <h3 className="mb-2 text-lg font-semibold">Alex Lee</h3>
                <p className="text-gray-600">Group Chief Marketing Officer</p>
              </div>
              <div className="rounded-lg bg-white p-6 shadow-md">
                <img
                  src={Employee3}
                  alt="Leader Image"
                  className="mb-4 w-full rounded-lg"
                />
                <h3 className="mb-2 text-lg font-semibold">Ann Mwikali</h3>
                <p className="text-gray-600">Product Manager</p>
              </div>
              {/* Additional Leader Cards */}
              {/* Add more leader cards as needed */}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default AboutUsPage
