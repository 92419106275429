import { useSelector } from 'react-redux'
import MetaData from '../Layouts/MetaData'
import MinCategory from '../Layouts/MinCategory'
import Sidebar from '../User/Sidebar'
import Product from './Product'

const Wishlist = () => {
  const { wishlistItems } = useSelector((state) => state.wishlist)

  return (
    <>
      <MetaData title="Wishlist | Kibanda4Devs" />

      <MinCategory />
      <main className="mt-12 w-full sm:mt-0">
        <div className="m-auto mb-7 flex gap-3.5 sm:mt-4 sm:w-11/12">
          <Sidebar activeTab={'wishlist'} />

          <div className="flex-1 bg-white shadow">
            {/* <!-- wishlist container --> */}
            <div className="flex flex-col">
              <span className="border-b px-4 py-4 text-lg font-medium sm:px-8">
                My Wishlist ({wishlistItems.length})
              </span>

              {wishlistItems.length === 0 && (
                <div className="m-6 flex flex-col items-center gap-2">
                  <img
                    draggable="false"
                    className="object-contain"
                    src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/mywishlist-empty_39f7a5.png"
                    alt="Empty Wishlist"
                  />
                  <span className="mt-6 text-lg font-medium">
                    Empty Wishlist
                  </span>
                  <p>You have no items in your wishlist. Start adding!</p>
                </div>
              )}

              {wishlistItems
                .map((item, index) => <Product {...item} key={index} />)
                .reverse()}
            </div>
            {/* <!-- wishlist container --> */}
          </div>
        </div>
      </main>
    </>
  )
}

export default Wishlist
