import React, { useState } from 'react'

import Story1Image from '../../../assets/images/workenv.jpeg'
import Story2Image from '../../../assets/images/workenv2.jpeg'
import Story3Image from '../../../assets/images/mission.avif'
import Story4Image from '../../../assets/images/mission.avif'
import Story5Image from '../../../assets/images/workenv.jpeg'
import Story6Image from '../../../assets/images/workenv2.jpeg'
import StoryCard from './storyCard'

const storyImages = [
  Story1Image,
  Story2Image,
  Story3Image,
  Story4Image,
  Story5Image,
  Story6Image,
]

const initialStories = [
  {
    title: 'Empowering Women in Tech: The Kibanda4Devs Story',
    image: storyImages[0],
    description:
      'Learn how Kibanda4Devs is empowering women in tech through its initiatives and programs.',
    slug: 'empowering-women-in-tech',
  },
  {
    title: 'Building Sustainable Solutions: Our Impact in Rural Communities',
    image: storyImages[1],
    description:
      'Discover how Kibanda4Devs is making a difference by building sustainable tech solutions in rural communities.',
    slug: 'building-sustainable-solutions',
  },
  {
    title: "From Passion to Profession: A Developer's Journey at Kibanda4Devs",
    image: storyImages[2],
    description:
      'Follow the journey of one of our developers and learn how Kibanda4Devs helped turn passion into a profession.',
    slug: 'developers-journey-at-kibanda4devs',
  },
]

function StoriesPage() {
  const [stories, setStories] = useState(initialStories)
  const [showAll, setShowAll] = useState(false)

  const toggleShowAll = () => {
    setShowAll(!showAll)
    if (!showAll) {
      setStories([
        ...initialStories,
        {
          title:
            'Innovating Education: Revolutionizing Learning for the Next Generation',
          image: storyImages[3],
          description:
            'Explore how Kibanda4Devs is innovating education to empower the next generation of learners.',
          slug: 'innovating-education',
        },
        {
          title:
            'Transforming Healthcare: Leveraging Technology for Better Patient Outcomes',
          image: storyImages[4],
          description:
            'Learn how Kibanda4Devs is transforming healthcare through innovative technological solutions.',
          slug: 'transforming-healthcare',
        },
        {
          title:
            "Sustainability in Action: Kibanda4Devs' Commitment to Environmental Responsibility",
          image: storyImages[5],
          description:
            'Discover how Kibanda4Devs is taking concrete steps towards environmental sustainability.',
          slug: 'sustainability-in-action',
        },
      ])
    } else {
      setStories(initialStories)
    }
  }

  return (
    <div className="stories-page relative flex min-h-screen items-center justify-center bg-gray-200">
      <div className="container relative z-10 mx-auto">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}

        <div className=" relative z-10 mx-auto rounded-lg p-8">
          <h1 className="text-orange-600 relative z-10 mb-6 mt-3 text-center font-sans text-3xl font-bold">
            Kibanda4Devs Stories
            <span
              className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            />
          </h1>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {stories.map((story, index) => (
              <StoryCard key={index} story={story} />
            ))}
          </div>
          <div className="mt-8 text-center">
            <button
              onClick={toggleShowAll}
              className="ml-8 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
            >
              {showAll ? 'View Less' : 'View More'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoriesPage
