import { useEffect } from 'react'
import Sidebar from './Sidebar'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../Layouts/Loader'
import MinCategory from '../Layouts/MinCategory'
import MetaData from '../Layouts/MetaData'

const Account = () => {
  const navigate = useNavigate()

  const { user, loading, isAuthenticated } = useSelector((state) => state.user)

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate('/login')
    }
  }, [isAuthenticated, navigate])

  const getLastName = () => {
    if (user && user.name) {
      const nameArray = user.name.split(' ')
      return nameArray[nameArray.length - 1]
    }
    return '' // or any default value you prefer
  }

  return (
    <>
      <MetaData title="My Profile" />

      {loading ? (
        <Loader />
      ) : (
        <>
          <MinCategory />
          <main className="mt-12 w-full sm:mt-0 ">
            {/* <!-- row --> */}
            <div className="m-auto mb-7 flex gap-3.5 sm:mt-4 sm:w-11/12 ">
              <Sidebar activeTab={'profile'} />

              {/* <!-- details column --> */}
              <div className="mb-8 mt-8 flex-1 overflow-hidden bg-gray-200 shadow">
                {/* <!-- edit info container --> */}
                <div className="m-4 flex flex-col gap-12 sm:mx-8 sm:my-6">
                  {/* <!-- personal info --> */}
                  <div className="flex flex-col items-start gap-5">
                    <div className=" relative z-10 p-8">
                      {/* Curved outward shadow effect */}
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                      {/* End of curved outward shadow effect */}
                      <span className="relative z-10 text-lg font-medium  ">
                        Personal Information{' '}
                        <Link
                          to="/account/update"
                          className="ml-8 cursor-pointer rounded-md px-2 py-1 text-sm font-medium text-white "
                          style={{
                            backgroundColor: '#00c9b7',
                            boxShadow: '0px 0px 10px 0px #00c9b7',
                          }}
                        >
                          Edit
                        </Link>
                      </span>
                    </div>
                    <div className=" relative z-10 rounded-lg p-8">
                      {/* Curved outward shadow effect */}
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                      {/* End of curved outward shadow effect */}
                      <div
                        className="relative z-10 flex flex-col items-center gap-3 sm:flex-row"
                        id="personalInputs"
                      >
                        <div className="inputs flex w-64 cursor-not-allowed flex-col gap-0.5 rounded-sm border bg-gray-100 px-3 py-1.5 focus-within:border-primary-blue">
                          <label className="text-xs text-gray-500">
                            First Name
                          </label>
                          <input
                            type="text"
                            value={user?.name?.split(' ', 1)}
                            className="cursor-not-allowed border-none text-sm text-gray-500 outline-none"
                            disabled
                          />
                        </div>
                        <div className="inputs flex w-64 cursor-not-allowed flex-col gap-0.5 rounded-sm border bg-gray-100 px-3 py-1.5 focus-within:border-primary-blue">
                          <label className="text-xs text-gray-500">
                            Last Name
                          </label>
                          <input
                            type="text"
                            value={getLastName()}
                            className="cursor-not-allowed border-none text-sm text-gray-500 outline-none"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className=" relative z-10 rounded-lg p-8">
                      {/* Curved outward shadow effect */}
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                      {/* End of curved outward shadow effect */}
                      {/* <!-- gender --> */}
                      <div className="relative z-10 flex flex-col gap-2">
                        <h2 className="text-sm">Your Gender</h2>
                        <div
                          className="flex items-center gap-8"
                          id="radioInput"
                        >
                          <div className="inputs flex cursor-not-allowed items-center gap-4 text-gray-500">
                            <input
                              type="radio"
                              name="gender"
                              checked={user?.gender === 'male'}
                              id="male"
                              className="h-4 w-4 cursor-not-allowed"
                              disabled
                            />
                            <label
                              htmlFor="male"
                              className="cursor-not-allowed"
                            >
                              Male
                            </label>
                          </div>
                          <div className="inputs flex cursor-not-allowed items-center gap-4 text-gray-500">
                            <input
                              type="radio"
                              name="gender"
                              checked={user?.gender === 'female'}
                              id="female"
                              className="h-4 w-4 cursor-not-allowed"
                              disabled
                            />
                            <label
                              htmlFor="female"
                              className="cursor-not-allowed"
                            >
                              Female
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- gender --> */}
                  </div>
                  {/* <!-- personal info --> */}
                  <div className=" relative z-10 rounded-lg p-8">
                    {/* Curved outward shadow effect */}
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    {/* End of curved outward shadow effect */}
                    {/* <!-- email address info --> */}
                    <div className="relative z-10 flex flex-col items-start gap-5">
                      <span className="text-lg font-medium">
                        Email Address
                        <Link
                          to="/account/update"
                          className="ml-3 cursor-pointer rounded-md px-2  py-1 text-sm font-medium text-white sm:ml-8 "
                          style={{
                            backgroundColor: '#00c9b7',
                            boxShadow: '0px 0px 10px 0px #00c9b7',
                          }}
                        >
                          Edit
                        </Link>
                        <Link
                          to="/password/update"
                          className="ml-3 rounded-md px-2  py-1 text-sm font-medium text-white sm:ml-8 "
                          style={{
                            backgroundColor: '#00c9b7',
                            boxShadow: '0px 0px 10px 0px #00c9b7',
                          }}
                        >
                          Change Password
                        </Link>
                      </span>

                      <div className="flex items-center gap-3">
                        <div className="flex cursor-not-allowed flex-col gap-0.5 rounded-sm border bg-gray-100 px-3 py-1.5 focus-within:border-primary-blue sm:w-64">
                          <label className="text-xs text-gray-500">
                            Email Address
                          </label>
                          <input
                            type="email"
                            value={user?.email}
                            className="cursor-not-allowed border-none text-sm text-gray-500 outline-none"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- email address info --> */}

                  {/* <!-- mobile number info --> */}
                  <div className=" relative z-10 rounded-lg p-8">
                    {/* Curved outward shadow effect */}
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    {/* End of curved outward shadow effect */}
                    <div className="relative z-10 flex flex-col items-start gap-5">
                      <span className="text-lg font-medium">
                        Mobile Number
                        <span
                          className="ml-3 cursor-pointer rounded-md px-2  py-1 text-sm font-medium text-white sm:ml-8 "
                          style={{
                            backgroundColor: '#00c9b7',
                            boxShadow: '0px 0px 10px 0px #00c9b7',
                          }}
                          id="mobEditBtn"
                        >
                          Edit
                        </span>
                      </span>

                      <div className="flex items-center gap-3">
                        <div className="flex cursor-not-allowed flex-col gap-0.5 rounded-sm border bg-gray-100 px-3 py-1.5 focus-within:border-primary-blue sm:w-64">
                          <label className="text-xs text-gray-500">
                            Mobile Number
                          </label>
                          <input
                            type="tel"
                            value="+919876543210"
                            className="cursor-not-allowed border-none text-sm text-gray-500 outline-none"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- mobile number info --> */}

                  {/* <!-- faqs --> */}
                  <div className=" relative z-10 p-8">
                    {/* Curved outward shadow effect */}
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    {/* End of curved outward shadow effect */}
                    <div className="relative z-10 mt-4 flex flex-col gap-4">
                      <span className="mb-2 text-lg font-medium">FAQS</span>
                      <h4 className="text-sm font-medium">
                        What happens when I update my email address (or mobile
                        number)?
                      </h4>
                      <p className="text-sm">
                        Your login email id (or mobile number) changes,
                        likewise. You'll receive all your account related
                        communication on your updated email address (or mobile
                        number).
                      </p>

                      <h4 className="text-sm font-medium">
                        When will my Kibanda4Devs account be updated with the
                        new email address (or mobile number)?
                      </h4>
                      <p className="text-sm">
                        It happens as soon as you confirm the verification code
                        sent to your email (or mobile) and save the changes.
                      </p>

                      <h4 className="text-sm font-medium">
                        What happens to my existing Kibanda4Devs account when I
                        update my email address (or mobile number)?
                      </h4>
                      <p className="text-sm">
                        Updating your email address (or mobile number) doesn't
                        invalidate your account. Your account remains fully
                        functional. You'll continue seeing your Order history,
                        saved information and personal details.
                      </p>

                      <h4 className="text-sm font-medium">
                        Does my Seller account get affected when I update my
                        email address?
                      </h4>
                      <p className="text-sm">
                        Kibanda4Devs has a 'single sign-on' policy. Any changes
                        will reflect in your Seller account also.
                      </p>
                    </div>
                  </div>
                  {/* <!-- faqs --> */}

                  {/* <!-- deactivate account --> */}
                  <div className=" relative z-10 p-8">
                    {/* Curved outward shadow effect */}
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    {/* End of curved outward shadow effect */}
                    <Link
                      className="relative z-10 rounded-md px-2 py-1 text-sm font-medium text-white"
                      style={{
                        backgroundColor: '#FF0000',
                        boxShadow: '0px 0px 10px 0px #FF0000',
                      }}
                      to="/"
                    >
                      Deactivate Account
                    </Link>
                  </div>
                  {/* <!-- deactivate account --> */}
                </div>
                {/* <!-- edit info container --> */}

                <img
                  draggable="false"
                  className="w-full object-contain"
                  src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/myProfileFooter_4e9fe2.png"
                  alt="footer"
                />
              </div>
              {/* <!-- details column --> */}
            </div>
          </main>
        </>
      )}
    </>
  )
}

export default Account
