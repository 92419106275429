import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import Searchbar from './Searchbar'
import logo from '../../../assets/images/k4dtranslogo.png'
import PrimaryDropDownMenu from './PrimaryDropDownMenu'
import SecondaryDropDownMenu from './SecondaryDropDownMenu'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Header = () => {
  const { isAuthenticated, user, token } = useSelector((state) => state.user)
  const { cartItems } = useSelector((state) => state.cart)

  const [togglePrimaryDropDown, setTogglePrimaryDropDown] = useState(false)
  const [toggleSecondaryDropDown, setToggleSecondaryDropDown] = useState(false)

  return (
    <header className="fixed top-0 z-20 w-full bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 py-2.5">
      {/* Navbar container */}
      <div className="relative m-auto flex w-full items-center justify-between px-1 sm:w-9/12 sm:px-4">
        {/* Logo & search container */}
        <div className="flex flex-1 items-center">
          <Link className="mr-1 h-7 sm:mr-4 lg:block hidden" to="/">
            <img
              draggable="false"
              className="h-full w-full rounded-md object-contain"
              style={{ backgroundColor: '#DA7806' }}
              src={logo}
              alt="Kibanda4Devs Logo"
            />
          </Link>

          <Searchbar />
        </div>
        {/* Logo & search container */}

        {/* Right navs */}
        <div className="relative ml-1 flex items-center justify-between gap-0.5 sm:ml-0 sm:gap-7">
          {isAuthenticated === false ? (
            <Link
              to="/login"
              className="cursor-pointer rounded-md border px-2 py-2 font-medium text-white sm:px-9"
              style={{ backgroundColor: '#FFA500' }}
            >
              Login
            </Link>
          ) : (
            <span
              className="userDropDown flex cursor-pointer items-center gap-1 rounded-md px-2 py-2 font-medium text-white"
              style={{ backgroundColor: '#FFA500' }}
              onClick={() => setTogglePrimaryDropDown(!togglePrimaryDropDown)}
            >
              {user?.name && user?.name?.split(' ', 1)}

              <span>
                {togglePrimaryDropDown ? (
                  <ExpandLessIcon sx={{ fontSize: '16px' }} />
                ) : (
                  <ExpandMoreIcon sx={{ fontSize: '16px' }} />
                )}
              </span>
            </span>
          )}

          {togglePrimaryDropDown && (
            <PrimaryDropDownMenu
              setTogglePrimaryDropDown={setTogglePrimaryDropDown}
              user={user}
              token={token} // Pass the token here if needed in PrimaryDropDownMenu
            />
          )}

          <span
            className="moreDropDown hidden cursor-pointer items-center gap-1 rounded-md px-2 py-2 font-medium text-white sm:flex"
            style={{ backgroundColor: '#FFA500' }}
            onClick={() => setToggleSecondaryDropDown(!toggleSecondaryDropDown)}
          >
            More
            <span>
              {toggleSecondaryDropDown ? (
                <ExpandLessIcon sx={{ fontSize: '16px' }} />
              ) : (
                <ExpandMoreIcon sx={{ fontSize: '16px' }} />
              )}
            </span>
          </span>

          {toggleSecondaryDropDown && <SecondaryDropDownMenu />}

          <Link
            to="/cart"
            className="relative flex items-center gap-2 font-medium text-white"
          >
            <span>
              <ShoppingCartIcon />
            </span>
            {cartItems?.length > 0 && (
              <div className="absolute -top-2 left-3 flex h-5 w-5 items-center justify-center rounded-full border bg-red-500 p-2 text-xs">
                {cartItems?.length}
              </div>
            )}
            Cart
          </Link>
        </div>
        {/* Right navs */}
      </div>
      {/* Navbar container */}
    </header>
  )
}

export default Header
