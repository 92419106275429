import notFound from '../../src/assets/images/404-not-found.svg'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className="mt-16 flex flex-col items-center justify-center gap-4">
      <img
        draggable="false"
        className="h-full sm:w-1/3"
        src={notFound}
        alt="Page Not Found"
      />
      <Link
        to="/"
        className="rounded-sm bg-primary-blue px-4 py-2 uppercase text-white shadow hover:shadow-lg"
      >
        Back To Home
      </Link>
    </div>
  )
}

export default NotFound
