import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { submitContactForm } from '../../../actions/formsAction'

const ContactForm = () => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    location: '',
    message: '',
  })

  const [submittedName, setSubmittedName] = useState('')

  const { loading, success, error } = useSelector((state) => state.contactForm)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmittedName(formData.name)
    dispatch(submitContactForm(formData))
  }

  useEffect(() => {
    if (success) {
      setFormData({
        name: '',
        email: '',
        phone: '',
        location: '',
        message: '',
      })
    }
  }, [success])

  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-200">
      <div className="container relative z-10 mx-auto">
        <div className="relative z-10 mx-auto rounded-lg p-8">
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="row relative z-10">
            <div className="col-lg-8">
              <div className="contact-box p-4">
                <h4 className="title">Contact Us</h4>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mt-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-3">
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Location"
                          name="location"
                          value={formData.location}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mt-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button
                        type="submit"
                        className="btn btn-danger-gradiant mb-3 mt-3 border-0 bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-3 py-2 text-white"
                        disabled={loading}
                      >
                        {loading ? 'Submitting...' : 'SUBMIT NOW'}
                      </button>
                      {success && (
                        <div className="alert alert-success">
                          Thank you, {submittedName}! Your message has been
                          received. We will assist you shortly.
                        </div>
                      )}
                      {error && (
                        <div className="alert alert-danger mt-4">
                          An error occurred. Please try again later.
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              className="col-lg-4 bg-image"
              style={{
                backgroundImage:
                  'url(https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/1.jpg)',
              }}
            >
              <div className="detail-box p-4">
                <h5 className="font-weight-light mb-3 text-white">ADDRESS</h5>
                <p className="op-7 text-white">
                  Kileleshwa, Nairobi
                  <br />
                  Tabere Crescent Road
                </p>
                <h5 className="font-weight-light mb-3 mt-4 text-white">
                  CALL US
                </h5>
                <p className="op-7 text-white">
                  +254 113116933
                  <br />
                  +25499840206
                </p>
                <div className="round-social light">
                  <a
                    href="#"
                    className="text-decoration-none rounded-circle ml-0 border border-white text-white"
                  >
                    <i className="icon-social-facebook"></i>
                  </a>
                  <a
                    href="#"
                    className="text-decoration-none rounded-circle border border-white text-white"
                  >
                    <i className="icon-social-twitter"></i>
                  </a>
                  <a
                    href="#"
                    className="text-decoration-none rounded-circle border border-white text-white"
                  >
                    <i className="icon-social-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
