// import { createStore, combineReducers, applyMiddleware } from 'redux'
// import thunk from 'redux-thunk'
// import { composeWithDevTools } from 'redux-devtools-extension'
// import { persistReducer, persistStore } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
// import {
//   forgotPasswordReducer,
//   profileReducer,
//   userReducer,
//   allUsersReducer,
//   userDetailsReducer,
// } from './reducers/userReducer'
// import {
//   newProductReducer,
//   newReviewReducer,
//   productDetailsReducer,
//   productReducer,
//   productsReducer,
//   productReviewsReducer,
//   reviewReducer,
// } from './reducers/productReducer'
// import { cartReducer } from './reducers/cartReducer'
// import { saveForLaterReducer } from './reducers/saveForLaterReducer'
// import {
//   allOrdersReducer,
//   myOrdersReducer,
//   newOrderReducer,
//   orderDetailsReducer,
//   orderReducer,
//   paymentStatusReducer,
// } from './reducers/orderReducer'
// import { wishlistReducer } from './reducers/wishlistReducer'
// import cartsReducer from './reducers/cartsReducer'

// // Define your root reducer
// const rootReducer = combineReducers({
//   user: userReducer,
//   profile: profileReducer,
//   forgotPassword: forgotPasswordReducer,
//   products: productsReducer,
//   productDetails: productDetailsReducer,
//   newReview: newReviewReducer,
//   cart: cartReducer,
//   saveForLater: saveForLaterReducer,
//   newOrder: newOrderReducer,
//   myOrders: myOrdersReducer,
//   paymentStatus: paymentStatusReducer,
//   orderDetails: orderDetailsReducer,
//   allOrders: allOrdersReducer,
//   order: orderReducer,
//   newProduct: newProductReducer,
//   product: productReducer,
//   users: allUsersReducer,
//   userDetails: userDetailsReducer,
//   reviews: productReviewsReducer,
//   review: reviewReducer,
//   wishlist: wishlistReducer,
//   carts: cartsReducer,
// })

// // Configure redux-persist
// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['user', 'cart', 'saveForLater', 'wishlist'], // Specify which reducers to persist
// }

// // Wrap the root reducer with persistReducer
// const persistedReducer = persistReducer(persistConfig, rootReducer)

// // Create the Redux store
// const store = createStore(
//   persistedReducer,
//   composeWithDevTools(applyMiddleware(thunk))
// )

// // Create a persistor object
// const persistor = persistStore(store)

// export { store, persistor }

//src/store.js

import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  forgotPasswordReducer,
  profileReducer,
  userReducer,
  allUsersReducer,
  userDetailsReducer,
} from './reducers/userReducer'
import {
  newProductReducer,
  newReviewReducer,
  productDetailsReducer,
  productReducer,
  productsReducer,
  productReviewsReducer,
  reviewReducer,
} from './reducers/productReducer'
import { cartReducer } from './reducers/cartReducer'
import { saveForLaterReducer } from './reducers/saveForLaterReducer'
import {
  allOrdersReducer,
  myOrdersReducer,
  newOrderReducer,
  orderDetailsReducer,
  orderReducer,
  paymentStatusReducer,
} from './reducers/orderReducer'
import { wishlistReducer } from './reducers/wishlistReducer'

import cartsReducer from './reducers/cartsReducer'
import {
  advertFormReducer,
  affiliateFormReducer,
  contactFormReducer,
  registrationFormReducer,
  wholesaleFormReducer,
} from './reducers/formsReducers'

const reducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  forgotPassword: forgotPasswordReducer,
  products: productsReducer,
  productDetails: productDetailsReducer,
  newReview: newReviewReducer,
  cart: cartReducer,
  saveForLater: saveForLaterReducer,
  newOrder: newOrderReducer,
  myOrders: myOrdersReducer,
  paymentStatus: paymentStatusReducer,
  orderDetails: orderDetailsReducer,
  allOrders: allOrdersReducer,
  order: orderReducer,
  newProduct: newProductReducer,
  product: productReducer,
  users: allUsersReducer,
  userDetails: userDetailsReducer,
  reviews: productReviewsReducer,
  review: reviewReducer,
  wishlist: wishlistReducer,
  carts: cartsReducer,
  contactForm: contactFormReducer,
  businessregistrationForm: registrationFormReducer,
  advertRegistrationForm: advertFormReducer,
  affiliateRegistrationForm: affiliateFormReducer,
  wholesaleRegistrationForm: wholesaleFormReducer,
})

let initialState = {
  cart: {
    cartItems: localStorage.getItem('cartItems')
      ? JSON.parse(localStorage.getItem('cartItems'))
      : [],
    shippingInfo: localStorage.getItem('shippingInfo')
      ? JSON.parse(localStorage.getItem('shippingInfo'))
      : {},
  },
  saveForLater: {
    saveForLaterItems: localStorage.getItem('saveForLaterItems')
      ? JSON.parse(localStorage.getItem('saveForLaterItems'))
      : [],
  },
  wishlist: {
    wishlistItems: localStorage.getItem('wishlistItems')
      ? JSON.parse(localStorage.getItem('wishlistItems'))
      : [],
  },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
