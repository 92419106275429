import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom' // Import useNavigate for redirection

const PrivacyModal = ({ onClose, onAgree }) => {
  const [isChecked, setChecked] = useState(false)
  const navigate = useNavigate() // Initialize useNavigate hook

  const handleAgree = () => {
    if (isChecked) {
      onAgree()
      navigate('/') // Redirect to home page on agreement
    }
  }

  const handleClose = () => {
    onClose()
    navigate('/') // Redirect to home page on close
  }

  return (
    <div className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-opacity-80 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 py-2.5">
      <div className=" relative z-10 mx-auto rounded-lg p-8">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}
        <div className=" relative z-10 max-w-screen-lg overflow-hidden p-8">
          <div className="max-h-[500px] overflow-y-auto">
            <h2 className="mb-8 text-center text-2xl font-bold">
              Privacy Policy
              <span
                className=" relative left-1/2 mt-1 block h-1 w-20 -translate-x-1/2 transform rounded-md"
                style={{ backgroundColor: '#DB7A03' }}
              ></span>
            </h2>
            <ul>
              <li>
                <p className="mb-4 mt-4">
                  This Privacy Policy applies to Users who are provided with
                  various products and services through the kibanda4devs
                  website, apps, and new forms emerging with technological
                  development by Kibandalabs.
                </p>
              </li>
              <ol>
                <li>1. INTRODUCTION</li>
                <li>2. DETAILS OF DATA PROCESSING</li>
                <li>3. DISCLOSURE OR SHARING OF PERSONAL INFORMATION</li>
                <li>4. RETENTION</li>
                <li>5. COOKIES</li>
                <li>6. YOUR RIGHTS</li>
                <li>7. USERS FROM EUROPEAN UNION OR THE UNITED KINGDOM</li>
                <li>8. INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION</li>
                <li>9. MINORS</li>
                <li>10. SECURITY MEASURES</li>
                <li>11. CHANGES TO THIS PRIVACY POLICY</li>
                <li>12. HOW TO CONTACT US</li>
              </ol>

              <ol className="mb-2 mt-4" style={{ textAlign: 'center' }}>
                <li>1. INTRODUCTION</li>
              </ol>

              <p>
                We at Kibandalabs and its sites including kibanda4devs.com
                (collectively “Kibandalabs”, the “Platform” or “we”) recognize
                the importance of privacy and confidentiality of personal
                information. Kibandalabs is a platform which connects and
                facilitates sales and purchases of electronic products and/or
                services between suppliers (“Sellers”) and buyers (“Buyers”).
                Collectively, the Sellers and Buyers shall be referred to as the
                “Users”.
              </p>

              <p>
                The controllers of your personal information are the legal
                entities set out in section 12. “HOW TO CONTACT US”.
              </p>

              <p>
                This Kibandalabs Privacy Policy (“Privacy Policy”) sets out how
                we collect, process and disclose information in connection with
                the operation of the Kibanda4devs Platform for visitors (i.e.,
                non-registered Platform Users) and the Users (“you”) including
                personal information about the visitors and, representatives and
                employees of the Users. This Privacy Policy also applies to your
                use of the Platform via a mobile device, either through mobile
                applications or mobile-optimized websites.
              </p>

              <ol className="mb-2 mt-4" style={{ textAlign: 'center' }}>
                <li>2. DETAILS OF DATA PROCESSING</li>
              </ol>

              <p>
                Below you will find an overview of what kind of personal
                information we process about you and for which purposes we
                collect this personal information.
              </p>

              <table style={{ borderCollapse: 'collapse' }}>
                <tr>
                  <th style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Purpose
                  </th>
                  <th style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Data Categories
                  </th>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Account administration. We use your information to verify
                    your eligibility to register as a Platform user in
                    accordance with our Terms of Use; and set up/administer user
                    accounts, such as providing log-in IDs and developing a
                    Platform profile
                  </td>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Contact information, Account information, Business
                    information, and Social media account information
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Purchase and delivery of products and services. We use your
                    information to take and handle orders, deliver products and
                    services on the Platform, process payment, provide logistics
                    and warehousing services via the Platform, facilitate
                    customs clearance declarations/applications via the
                    Platform, and provide Users with customer service support,
                    such as responding to queries, feedback, and managing claims
                    and/or disputes
                  </td>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Contact information, Account information, Business
                    information, Transaction information, Payment information,
                    Communication information, User-generated content, Device
                    information, Online activity information, and Location
                    information
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Communicate with you. We use your personal information to
                    facilitate communication between Users and us, and between
                    the Buyers and Sellers via the Platform, for the purpose of
                    processing transactions and settlement between the Buyers
                    and Sellers made over the Platform, assessing fund
                    withdrawal requests from Sellers, and improving the services
                    and products of the Platform
                  </td>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Contact information, Account information, Transaction
                    information, Payment information, Device information,
                    Location information, Communication information, and Online
                    activity information
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Provide voice, image and camera services. When you use
                    services that require access to contents stored in and/or
                    functions on your devices (including but not limited to
                    contacts in address book, photos, videos, microphone, and/or
                    camera), we will use your photos, videos, contact
                    information in your address book, and other personal
                    information to respond to your requests, provide the
                    requested services to you, and improve our services
                  </td>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Contact information, User-generated content, Communication
                    information, and Device information
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Recommendations and personalization. We use your personal
                    information to recommend features, products, and services
                    that might be of interest to you, identify your preferences,
                    and personalize your experience with the Platform. If you
                    are a Buyer, we will share details of the last viewed
                    products on the Platform to enable Sellers, their respective
                    affiliates and/or their third-party services provider to
                    contact you with details of products and/or services that
                    might interest you. You can opt not to share this
                    information with Sellers via the Buyer Privacy Settings
                  </td>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Contact information, Business information, Transaction
                    information, Online activity information, Marketing and
                    advertising information, and Location information
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Marketing and advertising. We use your personal information
                    to send and serve you tailored marketing and advertising
                    that we believe will be of interest to you based on the ways
                    in which you use the Platform, your browsing records, and
                    order history. Cookies or other similar technologies may be
                    used to provide you with advertising based upon your
                    browsing activities and interests. Please see section
                    5.“COOKIES” below for more information about how we use
                    cookies
                  </td>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Contact information, Business information, Transaction
                    information, Online activity information, Marketing and
                    advertising information, and Location information
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Research and development. We use your personal information
                    to perform research or statistical analysis in order to
                    improve the content and layout of the Platform, and use your
                    personal information to improve the product offerings and
                    services on Kibanda4devs, including, for example, using
                    anonymized data for machine learning purposes
                  </td>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Contact information, Account information, Business
                    information, Device information, Location information,
                    Online activity information, User-generated content, and
                    Social media account information
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Fraud prevention and credit risks. We use personal
                    information to prevent and detect fraud and abuse in order
                    to protect the security of the Platform, our customers and
                    others. We may also use scoring methods to assess and manage
                    credit risks
                  </td>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Contact information, Account information, Business
                    information, Transaction information, Payment information,
                    Communication information, User-generated content, Device
                    information, and Location information
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Comply with legal obligations. In certain cases, we collect
                    and use your personal information to comply with laws. For
                    instance, we use your information to verify your identity
                    and conducting security checks, KYC (know-your-customer) and
                    anti-money laundering due diligence in order to set up user
                    accounts
                  </td>
                  <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                    Contact information, Account information, Business
                    information, Communication information, Transaction
                    information, Payment information, and Online activity
                    information
                  </td>
                </tr>
              </table>

              <div>
                <p className="mb-4 mt-4">
                  The categories of personal information described in the table
                  above are detailed below:
                </p>
                <p
                  className="mb-2 mt-4"
                  style={{ textDecoration: 'underline', color: '#DB7A03' }}
                >
                  <strong>Information you provide to us:</strong>
                </p>
                <ul>
                  <li>
                    Contact information, such as contact/legal representative
                    name, address, phone number of the company/the contact,
                    email address of the company and the contact/legal
                    representative, job title and department, ID information,
                    phone bills of the contact/legal representative.
                  </li>
                  <li>
                    Account information, such as your username and password,
                    gender and your account settings.
                  </li>
                  <li>
                    Business information, such as identity information, tax
                    registration and/or related information about your business,
                    such as your company name, registration number and date,
                    company official website, business type and industry,
                    corporate registration details and information about your
                    business license, proof of business operation including
                    lease agreement, property deed, utility bills and etc.
                  </li>
                  <li>
                    Transaction information, such as transaction statements,
                    billing/delivery address of the recipient of your order,
                    payments and orders placed, and other details of the
                    products you have supplied or purchased through the
                    Platform.
                  </li>
                  <li>
                    Payment information, such as bank account numbers,
                    credit/debit card numbers, credit/debit card holders,
                    expiration dates and security code and tracking information
                    from cheques or money orders, bank statement and account
                    opening page.
                  </li>
                  <li>
                    In order to facilitate your use of the services available on
                    the Platform (including on mobile-optimized websites and/or
                    mobile applications), we may request for access to, and you
                    may provide us with, contents stored in your devices
                    including but not limited to contacts in address book,
                    photos, videos and/or other files. If you are using the
                    communication tools available on the Platform (particularly
                    in case of voice communication), we may require access to
                    your camera and/or microphone.
                  </li>
                  <li>
                    From time to time, we may also collect information about the
                    existing and prospective Users, during or in connection with
                    trade shows, industry events and/or other functions,
                    including representative contact names, addresses, phone
                    numbers, fax numbers and email addresses.
                  </li>
                </ul>
              </div>

              <div>
                <p
                  className="mb-2 mt-4"
                  style={{ textDecoration: 'underline', color: '#DB7A03' }}
                >
                  <strong>Information that we collect automatically:</strong>
                </p>
                <ul>
                  <li>
                    Device information, such as the type of device you use,
                    unique device identification numbers, IP addresses, browser
                    type, browsing patterns, advertising identifiers and other
                    types of identifiers.
                  </li>
                  <li>
                    Online activity information, such as page views and
                    searches, information about content that has been viewed
                    and/or downloaded, information about your activity on a page
                    or screen, access times and duration of access, and other
                    details of how you have interacted with our Platform.
                  </li>
                  <li>
                    Location information, such as your general geographic
                    location, and, with your permission, the approximate
                    location data of your mobile device. Please note that for
                    most mobile devices, you can manage or disable the use of
                    precise location services for all applications in the
                    settings menu of your mobile device.
                  </li>
                  <li>
                    Communication information, such as posts and/or contents
                    uploaded on the Platform, any comments on content you have
                    uploaded to the Platform or on other Users’ content on the
                    Platform.
                  </li>
                  <li>
                    Marketing and advertising information, such as your
                    interests based on your use of the Platform and your
                    purchases, responses to surveys, promotions you participate
                    in, preferences in receiving marketing content from us,
                    communication preferences, preferences for particular
                    products or services and your subscription data.
                  </li>
                  <li>
                    User-generated content, such as profile photos, videos or
                    audio recordings that you upload to the Platform as part of
                    product reviews or by sharing content in socializing within
                    the Platform.
                  </li>
                  <li>
                    Other information, such as statistical information about the
                    Platform and visitors to the Platform including, browser
                    software, operating system, software and hardware
                    attributes, number of sessions and unique visitors. Some of
                    this information may be collected using cookies and similar
                    tracking technology, as explained further under our Cookies
                    Policy.
                  </li>
                </ul>
              </div>

              <div>
                <p
                  className="mb-2 mt-4"
                  style={{ textDecoration: 'underline', color: '#DB7A03' }}
                >
                  <strong>
                    Information that we receive from third parties:
                  </strong>
                </p>
                <ul>
                  <li>
                    Social media account information, such as social media
                    username, profile picture and your login details where you
                    choose to log into the Platform using social media accounts
                    or when you interact with us through an external service or
                    application.
                  </li>
                  <li>
                    Communication information, such as feedback and other
                    communications with us (including when you interact with our
                    subcontracted customer support agents), disputes/complaints
                    from Sellers/Buyers on the Platform, copy of your
                    correspondence if you contact us such as via our customer
                    services department chat service, telephone or email
                    (including in the case wherein those functions involved
                    integration of services provided by third-party service
                    providers), chat information between you and other users via
                    instant messaging services.
                  </li>
                  <li>
                    Third parties that are engaged by us to provide verification
                    services, such as conducting applicable anti-money
                    laundering, fraud and “Know Your Customer” checks on the
                    Buyers and Sellers, conducting due diligence and
                    verification on Users. This includes your name, email
                    address, company details, and contact information.
                  </li>
                  <li>
                    Information about your interactions with products and
                    services offered by our group companies and affiliates.
                  </li>
                  <li>
                    If you choose to participate in reviewing or rating products
                    and/or services on Kibanda4devs, we may receive personal
                    information about you from third parties that work with us
                    to conduct reviews into our services and/or rate us.
                  </li>
                </ul>
              </div>

              <ol className="mb-2 mt-4" style={{ textAlign: 'center' }}>
                <li>3. DISCLOSURE OR SHARING OF PERSONAL INFORMATION</li>
              </ol>

              <p>
                We may disclose (or provide access to) personal information to
                the following categories of recipients:
              </p>
              <ul>
                <li>
                  <strong>Other Platform Users:</strong> where necessary in
                  connection with the facilitation of purchases and
                  communications made between you and the other Users.
                </li>
                <li>
                  <strong>
                    Our group companies and affiliates and/or their designated
                    service providers:
                  </strong>{' '}
                  that work with us to provide processing services such as
                  software, tools, systems, and messaging services for purposes
                  described in this Privacy Policy.
                </li>
                <li>
                  <strong>
                    Third party business partners and service providers:
                  </strong>{' '}
                  engaged by us or working with us to assist us to provide
                  services to you or who otherwise process personal information
                  for purposes described in this Privacy Policy or notified to
                  you when we collect your personal information. Categories of
                  these partners or service providers include:
                  <ul>
                    <li>
                      our business partners that offer supplementary services to
                      those provided by us, such as partners that resell our
                      products and services, to the extent you consent to such
                      sharing (where required by applicable law);
                    </li>
                    <li>
                      marketing and advertising platforms, such as Google,
                      Twitter, Facebook, and Instagram, and providers of
                      analytics services relating to Users’ behavior, in order
                      to tailor the content you see when visiting our Platform.
                      These platforms may combine information they collect on
                      our Platform with data on their platforms and data they
                      collect from other websites or through other sources in
                      order to conduct targeted advertising. Some of this
                      information may be collected using cookies and similar
                      tracking technology, as explained further under the
                      section 5. “COOKIES” below. The processing activities of
                      these third-party marketing platforms are governed by
                      their own privacy policies, not this Privacy Policy;
                    </li>
                    <li>
                      due diligence and verification service providers, who
                      collect and process personal information to perform due
                      diligence and business verification services;
                    </li>
                    <li>
                      payment service providers who collect and process personal
                      information to assist with settling the payments for
                      transactions or process withdrawal requests for Sellers.
                      The activities of payment service providers may be
                      governed by their own privacy policies, not this Privacy
                      Policy;
                    </li>
                    <li>
                      credit risk assessment providers to conduct risk
                      assessment on Sellers to determine whether a Seller can be
                      permitted to make a fund withdrawal;
                    </li>
                    <li>
                      logistics partners for providing delivery services for
                      Buyers, including return and exchange of products, and
                      warehousing services for Sellers;
                    </li>
                    <li>custom agents for customs clearances purposes;</li>
                    <li>
                      cloud computing service providers to provide cloud storage
                      services;
                    </li>
                    <li>
                      instant messaging service providers for enabling
                      communications between the Buyers and Sellers via the
                      Platform;
                    </li>
                    <li>
                      software development kit (“SDK”) service providers to
                      provide SDK services;
                    </li>
                    <li>
                      customer service providers to provide after-sale services;
                    </li>
                    <li>
                      risk control service providers to assess the security of
                      Users’ accounts and transaction risks;
                    </li>
                    <li>
                      third party rating / reviewing service providers to carry
                      out reviews of our services with Users who participate in
                      reviewing or rating Kibandalabs’s products and/or
                      services;
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    External professional advisers, law enforcement agencies,
                    insurers, government and regulators:
                  </strong>{' '}
                  and other organizations where it is necessary to comply with
                  applicable laws or to exercise, establish or defend our legal
                  rights or protect your vital interests or those of any other
                  person, we may also disclose and transfer your personal
                  information or as otherwise required or permitted by
                  applicable laws.
                </li>
                <li>
                  <strong>
                    Actual or potential business acquirers (and its agents and
                    advisers):
                  </strong>{' '}
                  in connection with any actual or proposed purchase, merger, or
                  acquisition of any part of our business, provided that we
                  inform the acquirer that it must use your personal information
                  only for the purposes disclosed in this Privacy Policy.
                </li>
                <li>
                  <strong>We may provide anonymized data:</strong> to third
                  parties, but when we do so, the information we share is in a
                  de-identified format that does not personally identify you.
                </li>
              </ul>
              <p>
                We have established relationships with third parties and
                websites to offer you products and services which we do not
                offer. We offer you access to these other parties and their
                websites either through the use of hyperlinks to these sites
                from our Platform or through offering co-branded sites in which
                both we and other parties share the same uniform resource
                locator (URL), domain name, or pages within a domain name on the
                Internet. In some cases, you may be required to submit personal
                information to register or apply for products or services
                provided by such third parties or co-branded partners. This
                Privacy Policy does not apply to these third-party sites or
                co-branded sites. The privacy policies of those other parties
                may differ from ours, and we have no control over the
                information that you submit to those third parties. You should
                read the relevant privacy policy for those third-party sites and
                co-branded sites before responding to any offers, products, or
                services advertised by those parties.
              </p>

              <ol className="mb-2 mt-4" style={{ textAlign: 'center' }}>
                <li>4. RETENTION</li>
              </ol>
              <p>
                We retain your personal information as long as we have an
                ongoing legitimate business need to do so, for example to
                provide services or products to you, or as required or permitted
                by applicable laws, such as tax and accounting laws.
              </p>

              <ol className="mb-2 mt-4" style={{ textAlign: 'center' }}>
                <li>5. COOKIES</li>
              </ol>
              <p>
                To enable our systems to recognize your browser or device and to
                provide and improve our services, we or our authorized service
                providers may use cookies, beacons and/or other similar
                technologies (collectively, “Cookies”). For more information
                about the types of Cookies we use, why, and how you can control
                them, please read our{' '}
                <a
                  href="https://www.kibanda4devs.com/pages/cookies"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: 'orange' }}
                >
                  Cookies Policy.
                </a>{' '}
              </p>

              <ol className="mb-2 mt-4" style={{ textAlign: 'center' }}>
                <li>6. YOUR RIGHTS</li>
              </ol>

              <p>
                As a user of the Platform, you may have the following data
                protection rights, which you can exercise pursuant to applicable
                laws at any time by contacting us using the contact details
                provided under section 12. “HOW TO CONTACT US” below:
              </p>
              <ul>
                <li>
                  The right to access, correct, update or request deletion of
                  your personal information.
                </li>
                <li>
                  The right to object to processing of your personal information
                  when it is based on our legitimate interests, and separately
                  the right to object to direct marketing.
                </li>
                <li>
                  The right to ask us, in some situations, to restrict
                  processing of your personal information or request portability
                  of your personal information.
                </li>
                <li>
                  The right to opt-out of marketing communications we send you
                  at any time. You can exercise this right by clicking on the
                  “unsubscribe” or “opt-out” link in the marketing e-mails we
                  send you. To opt-out of other forms of marketing (such as
                  postal marketing or telemarketing), then please contact us
                  using the contact details provided under section 12. “HOW TO
                  CONTACT US” below.
                </li>
                <li>
                  If we have collected and processed your personal information
                  with your consent, then you have the right to withdraw your
                  consent at any time. Withdrawing your consent will not affect
                  the lawfulness of any processing we conducted prior to your
                  withdrawal, nor will it affect processing of your personal
                  information conducted in reliance on lawful processing grounds
                  other than consent.
                </li>
                <li>
                  The right to complain to a data protection authority about our
                  collection and use of your personal information. For more
                  information, please contact your local data protection
                  authority.
                </li>
                <li>
                  We respond to all requests we receive from individuals wishing
                  to exercise their data protection rights in accordance with
                  applicable data protection laws. If you live in a jurisdiction
                  that provides for a right to appeal a denial of your request
                  to exercise such rights, you may submit your appeal using the
                  contact details provided under section 12. “HOW TO CONTACT US”
                  below.
                </li>
                <li>
                  If you are aware of changes or inaccuracies in your
                  information, you should inform us of such changes so that our
                  records may be updated or corrected.
                </li>
              </ul>
              <ol className="mb-2 mt-4" style={{ textAlign: 'center' }}>
                <li>7. USERS FROM THE EUROPEAN UNION</li>
              </ol>

              <p>
                If you are from the European Union or the United Kingdom, below
                you will find an overview of what kind of personal information
                we process about you, for which purposes, on which legal basis
                and where we collect this personal information.
              </p>
              <p>
                If you need us to provide you with our services or fulfill a
                contract with you, or because we are required to do so by law,
                but do not provide us with your personal information, we may be
                unable to provide our services or fulfill our contractual
                obligations to you.
              </p>

              <table
                style={{
                  borderCollapse: 'collapse',
                  width: '100%',
                  marginTop: '10px',
                }}
              >
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Purpose
                    </th>
                    <th style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Data Categories
                    </th>
                    <th style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Legal basis (where applicable)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Account administration. We use your information to verify
                      your eligibility to register as a Platform user in
                      accordance with our Terms of Use; and set up/administer
                      user accounts, such as providing log-in IDs and developing
                      a Platform profile
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Contact information, Account information, Business
                      information, Social media account information
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      <p>
                        Art. 6 (1) (b) GDPR - establishment, performance, or
                        termination of the contract with you
                      </p>
                      <p>
                        Art. 6 (1) (f) GDPR - our legitimate interest in the
                        security, stability, and usability of our Platform
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Purchase and delivery of products and services. We use
                      your information to take and handle orders, deliver
                      products and services on the Platform, process payment,
                      provide logistics and warehousing services via the
                      Platform, facilitate customs clearance
                      declarations/applications via the Platform, and provide
                      Users with customer service support, such as responding to
                      queries, feedback, and managing claims and/or disputes
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Contact information, Account information, Business
                      information, Transaction information, Payment information,
                      Communication information, User-generated content, Device
                      information, Online activity information, Location
                      information
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      <p>
                        Art. 6 (1) (b) GDPR - establishment, performance, or
                        termination of the contract with you
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Communicate with you. We use your personal information to
                      facilitate communication between Users and us, and between
                      the Buyers and Sellers via the Platform, for the purpose
                      of processing transactions and settlement between the
                      Buyers and Sellers made over the Platform, assessing fund
                      withdrawal requests from Sellers, and improving the
                      services and products of the Platform
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Contact information, Account information, Transaction
                      information, Payment information, Device information,
                      Location information, Communication information, and
                      Online activity information
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      <p>
                        Art. 6 (1) (b) GDPR - establishment, performance, or
                        termination of the contract with you
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Provide voice, image and camera services. When you use
                      services that require access to contents stored in and/or
                      functions on your devices (including but not limited to
                      contacts in address book, photos, videos, microphone,
                      and/or camera), we will use your photos, videos, contact
                      information in your address book, and other personal
                      information to respond to your requests, provide the
                      requested services to you, and improve our services
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Contact information, User-generated content, Communication
                      information, and Device information
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      <p>
                        Art. 6 (1) (b) GDPR - establishment, performance, or
                        termination of the contract with you
                      </p>
                      <p>
                        Art. 6 (1) (f) GDPR - our legitimate interest in
                        improving our services based on the information obtained
                      </p>
                      <p>Art. 6 (1) (a) GDPR - your consent</p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Recommendations and personalization. We use your personal
                      information to recommend features, products, and services
                      that might be of interest to you, identify your
                      preferences, and personalize your experience with the
                      Platform. If you are a Buyer, we will share details of the
                      last viewed products on the Platform to enable Sellers,
                      their respective affiliates and/or their third-party
                      services provider to contact you with details of products
                      and/or services that might interest you. You can opt not
                      to share this information with Sellers via the Buyer
                      Privacy Settings
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Contact information, Business information, Transaction
                      information, Online activity information, Marketing and
                      advertising information, and Location information
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      <p>Art. 6 (1) (a) GDPR - your consent</p>
                      <p>
                        Art. 6 (1) (f) GDPR - our legitimate interest in
                        offering recommendations
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Marketing and advertising. We use your personal
                      information to send and serve you tailored marketing and
                      advertising that we believe will be of interest to you
                      based on the ways in which you use the Platform, your
                      browsing records, and order history. Cookies or other
                      similar technologies may be used to provide you with
                      advertising based upon your browsing activities and
                      interests. Please see section 5.“COOKIES” below for more
                      information about how we use cookies
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Contact information, Business information, Transaction
                      information, Online activity information, Marketing and
                      advertising information, and Location information
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      <p>Art. 6 (1) (a) GDPR - your consent</p>
                      <p>
                        Art. 6 (1) (f) GDPR - our legitimate interest in
                        offering recommendations
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Research and development. We use your personal information
                      to perform research or statistical analysis in order to
                      improve the content and layout of the Platform, and use
                      your personal information to improve the product offerings
                      and services on Kibanda4devs, including, for example,
                      using anonymized data for machine learning purposes
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Contact information, Account information, Business
                      information, Device information, Location information,
                      Online activity information, User-generated content, and
                      Social media account information
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      <p>
                        Art. 6 (1) (f) GDPR - our legitimate interest in
                        improving the content and layout of the Platform
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Fraud prevention and credit risks. We use personal
                      information to prevent and detect fraud and abuse in order
                      to protect the security of the Platform, our customers and
                      others. We may also use scoring methods to assess and
                      manage credit risks
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Contact information, Account information, Business
                      information, Transaction information, Payment information,
                      Communication information, User-generated content, Device
                      information, and Location information
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      <p>
                        Art. 6 (1) (f) GDPR - our legitimate interest in
                        preventing and detecting fraud and carrying out
                        assessments to evaluate credit risks
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Comply with legal obligations. In certain cases, we
                      collect and use your personal information to comply with
                      laws. For instance, we use your information to verify your
                      identity and conducting security checks, KYC
                      (know-your-customer) and anti-money laundering due
                      diligence in order to set up user accounts
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      Contact information, Account information, Business
                      information, Communication information, Transaction
                      information, Payment information, and Online activity
                      information
                    </td>
                    <td style={{ border: '1px solid #DB7A03', padding: '8px' }}>
                      <p>
                        Art. 6 (1) (c) GDPR – Compliance with legal obligations
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="mb-4 mt-4">
                <p>
                  If we ask you to provide personal information to comply with a
                  legal requirement or to enter into a contract with you, we
                  will make this clear at the relevant time. We will advise you
                  whether the provision of your personal information is
                  mandatory or not (as well as of the possible consequences if
                  you do not provide your personal information). In some
                  instances, you may be required to provide us with personal
                  information for processing as described above, in order for us
                  to be able to provide you all of our services, and for you to
                  use all the features of our Platform.
                </p>

                <p>
                  For processing operations based on our legitimate interests,
                  we have carried out a legitimate interest assessment to ensure
                  that our interests are not prejudiced by your interests or
                  fundamental rights and freedoms.
                </p>

                <p>
                  If you have questions about or need further information
                  concerning the legal basis on which we collect and use your
                  personal information, please contact us using the contact
                  details provided under section 12. “HOW TO CONTACT US” below.
                </p>
              </div>

              <ol className="mb-2 mt-4" style={{ textAlign: 'center' }}>
                <li>8. INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION</li>
              </ol>
              <p>
                We will store the personal information we collect from you
                through the Platform in your respective country depending on the
                country in which you reside. It is inevitable that there will be
                certain cases that require international transfers of personal
                information of the Users among other countries. These countries
                have data protection laws that may have different provisions to
                the laws of your country. We ensure that any such international
                transfers will only be conducted to achieve legitimate purposes
                and with legal basis or as otherwise permitted by applicable
                law. We take appropriate steps to ensure that recipients of your
                personal information are bound to duties of confidentiality and
                we implement appropriate technical and organizational measures
                to ensure your personal information will remain protected in
                accordance with this Privacy Policy, such as standard
                contractual clauses. A copy of those clauses can be requested
                from{' '}
                <span style={{ color: 'orange' }}>info@Kibandalabs.com</span>.
              </p>

              <ol className="mb-2 mt-4" style={{ textAlign: 'center' }}>
                <li>9. MINORS</li>
              </ol>
              <p>
                The Platform is intended for adults. Our Platform is not
                directed to and we do not intend to, or knowingly, collect, or
                solicit personal information online from Users under the age of
                18 (“Minors”).
              </p>
              <p>
                We do not intend to sell any of our products or services to
                Minors. If a Minor has provided us with personal information
                without parental or guardian consent, the parent or guardian
                should contact{' '}
                <span style={{ color: 'orange' }}>Info@Kibandalabs.com</span> to
                remove the information.
              </p>

              <ol className="mb-2 mt-4" style={{ textAlign: 'center' }}>
                <li>10. SECURITY MEASURES</li>
              </ol>

              <p>
                We implement appropriate technical and organisational measures
                to prevent unauthorized access to the Platform, to maintain data
                accuracy and to ensure the correct use of the information we
                hold.
              </p>
              <p>
                For registered Users of the Platform, some of your information
                can be viewed and edited through your account, which is
                protected by a password. It is your responsibility to keep your
                password secure and confidential. You must not divulge your
                password to anyone. Our personnel will never ask you for your
                password in an unsolicited phone call or in an unsolicited
                email. If you share a computer with others, you should not save
                your log-in information (e.g., user ID and password) on that
                shared computer. Remember to sign out of your account and close
                your browser window when you have finished your session.
              </p>
              <p>
                No data transmission over the internet or any wireless network
                can be guaranteed to be perfectly secure. As a result, while we
                use appropriate technical and organizational measures to protect
                the information we hold for you, we cannot guarantee the
                security of any information you transmit to us and you do so at
                your own risk.
              </p>

              <ol className="mb-2 mt-4" style={{ textAlign: 'center' }}>
                <li>11. CHANGES TO THIS PRIVACY POLICY</li>
              </ol>
              <p>
                We may update this Privacy Policy from time to time including in
                response to changing legal, technical or business developments.
                When we update our Privacy Policy, we will take appropriate
                measures to notify you, consistent with the significance of the
                changes we make. We will obtain your consent if and when we
                materially change this Privacy Policy in a way that reduces the
                protection of your personal information collected in the past.
                Any changes to this Privacy Policy will be communicated by us
                posting an amended Privacy Policy on the Platform. Once posted
                on the Platform, the new Privacy Policy will be effective
                immediately. You can see when this Privacy Notice was last
                updated by checking the “last updated” date displayed at the top
                of this Privacy Notice.
              </p>

              <ol className="mb-2 mt-4" style={{ textAlign: 'center' }}>
                <li>12. HOW TO CONTACT US</li>
              </ol>
              <p>
                If you have any requests, questions or concerns about our use of
                your personal information and this Privacy Policy, you can
                contact our Data Protection Officer at{' '}
                <span style={{ color: 'orange' }}>info@Kibandalabs.com</span>.
              </p>
            </ul>
          </div>
          <div className="sticky bottom-0 p-4">
            <label className="mt-4 flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                checked={isChecked}
                onChange={() => setChecked(!isChecked)}
              />
              I have read and agree to the privacy policy
            </label>
            <div className="flex justify-end">
              <button
                className="btn btn-danger-gradiant mr-2 border-0  bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 text-white"
                onClick={handleAgree}
              >
                Agree
              </button>
              <button
                className="bg-gray-500 px-4 py-2 text-white"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyModal
