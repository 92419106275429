import React from 'react'

function NavBar() {
  return (
    <div className="navbar-area">
      <div className="mobile-responsive-nav">
        <div className="container-fluid">
          <div className="mobile-responsive-menu">
            <div className="logo">
              <a href="index.html">
                <img
                  src="assets/images/K4D1.png"
                  className="logo-one h-auto w-12"
                  alt="Logo"
                />
                <img
                  src="assets/images/K4D1.png"
                  className="logo-two h-auto w-12"
                  alt="Logo"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="desktop-nav nav-area">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-md navbar-light ">
            <a className="navbar-brand" href="index.html">
              <img
                src="assets/images/K4D1.png"
                className="logo-one h-auto w-16"
                alt="Logo"
              />
              <img
                src="assets/images/K4D1.png"
                className="logo-two h-auto w-16"
                alt="Logo"
              />
            </a>

            <div className="nav-widget-form nav-widget-form-bg">
              <form className="search-form">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search items, Creators "
                />
                <button type="submit">
                  <i className="ri-search-line"></i>
                </button>
              </form>
            </div>

            <div
              className="navbar-collapse mean-menu" //removed collapse as it was used to hide the menu in the navbar
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a href="/" className="nav-link active">
                    Home
                    {/* <i className="ri-arrow-down-s-line"></i> */}
                  </a>
                  {/* <ul className="dropdown-menu">
                    <li className="nav-item">
                      <a href="index.html" className="nav-link">
                        Home One
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="index-2.html" className="nav-link">
                        Home Two
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="index-3.html" className="nav-link active">
                        Home Three
                      </a>
                    </li>
                  </ul> */}
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link ">
                    Discover
                    <i className="ri-arrow-down-s-line"></i>
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <a href="/all" className="nav-link">
                        All Products
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/browse-art" className="nav-link">
                        Art
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/browse-desks" className="nav-link">
                        Desks
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/browse-seats" className="nav-link">
                        Seats
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/browse-lighting" className="nav-link">
                        Lighting
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/browse-electronics" className="nav-link">
                        Electronics
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/browse-merch" className="nav-link">
                        K4D Merchandise
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/browse-developer-books" className="nav-link">
                        Developer Books
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/browse-hushed-spaces" className="nav-link">
                        Hush Pods
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/browse-desk-plants" className="nav-link">
                        Desk Plants
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/accessories" className="nav-link">
                        Accessories
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a href="activity.html" className="nav-link">
                    Activity
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    Pages
                    <i className="ri-arrow-down-s-line"></i>
                  </a>
                  <ul className="dropdown-menu">
                    {/* <li className="nav-item">
                      <a href="collection.html" className="nav-link">
                        Collection
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="create-collection.html" className="nav-link">
                        Create Collection
                      </a>
                    </li> */}
                    {/* <li className="nav-item">
                      <a href="authors.html" className="nav-link">
                        Authors
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="author-profile.html" className="nav-link">
                        Author Profile
                      </a>
                    </li> */}
                    <li className="nav-item">
                      <a href="team.html" className="nav-link">
                        Team
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="testimonials.html" className="nav-link">
                        Testimonials
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        User
                        <i className="ri-arrow-down-s-line"></i>
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a href="login" className="nav-link">
                            Log In
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="register" className="nav-link">
                            Register
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/password/forgot" className="nav-link">
                            Forgot Password
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="terms-condition" className="nav-link">
                        Terms & Conditions
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="privacy-policy" className="nav-link">
                        Privacy Policy
                      </a>
                    </li>
                    {/* <li className="nav-item">
                      <a href="404.html" className="nav-link">
                        404 Page
                      </a>
                    </li> */}
                    <li className="nav-item">
                      <a href="coming-soon" className="nav-link">
                        Coming Soon
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    K4D Community
                    <i className="ri-arrow-down-s-line"></i>
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <a href="about.html" className="nav-link">
                        About Us
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="add-wallet" className="nav-link">
                        Add Wallet
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="k4d-podcast" className="nav-link">
                        K4D Podcast
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        Blog
                        <i className="ri-arrow-down-s-line"></i>
                      </a>
                      <ul className="dropdown-menu">
                        {/* <li className="nav-item">
                          <a href="blog-1.html" className="nav-link">
                            Blog Grid
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="blog-2.html" className="nav-link">
                            Blog Left Sidebar
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="blog-3.html" className="nav-link">
                            Blog Right Sidebar
                          </a>
                        </li> */}
                        {/* <li className="nav-item">
                          <a href="blog-details.html" className="nav-link">
                            Blog Details
                          </a>
                        </li> */}
                        <li className="nav-item">
                          <a href="categories.html" className="nav-link">
                            Categories
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="tags.html" className="nav-link">
                            Tags
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="help-center.html" className="nav-link">
                        Help Center
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a href="contact.html" className="nav-link">
                    Contact Us
                  </a>
                </li>
              </ul>
              <div className="others-options">
                <ul className="optional-item-list">
                  <li>
                    <a href="create-collection.html">Create</a>
                  </li>
                  <li>
                    <a href="add-wallet.html" className="active">
                      Connect Wallet
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className="mobile-nav">
        <div className="search-btn">
          <a
            className="#"
            href="#searchmodal"
            data-bs-toggle="modal"
            data-bs-target="#searchmodal"
          >
            <i className="ri-search-line"></i>
          </a>
        </div>
      </div>

      <div className="side-nav-responsive">
        <div className="container-max">
          <div className="dot-menu">
            <div className="circle-inner">
              <div className="circle circle-one"></div>
              <div className="circle circle-two"></div>
              <div className="circle circle-three"></div>
            </div>
          </div>
          <div className="container">
            <div className="side-nav-inner">
              <div className="side-nav justify-content-center align-items-center">
                <div className="side-nav-item">
                  <ul className="optional-item-list">
                    <li>
                      <a href="create-collection.html">Create</a>
                    </li>
                    <li>
                      <a href="add-wallet.html" className="active">
                        Connect Wallet
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavBar
