import React, { useState, useEffect } from 'react'
import NavBar from '../Guest/NavBar'
import PreLoader from '../Guest/PreLoader'

function CartAndCheckout() {
  const [currentLocation, setCurrentLocation] = useState('')
  const [liveLocation, setLiveLocation] = useState('')
  const [address, setAddress] = useState('')
  const [total, setTotalAmount] = useState(0)
  // const selectedProducts = useSelector((state) => state.carts.selectedProducts); // Get selectedProducts from Redux store
  const selectedProducts = JSON.parse(localStorage.getItem('reduxState'))
  console.log(selectedProducts, 'jjdhjshhdshhhshshdjhdsh') //
  const handleCurrentLocationChange = (event) =>
    setCurrentLocation(event.target.value)
  const handleLiveLocationChange = (event) =>
    setLiveLocation(event.target.value)
  const handleAddressChange = (event) => setAddress(event.target.value)

  return (
    <>
      <PreLoader />
      <NavBar />
      <div className="checkout-container  bg-slate-800 shadow-cyan-500/50 grid grid-cols-1 gap-8 rounded-lg p-6 text-gray-200 shadow-xl md:grid-cols-2">
        {/* Location & Address */}
        <div className="order-2 md:order-1">
          <h2 className="text-cyan-400 mb-2 text-lg font-bold">
            Delivery Details
          </h2>

          {/* Futuristic Location Integration */}
          <div className="relative mb-4">
            <label htmlFor="location" className="block text-sm font-medium">
              Live Location:
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={selectedProducts.liveLocation}
              onChange={handleLiveLocationChange}
              className="focus:ring-cyan-500 focus:border-cyan-500 bg-slate-700 mt-1 w-full rounded-md border border-gray-500 p-2 focus:outline-none focus:ring"
            />
            <button className="bg-cyan-500 hover:bg-cyan-600 absolute right-2 top-1/2 -translate-y-1/2 rounded-md px-3 py-1 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10.5a9 9 0 01-9 9H3m15.5-9.5a9 9 0 00-9-9v3"
                />
              </svg>
            </button>
          </div>

          {/* Similar structure for 'Current Location' */}

          <div className="relative mb-4">
            <label htmlFor="location" className="block text-sm font-medium">
              Current Location:
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={selectedProducts.currentLocation}
              onChange={handleCurrentLocationChange}
              className="focus:ring-cyan-500 focus:border-cyan-500 bg-slate-700 mt-1 w-full rounded-md border border-gray-500 p-2 focus:outline-none focus:ring"
            />
            <button className="bg-cyan-500 hover:bg-cyan-600 absolute right-2 top-1/2 -translate-y-1/2 rounded-md px-3 py-1 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10.5a9 9 0 01-9 9H3m15.5-9.5a9 9 0 00-9-9v3"
                />
              </svg>
            </button>
          </div>

          {/* Address  */}
          <div className="mb-4">
            <label htmlFor="address" className="block text-sm font-medium">
              Address:
            </label>
            <textarea
              id="address"
              name="address"
              value={selectedProducts.address}
              onChange={handleAddressChange}
              className="focus:ring-cyan-500 focus:border-cyan-500 bg-slate-700 mt-1 h-24 w-full resize-none rounded-md border border-gray-500 p-2 focus:outline-none focus:ring"
            ></textarea>
          </div>
        </div>

        {/* Order Summary */}
        <div className="bg-slate-700 order-1 rounded-md p-4 shadow-md md:order-2">
          <h2 className="text-cyan-400 mb-2 text-lg font-bold">
            Order Summary
          </h2>
          <ul className="space-y-3">
            <li className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src={selectedProducts.previewImage}
                  alt="Product"
                  className="mr-3 h-12 w-12 rounded-md object-cover"
                />
                <div>
                  <p className="text-sm font-medium">
                    {selectedProducts.productName}
                  </p>
                  <p className="text-xs text-gray-400">
                    Option: {selectedProducts.selectedOption}
                  </p>
                  <p className="text-xs text-gray-400">
                    Size: {selectedProducts.selectedSize}
                  </p>
                </div>
              </div>
              <p className="font-medium">KSh{selectedProducts.price}</p>
            </li>
          </ul>

          <div className="mt-6">
            <h2 className="mb-2 text-base font-semibold">
              Total:{' '}
              <span className="text-cyan-500">
                {' '}
                KSh{selectedProducts.total}
              </span>
            </h2>
            <button className="bg-cyan-500 hover:bg-cyan-600 w-full rounded-md px-6 py-3 text-white">
              Proceed to Checkout
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CartAndCheckout
