import Product from './Product'
import Slider from 'react-slick'
import { NextBtn, PreviousBtn } from '../Banner/Banner'
import { Link } from 'react-router-dom'
import { offerProducts } from '../../../utils/constants'
import { getRandomProducts } from '../../../utils/functions'

export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  initialSlide: 1,
  swipe: false,
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,  // Show 4 slides on screens larger than 1024px
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,  // Show 3 slides on screens larger than 768px
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,  // Show 2 slides on screens larger than 480px
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,  // Show 1 slide on screens smaller than 320px
        slidesToScroll: 1,
      },
    },
  ],
}

const DealSlider = ({ title }) => {
  return (
    <section className="w-full overflow-hidden bg-[#F4F4F4] shadow">
      {/* <!-- header --> */}
      <div className="flex items-center justify-between px-4 py-3 sm:px-6">
        <h1 className="text-lg font-medium sm:text-xl">{title}</h1>
        <Link
          to="/products"
          className="rounded-sm bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 text-xs font-medium text-white shadow-lg sm:px-5 sm:py-2.5"
        >
          VIEW ALL
        </Link>
      </div>
      <hr />
      {/* <!-- header --> */}

      <Slider {...settings}>
        {getRandomProducts(offerProducts, 12).map((item, i) => (
          <Product {...item} key={i} />
        ))}
      </Slider>
    </section>
  )
}

export default DealSlider
