import React from 'react'

function BlogArea() {
  return (
    <div className="blog-area  pt-100 pb-70 bg-white">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-md-8">
            <div className="section-title">
              <h2>From Our Blog</h2>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="blog-btn text-end">
              <a href="blog-1.html" className="default-btn border-radius-5">
                Explore More
              </a>
            </div>
          </div>
        </div>
        <div className="row justify-content-center pt-45">
          <div className="col-lg-4 col-md-6">
            <div className="blog-card">
              <div className="blog-img">
                <a href="blog-details.html">
                  <img src="assets/images/blog/blog-img1.jpg" alt="Images" />
                </a>
                <div className="blog-user">
                  <a href="author-profile.html" className="blog-user-option">
                    <img src="assets/images/blog/blog-img1.jpg" alt="Images" />
                    <span>Created by @Adison</span>
                  </a>
                </div>
                <a href="tags.html" className="blog-tag-btn">
                  Artwork
                </a>
              </div>
              <div className="content">
                <h3>
                  <a href="blog-details.html">
                    Announcing Our $100m Raise, Led By A16z
                  </a>
                </h3>
                <ul>
                  <li>June 5, 2021</li>
                  <li>No Comments</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="blog-card">
              <div className="blog-img">
                <a href="blog-details.html">
                  <img src="assets/images/blog/blog-img1.jpg" alt="Images" />
                </a>
                <div className="blog-user">
                  <a href="author-profile.html" className="blog-user-option">
                    <img
                      src="assets/images/featured/featured-user2.jpg"
                      alt="Images"
                    />
                    <span>Created by @Evelyn</span>
                  </a>
                </div>
                <a href="tags.html" className="blog-tag-btn">
                  Digital
                </a>
              </div>
              <div className="content">
                <h3>
                  <a href="blog-details.html">
                    Edition365: A Portrait Of The Year That Changed Everything
                  </a>
                </h3>
                <ul>
                  <li>June 7, 2021</li>
                  <li>02 Comments</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="blog-card">
              <div className="blog-img">
                <a href="blog-details.html">
                  <img src="assets/images/blog/blog-img3.jpg" alt="Images" />
                </a>
                <div className="blog-user">
                  <a href="author-profile.html" className="blog-user-option">
                    <img
                      src="assets/images/featured/featured-user3.jpg"
                      alt="Images"
                    />
                    <span>Created by @Julian</span>
                  </a>
                </div>
                <a href="tags.html" className="blog-tag-btn">
                  Creative
                </a>
              </div>
              <div className="content">
                <h3>
                  <a href="blog-details.html">
                    Christie’s Ended Up Selling For $69 Million In Nft
                    Marketplace
                  </a>
                </h3>
                <ul>
                  <li>June 9, 2021</li>
                  <li>03 Comments</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogArea
