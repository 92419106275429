import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Banner.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import gadgetSale from '../../../assets/images/Banners/banner.png'
import kitchenSale from '../../../assets/images/Banners/banner2.png'
import poco from '../../../assets/images/Banners/banner3.png'
import realme from '../../../assets/images/Banners/banner4.png'
import fashionSale from '../../../assets/images/Banners/banner5.png'
import oppo from '../../../assets/images/Banners/banner6.png'

export const PreviousBtn = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIosIcon />
    </div>
  )
}

export const NextBtn = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIosIcon />
    </div>
  )
}

const Banner = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
  }

  const banners = [gadgetSale, kitchenSale, poco, fashionSale, realme, oppo]

  return (
    <>
<section className="relative h-32 w-full overflow-hidden rounded-sm shadow sm:h-48 md:h-56 lg:h-72">
  <Slider {...settings}>
    {banners.map((el, i) => (
      <img
        draggable="false"
        className="w-full object-cover h-32 sm:h-48 md:h-56 lg:h-72"
        src={el}
        alt="banner"
        key={i}
      />
    ))}
  </Slider>
</section>

    </>
  )
}

export default Banner
