import React from 'react'

function AuctionsArea() {
  const setupTitles = [
    'Awesome Developer Setup',
    'Creative Coding Corner',
    'Productive Workspace',
    'Minimalist Coding Haven',
    "Designer's Dream Setup",
    'Tech Guru Station',
    "Coder's Paradise",
    "Innovator's Hub",
    "Geek's Lair",
    "Ann's Satellite",
    'Conservatives Corner',
  ]

  return (
    <div className="auctions-area-three pt-100 pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-md-6">
            <div className="section-title">
              <h2>Top Setups For The Week</h2>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="auction-btn text-end">
              <a href="/browse-setups" className="default-btn border-radius-5">
                Browse Setups
              </a>
            </div>
          </div>
        </div>

        <div className="row justify-content-center pt-45">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="col-lg-4 col-md-6">
              <div className="auction-card">
                <div className="auction-card-img">
                  <a href="/setup-details">
                    <img
                      src={`assets/images/setups/setups-style${index + 1}.jpeg`}
                      alt="Setup Image"
                    />
                  </a>
                  <div className="auction-card-user">
                    <a
                      href="/user-profile"
                      className="auction-card-user-option"
                    >
                      <img
                        src={`assets/images/setups/setups-user${index + 1}.jpg`}
                        alt="User Image"
                      />
                      <span>
                        Uploaded by @
                        {
                          [
                            'Adison',
                            'Emilia',
                            'Daniel',
                            'Jekob',
                            'Emilia',
                            'Jekob',
                            'Jekob',
                            'Emilia',
                            'Jekob',
                            'Adison',
                            'Emilia',
                          ][index]
                        }
                      </span>
                    </a>
                  </div>
                </div>
                <div className="content">
                  <h3>
                    <a href="/setup-details">{setupTitles[index]}</a>
                  </h3>
                  <p>
                    <i className="ri-heart-line"></i> 142
                  </p>
                  <div className="auction-card-content">
                    <div className="card-left">
                      <span>Uploaded</span>
                      <h4>5 days ago</h4>
                    </div>
                    {/* <div className="card-right">
                      <span>Likes</span>
                      <h4>150</h4>
                    </div> */}
                  </div>
                  <a href="/more-like-this" className="place-btn">
                    More Like This
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AuctionsArea
