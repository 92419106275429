import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Link } from 'react-router-dom'

const categories = [
  'Art',
  'Desks',
  'Seats',
  'Lighting',
  'Electronics',
  'Merchandise',
  'Developer Books',
  'Desk Plants',
  'Offer Zone',
  // 'Grocery',
  'Hush Meets',
]

const MinCategory = () => {
  return (
    <section className="mt-14 hidden w-full overflow-hidden  border-b bg-gradient-to-r from-gray-100 to-gray-200 px-2 shadow-md sm:block sm:px-12">
      <div className="flex items-center justify-between p-2">
        {categories.map((el, i) => (
          <Link
            to="/products"
            key={i}
            className="group flex items-center gap-1 rounded-md p-2 text-sm font-medium text-gray-800 transition-colors duration-200 hover:text-blue-600"
          >
            {el}{' '}
            <span className="text-gray-500 group-hover:text-blue-600">
              <ExpandMoreIcon sx={{ fontSize: '16px' }} />
            </span>
          </Link>
        ))}
      </div>
    </section>
  )
}

export default MinCategory
