import axios from 'axios'
import {
  ADD_TO_CART,
  EMPTY_CART,
  REMOVE_FROM_CART,
  SAVE_SHIPPING_INFO,
} from '../constants/cartConstants'

// add to cart
export const addItemsToCart =
  (id, quantity = 1) =>
  async (dispatch, getState) => {
    try {
      const { data } = await axios.get(`/api/v1/product/product/${id}`)
      const cartItem = {
        product: data?.product?._id,
        name: data?.product?.name,
        seller: data?.product?.brand?.name,
        price: data?.product?.price,
        cuttedPrice: data?.product?.cuttedPrice,
        image: data?.product?.images[0]?.url,
        stock: data?.product?.stock,
        quantity,
        selectedOption: data?.product?.selectedOption,
        selectedSize: data?.product?.selectedSize,
        previewImage: data?.product?.previewImage,
        selectedProducts: data?.product?.selectedProducts,
      }

      // Dispatch the action to add to cart
      dispatch({
        type: ADD_TO_CART,
        payload: cartItem,
      })

      // Save to local storage
      localStorage.setItem(
        'cartItems',
        JSON.stringify(getState().cart?.cartItems)
      )

      // Make a POST request to add the item to the cart in the database
      await axios.post('/api/v1/cart/add', {
        productId: cartItem.product,
        quantity: cartItem.quantity,
        // Add any other necessary fields here if needed
      })
    } catch (error) {
      console.error('Error adding item to cart:', error)
      // Optionally, dispatch an error action or handle the error accordingly
    }
  }

// remove cart item
export const removeItemsFromCart = (id) => async (dispatch, getState) => {
  try {
    // Dispatch action to remove from cart locally
    dispatch({
      type: REMOVE_FROM_CART,
      payload: id,
    })

    // Update local storage after removing item
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))

    // Make a DELETE request to remove the item from the cart in the database
    await axios.delete('/api/v1/cart/remove', {
      data: { id }, // Send 'id' instead of 'productId' if that's what the backend expects
    })

    // Optionally, you can handle success cases here if needed
  } catch (error) {
    console.error('Error removing item from cart:', error)
    // Optionally, dispatch an error action or handle the error accordingly
  }
}

// empty cart
export const emptyCart = () => async (dispatch, getState) => {
  try {
    const userId = getState().auth.user.id // Assuming userId is stored in your auth state

    // Make a DELETE request to empty the cart in the database
    const response = await axios.delete('/api/v1/cart/empty')

    dispatch({
      type: EMPTY_CART,
    })

    // Clear local storage for cartItems
    localStorage.removeItem('cartItems')

    // Optionally, handle success cases here if needed
  } catch (error) {
    console.error('Error emptying cart:', error)
    // Optionally, dispatch an error action or handle the error accordingly
  }
}

// save shipping info
export const saveShippingInfo = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data,
  })

  localStorage.setItem('shippingInfo', JSON.stringify(data))
}
