import { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { Avatar, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearErrors, loadUser, updateProfile } from '../../actions/userAction'
import { UPDATE_PROFILE_RESET } from '../../constants/userConstants'
import BackdropLoader from '../Layouts/BackdropLoader'
import MetaData from '../Layouts/MetaData'

const UpdateProfile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { user } = useSelector((state) => state.user)
  const { error, isUpdated, loading } = useSelector((state) => state.profile)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [gender, setGender] = useState('')
  const [avatar, setAvatar] = useState('')
  const [avatarPreview, setAvatarPreview] = useState('')

  const updateProfileHandler = (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.set('name', name)
    formData.set('email', email)
    formData.set('gender', gender)
    formData.set('avatar', avatar)

    dispatch(updateProfile(formData))
  }

  const handleUpdateDataChange = (e) => {
    const reader = new FileReader()
    setAvatar('')
    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatarPreview(reader.result)
        setAvatar(reader.result)
      }
    }

    reader.readAsDataURL(e.target.files[0])
  }

  useEffect(() => {
    if (user) {
      setName(user?.name)
      setEmail(user?.email)
      setGender(user?.gender)
      setAvatarPreview(user?.avatar?.url)
    }
    if (error) {
      enqueueSnackbar(error, { variant: 'error' })
      dispatch(clearErrors())
    }
    if (isUpdated) {
      enqueueSnackbar('Profile Updated Successfully', { variant: 'success' })
      dispatch(loadUser())
      navigate('/account')

      dispatch({ type: UPDATE_PROFILE_RESET })
    }
  }, [dispatch, error, user, isUpdated, navigate, enqueueSnackbar])

  return (
    <>
      <MetaData title="Update Profile | Kibanda4Devs" />

      {loading && <BackdropLoader />}
      <main className="mt-12 flex min-h-screen w-full items-center justify-center  sm:mt-0 sm:pt-20">
        {/* <!-- row --> */}
        <div className="m-auto mb-7 flex bg-white shadow-lg sm:mt-4 sm:w-4/6">
          {/* <!-- sidebar column  --> */}
          <div
            className="loginSidebar hidden w-2/5 flex-col gap-4  p-10 pr-12 sm:flex"
            style={{
              backgroundColor: '#00c9b7',
              boxShadow: '0px 0px 10px 0px #00c9b7',
            }}
          >
            <h1 className="text-3xl font-medium text-white">
              Looks like you're new here!
            </h1>
            <p className="pr-2 text-lg text-gray-200">
              Sign up with your mobile number to get started
            </p>
          </div>
          {/* <!-- sidebar column  --> */}

          {/* <!-- signup column --> */}
          <div className="flex-1 overflow-hidden">
            <h2 className="mt-6 text-center text-2xl font-medium text-gray-800">
              Update Profile
            </h2>
            {/* <!-- personal info procedure container --> */}
            <form
              onSubmit={updateProfileHandler}
              encType="multipart/form-data"
              className="p-5 sm:p-10"
            >
              <div className="flex flex-col items-start gap-4">
                {/* <!-- input container column --> */}
                <div className="flex w-full flex-col items-center justify-between gap-3 sm:flex-col">
                  <TextField
                    fullWidth
                    label="Full Name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                {/* <!-- input container column --> */}

                {/* <!-- gender input --> */}
                <div className="flex items-center gap-4">
                  <h2 className="text-md">Your Gender :</h2>
                  <div className="flex items-center gap-6" id="radioInput">
                    <RadioGroup
                      row
                      aria-labelledby="radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        name="gender"
                        value="male"
                        checked={gender === 'male'}
                        onChange={(e) => setGender(e.target.value)}
                        control={<Radio required />}
                        label="Male"
                      />
                      <FormControlLabel
                        name="gender"
                        value="female"
                        checked={gender === 'female'}
                        onChange={(e) => setGender(e.target.value)}
                        control={<Radio required />}
                        label="Female"
                      />
                    </RadioGroup>
                  </div>
                </div>
                {/* <!-- gender input --> */}

                <div className="flex w-full flex-col items-center justify-between gap-3 sm:flex-row">
                  <Avatar
                    alt="Avatar Preview"
                    src={avatarPreview}
                    sx={{ width: 56, height: 56 }}
                  />
                  <label className="w-full cursor-pointer rounded bg-gray-400 px-2.5 py-2 text-center font-medium text-white shadow hover:shadow-lg">
                    <input
                      type="file"
                      name="avatar"
                      accept="image/*"
                      onChange={handleUpdateDataChange}
                      className="hidden"
                    />
                    Choose File
                  </label>
                </div>
                <button
                  type="submit"
                  className="w-full rounded-sm bg-primary-orange py-3 font-medium text-white shadow hover:shadow-lg"
                >
                  Update
                </button>
                <Link
                  className="w-full rounded-sm border py-3 text-center font-medium text-primary-blue shadow hover:bg-gray-100"
                  to="/account"
                >
                  Cancel
                </Link>
              </div>
            </form>
            {/* <!-- personal info procedure container --> */}
          </div>
          {/* <!-- signup column --> */}
        </div>
        {/* <!-- row --> */}
      </main>
    </>
  )
}

export default UpdateProfile
