import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Slider from 'react-slick'
import {
  clearErrors,
  getProductDetails,
  getSimilarProducts,
  newReview,
} from '../../actions/productAction'
import { NextBtn, PreviousBtn } from '../Home/Banner/Banner'
import ProductSlider from '../Home/ProductSlider/ProductSlider'
import Loader from '../Layouts/Loader'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import StarIcon from '@mui/icons-material/Star'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import CachedIcon from '@mui/icons-material/Cached'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

import FavoriteIcon from '@mui/icons-material/Favorite'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Rating from '@mui/material/Rating'
import TextField from '@mui/material/TextField'
import { NEW_REVIEW_RESET } from '../../constants/productConstants'
import { addItemsToCart } from '../../actions/cartAction'
import { getDeliveryDate, getDiscount } from '../../utils/functions'
import { addToWishlist, removeFromWishlist } from '../../actions/wishlistAction'
import MinCategory from '../Layouts/MinCategory'
import MetaData from '../Layouts/MetaData'

const ProductDetails = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const params = useParams()
  const navigate = useNavigate()

  // reviews toggle
  const [open, setOpen] = useState(false)
  const [viewAll, setViewAll] = useState(false)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')

  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  )
  const { success, error: reviewError } = useSelector(
    (state) => state.newReview
  )
  const { cartItems } = useSelector((state) => state.cart)
  const { wishlistItems } = useSelector((state) => state.wishlist)

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
  }

  const productId = params.id
  const itemInWishlist = wishlistItems?.some((i) => i.product === productId)

  const addToWishlistHandler = () => {
    if (itemInWishlist) {
      dispatch(removeFromWishlist(productId))
      enqueueSnackbar('Remove From Wishlist', { variant: 'success' })
    } else {
      dispatch(addToWishlist(productId))
      enqueueSnackbar('Added To Wishlist', { variant: 'success' })
    }
  }

  const reviewSubmitHandler = () => {
    if (rating === 0 || !comment.trim()) {
      enqueueSnackbar('Empty Review', { variant: 'error' })
      return
    }
    const formData = new FormData()
    formData.set('rating', rating)
    formData.set('comment', comment)
    formData.set('productId', productId)
    dispatch(newReview(formData))
    setOpen(false)
  }

  const addToCartHandler = () => {
    dispatch(addItemsToCart(productId))
    enqueueSnackbar('Product Added To Cart', { variant: 'success' })
  }

  const handleDialogClose = () => {
    setOpen(!open)
  }

  const itemInCart = cartItems?.some((i) => i.product === productId)

  const goToCart = () => {
    navigate('/cart')
  }

  const buyNow = () => {
    addToCartHandler()
    navigate('/shipping')
  }

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' })
      dispatch(clearErrors())
    }
    if (reviewError) {
      enqueueSnackbar(reviewError, { variant: 'error' })
      dispatch(clearErrors())
    }
    if (success) {
      enqueueSnackbar('Review Submitted Successfully', { variant: 'success' })
      dispatch({ type: NEW_REVIEW_RESET })
    }
    dispatch(getProductDetails(productId))
    // eslint-disable-next-line
  }, [dispatch, productId, error, reviewError, success, enqueueSnackbar])

  useEffect(() => {
    dispatch(getSimilarProducts(product?.category))
  }, [dispatch, product, product.category])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData title={product.name} />
          <MinCategory />
          <main className="mt-12 bg-gray-200 sm:mt-0 ">
            {/* <!-- product image & description container --> */}
            <div className="relative relative flex w-full flex-col bg-gray-200 sm:flex-row sm:p-2">
              {/* <!-- image wrapper --> */}
              <div className="top-16 w-full sm:sticky sm:h-screen sm:w-2/5">
                {/* <!-- imgbox --> */}
                <div className="m-3 flex flex-col gap-3">
                  <div className="relative h-full w-full border pb-6">
                    <div className=" relative z-10 mx-auto rounded-lg p-8">
                      {/* Curved outward shadow effect */}
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                      {/* End of curved outward shadow effect */}
                      <Slider {...settings}>
                        {product.images &&
                          product.images.map((item, i) => (
                            <img
                              draggable="false"
                              className="h-96 w-full object-contain"
                              src={item.url}
                              alt={product.name}
                              key={i}
                            />
                          ))}
                      </Slider>
                    </div>
                    <div className="absolute right-4 top-4 z-10 flex h-9 w-9 items-center justify-center rounded-full  border bg-white shadow-lg">
                      <span
                        onClick={addToWishlistHandler}
                        className={`KSh{itemInWishlist ? 'text-red-500' : 'text-gray-300 hover:text-red-500'} cursor-pointer`}
                      >
                        <FavoriteIcon sx={{ fontSize: '18px' }} />
                      </span>
                    </div>
                  </div>

                  <div className="relative z-10 mx-auto flex w-full max-w-md gap-3 rounded-lg p-4 sm:p-6 md:p-8">
  {/* Curved outward shadow effect */}
  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
  {/* End of curved outward shadow effect */}

  <div className="flex flex-col gap-3 w-full">
    {/* Add to cart button */}
    {product.stock > 0 && (
      <button
        onClick={itemInCart ? goToCart : addToCartHandler}
        className="z-10 flex items-center justify-center gap-2 rounded-sm bg-primary-yellow p-3 text-white shadow hover:shadow-lg sm:p-4"
      >
        <ShoppingCartIcon />
        {itemInCart ? 'GO TO CART' : 'ADD TO CART'}
      </button>
    )}
    <button
      onClick={buyNow}
      disabled={product.stock < 1}
      className={
        product.stock < 1
          ? 'z-10 flex items-center justify-center gap-2 rounded-sm bg-red-600 p-3 text-white shadow cursor-not-allowed sm:p-4'
          : 'z-10 flex items-center justify-center gap-2 rounded-sm bg-primary-orange p-3 text-white shadow hover:shadow-lg sm:p-4'
      }
    >
      <FlashOnIcon />
      {product.stock < 1 ? 'OUT OF STOCK' : 'BUY NOW'}
    </button>
  </div>
</div>

                </div>
                {/* <!-- imgbox --> */}
              </div>
              {/* <!-- image wrapper --> */}

              {/* <!-- product desc wrapper --> */}
              <div className="flex-1 px-3 py-2">
                {/* <!-- whole product description --> */}

                <div className="relative z-10 mb-4 flex flex-col gap-2 rounded-sm border ">
                  {/* Curved outward shadow effect */}
                  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                  {/* End of curved outward shadow effect */}

                  <h2 className="relative z-10 ml-4 py-4 text-xl">
                    {product.name}
                  </h2>
                  {/* <!-- rating badge --> */}
                  <span className="relative z-10 ml-4 flex items-center gap-2 text-sm font-medium text-gray-500">
                    <span className="flex items-center gap-0.5 rounded-sm bg-primary-green px-1.5 py-0.5 text-xs text-white ">
                      {product.ratings && product.ratings.toFixed(1)}{' '}
                      <StarIcon sx={{ fontSize: '12px' }} />
                    </span>
                    <span>{product.numOfReviews} Reviews</span>
                  </span>
                  {/* <!-- rating badge --> */}

                  {/* <!-- price desc --> */}
                  <span className="relative z-10 ml-4 text-sm font-medium text-primary-green">
                    Special Price
                  </span>
                  <div className="relative z-10 ml-4 flex items-baseline gap-2 text-3xl font-medium">
                    <span className="text-gray-800">
                      KSh {product.price?.toLocaleString()}
                    </span>
                    <span className="text-base text-gray-500 line-through">
                      KSh{product.cuttedPrice?.toLocaleString()}
                    </span>
                    <span className="text-base text-primary-green">
                      {getDiscount(product.price, product.cuttedPrice)}
                      %&nbsp;off
                    </span>
                  </div>
                  {product.stock <= 10 && product.stock > 0 && (
                    <span className="relative z-10 ml-4 text-sm font-medium text-red-500">
                      Hurry, Only {product.stock} left!
                    </span>
                  )}
                  {/* <!-- price desc --> */}

                  {/* <!-- banks offers --> */}
                  {/* <p className="text-md font-medium">Available offers</p>
                                    {Array(3).fill("").map((el, i) => (
                                        <p className="text-sm flex items-center gap-1" key={i}>
                                            <span className="text-primary-lightGreen"><LocalOfferIcon sx={{ fontSize: "20px" }} /></span>
                                            <span className="font-medium ml-2">Bank Offer</span> 15% Instant discount on first AtEMkart Pay Later order of 500 and above <Link className="text-primary-blue font-medium" to="/">T&C</Link>
                                        </p>
                                    ))} */}
                  {/* <!-- banks offers --> */}

                  {/* <!-- warranty & brand --> */}
                  <div className="relative z-10 ml-4 mt-2 flex items-center gap-8 rounded-lg px-3 py-3 text-sm ">
                    {/* Curved outward shadow effect */}
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    {/* End of curved outward shadow effect */}
                    <img
                      draggable="false"
                      className="relative z-10 h-8 w-20 border object-contain p-0.5"
                      src={product.brand?.logo.url}
                      alt={product.brand && product.brand.name}
                    />
                    <span className=" relative z-10">
                      {product.warranty} Months Warranty{' '}
                      <Link
                        className="relative z-10 font-medium text-primary-blue"
                        to="/"
                      >
                        Know More
                      </Link>
                    </span>
                  </div>
                  {/* <!-- warranty & brand --> */}

                  {/* <!-- delivery details --> */}
                  <div className="relative z-10 ml-4 mt-4 flex items-center gap-16 text-sm font-medium">
                    <p className="text-gray-500">Delivery</p>
                    <span>Delivery by {getDeliveryDate()}</span>
                  </div>
                  {/* <!-- delivery details --> */}

                  {/* <!-- highlights & services details --> */}
                  <div className="relative z-10 ml-4 flex flex-col justify-between sm:flex-row">
                    {/* <!-- highlights details --> */}
                    <div className="mt-4 flex items-stretch gap-16 text-sm">
                      <p className="font-medium text-gray-500">Highlights</p>

                      <ul className="flex w-64 list-disc flex-col gap-2">
                        {product.highlights?.map((highlight, i) => (
                          <li key={i}>
                            <p>{highlight}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/* <!-- highlights details --> */}

                    {/* <!-- services details --> */}
                    <div className="mr-6 mt-4 flex items-stretch gap-16 text-sm">
                      <p className="font-medium text-gray-500">Services</p>
                      <ul className="flex flex-col gap-2">
                        <li>
                          <p className="flex items-center gap-3">
                            <span className="text-primary-blue">
                              <VerifiedUserIcon sx={{ fontSize: '18px' }} />
                            </span>{' '}
                            {product.warranty} Month
                          </p>
                        </li>
                        <li>
                          <p className="flex items-center gap-3">
                            <span className="text-primary-blue">
                              <CachedIcon sx={{ fontSize: '18px' }} />
                            </span>{' '}
                            7 Days Replacement Policy
                          </p>
                        </li>
                        <li>
                          <p className="flex items-center gap-3">
                            <span className="text-primary-blue">
                              <AttachMoneyIcon sx={{ fontSize: '18px' }} />
                            </span>{' '}
                            Cash on Delivery available
                          </p>
                        </li>
                      </ul>
                    </div>
                    {/* <!-- services details --> */}
                  </div>
                  {/* <!-- highlights & services details --> */}

                  {/* <!-- seller details --> */}
                  <div className="relative z-10 ml-4 mt-4 flex items-center gap-16 text-sm font-medium">
                    <p className="text-gray-500">Seller</p>
                    <Link
                      className="relative z-10 ml-3 rounded-md px-2 py-2 font-medium text-gray-800"
                      style={{ backgroundColor: '#FFA500' }}
                      to="/"
                    >
                      {product?.brand && product?.brand?.name}
                    </Link>
                  </div>
                  {/* <!-- seller details --> */}

                  {/* <!-- atemkart plus banner --> */}
                  {/* <div className="sm:w-1/2 mt-4 border">
                                        <img draggable="false" className="w-full h-full object-contain" src="https://rukminim1.flixcart.com/lockin/763/305/images/promotion_banner_v2_active.png" alt="" />
                                    </div> */}
                  {/* <!-- atemkart plus banner --> */}

                  {/* <!-- description details --> */}
                  {/* <div className="mt-4 flex flex-col items-stretch gap-1 text-sm sm:flex-row sm:gap-14">
                    <p className="font-medium text-gray-500">Description</p>
                    <span>{product.description}</span>
                  </div> */}
                  {/* <!-- description details --> */}

                  {/* <!-- border box --> */}
                  <div className="relative z-10 mt-6 flex w-full flex-col rounded-sm border ">
                    {/* Curved outward shadow effect */}
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    {/* End of curved outward shadow effect */}
                    <h1 className="relative z-10 border-b px-6 py-4 text-2xl font-medium">
                      Product Description
                    </h1>
                    <div className="relative z-10 p-6">
                      <p className="text-sm">{product.description}</p>
                    </div>
                  </div>
                  {/* <!-- border box --> */}

                  {/* <!-- specifications border box --> */}
                  <div className="relative z-10 mt-6 flex w-full flex-col rounded-sm border  ">
                    {/* Curved outward shadow effect */}
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    {/* End of curved outward shadow effect */}

                    <h1 className="relative z-10 border-b px-6 py-4 text-2xl font-medium ">
                      Specifications
                    </h1>
                    <h1 className="relative z-10 px-6 py-3 text-lg">General</h1>

                    {/* <!-- specs list --> */}
                    {product.specifications?.map((spec, i) => (
                      <div
                        className="relative z-10 flex items-center px-6 py-2 text-sm"
                        key={i}
                      >
                        <p className="w-3/12 text-gray-500">{spec.title}</p>
                        <p className="flex-1">{spec.description}</p>
                      </div>
                    ))}
                    {/* <!-- specs list --> */}
                  </div>
                  {/* <!-- specifications border box --> */}

                  {/* <!-- reviews border box --> */}
                  <div className="relative z-10 mt-6 flex w-full flex-col rounded-sm border ">
                    {/* Curved outward shadow effect */}
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    {/* End of curved outward shadow effect */}
                    <div className="relative z-10 flex flex-col items-start justify-between border-b px-4 py-3 sm:px-6 sm:py-4 md:flex-row md:items-center md:justify-between">
  <h1 className="relative z-10 text-xl font-medium sm:text-2xl">
    Ratings & Reviews
  </h1>
  <button
    onClick={handleDialogClose}
    className="mt-3 rounded-sm bg-primary-yellow px-4 py-2 text-white shadow hover:shadow-lg md:mt-0"
  >
    Rate Product
  </button>
</div>

                    <div className=" relative z-10 rounded-lg">
                      {/* Curved outward shadow effect */}
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                      {/* End of curved outward shadow effect */}
                      <Dialog
                        aria-labelledby="review-dialog"
                        open={open}
                        onClose={handleDialogClose}
                      >
                        <DialogTitle className="border-b ">
                          Submit Review
                        </DialogTitle>
                        <DialogContent className="m-1 flex flex-col gap-4">
                          <Rating
                            onChange={(e) => setRating(e.target.value)}
                            value={rating}
                            size="large"
                            precision={0.5}
                          />
                          <TextField
                            label="Review"
                            multiline
                            rows={3}
                            sx={{ width: 400 }}
                            size="small"
                            variant="outlined"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                          />
                        </DialogContent>
                        <DialogActions>
                          <button
                            onClick={handleDialogClose}
                            className="rounded border border-red-500 bg-white px-6 py-2 uppercase text-red-600 shadow hover:bg-red-100"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={reviewSubmitHandler}
                            className="rounded bg-green-600 px-6 py-2 uppercase text-white shadow hover:bg-green-700"
                          >
                            Submit
                          </button>
                        </DialogActions>
                      </Dialog>
                    </div>
                    <div className="relative z-10 flex items-center border-b">
                      <h1 className="relative z-10 px-6 py-3 text-3xl font-semibold">
                        {product.ratings && product.ratings.toFixed(1)}
                        <StarIcon />
                      </h1>
                      <p className="text-lg text-gray-500">
                        ({product.numOfReviews}) Reviews
                      </p>
                    </div>

                    {viewAll
                      ? product.reviews
                          ?.map((rev, i) => (
                            <div
                              className="flex flex-col gap-2 border-b px-6 py-4"
                              key={i}
                            >
                              <Rating
                                name="read-only"
                                value={rev.rating}
                                readOnly
                                size="small"
                                precision={0.5}
                              />
                              <p>{rev.comment}</p>
                              <span className="text-sm text-gray-500">
                                by {rev.name}
                              </span>
                            </div>
                          ))
                          .reverse()
                      : product.reviews
                          ?.slice(-3)
                          .map((rev, i) => (
                            <div
                              className="flex flex-col gap-2 border-b px-6 py-4"
                              key={i}
                            >
                              <Rating
                                name="read-only"
                                value={rev.rating}
                                readOnly
                                size="small"
                                precision={0.5}
                              />
                              <p>{rev.comment}</p>
                              <span className="text-sm text-gray-500">
                                by {rev.name}
                              </span>
                            </div>
                          ))
                          .reverse()}
                    {product.reviews?.length > 3 && (
                      <button
                        onClick={() => setViewAll(!viewAll)}
                        className="m-2 w-1/3 rounded-sm bg-primary-blue py-2 text-white shadow hover:shadow-lg"
                      >
                        {viewAll ? 'View Less' : 'View All'}
                      </button>
                    )}
                  </div>
                  {/* <!-- reviews border box --> */}
                </div>
              </div>
              {/* <!-- product desc wrapper --> */}
            </div>
            {/* <!-- product image & description container --> */}

            {/* Sliders */}
            <div className="relative z-10 mx-auto mt-6 flex flex-col gap-3 bg-gray-200 p-4 sm:p-6 md:p-8">
  {/* Curved outward shadow effect */}
  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
  {/* End of curved outward shadow effect */}

  <div className="relative z-10 rounded-lg bg-white p-4 sm:p-6 md:p-8">
    <ProductSlider
      title={'Similar Products'}
      tagline={'Based on the category'}
    />
  </div>
</div>

          </main>
        </>
      )}
    </>
  )
}

export default ProductDetails
