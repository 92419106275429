//src/reducers/formsReducers.js

import {
  SUBMIT_CONTACT_FORM_REQUEST,
  SUBMIT_CONTACT_FORM_SUCCESS,
  SUBMIT_CONTACT_FORM_FAIL,
  SUBMIT_REGISTRATION_FORM_REQUEST,
  SUBMIT_REGISTRATION_FORM_SUCCESS,
  SUBMIT_REGISTRATION_FORM_FAIL,
  SUBMIT_ADVERT_FORM_REQUEST,
  SUBMIT_ADVERT_FORM_SUCCESS,
  SUBMIT_ADVERT_FORM_FAIL,
  SUBMIT_AFFILIATE_FORM_REQUEST,
  SUBMIT_AFFILIATE_FORM_SUCCESS,
  SUBMIT_AFFILIATE_FORM_FAIL,
  SUBMIT_WHOLESALE_FORM_REQUEST,
  SUBMIT_WHOLESALE_FORM_SUCCESS,
  SUBMIT_WHOLESALE_FORM_FAIL,
} from '../constants/formsConstants'

export const contactFormReducer = (
  state = { loading: false, success: false, error: null },
  action
) => {
  switch (action.type) {
    case SUBMIT_CONTACT_FORM_REQUEST:
      return { ...state, loading: true }
    case SUBMIT_CONTACT_FORM_SUCCESS:
      return { ...state, loading: false, success: true }
    case SUBMIT_CONTACT_FORM_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const registrationFormReducer = (
  state = { loading: false, success: false, error: null },
  action
) => {
  switch (action.type) {
    case SUBMIT_REGISTRATION_FORM_REQUEST:
      return { ...state, loading: true }
    case SUBMIT_REGISTRATION_FORM_SUCCESS:
      return { ...state, loading: false, success: true }
    case SUBMIT_REGISTRATION_FORM_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const advertFormReducer = (
  state = { loading: false, success: false, error: null },
  action
) => {
  switch (action.type) {
    case SUBMIT_ADVERT_FORM_REQUEST:
      return { ...state, loading: true }
    case SUBMIT_ADVERT_FORM_SUCCESS:
      return { ...state, loading: false, success: true }
    case SUBMIT_ADVERT_FORM_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const affiliateFormReducer = (
  state = { loading: false, success: false, error: null },
  action
) => {
  switch (action.type) {
    case SUBMIT_AFFILIATE_FORM_REQUEST:
      return { ...state, loading: true }
    case SUBMIT_AFFILIATE_FORM_SUCCESS:
      return { ...state, loading: false, success: true }
    case SUBMIT_AFFILIATE_FORM_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const wholesaleFormReducer = (
  state = { loading: false, success: false, error: null },
  action
) => {
  switch (action.type) {
    case SUBMIT_WHOLESALE_FORM_REQUEST:
      return { ...state, loading: true }
    case SUBMIT_WHOLESALE_FORM_SUCCESS:
      return { ...state, loading: false, success: true }
    case SUBMIT_WHOLESALE_FORM_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}
