import { Link } from 'react-router-dom'
import { getDiscount } from '../../utils/functions'
import DeleteIcon from '@mui/icons-material/Delete'
import StarIcon from '@mui/icons-material/Star'
import { removeFromWishlist } from '../../actions/wishlistAction'
import { useDispatch } from 'react-redux'

const Product = (props) => {
  const { product, name, price, cuttedPrice, image, ratings, reviews } = props

  const dispatch = useDispatch()

  const deleteHandler = () => {
    dispatch(removeFromWishlist(product))
  }

  return (
    <div className="group flex w-full gap-4 overflow-hidden border-b p-4 sm:pb-8">
      <div className="h-28 w-1/6 flex-shrink-0">
        <img
          draggable="false"
          className="h-full w-full object-contain"
          src={image}
          alt={name}
        />
      </div>

      {/* <!-- description --> */}
      <div className="flex w-full flex-col gap-5 p-1">
        {/* <!-- product title --> */}
        <div className="flex items-start justify-between sm:pr-5">
          <Link to={`/product/${product}`} className="flex flex-col gap-0.5">
            <p className="w-56 truncate group-hover:text-primary-blue sm:w-full">
              {name.length > 85 ? `${name.substring(0, 85)}...` : name}
            </p>
            {/* <!-- rating badge --> */}
            <span className="flex items-center gap-2 text-sm font-medium text-gray-500">
              <span className="flex items-center gap-0.5 rounded-sm bg-primary-green px-1.5 py-0.5 text-xs text-white">
                {ratings} <StarIcon sx={{ fontSize: '14px' }} />
              </span>
              <span>({reviews.toLocaleString()})</span>
            </span>
            {/* <!-- rating badge --> */}
          </Link>
          <button
            onClick={deleteHandler}
            className="text-gray-400 hover:text-red-500"
          >
            <span>
              <DeleteIcon />
            </span>
          </button>
        </div>
        {/* <!-- product title --> */}

        {/* <!-- price desc --> */}
        <div className="flex items-center gap-2 text-2xl font-medium">
          <span>KSh{price.toLocaleString()}</span>
          <span className="mt-1 text-sm font-normal text-gray-500 line-through">
            KSh{cuttedPrice.toLocaleString()}
          </span>
          <span className="mt-1 text-sm text-primary-green">
            {getDiscount(price, cuttedPrice)}%&nbsp;off
          </span>
        </div>
        {/* <!-- price desc --> */}
      </div>
      {/* <!-- description --> */}
    </div>
  )
}

export default Product
