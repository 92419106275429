import React from 'react'

function FooterArea() {
  return (
    <footer className="footer-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-8">
            <div className="footer-widget">
              <div className="footer-logo">
                <a href="/">
                  <img
                    src="assets/images/K4D2.png"
                    className="footer-logo1 h-auto w-32"
                    alt="Footer Logo"
                  />
                  <img
                    src="assets/images/K4D2.png"
                    className="footer-logo2 h-auto w-32"
                    alt="Footer Logo"
                  />
                </a>
              </div>
              <p>Subscribe to our newsletter</p>
              <div className="newsletter-area">
                <form
                  className="newsletter-form"
                  data-toggle="validator"
                  method="POST"
                >
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Your Email"
                    name="EMAIL"
                    required
                    autoComplete="off"
                  />
                  <button className="subscribe-btn" type="submit">
                    Subscribe Now
                  </button>
                  <div id="validator-newsletter" className="form-result"></div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4">
            <div className="footer-widget ps-5">
              <h3>Explore</h3>
              <ul className="footer-list">
                <li>
                  <a href="/art">Art</a>
                </li>
                <li>
                  <a href="/browse-setups">Browse Setups</a>
                </li>
                <li>
                  <a href="/discover">Discover</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
                <li>
                  <a href="/about">About Us</a>
                </li>
                {/* <li>
                  <a href="discover-1.html" target="_blank">
                    Trending Cards
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4">
            <div className="footer-widget ps-5">
              <h3>My Account</h3>
              <ul className="footer-list">
                <li>
                  <a href="/authors">Authors</a>
                </li>
                <li>
                  <a href="/collection">Collection</a>
                </li>
                <li>
                  <a href="/author-profile">Author Profile</a>
                </li>
                <li>
                  <a href="/create-collection">Create Collection</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4">
            <div className="footer-widget ps-5">
              <h3>Resources</h3>
              <ul className="footer-list">
                <li>
                  <a href="/help-center">Helps & Support</a>
                </li>
                <li>
                  <a href="auction.html" target="_blank">
                    Top Setups
                  </a>
                </li>
                <li>
                  <a href="/blog">Our Blog</a>
                </li>
                <li>
                  <a href="/activity">Activity</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4">
            <div className="footer-widget ps-5">
              <h3>Company</h3>
              <ul className="footer-list">
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
                <li>
                  <a href="/our-blog">Our Blog</a>
                </li>
                <li>
                  <a href="/discover" target="_blank">
                    Discover
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-shape">
        <div className="footer-shape1">
          <img src="assets/images/shape/shape-bg.png" alt="Images" />
        </div>
        <div className="footer-shape2">
          <img src="assets/images/shape/shape1.png" alt="Images" />
        </div>
        <div className="footer-shape3">
          <img src="assets/images/shape/shape2.png" alt="Images" />
        </div>
      </div>
    </footer>
  )
}

export default FooterArea
