import art from '../../assets/images/Categories/art.jpg'
import desks from '../../assets/images/Categories/desk4-removebg-preview.png'
import electronics from '../../assets/images/Categories/electronics-icon.png'
import seats from '../../assets/images/Categories/seat.png'
import lighting from '../../assets/images/Categories/lighting4.jpeg'
import books from '../../assets/images/Categories/book4.jpeg'
import merch from '../../assets/images/Categories/merch-removebg-preview.png'
import plant from '../../assets/images/Categories/plant-removebg-preview.png'
import health from '../../assets/images/Categories/health5-removebg-preview.png'
import { Link } from 'react-router-dom'

const catNav = [
  {
    name: 'Art',
    icon: art,
  },
  {
    name: 'Desks',
    icon: desks,
  },
  {
    name: 'Electronics',
    icon: electronics,
  },
  {
    name: 'Chairs',
    icon: seats,
  },
  {
    name: 'Lighting',
    icon: lighting,
  },
  {
    name: 'Developer Books',
    icon: books,
  },
  {
    name: 'Merchandise',
    icon: merch,
  },
  {
    name: 'Desk Plants',
    icon: plant,
  },
  {
    name: "Developer's Health",
    icon: health,
  },
  // {
  //   name: 'Acessories',
  //   icon: acessories,
  // },
]

const Categories = () => {
  return (
    <section className="relative z-10 mb-4 mt-10 hidden min-w-full overflow-hidden bg-gray-100 bg-gray-200 px-12 py-1 shadow sm:block">
      <div className="mt-4 flex items-center justify-between">
        {catNav.map((item, i) => (
          <Link
            to={`/products?category=${item?.name}`}
            className="group relative z-10 flex flex-col items-center gap-1 p-2"
            key={i}
          >
            <div className=" relative z-10 rounded-md">
              {/* Curved outward shadow effect */}
              <div className="absolute left-0 top-0 z-0 h-full w-full rounded-md bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full rounded-md bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full rounded-md bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full rounded-md bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              {/* End of curved outward shadow effect */}
              <div className="relative z-10 h-16 w-16 overflow-hidden rounded-md">
                <img
                  draggable="false"
                  className="h-full w-full object-contain"
                  src={item?.icon}
                  alt={item?.name}
                />
              </div>
            </div>

            <span className="text-sm font-medium text-gray-800 group-hover:text-primary-blue">
              {item?.name}
            </span>
          </Link>
        ))}
      </div>
    </section>
  )
}

export default Categories
