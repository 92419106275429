import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ReturnPolicyModal = ({ onClose, onAgree }) => {
  const [isChecked, setChecked] = useState(false)
  const navigate = useNavigate()

  const handleAgree = () => {
    if (isChecked) {
      onAgree()
      navigate('/') // Redirect to home page on agreement
    }
  }

  const handleClose = () => {
    onClose()
    navigate('/') // Redirect to home page on close
  }

  return (
    <div className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-opacity-80 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 py-2.5">
      <div className="relative z-10 mx-auto rounded-lg p-8">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}
        <div className="relative z-10 max-w-screen-lg overflow-hidden p-8">
          <div className="max-h-[500px] overflow-y-auto">
            <h2 className="mb-8 text-center text-2xl font-bold">
              Refunds and Return Policy
              <span
                className="relative left-1/2 mt-1 block h-1 w-20 -translate-x-1/2 transform rounded-md"
                style={{ backgroundColor: '#DB7A03' }}
              ></span>
            </h2>
            <ul className="text-left">
              <li className="mb-4" style={{ textAlign: 'center' }}>
                <strong style={{ fontWeight: 'bold' }}>
                  PLEASE READ THESE REFUNDS AND RETURN POLICY CAREFULLY!
                </strong>
              </li>
              <li>
                <strong>1. Application for Returns/Refunds</strong>
                <ul>
                  <li>
                    1.1 Subject to the terms and conditions in this Refunds and
                    Return Policy and the Terms of Use, a buyer may apply for
                    return of the purchased items (“Item”) and/or refund prior
                    to the expiry of the Kibandalabs Guarantee Period as stated
                    in the Terms of Service.
                  </li>
                  <li>
                    1.2 Kibandalabs Guarantee is a service provided by
                    Kibandalabs, on User’s request, to assist Users in dealing
                    with certain conflicts which may arise during the course of
                    a transaction. Users may communicate with each other
                    privately to resolve their differences or approach their
                    relevant local authorities to assist them in overcoming any
                    dispute prior, during or after using Kibandalabs Guarantee.
                  </li>
                </ul>
              </li>
              <li>
                <strong>2. Application for the Return of an Item</strong>
                <ul>
                  <li>
                    2.1 Buyer may only apply for the refund and/or return of the
                    Item in the following circumstances:
                  </li>
                  <ul>
                    <li>The Item has not been received by Buyer;</li>
                    <li>The Item was defective and/or damaged on delivery;</li>
                    <li>
                      The Item received is incomplete (missing quantity or
                      accessories);
                    </li>
                    <li>
                      Seller has delivered an Item that does not match the
                      agreed specification (e.g. wrong size, colour, etc.) to
                      Buyer;
                    </li>
                    <li>
                      The Item delivered to Buyer is materially different from
                      the description provided by Seller in the listing of the
                      Item;
                    </li>
                    <li>The Item received is a counterfeit item**;</li>
                    <li>
                      The Item received has physical damage (e.g. dented,
                      scratched, shattered);
                    </li>
                    <li>
                      The Item received is faulty (e.g. malfunction, does not
                      work as intended);
                    </li>
                    <li>
                      By way of private agreement with Seller and Seller must
                      send his/her confirmation to Kibandalabs confirming such
                      agreement;
                    </li>
                    <li>Change of mind*;</li>
                    <li>Expired products; or</li>
                    <li>Empty / suspicious parcels.</li>
                  </ul>
                  <li>
                    *Items of certain categories are not eligible for Change of
                    Mind returns and/or refunds. **Counterfeit item return
                    policy is applicable to Kibandalabs only.
                  </li>
                  <li>
                    2.2 Buyer’s application must be submitted via the
                    kibanda4devs’ website or mobile app. (or email).
                  </li>
                  <li>
                    2.3 Kibandalabs will review each Buyer’s application on a
                    case-by-case basis and, in its sole discretion, determine
                    whether Buyer’s application is successful.
                  </li>
                  <li>
                    2.4 Please note that, if required by Kibandalabs, Buyer must
                    ship the Item to a location designated by Kibandalabs within
                    five (5) calendar days after the return request is approved.
                  </li>
                  <li>
                    2.5 The approval of your request for refund and return will
                    be made by Kibandalabs in its sole discretion. Buyer
                    acknowledges and agrees that Kibandalabs’s decision is
                    final, conclusive and binding, and covenants and agrees that
                    it will not bring suit or otherwise assert any claim against
                    Kibandalabs or its affiliates in relation to such decision.
                  </li>
                  <li>
                    2.6 In the event where Buyer has commenced legal action
                    against Seller, Buyer may provide the formal notification
                    from the appropriate authority to Kibandalabs to request
                    Kibandalabs to continue to hold the purchase monies until a
                    formal determination is available. Kibandalabs will, at its
                    sole and absolute discretion, determine whether it is
                    necessary to continue to hold such purchase monies.
                  </li>
                </ul>
              </li>
              <li>
                <strong>3. Rights of Sellers</strong>
                <ul>
                  <li>
                    3.1 When Kibandalabs receives an application from Buyer for
                    the return of the Item and/or refund, Kibandalabs will
                    notify Seller in writing. Seller may respond to Buyer’s
                    application according to the steps provided by Kibandalabs
                    in the written notification. Seller must respond within the
                    time-frame stipulated in the written notification (the
                    “Stipulated Period”). Should Kibandalabs not hear from
                    Seller within the Stipulated Period, Kibandalabs will assume
                    that Seller has no response to Buyer’s application and will
                    proceed to assess Buyer’s application without further notice
                    to Seller. Kibandalabs will review each Seller’s response on
                    a case-by-case basis and, in its sole discretion, determine
                    whether Buyer’s application may be successful against the
                    circumstances stated by Seller.
                  </li>
                </ul>
              </li>
              <li>
                <strong>4. Rights of Kenyan Sellers</strong>
                <ul>
                  <li>
                    4.1 The rights of Sellers that are located in Kenya (“Kenyan
                    Sellers”) in relation to this policy are set out in this
                    Section 4, and Section 3 of this policy shall not apply to
                    Kenyan Sellers.
                  </li>
                  <li>
                    4.2 When Kibandalabs receives an application from Buyer for
                    the return of the Item and/or refund, Kibandalabs will
                    proceed to assess Buyer’s application without further notice
                    to Kenyan Seller. Kibandalabs will review each application
                    on a case-by-case basis and, in its sole discretion,
                    determine whether Buyer’s application may be successful.
                  </li>
                  <li>
                    4.3 Where Kibandalabs approves any refund or return request,
                    it shall notify Seller in writing. Kenyan Seller may appeal
                    such decision by email to Kibandalabs [insert full address]
                    and providing evidence in support of such appeal. Any appeal
                    must be made within three (3) days of the date the decision
                    is communicated to the buyer. If Kibandalabs upholds Kenyan
                    Seller’s appeal, it will disburse all or part of the
                    purchase monies to Kenyan Seller. Kenyan Seller acknowledges
                    and agrees that Kibandalabs’s decision is final, conclusive
                    and binding, and covenants and agrees that it will not bring
                    suit or otherwise assert any claim against Kibandalabs or
                    its affiliates in relation to such decision. Kenyan Seller
                    acknowledges and agrees that (i) the returned Item will not
                    be shipped back to Kenyan Seller; (ii) Kibandalabs reserves
                    the right to dispose of such returned Item in any manner it
                    sees fit; and (iii) Kenyan Seller shall be deemed to have
                    forfeited all rights to such returned Item.
                  </li>
                </ul>
              </li>
              <li>
                <strong>5. Condition of Returning Item</strong>
                <ul>
                  <li>
                    5.1 For Change of Mind returns and/or refunds, and subject
                    always to Clause 2.1, Buyer must ensure that the Item
                    remains unused with its tag (if any) remaining on the Item
                    when returning the Item. Without limiting the generality of
                    the foregoing:
                  </li>
                  <ul>
                    <li>
                      Items must be returned in their original, unopened
                      packaging, with seals and shrink-wrap intact. All tags,
                      manuals, and accessories that were included with the
                      purchase must be returned as well.
                    </li>
                  </ul>
                  <li>
                    5.2 For other refund and/or returns permitted under Clause
                    2.1, to enjoy a hassle-free experience when returning the
                    Item, Buyer should ensure that the Item, including any
                    complimentary items such as accessories that come with the
                    Item, must be returned to Seller in the condition received
                    by Buyer on delivery. We will recommend Buyer to take a
                    photo of the Item upon receipt.
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  6. Liability for Forward Shipping Fee and Return Shipping Fee
                </strong>
                <ul>
                  <li>
                    (a) In the scenario of an unforeseen error from the seller's
                    end (i.e. - damaged, faulty, incomplete or wrong Item
                    delivered to the buyer), the seller will bear buyer's
                    forward and return shipping fees.
                  </li>
                  <li>
                    (b) In the scenario of the buyer's change of mind, buyer,
                    depending on category of the Item, shall get seller's
                    consent prior to the return request and the seller shall
                    bear the return shipping fee. The buyer shall otherwise bear
                    the return shipping fee.
                  </li>
                  <li>
                    (c) In the scenario where both seller-buyer disputing the
                    party liable for the return shipping fee, Kibandalabs at its
                    sole discretion will determine the party liable for the
                    return shipping fee.
                  </li>
                </ul>
              </li>
              <li>
                <strong>7. Refunds</strong>
                <ul>
                  <li>
                    7.1 Buyer will only be refunded after Kibandalabs has
                    received the confirmation from Seller that Seller has
                    received the returned Item. In the event where Kibandalabs
                    does not hear from Seller within a specified time,
                    Kibandalabs will be at liberty to refund the applicable sum
                    to Buyer without further notice to Seller. The refund may be
                    made via the same payment method used by the Buyer for the
                    original purchase (“Original Payment Method”). In the event
                    that Kibandalabs is unable to effect the refund via the
                    Original Payment Method for any reason whatsoever
                    (including, but not limited to, where the Original Payment
                    Method is no longer valid or cannot be used to receive
                    funds), Buyer acknowledges and agrees that (i) Kibandalabs
                    may effect the refund via the issuance of Kibandalabs
                    vouchers (of equivalent value to the refund amount) in
                    accordance with such timeline as may be determined by
                    Kibandalabs, and (ii) upon issuance of such vouchers, Buyer
                    shall have no further right or claim against Kibandalabs in
                    connection with such refund.
                  </li>
                  <li>
                    7.2 In the event that a request for a refund has been
                    approved by Kibandalabs and the purchase monies had already
                    been disbursed to Seller, Kibandalabs may deduct the
                    necessary amount from the Seller’s Seller Balance in
                    accordance with the Terms of Service.
                  </li>
                </ul>
              </li>
              <li>
                <strong>8. Communication Between Buyer and Seller</strong>
                <ul>
                  <li>
                    8.1 Kibandalabs encourages Users to communicate with each
                    other in the event where problem arises in a transaction. As
                    Kibandalabs is a platform for Users to conduct trading,
                    Buyer should contact Seller directly for any issue relating
                    to the Item purchased.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="sticky bottom-0 p-4">
            <label className="mt-4 flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                checked={isChecked}
                onChange={() => setChecked(!isChecked)}
              />
              I have read and agree to the Refunds and Return Policy
            </label>
            <div className="flex justify-end">
              <button
                className="btn btn-danger-gradiant mr-2 border-0 bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 text-white"
                onClick={handleAgree}
              >
                Agree
              </button>
              <button
                className="bg-gray-500 px-4 py-2 text-white"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReturnPolicyModal
