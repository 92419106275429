const FormSidebar = ({ title, tag }) => {
  return (
    <div
      className="loginSidebar hidden w-2/5 flex-col gap-4 px-9 py-10 sm:flex"
      style={{
        backgroundColor: '#00c9b7',
        boxShadow: '0px 0px 10px 0px #00c9b7',
      }}
    >
      <h1 className="text-3xl font-medium text-white">{title}</h1>
      <p className="pr-2 text-lg text-gray-200">{tag}</p>
    </div>
  )
}

export default FormSidebar
