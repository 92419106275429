//src/components/Cart/OrderSuccess.jsx

import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MetaData from '../Layouts/MetaData'
import successfull from '../../assets/images/Transaction/success.png'
import failed from '../../assets/images/Transaction/failed.png'

const OrderSuccess = () => {
  const [success, setSuccess] = useState(true)
  const navigate = useNavigate()
  const [time, setTime] = useState(3)

  useEffect(() => {
    if (time === 0) {
      if (success) {
        navigate('/orders')
      } else {
        navigate('/cart')
      }
      return
    }
    const intervalId = setInterval(() => {
      setTime(time - 1)
    }, 1000)

    return () => clearInterval(intervalId)
    // eslint-disable-next-line
  }, [time])

  return (
    <>
      <MetaData title={`Transaction ${success ? 'Successfull' : 'Failed'}`} />

      <main className="mt-20 w-full">
        {/* <!-- row --> */}
        <div className="m-auto mb-7 flex flex-col items-center justify-center gap-2 rounded bg-white p-6 pb-12 shadow sm:mt-4 sm:w-4/6">
          <img
            draggable="false"
            className="h-60 w-1/2 object-contain"
            src={success ? successfull : failed}
            alt="Transaction Status"
          />
          <h1 className="text-2xl font-semibold">
            Transaction {success ? 'Successfull' : 'Failed'}
          </h1>
          <p className="mt-4 text-lg text-gray-800">
            Redirecting to {success ? 'orders' : 'cart'} in {time} sec
          </p>
          <Link
            to={success ? '/orders' : '/cart'}
            className="mt-2 rounded-sm bg-primary-blue px-6 py-2.5 uppercase text-white shadow hover:shadow-lg"
          >
            go to {success ? 'orders' : 'cart'}
          </Link>
        </div>
        {/* <!-- row --> */}
      </main>
    </>
  )
}

export default OrderSuccess
