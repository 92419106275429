//src/components/ArtWork/artList.jsx

import React, { useEffect, useState, useReducer } from 'react'
import NavBar from '../Guest/NavBar'
import CartModal from './cartModal'
import PreLoader from '../Guest/PreLoader'
import { getProduct } from '../../reducers/cartsReducer'
import { useDispatch, useSelector } from 'react-redux' // Import useSelector
import { useNavigate } from 'react-router-dom' // Import useHistory

const initialState = {
  selectedOption: '',
  selectedSize: '',
  price: 0,
  previewImage: '',
  selectedProducts: [],
}

function reducer(state, action) {
  switch (action.type) {
    case 'updateSelectedOption':
      return { ...state, selectedOption: action.payload }
    case 'updateSelectedSize':
      return { ...state, selectedSize: action.payload }
    // Add more cases as needed
    default:
      return state
  }
}

function Artlist() {
  const [selectedOption, setSelectedOption] = useState('')
  const [selectedSize, setSelectedSize] = useState('')
  const [price, setPrice] = useState(0)
  const [previewImage, setPreviewImage] = useState('') // Set default image
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [state, dispatch] = useReducer(reducer, initialState) // Initialize selectedProducts state
  useEffect(() => {
    if (
      state.selectedOption !== '' &&
      state.selectedSize !== '' &&
      state.price !== 0 &&
      state.previewImage !== ''
    ) {
      setSelectedProducts([
        ...state.selectedProducts,
        [
          state.previewImage,
          state.selectedOption,
          state.selectedSize,
          state.price,
        ],
      ])
    }
  }, [
    state.selectedOption,
    state.selectedSize,
    state.price,
    state.previewImage,
    state.selectedProducts,
  ])

  const dispatchz = useDispatch()
  const navigate = useNavigate()
  const persistedState = useSelector((state) => state.carts) // Get persisted state from Redux store

  useEffect(() => {
    if (
      selectedOption !== '' &&
      selectedSize !== '' &&
      price !== 0 &&
      previewImage !== ''
    ) {
      // Dispatch getProduct action with type: 'persistState'
      dispatchz(
        getProduct({
          selectedOption,
          selectedSize,
          price,
          previewImage,
          selectedProducts,
          type: 'persistState',
        })
      )
    }

    console.log(selectedOption, selectedSize, price)
  }, [
    price,
    selectedSize,
    selectedOption,
    previewImage,
    dispatchz,
    selectedProducts,
  ])

  // Add a useEffect to update selectedProducts state with persistedState

  const productImages = {
    wallpaper: 'assets/images/trending/laptop-skins/wallpaper2.jpeg',
    sticker: 'assets/images/trending/laptop-skins/sticker3.jpg',
    'laptop-skin': 'assets/images/trending/laptop-skins/laptop-skin2.jpeg',
    'framed-art': 'assets/images/trending/laptop-skins/framed-art.jpeg',
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
    setPrice(0) // Reset price

    // Update image preview based on product type
    const newImage = productImages[event.target.value]
    setPreviewImage(newImage || '') // Set image from mapping or default to empty
  }

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value)
    // Adjust price based on selected size
    let newSizePrice = 0 // default price for new size

    // Logic to adjust price based on the selected size
    if (selectedOption === 'wallpaper') {
      switch (event.target.value) {
        case '8x10':
          newSizePrice = 10 // adjust price for A4 size
          break
        case '11x14':
          newSizePrice = 15 // adjust price for A3 size
          break
        case '12x12':
          newSizePrice = 20 // adjust price for A3 size
          break
        case '16x20':
          newSizePrice = 15 // adjust price for A3 size
          break
        case '18x24':
          newSizePrice = 15 // adjust price for A3 size
          break
        case '20x20':
          newSizePrice = 15 // adjust price for A3 size
          break
        case '24x36':
          newSizePrice = 15 // adjust price for A3 size
          break
        case '30x40':
          newSizePrice = 15 // adjust price for A3 size
          break
        case '36x36':
          newSizePrice = 15 // adjust price for A3 size
          break
        case '40x60':
          newSizePrice = 15 // adjust price for A3 size
          break
        case '48x72':
          newSizePrice = 15 // adjust price for A3 size
          break
        // Add more cases for other sizes if needed
        default:
          newSizePrice = 0 // default price for other sizes
      }
    } else if (selectedOption === 'sticker') {
      switch (event.target.value) {
        case '1.5x1.5':
          newSizePrice = 3 // adjust price for 1.5" x 1.5" sticker size
          break
        case '2x2':
          newSizePrice = 4 // adjust price for 2" x 2" sticker size
          break
        case '2.4x2.4':
          newSizePrice = 5 // adjust price for 2.4" x 2.4" sticker size
          break
        case '2.8x2.8':
          newSizePrice = 6 // adjust price for 2.8" x 2.8" sticker size
          break
        case '3x3':
          newSizePrice = 7 // adjust price for 3" x 3" sticker size
          break
        case '3.1x3.1':
          newSizePrice = 8 // adjust price for 3.1" x 3.1" sticker size
          break
        case '3.5x3.5':
          newSizePrice = 9 // adjust price for 3.5" x 3.5" sticker size
          break
        case '4x4':
          newSizePrice = 10 // adjust price for 4" x 4" sticker size
          break
        // Add more cases for other sizes if needed
        default:
          newSizePrice = 0 // default price for other sizes
      }
    } else if (selectedOption === 'laptop-skin') {
      switch (event.target.value) {
        case '11-inch':
          newSizePrice = 15 // adjust price for 11-inch laptop
          break
        case '12-inch':
          newSizePrice = 18 // adjust price for 12-inch laptop
          break
        case '13-inch':
          newSizePrice = 20 // adjust price for 13-inch laptop
          break
        case '14-inch':
          newSizePrice = 22 // adjust price for 14-inch laptop
          break
        case '15-inch':
          newSizePrice = 25 // adjust price for 15-inch laptop
          break
        case '17-inch':
          newSizePrice = 30 // adjust price for 17-inch laptop
          break
        // Add more cases for other sizes if needed
        default:
          newSizePrice = 0 // default price for other sizes
      }
    } else if (selectedOption === 'framed-art') {
      switch (event.target.value) {
        case '4x6':
          newSizePrice = 15 // adjust price for 4" x 6" framed art
          break
        case '5x7':
          newSizePrice = 20 // adjust price for 5" x 7" framed art
          break
        case '8x10':
          newSizePrice = 30 // adjust price for 8" x 10" framed art
          break
        case '11x14':
          newSizePrice = 40 // adjust price for 11" x 14" framed art
          break
        case '8.5x11':
          newSizePrice = 35 // adjust price for 8.5" x 11" framed art
          break
        case '16x20':
          newSizePrice = 50 // adjust price for 16" x 20" framed art
          break
        case '20x24':
          newSizePrice = 60 // adjust price for 20" x 24" framed art
          break
        // Add more cases for other sizes if needed
        default:
          newSizePrice = 0 // default price for other sizes
      }
    }

    setPrice(newSizePrice) // Update the price state with the new size price
  }

  return (
    <>
      <PreLoader />
      <NavBar />
      <div className="trending-area trending-area-bg pt-100 pb-70">
        <div className="container">
          <div className="trending-slider owl-carousel owl-theme pt-45">
            <div className="trending-card">
              <div className="trending-card">
                <div className="trending-card-img">
                  {!previewImage && ( // Render default image block only if no preview is set
                    <>
                      <a href="item-details.html">
                        <img
                          src="assets/images/trending/trending-style1.jpeg"
                          alt="Images"
                        />
                      </a>
                      <span>
                        <i className="ri-heart-line"></i> 240
                      </span>
                      <a href="/view_details">
                        <button
                          type="button"
                          className="default-btn border-radius-5"
                        >
                          View Details
                        </button>
                      </a>
                    </>
                  )}

                  {previewImage && ( // Render preview image conditionally
                    <div className="product-preview">
                      <img src={previewImage} alt="Product Preview" />
                    </div>
                  )}
                </div>
                <div className="content">
                  <h3>
                    <a href="item-details.html">Earth</a>
                  </h3>
                  <div className="mb-4 flex items-center justify-between">
                    <div>
                      <label
                        htmlFor="product-type"
                        className="mb-2 mr-2 text-sm"
                      >
                        Use This Art As:
                      </label>
                      <select
                        id="product-type"
                        value={selectedOption}
                        onChange={handleOptionChange}
                        className="mr-2 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 py-1 text-center text-sm font-bold text-white shadow-lg hover:scale-105 hover:shadow-2xl"
                      >
                        <option value="" disabled>
                          Select product
                        </option>
                        <option value="wallpaper">Wallpaper</option>
                        <option value="sticker">Sticker</option>
                        <option value="laptop-skin">Laptop Skin</option>
                        <option value="framed-art">Framed Art</option>
                      </select>
                      <p className="mt-2 text-xs text-gray-500">
                        Select what you'd like to customize
                      </p>
                    </div>
                    {price > 0 && (
                      <p className="text-shadow: 0 0 7px rgba(0, 255, 255, 0.7); ml-2 mr-2 text-sm font-bold">
                        Price: KSh{price}
                      </p>
                    )}
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex w-16 items-center justify-between">
                      <label
                        htmlFor="product-size"
                        className="mb-2 mr-2 text-sm"
                      >
                        Size:
                      </label>
                      <select
                        id="product-size"
                        value={selectedSize}
                        onChange={handleSizeChange}
                        className="mr-2 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 py-1 text-center text-sm font-bold text-white shadow-lg hover:scale-105 hover:shadow-2xl"
                      >
                        <option value="">Select size</option>
                        {/* Add options for sizes based on selected product type */}
                        {selectedOption === 'wallpaper' && (
                          <>
                            {/* Other options... */}
                            <option value="8x10">8"x10"</option>
                            <option value="11x14">11"x14"</option>
                            <option value="24x36">12"x12"</option>
                            {/* Add more sizes as needed */}
                          </>
                        )}
                        {selectedOption === 'sticker' && (
                          <>
                            <option value="1.5x1.5">1.5"x1.5"</option>
                            <option value="2x2">2"x2"</option>
                            <option value="2.4x2.4">2.4"x2.4"</option>
                            {/* Add more sizes as needed */}
                          </>
                        )}
                        {selectedOption === 'laptop-skin' && (
                          <>
                            <option value="11-inch">11-inch</option>
                            <option value="12-inch">12-inch</option>
                            <option value="13-inch">13-inch</option>
                          </>
                        )}
                        {selectedOption === 'framed-art' && (
                          <>
                            <option value="4x6">4"x6"</option>
                            <option value="5x7">5"x7"</option>
                            <option value="8x10">8"x10"</option>
                          </>
                        )}
                      </select>
                    </div>

                    <button
                      className="ml-1 inline-block rounded bg-pink-900 px-3 py-1 text-sm font-bold text-white hover:bg-purple-500"
                      onClick={(event) => {
                        event.preventDefault() // Prevent default link behavior
                        setIsModalOpen(true)
                      }}
                    >
                      Add To Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        // In the Artlist component where CartModal is rendered
        <CartModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          productType={selectedOption}
          size={selectedSize}
          price={price}
          previewImage={previewImage}
          selectedProducts={[
            { type: selectedOption, size: selectedSize, price },
          ]} // Pass selected products
        />
      )}
    </>
  )
}

export default Artlist
