import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { submitWholesaleForm } from '../../../actions/formsAction'

const WholesalePage = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    organization: '',
    phone: '',
    message: '',
  })

  const [submittedFullName, setSubmittedFullName] = useState('')
  const dispatch = useDispatch()
  const { loading, success, error } = useSelector(
    (state) => state.wholesaleRegistrationForm
  )

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmittedFullName(formData.fullName)
    dispatch(submitWholesaleForm(formData))
  }

  useEffect(() => {
    if (success) {
      setFormData({
        fullName: '',
        email: '',
        organization: '',
        phone: '',
        message: '',
      })
    }
  }, [success])

  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-100">
      <div className="container relative z-10 mx-auto">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}

        <div className="relative z-10 mx-auto max-w-4xl rounded-lg p-8">
          <h1
            className="relative mb-6 text-center font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            Wholesale Purchasing
            <span
              className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            />
          </h1>
          <div className="mx-auto max-w-4xl">
            <p className="mb-4 text-lg">
              Welcome to our Wholesale Purchasing page! We offer special pricing
              and bulk purchase options for cooperates, organizations, and
              individuals looking to buy products in large quantities.
            </p>
            <p className="mb-4 text-lg">
              Whether you need products for your business, events, or any other
              purposes, we've got you covered. Purchasing in bulk through our
              website is simple and convenient.
            </p>
            <p className="mb-4 text-lg">
              To get started with wholesale purchasing, please contact our sales
              team at{' '}
              <span className="font-semibold">sales@kibanda4devs.com</span> or
              fill out the form below:
            </p>
            <form
              className="mb-4  rounded-md bg-white px-8 pb-8 pt-6"
              onSubmit={handleSubmit}
            >
              <div className="mb-4">
                <label
                  className="mb-2 block text-sm font-bold text-gray-700"
                  htmlFor="fullName"
                >
                  Full Name
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                  id="fullName"
                  type="text"
                  placeholder="Full Name"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="mb-2 block text-sm font-bold text-gray-700"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                  id="email"
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="mb-2 block text-sm font-bold text-gray-700"
                  htmlFor="organization"
                >
                  Organization Name (if applicable)
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                  id="organization"
                  type="text"
                  placeholder="Organization Name"
                  name="organization"
                  value={formData.organization}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label
                  className="mb-2 block text-sm font-bold text-gray-700"
                  htmlFor="phone"
                >
                  Phone Number
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                  id="phone"
                  type="tel"
                  placeholder="Phone Number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  className="mb-2 block text-sm font-bold text-gray-700"
                  htmlFor="message"
                >
                  Message (Optional)
                </label>
                <textarea
                  className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                  id="message"
                  rows="5"
                  placeholder="Your message..."
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="flex items-center justify-center">
                <button
                  className="focus:shadow-outline rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </form>
            {success && (
              <div className="alert alert-success mt-4">
                Thank you, {submittedFullName}! Your wholesale information has
                been successfully submitted. We appreciate your interest in
                partnering with us. Our team will review your application
                promptly. If further information is required, we will contact
                you via the provided email address.
              </div>
            )}

            {error && (
              <div className="alert alert-danger mt-4">
                An error occurred. Please try again later.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WholesalePage
