//src/components/ArtWork/cartModal.jsx

import React, { useState } from 'react'

const CartModal = ({
  isOpen,
  onClose,
  productType,
  size,
  price,
  selectedProducts,
  previewImage,
}) => {
  const [quantity, setQuantity] = useState(1)

  const handleQuantityChange = (change) => {
    let newQuantity = quantity + change
    if (newQuantity < 1) {
      newQuantity = 1
    }
    setQuantity(newQuantity)
  }

  return (
    <div
      className={`modal fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black/80 ${isOpen ? '' : 'hidden'}`}
    >
      <div className="rounded-lg bg-gray-900 p-8 font-mono text-gray-200 shadow-xl">
        <button
          onClick={onClose}
          className="ml-auto flex rounded-md bg-gray-800 px-3 py-1 text-white hover:text-green-400"
        >
          Close
        </button>
        <h2 className="mb-4 text-xl font-bold">Your Cart</h2>

        <div className="mb-4 flex items-center">
          <div className="mr-4">
            {/* Add a small preview image here if you like */}
          </div>

          <div>
            <img className="h-24 w-24" src={previewImage} />
            <p>Product: {productType}</p>
            <p>Size: {size}</p>
            <p>Price: KSh{price}</p>
            <div className="mt-2 flex items-center">
              <button
                onClick={() => handleQuantityChange(-1)}
                className="rounded-md bg-gradient-to-r from-blue-500 to-indigo-600 px-3 py-1 text-white hover:shadow-lg hover:shadow-green-500/50"
              >
                -
              </button>

              <p className="mx-4">{quantity}</p>

              <button
                onClick={() => handleQuantityChange(1)}
                className="rounded-md bg-gradient-to-r from-blue-500 to-indigo-600 px-3 py-1 text-white hover:shadow-lg hover:shadow-green-500/50"
              >
                +
              </button>
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="mt-4 flex justify-between">
          <button
            onClick={onClose}
            className="mr-2 rounded-md bg-gray-700 px-4 py-2 text-sm text-white hover:bg-gray-800"
          >
            Continue Shopping
          </button>
          <a
            href="/cart_and_checkout"
            className="ml-2 rounded-md bg-green-500 px-4 py-2 text-sm text-white transition-all hover:bg-green-600 hover:shadow-lg hover:shadow-green-500/50"
          >
            View Cart & Checkout
          </a>
        </div>
      </div>
    </div>
  )
}

export default CartModal
