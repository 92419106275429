import { createSlice } from '@reduxjs/toolkit'

// Load state from local storage if available
const persistedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {}

const initialState = {
  // Merge persisted state with initial state
  ...persistedState,
  selectedOption: null,
  selectedSize: null,
  price: null,
  previewImage: null,
  selectedProducts: [],
  total: 0,
  liveLocation: null,
  currentLocation: null,
  address: null,
}

const cartsReducer = createSlice({
  name: 'second',
  initialState,
  reducers: {
    getProduct: (state, action) => {
      const {
        selectedOption,
        selectedSize,
        price,
        selectedProducts,
        total,
        liveLocation,
        currentLocation,
        address,
        previewImage,
        type,
      } = action.payload
      state.selectedOption = selectedOption
      state.selectedSize = selectedSize
      state.price = price
      state.selectedProducts = selectedProducts
      state.total = total
      state.previewImage = previewImage
      state.liveLocation = liveLocation
      state.currentLocation = currentLocation
      state.address = address
      if (type === 'persistState') {
        // Persist state to localStorage only if type is 'persistState'
        localStorage.setItem('reduxState', JSON.stringify(state))
      }
    },
  },
  // Reducer to update state on persist action
  extraReducers: (builder) => {
    builder.addCase('persistState', (state) => {
      localStorage.setItem('reduxState', JSON.stringify(state))
    })
  },
})

export const { getProduct } = cartsReducer.actions

export default cartsReducer.reducer
