import React, { useState } from 'react'

const ApplyFormPage = () => {
  const [cvFile, setCvFile] = useState(null)
  const [coverLetterFile, setCoverLetterFile] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault()
    // Handle form submission logic here
  }

  const handleCvChange = (event) => {
    const file = event.target.files[0]
    setCvFile(file)
  }

  const handleCoverLetterChange = (event) => {
    const file = event.target.files[0]
    setCoverLetterFile(file)
  }

  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-200">
      <div className="container relative z-10 mx-auto">
        <div className=" relative z-10 mx-auto rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <h1
            className="relative z-10 mb-2 text-center font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            Apply Now
            <span
              className="absolute relative left-1/2 z-10 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            ></span>
          </h1>
          <form className="relative z-10" onSubmit={handleSubmit}>
            <div className="relative z-10 mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="fullName"
              >
                Full Name
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="fullName"
                type="text"
                placeholder="Full Name"
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="email"
                type="email"
                placeholder="Email"
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="cv"
              >
                Upload CV
              </label>
              <input
                className="block"
                type="file"
                id="cv"
                accept=".pdf,.doc,.docx"
                onChange={handleCvChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="coverLetter"
              >
                Upload Cover Letter
              </label>
              <input
                className="block"
                type="file"
                id="coverLetter"
                accept=".pdf,.doc,.docx"
                onChange={handleCoverLetterChange}
                required
              />
            </div>
            <div className="mb-6">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="message"
                rows="5"
                placeholder="Your message..."
                required
              ></textarea>
            </div>
            <div className="flex items-center justify-center">
              <button
                className="focus:shadow-outline rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ApplyFormPage
