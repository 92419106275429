import { useEffect, useState } from 'react'
import Sidebar from './Sidebar/Sidebar'
import MenuIcon from '@mui/icons-material/Menu'

const Dashboard = ({ activeTab, children }) => {
  const [onMobile, setOnMobile] = useState(false)
  const [toggleSidebar, setToggleSidebar] = useState(false)

  useEffect(() => {
    if (window.innerWidth < 600) {
      setOnMobile(true)
    }
  }, [])

  return (
    <>
      <main className="mt-14 flex min-h-screen sm:min-w-full">
        {!onMobile && <Sidebar activeTab={activeTab} />}
        {toggleSidebar && (
          <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar} />
        )}

        <div className="min-h-screen w-full sm:ml-72 sm:w-4/5">
          <div className="flex flex-col gap-6 overflow-hidden p-2 pb-6 sm:m-8">
            <button
              onClick={() => setToggleSidebar(true)}
              className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-700 text-white shadow sm:hidden"
            >
              <MenuIcon />
            </button>
            {children}
          </div>
        </div>
      </main>
    </>
  )
}

export default Dashboard
