import React from 'react'
import { useParams } from 'react-router-dom'

const StoryPage = ({ stories }) => {
  const { slug } = useParams()
  const story = stories?.find((story) => story.slug === slug)

  if (!story) {
    return <div className="text-center text-gray-600">Story not found</div>
  }

  return (
    <div className="bg-white py-8">
      <div className="container mx-auto">
        <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
          <img
            src={story.image}
            alt={story.title}
            className="mb-6 h-96 w-full rounded-lg object-cover object-center shadow-md"
          />
          <h1 className="mb-4 text-3xl font-bold text-gray-800">
            {story.title}
          </h1>
          <p className="mb-8 text-gray-600">{story.description}</p>
          <div className="prose max-w-none">
            {/* Sample story content */}
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non
              risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing
              nec, ultricies sed, dolor. Cras elementum ultrices diam.
            </p>
            <p>
              Maecenas ligula massa, varius a, semper congue, euismod non, mi.
              Proin porttitor, orci nec nonummy molestie, enim est eleifend mi,
              non fermentum diam nisl sit amet erat.
            </p>
            <p>
              Duis semper. Duis arcu massa, scelerisque vitae, consequat in,
              pretium a, enim. Pellentesque congue.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoryPage
