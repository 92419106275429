import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PriceSidebar from './PriceSidebar'
import Stepper from './Stepper'
import { useSnackbar } from 'notistack'
import { saveShippingInfo } from '../../actions/cartAction'
import { useNavigate } from 'react-router-dom'
import MetaData from '../Layouts/MetaData'
import counties from '../../utils/counties'
import { newOrder } from '../../actions/orderAction'

const Shipping = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { cartItems } = useSelector((state) => state.cart)
  const { shippingInfo } = useSelector((state) => state.cart)

  const [address, setAddress] = useState(shippingInfo?.address)
  const [city, setCity] = useState(shippingInfo?.city)
  const [country, setCountry] = useState('KE')
  const [state, setState] = useState(shippingInfo?.state)
  const [pincode, setPincode] = useState(shippingInfo?.pincode)
  const [phoneNo, setPhoneNo] = useState(shippingInfo?.phoneNo)

  const { user } = useSelector((state) => state?.user)

  const totalPrice = cartItems?.reduce(
    (sum, item) => sum + item?.price * item?.quantity,
    0
  )

  const orderData = {
    shippingInfo,
    orderItems: cartItems,
    totalPrice,
  }

  const shippingSubmit = (e) => {
    e.preventDefault()

    if (phoneNo.length < 10 || phoneNo.length > 10) {
      enqueueSnackbar('Invalid Phone Number', { variant: 'error' })
      return
    }
    dispatch(
      saveShippingInfo({ address, city, country, state, pincode, phoneNo })
    )
    dispatch(newOrder(orderData))
    navigate('/order/confirm')
  }

  return (
    <>
      <MetaData title="Kibanda4Devs: Shipping Details" />
      <main className="mt-20 w-full bg-gray-200 py-8">
        {/* <!-- row --> */}
        <div className="m-auto mt-0 flex w-full flex-col gap-3.5 overflow-hidden sm:mb-7 sm:mt-4 sm:w-11/12 sm:flex-row">
          {/* <!-- cart column --> */}
          <div className="flex-1">
            <Stepper activeStep={1}>
              <div className="flex w-full items-center justify-center bg-white">
                <form
                  onSubmit={shippingSubmit}
                  autoComplete="off"
                  className="mx-1 my-4 flex w-full flex-col justify-start gap-3 sm:mx-8 sm:w-3/4 "
                >
                  <TextField
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    fullWidth
                    label="Address"
                    variant="outlined"
                    required
                  />

                  <div className="flex gap-6">
                    <TextField
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      type="number"
                      label="Pincode"
                      fullWidth
                      variant="outlined"
                      required
                    />
                    <TextField
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      type="number"
                      label="Phone No"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </div>

                  <div className="flex gap-6">
                    <TextField
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      label="City"
                      fullWidth
                      variant="outlined"
                      required
                    />
                    <TextField
                      label="Landmark (Optional)"
                      fullWidth
                      variant="outlined"
                    />
                  </div>

                  <div className="flex gap-6">
                    <FormControl fullWidth>
                      <InputLabel id="country-select">Country</InputLabel>
                      <Select
                        labelId="country-select"
                        id="country-select"
                        defaultValue={country}
                        disabled
                        label="Country"
                        // onChange={(e) => setCountry(e.target.value)}
                      >
                        <MenuItem value={'KE'}>Kenya</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth disabled={country ? false : true}>
                      <InputLabel id="state-select">County</InputLabel>
                      <Select
                        labelId="state-select"
                        id="state-select"
                        value={state}
                        label="State"
                        onChange={(e) => setState(e.target.value)}
                        required
                      >
                        {counties.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <>
                    <button
                      type="submit"
                      className="relative z-10 my-2 w-full rounded-sm bg-primary-orange py-3.5 text-sm font-medium uppercase text-white shadow outline-none hover:shadow-lg sm:w-1/3"
                    >
                      save and deliver here
                    </button>
                  </>
                </form>
              </div>
            </Stepper>
          </div>

          <PriceSidebar cartItems={cartItems} />
        </div>
      </main>
    </>
  )
}

export default Shipping
