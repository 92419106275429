import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { clearErrors, getOrderDetails } from '../../actions/orderAction'
import Loader from '../Layouts/Loader'
import TrackStepper from './TrackStepper'
import MinCategory from '../Layouts/MinCategory'
import MetaData from '../Layouts/MetaData'

const OrderDetails = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const params = useParams()

  const { order, error, loading } = useSelector((state) => state.orderDetails)

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' })
      dispatch(clearErrors())
    }
    dispatch(getOrderDetails(params.id))
  }, [dispatch, error, params.id, enqueueSnackbar])

  return (
    <>
      <MetaData title="Order Details | Kibanda4Devs" />

      <MinCategory />
      <main className="mt-14 min-h-screen w-full bg-gray-200 sm:mt-4">
        {loading ? (
          <Loader />
        ) : (
          <>
            {order && order.user && order.shippingInfo && (
              <div className="mx-auto mb-8 mt-8 flex max-w-6xl flex-col gap-4 bg-gray-200">
                <div className=" relative z-10">
                  {/* Curved outward shadow effect */}
                  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                  {/* End of curved outward shadow effect */}
                  <div className="relative z-10 flex min-w-full rounded-sm shadow">
                    <div className="border-r sm:w-1/2">
                      <div className="mx-10 my-8 flex flex-col gap-3">
                        <h3 className="text-lg font-medium">
                          Delivery Address
                        </h3>
                        <h4 className="font-medium">{order.user.name}</h4>
                        <p className="text-sm">{`${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state} - ${order.shippingInfo.pincode}`}</p>
                        <div className="flex gap-2 text-sm">
                          <p className="font-medium">Email:</p>
                          <p
                            className="rounded-md px-2 py-1"
                            style={{ backgroundColor: '#FFA500' }}
                          >
                            {order.user.email}
                          </p>
                        </div>
                        <div className="flex gap-2 text-sm">
                          <p className="font-medium">Phone Number:</p>
                          <p
                            className=" rounded-md px-2 py-1"
                            style={{ backgroundColor: '#FFA500' }}
                          >
                            {order.shippingInfo.phoneNo}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {order.orderItems &&
                  order.orderItems.map((item) => {
                    const { _id, image, name, price, quantity } = item

                    return (
                      <div
                        className="flex min-w-full flex-col rounded-sm  px-2 py-5 shadow sm:flex-row"
                        key={_id}
                      >
                        <div className="flex flex-col gap-2 sm:w-1/2 sm:flex-row">
                          <div className="h-20 w-full sm:w-32">
                            <img
                              draggable="false"
                              className="h-full w-full object-contain"
                              src={image}
                              alt={name}
                            />
                          </div>
                          <div className="flex flex-col gap-1 overflow-hidden">
                            <p className="text-sm">
                              {name.length > 60
                                ? `${name.substring(0, 60)}...`
                                : name}
                            </p>
                            <p className="mt-2 text-xs text-gray-600">
                              Quantity: {quantity}
                            </p>
                            <p className="text-xs text-gray-600">
                              Price: KSh{price.toLocaleString()}
                            </p>
                            <span className="font-medium">
                              Total: Ksh{(quantity * price).toLocaleString()}
                            </span>
                          </div>
                        </div>

                        <div className="flex w-full flex-col sm:w-1/2">
                          <h3 className="font-medium sm:text-center">
                            Order Status
                          </h3>
                          <TrackStepper
                            orderOn={order.createdAt}
                            shippedAt={order.shippedAt}
                            deliveredAt={order.deliveredAt}
                            activeStep={
                              order.orderStatus === 'Delivered'
                                ? 2
                                : order.orderStatus === 'Shipped'
                                  ? 1
                                  : 0
                            }
                          />
                        </div>
                      </div>
                    )
                  })}
              </div>
            )}
          </>
        )}
      </main>
    </>
  )
}

export default OrderDetails
