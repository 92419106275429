import { useSelector } from 'react-redux'
import CheckIcon from '@mui/icons-material/Check'
import OrderStatus from './OrderStatus'

const Stepper = ({ activeStep, children }) => {
  const { user } = useSelector((state) => state.user)
  const { shippingInfo, cartItems } = useSelector((state) => state.cart)

  const address = `${shippingInfo?.address}, ${shippingInfo?.city}, ${shippingInfo?.state} - ${shippingInfo?.pincode}`

  const steps = [
    {
      label: 'LOGIN',
      desc: (
        <div className=" relative z-10 mx-auto rounded-lg p-4">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <p className="relative z-10 py-1 text-sm font-medium">
            {/* {user.name}  */}
            <span
              className="relative z-10 rounded-md px-2 py-2 text-sm font-normal"
              style={{ backgroundColor: '#FFA500' }}
            >
              {user?.email}
            </span>
          </p>
        </div>
      ),
    },
    {
      label: 'DELIVERY ADDRESS',
      desc: (
        <div className=" relative z-10 mx-auto rounded-lg p-4">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <p className="relative z-10 text-sm font-medium">
            {/* {user.name}  */}
            <span
              className="relative z-10 rounded-md px-2 py-2 text-sm font-normal"
              style={{ backgroundColor: '#FFA500' }}
            >
              {address}
            </span>
          </p>
        </div>
      ),
    },
    {
      label: 'ORDER SUMMARY',

      desc: (
        <p className="relative z-10 text-sm font-medium">
          <div className=" relative z-10 rounded-lg p-4">
            {/* Curved outward shadow effect */}
            <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
            <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
            <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
            <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
            {/* End of curved outward shadow effect */}
            <span
              className="relative z-10 flex items-center justify-center rounded-md px-1 py-1 font-medium"
              style={{ backgroundColor: '#FFA500' }}
            >
              {cartItems?.length} Item
            </span>
          </div>
        </p>
      ),
    },
    {
      label: 'PAYMENT OPTIONS',

      desc: <p className="text-sm font-medium">PayStack</p>,
    },
  ]

  return (
    <div className="flex flex-col gap-4  bg-gray-200 ">
      {steps.map((step, index) => {
        return (
          <>
            {activeStep === index ? (
              <div className="relative z-10 flex flex-col rounded-lg rounded-sm shadow">
                {/* Curved outward shadow effect */}
                <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                {/* End of curved outward shadow effect */}
                <div
                  className="relative z-10 flex items-center gap-4 rounded-t-sm px-6 py-2"
                  style={{
                    backgroundColor: '#00c9b7',
                    boxShadow: '0px 0px 10px 0px #00c9b7',
                  }}
                >
                  <span className="relative z-10 flex h-5 w-5 items-center justify-center rounded-sm bg-white text-xs font-medium text-primary-blue">
                    {index + 1}
                  </span>
                  <h2 className="relative z-10 font-medium text-white">
                    {step.label}
                  </h2>
                </div>
                {children}
              </div>
            ) : (
              <>
                {activeStep > index ? (
                  <Step isDesc={true} {...step} index={index} />
                ) : (
                  <Step isDesc={false} {...step} index={index} />
                )}
              </>
            )}
          </>
        )
      })}
    </div>
  )
}

const Step = ({ isDesc, label, desc, index }) => {
  return (
    <div className="relative z-10 flex rounded-sm bg-white px-4 py-3 pb-4 shadow">
      {/* Curved outward shadow effect */}
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
      {/* End of curved outward shadow effect */}
      <span className="relative z-10 ml-2 mr-4 mt-2 flex h-5 w-5 items-center justify-center rounded-sm bg-gray-100 text-xs font-medium text-primary-blue">
        {index + 1}
      </span>
      <div className="relative z-10 mt-1 flex flex-col gap-0.5">
        <h2 className="relative z-10 flex items-center gap-2 font-medium text-gray-500">
          {label}
          {isDesc && (
            <span className="relative z-10 mb-1 text-primary-blue">
              <CheckIcon sx={{ fontSize: '20px' }} />
            </span>
          )}
        </h2>
        {isDesc && desc}
      </div>
    </div>
  )
}

export default Stepper
