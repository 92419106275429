import React from 'react'
import { Link } from 'react-router-dom'
import MissionImage from '../../../assets/images/mission.avif'
import CreditCard from '../../../assets/images/credit4.png'
import Mpesa from '../../../assets/images/mpesalogo.png'
import OrderProcess from '../../../assets/images/order-process.png'

function ConsumerServicesPage() {
  return (
    <div className="cancellation-returns-page relative flex items-center justify-center bg-gray-200">
      <div className="container relative z-10 mx-auto">
        {/* Header Section */}
        <div className="mb-12 mt-6 text-center">
          <h1
            className="relative z-10 mb-2 text-center font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            Shop With Us For Any Developer Product
            <span
              className="absolute relative left-1/2 z-10 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            ></span>
          </h1>
        </div>

        <div className=" relative z-10 mx-auto mb-6 rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}

          {/* Consumer Services Section */}
          <section className="relative z-10 mb-12 flex flex-col items-center md:flex-row">
            <div className="md:w-1/2 md:pr-8">
              <h2
                className="mb-4 ml-8 text-2xl font-semibold"
                style={{ color: '#DB7A03' }}
              >
                Consumer Services
              </h2>
              <p className="mb-4 ml-8 text-gray-600">
                At Kibanda4devs, we offer a wide range of products to meet the
                needs of developers. From hardware to software, we provide
                top-quality items to enhance your productivity and performance.
                Shop with us to experience seamless service and excellent
                customer support.
              </p>
              {/* Start Shopping Button */}
              <Link
                to="/"
                className="ml-8 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
              >
                Start Shopping
              </Link>
            </div>
            <div className="ml-8 md:w-1/2">
              <img
                src={MissionImage}
                alt="Welcome Image"
                className="rounded-lg"
              />
            </div>
          </section>
        </div>

        {/* Advertising Section */}
        <div className=" relative z-10 mx-auto mb-6 rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <section className="relative z-10 mb-12 flex flex-col items-center md:flex-row">
            <div className="ml-4 md:w-1/2">
              {' '}
              {/* Reduced left margin */}
              <img
                src={OrderProcess}
                alt="Advertise Your Business"
                className="rounded-lg shadow-md"
              />
            </div>
            <div className="md:w-1/2 md:pl-4">
              {' '}
              {/* Reduced left padding */}
              <h2
                className="mb-4 ml-8 text-2xl font-semibold"
                style={{ color: '#DB7A03' }}
              >
                Super Easy to Shop and Pay
              </h2>
              <p className="mb-4 ml-8 text-gray-600">
                <strong>Four Simple Steps:</strong>
                <ol className="ml-4 list-decimal">
                  <li>Choose your product</li>
                  <li>Add it to your cart</li>
                  <li>Select your payment method</li>
                  <li>We'll ship your product</li>
                </ol>
              </p>
              {/* Learn More Button */}
              <Link
                to="/#"
                className="ml-8 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
              >
                Learn More
              </Link>
            </div>
          </section>
        </div>

        {/* Payment Options Section */}

        <div className=" relative z-10 mx-auto mb-6 rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <section className="relative z-10 mb-12 flex flex-col items-center font-sans md:flex-row">
            <div className="ml-8 font-sans md:w-1/2">
              <div className="text-gray-600">
                <h2
                  className="mb-4 text-2xl font-semibold"
                  style={{ color: '#DB7A03' }}
                >
                  Secure and Easy Payments
                </h2>
                <p>
                  We understand the importance of a smooth checkout experience.
                  Kibanda4devs offers a variety of secure payment options to
                  ensure a hassle-free purchase:
                </p>
                <ul className="ml-6 list-disc">
                  <li>
                    <b>Credit/Debit Cards:</b> Pay conveniently using your Visa,
                    Mastercard, or other major credit/debit cards.
                  </li>
                  <li>
                    <b>Mobile Money:</b> Use popular mobile money services like
                    Mpesa and Airtel for a quick and secure checkout experience.
                  </li>
                  <li>
                    <b>Bank Transfers:</b> Transfer funds directly from your
                    bank account for larger purchases.
                  </li>
                </ul>
              </div>
            </div>
            {/* Images Grid */}
            <div className="ml-8 md:w-1/2">
              {' '}
              {/* Adjusted width */}
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                {' '}
                {/* Added grid classes */}
                <img
                  src={CreditCard}
                  alt="Credit Card"
                  className="rounded-lg "
                />
                <img src={Mpesa} alt="Mpesa" className="mt-6 rounded-lg" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default ConsumerServicesPage
