import React from 'react'
import { Link } from 'react-router-dom'
import AdvertImage from '../../../assets/images/advertise2.jpeg'

function Advertiseinfo() {
  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-200 py-8">
      <div className="container relative z-10 mx-auto text-center">
        {/* Advertisement Section */}
        <div className="relative z-10 mb-12 text-center">
          <h1
            className="relative mx-auto text-center  font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            Advertise with Us
            <span
              className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            />
          </h1>
          <div className="relative z-10 mx-auto mb-12 rounded-lg p-8 text-center  ">
            {/* Curved outward shadow effect */}
            <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
            <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
            <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
            <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
            {/* End of curved outward shadow effect */}
            <p className="relative z-10 mb-4 py-2 text-gray-600 ">
              Boost your business by advertising with Kibanda4devs. Reach
              thousands of potential customers and increase your sales!
            </p>
            <Link
              to="/advertise-form"
              className="relative z-10 ml-8 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
            >
              Get Started
            </Link>
          </div>
        </div>

        {/* Steps Section */}
        <div className="relative z-10 mx-auto mb-12 rounded-lg p-8 text-center  ">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <div className="relative z-10 mb-12 flex flex-col items-center md:flex-row">
            <div className="relative z-10 md:w-1/5">
              {/* Curved outward shadow effect */}
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              {/* End of curved outward shadow effect */}
              <h2 className="relative z-10 mb-2 text-lg font-semibold">
                Step 1
              </h2>
              <p className="relative z-10 mb-4 text-gray-600">
                Sign up for an advertiser account.
              </p>
            </div>
            <div className="md:w-1/5">
              <h2 className="mb-2 text-lg font-semibold">Step 2</h2>
              <p className="mb-4 text-gray-600">
                Create your ad campaign (video or photos).
              </p>
            </div>
            <div className="relative  z-10 md:w-1/5">
              {/* Curved outward shadow effect */}
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              {/* End of curved outward shadow effect */}
              <h2 className="relative z-10 mb-2  text-lg font-semibold">
                Step 3
              </h2>
              <p className="relative z-10  mb-4 text-gray-600">
                Share your ad content with us.
              </p>
            </div>
            <div className="md:w-1/5">
              <h2 className="mb-2 text-lg font-semibold">Step 4</h2>
              <p className="mb-4 text-gray-600">
                Select your budget and duration.
              </p>
            </div>
            <div className="relative  z-10 md:w-1/5">
              {/* Curved outward shadow effect */}
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              {/* End of curved outward shadow effect */}
              <h2 className="relative z-10 mb-2  text-lg font-semibold">
                Step 5
              </h2>
              <p className="relative z-10  mb-4 text-gray-600">
                Launch your ad and start reaching customers!
              </p>
            </div>
          </div>
        </div>

        {/* Advertisement Image */}
        <div className="">
          <img
            src={AdvertImage}
            alt="Advertisement"
            className="mx-auto rounded-lg shadow-md"
            style={{ maxWidth: '500px' }}
          />
        </div>
      </div>
    </div>
  )
}

export default Advertiseinfo
