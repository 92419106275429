import SearchIcon from '@mui/icons-material/Search'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Searchbar = () => {
  const [keyword, setKeyword] = useState('')
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (keyword.trim()) {
      navigate(`/products/${keyword}`)
    } else {
      navigate('/products')
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="flex w-full items-center justify-between overflow-hidden rounded-md bg-white px-1 py-1.5 shadow-md sm:w-9/12 sm:px-4"
    >
      <input
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        className="flex-1 border-none text-sm placeholder-gray-500 outline-none"
        type="text"
        placeholder="Search for products, brands and more..."
      />
      <button type="submit" className="text-[#00B6D0]">
        <SearchIcon />
      </button>
    </form>
  )
}

export default Searchbar
