import { getDiscount } from '../../../utils/functions'
import StarIcon from '@mui/icons-material/Star'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  addToWishlist,
  removeFromWishlist,
} from '../../../actions/wishlistAction'
import { useSnackbar } from 'notistack'

const Product = (props) => {
  const { _id, name, images, ratings, numOfReviews, price, cuttedPrice } = props

  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { wishlistItems } = useSelector((state) => state.wishlist)

  const itemInWishlist = wishlistItems?.some((i) => i.product === _id)

  const addToWishlistHandler = () => {
    if (itemInWishlist) {
      dispatch(removeFromWishlist(_id))
      enqueueSnackbar('Remove From Wishlist', { variant: 'success' })
    } else {
      dispatch(addToWishlist(_id))
      enqueueSnackbar('Added To Wishlist', { variant: 'success' })
    }
  }

  return (
    <div className="relative flex flex-col items-center gap-2 px-2 py-6">
      {/* <!-- image & product title --> */}
      <Link
        to={`/product/${_id}`}
        className="group flex flex-col items-center text-center"
      >
        <div className="h-36 w-36">
          <img
            draggable="false"
            className="h-full w-full object-contain"
            src={images[0]?.url}
            alt={name}
          />
        </div>
        <h2 className="mt-4 text-sm group-hover:text-primary-blue">
          {name.length > 50 ? `${name.substring(0, 50)}...` : name}
        </h2>
      </Link>
      {/* <!-- image & product title --> */}

      {/* <!-- product description --> */}
      <div className="flex flex-col items-center gap-2">
        {/* <!-- rating badge --> */}
        <span className="flex items-center gap-2 text-sm font-medium text-gray-500">
          <span className="flex items-center gap-0.5 rounded-sm bg-primary-green px-1.5 py-0.5 text-xs text-white">
            {ratings.toFixed(1)} <StarIcon sx={{ fontSize: '14px' }} />
          </span>
          <span>({numOfReviews.toLocaleString()})</span>
        </span>
        {/* <!-- rating badge --> */}

        {/* <!-- price container --> */}
        <div className="text-md flex items-center gap-1.5 font-medium">
          <span>KSh{price.toLocaleString()}</span>
          <span className="text-xs text-gray-500 line-through">
            KSh{cuttedPrice.toLocaleString()}
          </span>
          <span className="text-xs text-primary-green">
            {getDiscount(price, cuttedPrice)}%&nbsp;off
          </span>
        </div>
        {/* <!-- price container --> */}
      </div>
      {/* <!-- product description --> */}

      {/* <!-- wishlist badge --> */}
      <span
        onClick={addToWishlistHandler}
        className={`${itemInWishlist ? 'text-red-500' : 'text-gray-300 hover:text-red-500'} absolute right-2 top-5 cursor-pointer`}
      >
        <FavoriteIcon sx={{ fontSize: '16px' }} />
      </span>
      {/* <!-- wishlist badge --> */}
    </div>
  )
}

export default Product
