import { useEffect, useState } from 'react'
import { myOrders, clearErrors } from '../../actions/orderAction'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../Layouts/Loader'
import { useSnackbar } from 'notistack'
import OrderItem from './OrderItem'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import SearchIcon from '@mui/icons-material/Search'
import MinCategory from '../Layouts/MinCategory'
import MetaData from '../Layouts/MetaData'

const orderStatus = ['Processing', 'Shipped', 'Delivered']
const dt = new Date()
const ordertime = [dt.getMonth(), dt.getFullYear() - 1, dt.getFullYear() - 2]

const MyOrders = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [status, setStatus] = useState('')
  const [orderTime, setOrderTime] = useState(0)
  const [search, setSearch] = useState('')
  const [filteredOrders, setFilteredOrders] = useState([])

  const { orders, loading, error } = useSelector((state) => state.myOrders)

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' })
      dispatch(clearErrors())
    }
    dispatch(myOrders())
  }, [dispatch, error, enqueueSnackbar])

  useEffect(() => {
    if (loading === false) {
      setFilteredOrders(orders)
    }
  }, [loading, orders])
  // const  isAuthenticated  = useSelector((state) => state.user)
  // console.log(isAuthenticated,"gggggg")
  useEffect(() => {
    setSearch('')
    // console.log(status);
    // console.log(typeof orderTime);
    // console.log(orderTime);

    if (!status && +orderTime === 0) {
      setFilteredOrders(orders)
      return
    }

    if (status && orderTime) {
      if (+orderTime === dt.getMonth()) {
        const filteredArr = orders.filter(
          (order) =>
            order.orderStatus === status &&
            new Date(order.createdAt).getMonth() === +orderTime
        )
        setFilteredOrders(filteredArr)
      } else {
        const filteredArr = orders.filter(
          (order) =>
            order.orderStatus === status &&
            new Date(order.createdAt).getFullYear() === +orderTime
        )
        setFilteredOrders(filteredArr)
      }
    } else if (!status) {
      if (+orderTime === dt.getMonth()) {
        const filteredArr = orders.filter(
          (order) => new Date(order.createdAt).getMonth() === +orderTime
        )
        setFilteredOrders(filteredArr)
      } else {
        const filteredArr = orders.filter(
          (order) => new Date(order.createdAt).getFullYear() === +orderTime
        )
        setFilteredOrders(filteredArr)
      }
    } else {
      const filteredArr = orders.filter((order) => order.orderStatus === status)
      setFilteredOrders(filteredArr)
    }
    // eslint-disable-next-line
  }, [status, orderTime])

  const searchOrders = (e) => {
    e.preventDefault()
    if (!search.trim()) {
      enqueueSnackbar('Empty Input', { variant: 'warning' })
      return
    }
    const arr = orders.map((el) => ({
      ...el,
      orderItems: el.orderItems.filter((order) =>
        order.name.toLowerCase().includes(search.toLowerCase())
      ),
    }))
    setFilteredOrders(arr)
  }

  const clearFilters = () => {
    setStatus('')
    setOrderTime(0)
  }

  return (
    <>
      <MetaData title="My Orders | Kibanda4Devs" />

      <MinCategory />
      <main className="mt-16 w-full bg-gray-200 sm:mt-0 ">
        {/* <!-- row --> */}
        <div className="m-auto mb-7 mt-2 flex gap-3.5 sm:mx-3 sm:mt-6">
          {/* <!-- sidebar column  --> */}
          <div className="hidden w-1/5 flex-col px-1 sm:flex">
            {/* <!-- nav tiles --> */}
            <div className=" relative z-10 rounded-lg">
              {/* Curved outward shadow effect */}
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              {/* End of curved outward shadow effect */}
              <div className="relative z-10 mt-4 flex flex-col rounded-sm shadow">
                {/* <!-- filters header --> */}
                <div className="flex items-center justify-between gap-5 border-b px-4 py-2">
                  <p className="text-lg font-medium">Filters</p>
                  <span
                    onClick={clearFilters}
                    className="cursor-pointer rounded-md px-2 py-1 text-sm font-medium uppercase text-white "
                    style={{
                      backgroundColor: '#00c9b7',
                      boxShadow: '0px 0px 10px 0px #00c9b7',
                    }}
                  >
                    clear all
                  </span>
                </div>

                {/* <!-- order status checkboxes --> */}
                <div className="flex flex-col py-3 text-sm">
                  <span className="px-4 font-medium">ORDER STATUS</span>

                  {/* <!-- checkboxes --> */}
                  <div className="mt-1 flex flex-col gap-3 border-b px-4 pb-3">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="orderstatus-radio-buttons-group"
                        onChange={(e) => setStatus(e.target.value)}
                        name="orderstatus-radio-buttons"
                        value={status}
                      >
                        {orderStatus.map((el, i) => (
                          <FormControlLabel
                            value={el}
                            control={<Radio size="small" />}
                            key={i}
                            label={<span className="text-sm">{el}</span>}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {/* <!-- checkboxes --> */}
                </div>
                {/* <!-- order status checkboxes --> */}

                {/* <!-- order time checkboxes --> */}
                <div className="flex flex-col pb-2 text-sm">
                  <span className="px-4 font-medium">ORDER TIME</span>

                  {/* <!-- checkboxes --> */}
                  <div className="mt-1 flex flex-col gap-3 px-4 pb-3">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="ordertime-radio-buttons-group"
                        onChange={(e) => setOrderTime(e.target.value)}
                        name="ordertime-radio-buttons"
                        value={orderTime}
                      >
                        {ordertime.map((el, i) => (
                          <FormControlLabel
                            value={el}
                            control={<Radio size="small" />}
                            key={i}
                            label={
                              <span className="text-sm">
                                {i === 0 ? 'This Month' : el}
                              </span>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {/* <!-- checkboxes --> */}
                </div>
                {/* <!-- order time checkboxes --> */}
              </div>
            </div>
            {/* <!-- nav tiles --> */}
          </div>
          {/* <!-- sidebar column  --> */}

          {/* <!-- orders column --> */}

          <div className="relative z-10 mb-6 mt-6 flex-1">
            {loading ? (
              <Loader />
            ) : (
              <div className="flex flex-col gap-3 overflow-hidden sm:mr-4">
                {/* <!-- searchbar --> */}
                <form
                  onSubmit={searchOrders}
                  className="mx-1 flex items-center justify-between rounded border bg-white hover:shadow sm:mx-0 sm:w-10/12"
                >
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    name="search"
                    placeholder="Search your orders here"
                    className="flex-1 rounded-l p-2 text-sm outline-none"
                  />
                  <button
                    type="submit"
                    className="flex h-full items-center gap-1 rounded-md rounded-r px-2 py-2
                    text-sm text-white text-white sm:px-4 "
                    style={{
                      backgroundColor: '#00c9b7',
                      boxShadow: '0px 0px 10px 0px #00c9b7',
                    }}
                  >
                    <SearchIcon sx={{ fontSize: '22px' }} />
                    Search Orders
                  </button>
                </form>
                {/* <!-- searchbar --> */}

                {orders && filteredOrders.length === 0 && (
                  <div className="flex flex-col items-center gap-2 bg-white p-8">
                    <img
                      draggable="false"
                      src="https://rukminim1.flixcart.com/www/100/100/promos/23/08/2020/c5f14d2a-2431-4a36-b6cb-8b5b5e283d4f.png"
                      alt="Empty Orders"
                    />
                    <span className="text-lg font-medium">
                      Sorry, no results found
                    </span>
                    <p>Edit search or clear all filters</p>
                  </div>
                )}

                {orders &&
                  filteredOrders
                    .map((order) => {
                      const {
                        _id,
                        orderStatus,
                        orderItems,
                        createdAt,
                        deliveredAt,
                      } = order

                      return orderItems.map((item, index) => (
                        <OrderItem
                          {...item}
                          key={index}
                          orderId={_id}
                          orderStatus={orderStatus}
                          createdAt={createdAt}
                          deliveredAt={deliveredAt}
                        />
                      ))
                    })
                    .reverse()}
              </div>
            )}
          </div>
          {/* <!-- orders column --> */}
        </div>
        {/* <!-- row --> */}
      </main>
    </>
  )
}

export default MyOrders
