import { useEffect, useState } from 'react';
import WorkIcon from '@mui/icons-material/Work';
import StarsIcon from '@mui/icons-material/Stars';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import HelpIcon from '@mui/icons-material/Help';
import paymentMethods from '../../../assets/images/payment-methods.svg';
import { useLocation } from 'react-router-dom';

const footerLinks = [
  {
    title: 'About',
    links: [
      { name: 'Contact Us', redirect: 'https://www.Kibanda4Devs.com/helpcentre' },
      { name: 'About Us', redirect: 'https://www.Kibanda4Devs.com/about-us' },
      { name: 'Blog', redirect: 'https://www.Kibanda4Devs.com/top-stories/tech-news' },
      { name: 'Kibanda4Devs Wholesale', redirect: 'https://www.Kibanda4Devs.com/wholesale' },
      //  { name: 'Careers', redirect: 'https://www.Kibanda4Devs.com/careers'},
      // { name: 'Kibanda4Devs Stories', redirect: 'https://www.Kibanda4Devs.com/stories'},
    ],
  },
  {
    title: 'Help',
    links: [
      { name: 'Payments', redirect: 'https://www.Kibanda4Devs.com/payments' },
      { name: 'Shipping', redirect: 'https://www.Kibanda4Devs.com/help/shipping' },
      { name: 'Cancellation & Returns', redirect: 'https://www.kibanda4devs.com/pages/returnpolicy' },
      { name: 'FAQ', redirect: 'https://www.Kibanda4Devs.com/FAQ' },
    ],
  },
  {
    title: 'Policy',
    links: [
      { name: 'Refund and Return Policy', redirect: 'https://www.kibanda4devs.com/pages/returnpolicy' },
      { name: 'Terms and Conditions', redirect: 'https://www.kibanda4devs.com/pages/terms' },
      { name: 'Cookie Policy', redirect: 'https://www.kibanda4devs.com/pages/cookies' },
      { name: 'Privacy', redirect: 'https://www.kibanda4devs.com/pages/privacypolicy' },
      // { name: 'Security', redirect: 'https://www.Kibanda4Devs.com/pages/paymentsecurity'},
      // { name: 'Sitemap', redirect: 'https://www.Kibanda4Devs.com/sitemap'},
      // { name: 'EPR Compliance', redirect: 'https://www.Kibanda4Devs.com/pages/ewaste-compliance-tnc'},
    ],
  },
  {
    title: 'Social',
    links: [
      { name: 'Facebook', redirect: 'https://www.facebook.com/Kibanda4Devs' },
      { name: 'Twitter', redirect: 'https://twitter.com/Kibanda4Devs' },
      { name: 'TikTok', redirect: 'https://www.tiktok.com/tag/kibanda4devs' },
      { name: 'Instagram', redirect: 'https://www.instagram.com/kibanda4devs' },
      // { "name": "YouTube", redirect: "https://www.youtube.com/Kibanda4Devs"},
    ],
  },
];

const Footer = () => {
  const location = useLocation();
  const [adminRoute, setAdminRoute] = useState(false);

  useEffect(() => {
    setAdminRoute(location.pathname.split('/', 2).includes('admin'));
  }, [location]);

  return (
    <>
      {!adminRoute && (
        <>
          <footer className="bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 text-white py-6 px-4 sm:px-12">
          <div className="container mx-auto flex flex-col sm:flex-row gap-8 justify-center">
  {footerLinks.map((section, index) => (
    <div key={index} className="flex-1 text-center">
      <h2 className="text-sm font-semibold mb-4">{section.title}</h2>
      <ul className="space-y-2">
        {section.links.map((link, idx) => (
          <li key={idx}>
            <a
              href={link.redirect}
              target="_blank"
              rel="noreferrer"
              className="hover:underline text-sm"
            >
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  ))}
</div>

<div className="mt-6 border-t border-gray-700 pt-6 text-sm text-center">
  <div className="flex flex-wrap justify-center gap-6 mb-4">
    <a
      href="https://www.Kibanda4Devs.com/about-us/business-services/sell"
      target="_blank"
      rel="noreferrer"
      className="flex items-center gap-2 hover:text-yellow-300"
    >
      <WorkIcon sx={{ fontSize: '20px' }} />
      Sell On Kibanda4Devs
    </a>
    <a
      href="https://www.Kibanda4Devs.com/about-us/business-services/advertise"
      target="_blank"
      rel="noreferrer"
      className="flex items-center gap-2 hover:text-yellow-300"
    >
      <StarsIcon sx={{ fontSize: '20px' }} />
      Advertise
    </a>
    <a
      href="https://www.Kibanda4Devs.com/the-gift-card-store"
      target="_blank"
      rel="noreferrer"
      className="flex items-center gap-2 hover:text-yellow-300"
    >
      <CardGiftcardIcon sx={{ fontSize: '20px' }} />
      Gift Cards
    </a>
    <a
      href="https://www.Kibanda4Devs.com/helpcentre"
      target="_blank"
      rel="noreferrer"
      className="flex items-center gap-2 hover:text-yellow-300"
    >
      <HelpIcon sx={{ fontSize: '20px' }} />
      Help Center
    </a>
  </div>
  <p>&copy; 2022-{new Date().getFullYear()} Kibanda4Devs.com</p>
  <img
    className="mx-auto mt-4"
    draggable="false"
    src={paymentMethods}
    alt="Payment Methods"
  />
</div>

          </footer>
        </>
      )}
    </>
  );
};

export default Footer;
