import { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useSnackbar } from 'notistack'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearErrors, registerUser } from '../../actions/userAction'
import BackdropLoader from '../Layouts/BackdropLoader'
import MetaData from '../Layouts/MetaData'
import FormSidebar from './FormSidebar'

const Register = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { loading, isAuthenticated, error } = useSelector((state) => state.user)

  const [user, setUser] = useState({
    name: '',
    email: '',
    gender: '',
    password: '',
    cpassword: '',
  })

  const { name, email, gender, password, cpassword } = user

  const [avatar, setAvatar] = useState()
  const [avatarPreview, setAvatarPreview] = useState('preview.png')

  const handleRegister = (e) => {
    e.preventDefault()
    if (password.length < 8) {
      enqueueSnackbar('Password length must be atleast 8 characters', {
        variant: 'warning',
      })
      return
    }
    if (password !== cpassword) {
      enqueueSnackbar("Password Doesn't Match", { variant: 'error' })
      return
    }
    if (!avatar) {
      enqueueSnackbar('Select Avatar', { variant: 'error' })
      return
    }

    const formData = new FormData()
    formData.set('name', name)
    formData.set('email', email)
    formData.set('gender', gender)
    formData.set('password', password)
    formData.set('avatar', avatar)

    dispatch(registerUser(formData))
  }

  const handleDataChange = (e) => {
    if (e.target.name === 'avatar') {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result)
          setAvatar(reader.result)
        }
      }

      reader.readAsDataURL(e.target.files[0])
    } else {
      setUser({ ...user, [e.target.name]: e.target.value })
    }
  }

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' })
      dispatch(clearErrors())
    }
    if (isAuthenticated) {
      navigate('/')
    }
  }, [dispatch, error, isAuthenticated, navigate, enqueueSnackbar])

  return (
    <>
      <MetaData title="Register | Kibanda4Devs" />

      {loading && <BackdropLoader />}
      <main className="mt-12 flex min-h-screen w-full items-center justify-center  sm:mt-0 sm:pt-20">
        {/* <!-- row --> */}
        <div className="m-auto mb-7 flex bg-gray-200 shadow-lg sm:mt-4 sm:w-4/6">
          <FormSidebar
            title="Looks like you're new here!"
            tag="Sign up with your mobile number to get started"
          />

          {/* <!-- signup column --> */}
          <div className="flex-1 overflow-hidden">
            {/* <!-- personal info procedure container --> */}
            <form
              onSubmit={handleRegister}
              encType="multipart/form-data"
              className="p-5 sm:p-10"
            >
              <div className="flex flex-col items-start gap-4">
                {/* <!-- input container column --> */}
                <div className="flex w-full flex-col items-center justify-between gap-3 sm:flex-col">
                  <TextField
                    fullWidth
                    id="full-name"
                    label="Full Name"
                    name="name"
                    value={name}
                    onChange={handleDataChange}
                    required
                  />
                  <TextField
                    fullWidth
                    id="email"
                    label="Email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleDataChange}
                    required
                  />
                </div>
                {/* <!-- input container column --> */}

                {/* <!-- gender input --> */}
                <div className="flex items-center gap-4">
                  <h2 className="text-md">Your Gender :</h2>
                  <div className="flex items-center gap-6" id="radioInput">
                    <RadioGroup
                      row
                      aria-labelledby="radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        name="gender"
                        value="male"
                        onChange={handleDataChange}
                        control={<Radio required />}
                        label="Male"
                      />
                      <FormControlLabel
                        name="gender"
                        value="female"
                        onChange={handleDataChange}
                        control={<Radio required />}
                        label="Female"
                      />
                    </RadioGroup>
                  </div>
                </div>
                {/* <!-- gender input --> */}

                {/* <!-- input container column --> */}
                <div className="flex w-full flex-col items-center justify-between gap-3 sm:flex-row">
                  <TextField
                    id="password"
                    label="Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={handleDataChange}
                    required
                  />
                  <TextField
                    id="confirm-password"
                    label="Confirm Password"
                    type="password"
                    name="cpassword"
                    value={cpassword}
                    onChange={handleDataChange}
                    required
                  />
                </div>
                {/* <!-- input container column --> */}

                <div className="flex w-full flex-col items-center justify-between gap-3 sm:flex-row">
                  <Avatar
                    alt="Avatar Preview"
                    src={avatarPreview}
                    sx={{ width: 56, height: 56 }}
                  />
                  <label className="w-full cursor-pointer rounded bg-gray-400 px-2.5 py-2 text-center font-medium text-white shadow hover:shadow-lg">
                    <input
                      type="file"
                      name="avatar"
                      accept="image/*"
                      onChange={handleDataChange}
                      className="hidden"
                    />
                    Choose File
                  </label>
                </div>
                <button
                  type="submit"
                  className="w-full rounded-sm bg-[#DB7904] py-3 font-medium text-white shadow hover:shadow-lg"
                >
                  Signup
                </button>
                <Link
                  to="/login"
                  className="w-full rounded-sm border py-3 text-center font-medium text-primary-blue shadow hover:bg-gray-50"
                >
                  Existing User? Log in
                </Link>
              </div>
            </form>
            {/* <!-- personal info procedure container --> */}
          </div>
          {/* <!-- signup column --> */}
        </div>
        {/* <!-- row --> */}
      </main>
    </>
  )
}

export default Register
