import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword, clearErrors } from '../../actions/userAction'
import { useSnackbar } from 'notistack'
import BackdropLoader from '../Layouts/BackdropLoader'
import MetaData from '../Layouts/MetaData'
import FormSidebar from './FormSidebar'

const ResetPassword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const params = useParams()

  const { error, success, loading } = useSelector(
    (state) => state.forgotPassword
  )

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()

    if (newPassword.length < 8) {
      enqueueSnackbar('Password length must be atleast 8 characters', {
        variant: 'warning',
      })
      return
    }
    if (newPassword !== confirmPassword) {
      enqueueSnackbar("Password Doesn't Match", { variant: 'error' })
      return
    }

    const formData = new FormData()
    formData.set('password', newPassword)
    formData.set('confirmPassword', confirmPassword)
    dispatch(resetPassword(params.token, formData))
  }

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' })
      dispatch(clearErrors())
    }
    if (success) {
      enqueueSnackbar('Password Updated Successfully', { variant: 'success' })
      navigate('/login')
    }
  }, [dispatch, error, success, navigate, enqueueSnackbar])

  return (
    <>
      <MetaData title="Password Reset | Kibanda4Devs" />

      {loading && <BackdropLoader />}
      <main class="mt-12 flex min-h-screen w-full items-center justify-center  sm:mt-0 sm:pt-20">
        {/* <!-- row --> */}
        <div class="m-auto mb-7 flex bg-gray-200 shadow-lg sm:mt-4 sm:w-4/6">
          <FormSidebar
            title="Reset Password"
            tag="Get access to your Orders, Wishlist and Recommendations"
          />

          {/* <!-- login column --> */}
          <div class="flex-1 overflow-hidden">
            <h2 className="mt-6 text-center text-2xl font-medium text-gray-800">
              Reset Password
            </h2>

            {/* <!-- edit info container --> */}
            <div class="px-4 py-10 text-center sm:px-14">
              {/* <!-- input container --> */}
              <form onSubmit={handleSubmit}>
                <div class="flex w-full flex-col gap-4">
                  <TextField
                    fullWidth
                    label="New Password"
                    type="password"
                    name="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Confirm New Password"
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />

                  {/* <!-- button container --> */}
                  <div class="mb-32 mt-2 flex flex-col gap-2.5">
                    <p class="text-left text-xs text-primary-grey">
                      By continuing, you agree to Kibanda4Devs's{' '}
                      <a
                        href="https://www.Kibanda4Devs.com/pages/terms"
                        class="text-primary-blue"
                      >
                        {' '}
                        Terms of Use
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://www.Kibanda4Devs.com/pages/privacypolicy"
                        class="text-primary-blue"
                      >
                        {' '}
                        Privacy Policy.
                      </a>
                    </p>
                    <button
                      type="submit"
                      class="w-full rounded-sm bg-[#DB7904] py-3 font-medium text-white shadow hover:shadow-lg"
                    >
                      Submit
                    </button>
                  </div>
                  {/* <!-- button container --> */}
                </div>
              </form>
              {/* <!-- input container --> */}

              <Link
                to="/register"
                class="text-sm font-medium text-primary-blue"
              >
                New to Kibanda4Devs? Create an account
              </Link>
            </div>
            {/* <!-- edit info container --> */}
          </div>
          {/* <!-- login column --> */}
        </div>
        {/* <!-- row --> */}
      </main>
    </>
  )
}

export default ResetPassword
