//src/App.js
import React, { useState } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'
import Login from './components/User/Login'
import Register from './components/User/Register'
import GuestMode from './components/Guest/GuestMode'
import deskList from './components/Desks/deskList'
import Artlist from './components/ArtWork/artList'
import cartAndCheckout from './components/CheckOut/cartAndCheckout'
import ProductDetails from './components/ProductDetails/ProductDetails'
import UpdatePassword from './components/User/UpdatePassword'
import Sidebar from './components/Admin/Sidebar/Sidebar'
import ForgotPassword from './components/User/ForgotPassword'
import NotFound from './components/NotFound'
// import viewdetails from './components/ViewDetails/viewDetails'
import Footer from './components/Layouts/Footer/Footer'
import Header from './components/Layouts/Header/Header'
import Categories from './components/Layouts/Categories'
import Account from './components/User/Account'
import MyOrders from './components/Order/MyOrders'
import Wishlist from './components/Wishlist/Wishlist'
import Home from './components/Home/Home'
import Products from './components/Products/Products'
import Product from './components/Products/Product'
import Cart from './components/Cart/Cart'
import Shipping from './components/Cart/Shipping'
import OrderConfirm from './components/Cart/OrderConfirm'
import Payment from './components/Cart/Payment'

import Dashboard from './components/Admin/Dashboard'
import OrderTable from './components/Admin/OrderTable'
import ProductTable from './components/Admin/ProductTable'
import NewProduct from './components/Admin/NewProduct'
import UserTable from './components/Admin/UserTable'
import ReviewsTable from './components/Admin/ReviewsTable'
import UpdateOrder from './components/Admin/UpdateOrder'
import UpdateProduct from './components/Admin/UpdateProduct'
import UpdateUser from './components/Admin/UpdateUser'
import OrderDetails from './components/Order/OrderDetails'
import OrderStatus from './components/Cart/OrderStatus'
import OrderSuccess from './components/Cart/OrderSuccess'
import UpdateProfile from './components/User/UpdateProfile'
import MainData from './components/Admin/MainData'
import ContactForm from './components/Layouts/Footer/contactUs'
import AboutUsPage from './components/Layouts/Footer/aboutUs'
import ConsumerServicesPage from './components/Layouts/Footer/consumerServicesPage.jsx'
import BusinessServicePage from './components/Layouts/Footer/businessServicePage.jsx'
import SellInfo from './components/Layouts/Footer/sellInfo.jsx'
import Advertiseinfo from './components/Layouts/Footer/advertiseinfo.jsx'
import Affiliate from './components/Layouts/Footer/Affiliate.jsx'
import CareersPage from './components/Layouts/Footer/Careers.jsx'
import JobInfoPage from './components/Layouts/Footer/jobInfo.jsx'
import ApplyFormPage from './components/Layouts/Footer/applyForm.jsx'
import StoriesPage from './components/Layouts/Footer/k4dStories.jsx'
import StoryPage from './components/Layouts/Footer/storyPage.jsx'
import BlogArea from './components/Guest/BlogArea.jsx'
import Blog from './components/Layouts/Footer/Blog.jsx'
import WholesalePage from './components/Layouts/Footer/wholesalePage.jsx'
import PaymentsPage from './components/Layouts/Footer/paymentsPage.jsx'
import ShippingPage from './components/Layouts/Footer/shippingPage.jsx'
import CancellationReturnsPage from './components/Layouts/Footer/cancellationAndReturns.jsx'
import FAQPage from './components/Layouts/Footer/fAQPage.jsx'
import termsAndConditionsPage from './components/Layouts/Footer/termsAndConditionsPage.jsx'
import PrivacyPage from './components/Layouts/Footer/privacyPage.jsx'
import ReturnPolicyPage from './components/Layouts/Footer/returnPolicyPage.jsx'
import CookiesPage from './components/Layouts/Footer/cookiesPage.jsx'
import BusinessRegistrationForm from './components/Layouts/Footer/businessRegistrationForm.jsx'
import AdvertRegistrationForm from './components/Layouts/Footer/advertRegsitrationForm.jsx'
import AffiliateRegistrationForm from './components/Layouts/Footer/affiliateRegistrationForm.jsx'
import ResetPassword from './components/User/ResetPassword.jsx'
// 
function App() {
  const location = useLocation()

  // Function to check if the current path is one of the excluded routes
  const excludedRoutes = [
    '/login',
    '/password/forgot',
    '/password/reset',
    '/register',
    '/password/update',
    '/account/update',
    '/pages/terms',
    '/pages/privacypolicy',
  ]
  const isExcludedRoute = excludedRoutes.includes(location.pathname)

  return (
    <div className="App mt-14 bg-gray-200">
      {!isExcludedRoute && <Header />}
      {/* <Categories/> */}
      {/* <Home/> */}
      <Routes>
        <Route exact path="/" Component={Home} />
        <Route exact path="/products" Component={Products} />
        <Route exact path={`/product/:id`} Component={ProductDetails} />
        <Route exact path="/cart" Component={Cart} />
        <Route exact path="/shipping" Component={Shipping} />
        <Route exact path="/order/confirm" Component={OrderConfirm} />
        <Route exact path="/process/payment" Component={Payment} />

        <Route exact path="/account" Component={Account} />
        <Route exact path="/orders" Component={MyOrders} />
        <Route exact path="/wishlist" Component={Wishlist} />
        {/* protected routes */}
        <Route exact path="/login" Component={Login} />
        <Route exact path="/admin/dashboard" Component={Dashboard} />
        <Route exact path="/admin/statistics" Component={MainData} />
        <Route exact path="/admin/orders" Component={OrderTable} />
        <Route exact path="/admin/products" Component={ProductTable} />
        <Route exact path="/admin/new_product" Component={NewProduct} />
        <Route exact path="/admin/users" Component={UserTable} />
        <Route exact path="/admin/reviews" Component={ReviewsTable} />
        <Route exact path="/admin/order/:id" Component={UpdateOrder} />
        <Route exact path="/admin/product/:id" Component={UpdateProduct} />
        <Route exact path="/products/:keyword" Component={Products} />

        <Route exact path="/admin/user/:id" Component={UpdateUser} />
        <Route exact path="/order_details/:id" Component={OrderDetails} />
        <Route exact path="/order/success" Component={OrderSuccess} />
        {/* <Route exact path="/" Component={GuestMode} /> */}
        {/* <Route exact path="/browse-desks" Component={deskList}/> */}
        {/* <Route exact path='/all-art' Component={Artlist}/> */}
        {/* <Route exact path='/cart_and_checkout' Component={UpdatePassword}/> */}
        {/* <Route exact path= '/view_details' Component={viewdetails}/> */}
        {/* <Route exact path='/admin' Component={Sidebar}/> */}
        <Route exact path="/password/forgot" Component={ForgotPassword} />
        <Route exact path="/register" Component={Register} />
        <Route exact path="/password/update" Component={UpdatePassword} />
        <Route exact path="/password/reset" Component={ResetPassword} />
        <Route exact path="/account/update" Component={UpdateProfile} />
        <Route exact path="/helpcentre" Component={ContactForm} />
        <Route exact path="/about-us" Component={AboutUsPage} />
        <Route
          exact
          path="/about-us/consumer-services"
          Component={ConsumerServicesPage}
        />
        <Route
          exact
          path="/about-us/business-services"
          Component={BusinessServicePage}
        />
        <Route
          exact
          path="/about-us/business-services/sell"
          Component={SellInfo}
        />
        <Route
          exact
          path="/about-us/business-services/advertise"
          Component={Advertiseinfo}
        />
        <Route
          exact
          path="/about-us/business-services/affiliate"
          Component={Affiliate}
        />
        <Route exact path="/careers" Component={CareersPage} />
        <Route exact path="/careers/apply/:jobTitle" Component={JobInfoPage} />
        <Route
          exact
          path="/careers/apply/:jobTitle/apply-form"
          Component={ApplyFormPage}
        />
        <Route exact path="/stories" Component={StoriesPage} />
        <Route exact path="/stories/:slug" Component={StoryPage} />
        <Route exact path="/top-stories/tech-news" Component={Blog} />
        <Route exact path="/wholesale" Component={WholesalePage} />
        <Route exact path="/payments" Component={PaymentsPage} />
        <Route exact path="help/shipping" Component={ShippingPage} />
        <Route exact path="/return" Component={CancellationReturnsPage} />
        <Route exact path="/FAQ" Component={FAQPage} />
        <Route exact path="/pages/terms" Component={termsAndConditionsPage} />
        <Route exact path="/pages/privacypolicy" Component={PrivacyPage} />
        <Route exact path="/pages/returnpolicy" Component={ReturnPolicyPage} />
        <Route exact path="/pages/cookies" Component={CookiesPage} />
        <Route
          exact
          path="/business-registration-form"
          Component={BusinessRegistrationForm}
        />
        <Route
          exact
          path="/advertise-form"
          Component={AdvertRegistrationForm}
        />
        <Route
          exact
          path="/affiliate-registration-form"
          Component={AffiliateRegistrationForm}
        />

        <Route exact path="/*" Component={NotFound} />
      </Routes>
      {!isExcludedRoute && <Footer />}
    </div>
  )
}

export default App
