//src/constants/formsConstants.js

//CONTACT_FORM
export const SUBMIT_CONTACT_FORM_REQUEST = 'SUBMIT_CONTACT_FORM_REQUEST'
export const SUBMIT_CONTACT_FORM_SUCCESS = 'SUBMIT_CONTACT_FORM_SUCCESS'
export const SUBMIT_CONTACT_FORM_FAIL = 'SUBMIT_CONTACT_FORM_FAIL'

//NEW_BUSINESS_REGISTRATION
export const SUBMIT_REGISTRATION_FORM_REQUEST =
  'SUBMIT_REGISTRATION_FORM_REQUEST'
export const SUBMIT_REGISTRATION_FORM_SUCCESS =
  'SUBMIT_REGISTRATION_FORM_SUCCESS'
export const SUBMIT_REGISTRATION_FORM_FAIL = 'SUBMIT_REGISTRATION_FORM_FAIL'

//NEW_ADVERT_REGISTRATION
export const SUBMIT_ADVERT_FORM_REQUEST = 'SUBMIT_ADVERT_FORM_REQUEST'
export const SUBMIT_ADVERT_FORM_SUCCESS = 'SUBMIT_ADVERT_FORM_SUCCESS'
export const SUBMIT_ADVERT_FORM_FAIL = 'SUBMIT_ADVERT_FORM_FAIL'

//AFFILIATE REGISTRATION
export const SUBMIT_AFFILIATE_FORM_REQUEST = 'SUBMIT_AFFILIATE_FORM_REQUEST'
export const SUBMIT_AFFILIATE_FORM_SUCCESS = 'SUBMIT_AFFILIATE_FORM_SUCCESS'
export const SUBMIT_AFFILIATE_FORM_FAIL = 'SUBMIT_AFFILIATE_FORM_FAIL'

//WHOLESALE_REGISTRATION
export const SUBMIT_WHOLESALE_FORM_REQUEST = 'SUBMIT_WHOLESALE_FORM_REQUEST'
export const SUBMIT_WHOLESALE_FORM_SUCCESS = 'SUBMIT_WHOLESALE_FORM_SUCCESS'
export const SUBMIT_WHOLESALE_FORM_FAIL = 'SUBMIT_WHOLESALE_FORM_FAIL'
