import React from 'react'

const CancellationReturnsPage = () => {
  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-100">
      <div className="container relative z-10 mx-auto">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}

        <div className="py-8">
          <div className="relative z-10 mx-auto max-w-4xl rounded-lg p-8">
            <h1 className="text-orange-600 relative z-10 mb-6 mt-3 text-center font-sans text-3xl font-bold">
              Cancellation & Returns
              <span
                className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
                style={{ backgroundColor: '#DB7A03' }}
              ></span>
            </h1>
            <p className="mb-4 text-base text-gray-800">
              We want you to be completely satisfied with your purchase from
              Kibanda4Devs. Here's our policy for cancellations and returns:
            </p>
            <p
              className="text-orange-700 mb-4 text-base font-semibold"
              style={{ color: '#D97707' }}
            >
              Cancellation:
            </p>
            <p className="mb-4 text-base text-gray-800">
              You can cancel your order within 24 hours of purchase for a full
              refund. After 24 hours, cancellations are subject to a
              cancellation fee.
            </p>
            <p
              className="text-orange-700 mb-4 text-base font-semibold"
              style={{ color: '#D97707' }}
            >
              Returns:
            </p>
            <p className="mb-4 text-base text-gray-800">
              If you're not satisfied with your purchase, you can return the
              item within 30 days for a refund or exchange. The item must be
              unused and in its original packaging.
            </p>
            <p className="text-base text-gray-800">
              For assistance with cancellations and returns, please contact our
              customer support team.
            </p>
            <p className="mt-4 text-base text-gray-800">
              For more details, you can read our
              <a
                href="/cancellation-and-returns-policy"
                className="ml-2 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-2 py-2 font-bold text-white hover:bg-blue-700"
              >
                Cancellation & Returns Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CancellationReturnsPage
