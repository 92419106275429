import React from 'react'

function TopSellers() {
  return (
    <div className="top-sellers-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-6">
            <div className="section-title">
              <h2>Top Artists</h2>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="trending-btn text-end">
              <a href="authors.html" className="default-btn border-radius-5">
                Explore More
              </a>
            </div>
          </div>
        </div>
        <div className="row justify-content-center pt-45">
          <div className="col-lg-3 col-6 col-md-4">
            <div className="top-sellers-item">
              <div className="number">1.</div>
              <div className="top-sellers-img">
                <a href="author-profile.html">
                  <img
                    src="assets/images/top-sellers/top-sellers1.jpg"
                    alt="Images"
                  />
                </a>
                <i className="ri-check-line"></i>
              </div>
              <div className="content">
                <h3>
                  <a href="author-profile.html">Benjamin Doe</a>
                </h3>
                <span>129.301 ETH</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6 col-md-4">
            <div className="top-sellers-item">
              <div className="number">2.</div>
              <div className="top-sellers-img">
                <a href="author-profile.html">
                  <img
                    src="assets/images/top-sellers/top-sellers2.jpg"
                    alt="Images"
                  />
                </a>
                <i className="ri-check-line"></i>
              </div>
              <div className="content">
                <h3>
                  <a href="author-profile.html">Evelyn Adison</a>
                </h3>
                <span>130.201 ETH</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6 col-md-4">
            <div className="top-sellers-item">
              <div className="number">3.</div>
              <div className="top-sellers-img">
                <a href="author-profile.html">
                  <img
                    src="assets/images/top-sellers/top-sellers3.jpg"
                    alt="Images"
                  />
                </a>
                <i className="ri-check-line"></i>
              </div>
              <div className="content">
                <h3>
                  <a href="author-profile.html">Aria July</a>
                </h3>
                <span>120.101 ETH</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6 col-md-4">
            <div className="top-sellers-item">
              <div className="number">4.</div>
              <div className="top-sellers-img">
                <a href="author-profile.html">
                  <img
                    src="assets/images/top-sellers/top-sellers4.jpg"
                    alt="Images"
                  />
                </a>
                <i className="ri-check-line"></i>
              </div>
              <div className="content">
                <h3>
                  <a href="author-profile.html">Aria July</a>
                </h3>
                <span>124.101 ETH</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6 col-md-4">
            <div className="top-sellers-item">
              <div className="number">5.</div>
              <div className="top-sellers-img">
                <a href="author-profile.html">
                  <img
                    src="assets/images/top-sellers/top-sellers5.jpg"
                    alt="Images"
                  />
                </a>
                <i className="ri-check-line"></i>
              </div>
              <div className="content">
                <h3>
                  <a href="author-profile.html">Sebastian Farnil</a>
                </h3>
                <span>127.301 ETH</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6 col-md-4">
            <div className="top-sellers-item">
              <div className="number">6.</div>
              <div className="top-sellers-img">
                <a href="author-profile.html">
                  <img
                    src="assets/images/top-sellers/top-sellers6.jpg"
                    alt="Images"
                  />
                </a>
                <i className="ri-check-line"></i>
              </div>
              <div className="content">
                <h3>
                  <a href="author-profile.html">Henry Martina</a>
                </h3>
                <span>128.301 ETH</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6 col-md-4">
            <div className="top-sellers-item">
              <div className="number">7.</div>
              <div className="top-sellers-img">
                <a href="author-profile.html">
                  <img
                    src="assets/images/top-sellers/top-sellers7.jpg"
                    alt="Images"
                  />
                </a>
                <i className="ri-check-line"></i>
              </div>
              <div className="content">
                <h3>
                  <a href="author-profile.html">Daniel Adams</a>
                </h3>
                <span>130.301 ETH</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6 col-md-4">
            <div className="top-sellers-item">
              <div className="number">8.</div>
              <div className="top-sellers-img">
                <a href="author-profile.html">
                  <img
                    src="assets/images/top-sellers/top-sellers8.jpg"
                    alt="Images"
                  />
                </a>
                <i className="ri-check-line"></i>
              </div>
              <div className="content">
                <h3>
                  <a href="author-profile.html">Julian Rin</a>
                </h3>
                <span>120.201 ETH</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6 col-md-4">
            <div className="top-sellers-item">
              <div className="number">9.</div>
              <div className="top-sellers-img">
                <a href="author-profile.html">
                  <img
                    src="assets/images/top-sellers/top-sellers9.jpg"
                    alt="Images"
                  />
                </a>
                <i className="ri-check-line"></i>
              </div>
              <div className="content">
                <h3>
                  <a href="author-profile.html">Samuel Anvi</a>
                </h3>
                <span>130.201 ETH</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6 col-md-4">
            <div className="top-sellers-item">
              <div className="number">10.</div>
              <div className="top-sellers-img">
                <a href="author-profile.html">
                  <img
                    src="assets/images/top-sellers/top-sellers10.jpg"
                    alt="Images"
                  />
                </a>
                <i className="ri-check-line"></i>
              </div>
              <div className="content">
                <h3>
                  <a href="author-profile.html">Emilia Adison</a>
                </h3>
                <span>124.201 ETH</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6 col-md-4">
            <div className="top-sellers-item">
              <div className="number">11.</div>
              <div className="top-sellers-img">
                <a href="author-profile.html">
                  <img
                    src="assets/images/top-sellers/top-sellers11.jpg"
                    alt="Images"
                  />
                </a>
                <i className="ri-check-line"></i>
              </div>
              <div className="content">
                <h3>
                  <a href="author-profile.html">Muhammad Ali</a>
                </h3>
                <span>127.201 ETH</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6 col-md-4">
            <div className="top-sellers-item">
              <div className="number">12.</div>
              <div className="top-sellers-img">
                <a href="author-profile.html">
                  <img
                    src="assets/images/top-sellers/top-sellers12.jpg"
                    alt="Images"
                  />
                </a>
                <i className="ri-check-line"></i>
              </div>
              <div className="content">
                <h3>
                  <a href="author-profile.html">Alexander Luis</a>
                </h3>
                <span>129.201 ETH</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopSellers
