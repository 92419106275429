import React from 'react'

function TestimonialArea() {
  return (
    <div className="testimonial-area ptb-100">
      <div className="container">
        <div className="col-lg-8 col-md-6">
          <div className="section-title">
            <h2>Testimonials</h2>
          </div>
        </div>
        <div className="testimonial-slider-two owl-carousel owl-theme">
          <div className="testimonial-slider-item">
            <div className="row align-items-center">
              <div className="col-lg-9">
                <div className="testimonial-slider-content">
                  <img
                    src="assets/images/testimonial/testimonial-line.png"
                    alt="image"
                  />
                  <p>
                    Kibanda4devs has become my go-to source for top-notch
                    development products. Their attention to detail and
                    commitment to excellence shine through in every tool they
                    offer. I'm incredibly satisfied with my purchases.
                  </p>
                  <ul>
                    <li>Rennie Wood</li>
                    <li>Work at home mum</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="testimonial-slider-img">
                  <img
                    src="assets/images/testimonial/testimonial-img2.png"
                    alt="Images"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-slider-item">
            <div className="row align-items-center">
              <div className="col-lg-9">
                <div className="testimonial-slider-content">
                  <img
                    src="assets/images/testimonial/testimonial-line.png"
                    alt="image"
                  />
                  <p>
                    As a developer, finding reliable resources is crucial.
                    Kibanda4devs has consistently delivered high-quality
                    products that have exceeded my expectations. I highly
                    recommend them to anyone looking to elevate their
                    development game..
                  </p>
                  <ul>
                    <li>Macie Mood</li>
                    <li>Developer</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="testimonial-slider-img">
                  <img
                    src="assets/images/testimonial/testimonial-img3.png"
                    alt="Images"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-slider-item">
            <div className="row align-items-center">
              <div className="col-lg-9">
                <div className="testimonial-slider-content">
                  <img
                    src="assets/images/testimonial/testimonial-line.png"
                    alt="image"
                  />
                  <p>
                    I can't believe the quality of the products from
                    Kibanda4devs! Their tools have really helped streamline my
                    development process, making my projects more efficient and
                    enjoyable.
                  </p>
                  <ul>
                    <li>Olivia Jenar</li>
                    <li>Product Engineer</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="testimonial-slider-img">
                  <img
                    src="assets/images/testimonial/testimonial-img.png"
                    alt="Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialArea
