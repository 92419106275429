import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { submitAffiliateForm } from '../../../actions/formsAction'

function AffiliateRegistrationForm() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    contactNumber: '',
    socialMediaPlatform: '',
    socialMediaHandle: '',
    followerCount: '',
    audienceDemographics: '',
    specialization: '',
    message: '',
  })

  const [submittedFullName, setSubmittedFullName] = useState('')

  const dispatch = useDispatch()
  const { loading, success, error } = useSelector(
    (state) => state.affiliateRegistrationForm
  )

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmittedFullName(formData.fullName)
    dispatch(submitAffiliateForm(formData))
  }

  useEffect(() => {
    if (success) {
      setFormData({
        fullName: '',
        email: '',
        contactNumber: '',
        socialMediaPlatform: '',
        socialMediaHandle: '',
        followerCount: '',
        audienceDemographics: '',
        specialization: '',
        message: '',
      })
    }
  }, [success])

  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-200">
      <div className="container relative z-10 mx-auto mb-8 mt-8">
        <div className=" relative z-10 mx-auto rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <h1
            className="relative z-10 mb-2 text-center font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            Join Our Affiliate Program
            <span
              className="absolute relative left-1/2 z-10 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            ></span>
          </h1>
          <form className="relative z-10" onSubmit={handleSubmit}>
            <div className="relative z-10 mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="fullName"
              >
                Full Name
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="fullName"
                type="text"
                placeholder="Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="email"
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="contactNumber"
              >
                Contact Number
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="contactNumber"
                type="tel"
                placeholder="Contact Number"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="socialMediaPlatform"
              >
                Social Media Platform
              </label>
              <select
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="socialMediaPlatform"
                name="socialMediaPlatform"
                value={formData.socialMediaPlatform}
                onChange={handleChange}
                required
              >
                <option value="">Select a social media platform</option>
                <option value="Facebook">Facebook</option>
                <option value="Twitter">Twitter</option>
                <option value="Instagram">Instagram</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="TikTok">TikTok</option>
              </select>
            </div>

            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="socialMediaHandle"
              >
                Social Media Handle
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="socialMediaHandle"
                type="text"
                placeholder="Social Media Handle"
                name="socialMediaHandle"
                value={formData.socialMediaHandle}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="followerCount"
              >
                Follower Count
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="followerCount"
                type="number"
                placeholder="Follower Count"
                name="followerCount"
                value={formData.followerCount}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="audienceDemographics"
              >
                Audience Demographics
              </label>
              <textarea
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="audienceDemographics"
                rows="3"
                placeholder="Audience Demographics"
                name="audienceDemographics"
                value={formData.audienceDemographics}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="specialization"
              >
                Specialization
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="specialization"
                type="text"
                placeholder="Specialization"
                name="specialization"
                value={formData.specialization}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-6">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="message"
                rows="5"
                placeholder="Your message..."
                name="message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="flex items-center justify-center">
              <button
                className="focus:shadow-outline rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </form>
          {success && (
            <div className="alert alert-success mt-4">
              Thank you, {submittedFullName}! Your affiliate application has
              been successfully submitted. We appreciate your interest in
              partnering with us. Our team will review your application
              promptly. If further information is required, we will contact you
              via the provided email address.
            </div>
          )}

          {error && (
            <div className="alert alert-danger mt-4">
              An error occurred. Please try again later.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AffiliateRegistrationForm
