import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Pagination from '@mui/material/Pagination'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Slider from '@mui/material/Slider'
import { useSnackbar } from 'notistack'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { clearErrors, getProducts } from '../../actions/productAction'
import Loader from '../Layouts/Loader'
import MinCategory from '../Layouts/MinCategory'
import Product from './Product'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import StarIcon from '@mui/icons-material/Star'
import { categories } from '../../utils/constants'
import MetaData from '../Layouts/MetaData'
import { getRandomProducts } from '../../utils/functions'
import { useLocation } from 'react-router-dom'

const Products = () => {
  console.log(getProducts, 'oooooo')
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const params = useParams()
  const location = useLocation()

  const [price, setPrice] = useState([0, 200000])
  const [category, setCategory] = useState(
    location.search ? location.search.split('=')[1] : ''
  )
  const [ratings, setRatings] = useState(0)

  // pagination
  const [currentPage, setCurrentPage] = useState(1)

  // filter toggles
  const [categoryToggle, setCategoryToggle] = useState(true)
  const [ratingsToggle, setRatingsToggle] = useState(true)

  const {
    products,
    loading,
    error,
    productsCount,
    resultPerPage,
    filteredProductsCount,
  } = useSelector((state) => state.products)
  console.log('kkk', products)
  const keyword = params.keyword

  const priceHandler = (e, newPrice) => {
    setPrice(newPrice)
  }

  const clearFilters = () => {
    setPrice([0, 200000])
    setCategory('')
    setRatings(0)
  }

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' })
      dispatch(clearErrors())
    }
    dispatch(getProducts(keyword, category, price, ratings, currentPage))
  }, [
    dispatch,
    keyword,
    category,
    price,
    ratings,
    currentPage,
    error,
    enqueueSnackbar,
  ])

  return (
    <>
      <MetaData title="All Products | Kibanda4Devs" />

      <MinCategory />
      <main className="mt-14 w-full bg-gray-200 sm:mt-0">
        {/* <!-- row --> */}
        <div className="m-auto mb-7 mt-2 flex gap-3 sm:mx-3 sm:mt-2">
          {/* <!-- sidebar column  --> */}
          <div className="hidden w-1/5 flex-col px-1 sm:flex">
            {/* <!-- nav tiles --> */}
            <div className=" relative z-10 rounded-lg ">
              {/* Curved outward shadow effect */}
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              {/* End of curved outward shadow effect */}
              <div className="relative z-10 flex flex-col  rounded-sm shadow">
                {/* <!-- filters header --> */}
                <div className="flex items-center justify-between gap-5 border-b px-4 py-2">
                  <p className="text-lg font-medium">Filters</p>
                  <span
                    className="cursor-pointer rounded-md px-2 py-1 text-xs font-medium uppercase text-white "
                    style={{
                      backgroundColor: '#00c9b7',
                      boxShadow: '0px 0px 10px 0px #00c9b7',
                    }}
                    onClick={() => clearFilters()}
                  >
                    clear all
                  </span>
                </div>

                <div className="flex flex-col gap-2 overflow-hidden py-3 text-sm">
                  {/* price slider filter */}
                  <div className="flex flex-col gap-2 border-b px-4">
                    <span className="text-xs font-medium">PRICE</span>

                    <Slider
                      value={price}
                      onChange={priceHandler}
                      valueLabelDisplay="auto"
                      getAriaLabel={() => 'Price range slider'}
                      min={0}
                      max={200000}
                    />

                    <div className="mb-2 flex min-w-full items-center justify-between gap-3">
                      <span className="flex-1 rounded-sm border bg-gray-50 px-4 py-1 text-gray-800">
                        KSh{price[0].toLocaleString()}
                      </span>
                      <span className="font-medium text-gray-400">to</span>
                      <span className="flex-1 rounded-sm border bg-gray-50 px-4 py-1 text-gray-800">
                        KSh{price[1].toLocaleString()}
                      </span>
                    </div>
                  </div>
                  {/* price slider filter */}

                  {/* category filter */}
                  <div className="flex flex-col border-b px-4">
                    <div
                      className="flex cursor-pointer items-center justify-between py-2 pb-4"
                      onClick={() => setCategoryToggle(!categoryToggle)}
                    >
                      <p className="text-xs font-medium uppercase">Category</p>
                      {categoryToggle ? (
                        <ExpandLessIcon sx={{ fontSize: '20px' }} />
                      ) : (
                        <ExpandMoreIcon sx={{ fontSize: '20px' }} />
                      )}
                    </div>

                    {categoryToggle && (
                      <div className="flex flex-col pb-1">
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="category-radio-buttons-group"
                            onChange={(e) => setCategory(e.target.value)}
                            name="category-radio-buttons"
                            value={category}
                          >
                            {categories.map((el, i) => (
                              <FormControlLabel
                                key={i}
                                value={el}
                                control={<Radio size="small" />}
                                label={
                                  <span className="text-sm" key={i}>
                                    {el}
                                  </span>
                                }
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}
                  </div>
                  {/* category filter */}

                  {/* ratings filter */}
                  <div className="flex flex-col border-b px-4">
                    <div
                      className="flex cursor-pointer items-center justify-between py-2 pb-4"
                      onClick={() => setRatingsToggle(!ratingsToggle)}
                    >
                      <p className="text-xs font-medium uppercase">ratings</p>
                      {ratingsToggle ? (
                        <ExpandLessIcon sx={{ fontSize: '20px' }} />
                      ) : (
                        <ExpandMoreIcon sx={{ fontSize: '20px' }} />
                      )}
                    </div>

                    {ratingsToggle && (
                      <div className="flex flex-col pb-1">
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="ratings-radio-buttons-group"
                            onChange={(e) => setRatings(e.target.value)}
                            value={ratings}
                            name="ratings-radio-buttons"
                          >
                            {[4, 3, 2, 1].map((el, i) => (
                              <FormControlLabel
                                value={el}
                                key={i}
                                control={<Radio size="small" />}
                                label={
                                  <span className="flex items-center text-sm">
                                    {el}
                                    <StarIcon
                                      sx={{ fontSize: '12px', mr: 0.5 }}
                                    />{' '}
                                    & above
                                  </span>
                                }
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}
                  </div>
                  {/* ratings filter */}
                </div>
              </div>
            </div>
            {/* <!-- nav tiles --> */}
          </div>
          {/* <!-- sidebar column  --> */}

          {/* <!-- search column --> */}
          <div className="flex-1">
            <div className=" relative z-10 mx-auto rounded-lg p-8">
              {/* Curved outward shadow effect */}
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              {/* End of curved outward shadow effect */}
              {!loading && products?.length === 0 && (
                <div className="flex flex-col items-center justify-center gap-3 rounded-sm bg-white p-6 shadow-sm sm:p-16">
                  <div className=" relative z-10 mx-auto rounded-lg p-8">
                    {/* Curved outward shadow effect */}
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                    <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                    {/* End of curved outward shadow effect */}
                    <img
                      draggable="false"
                      className="relative z-10 h-44 w-1/2 object-contain"
                      src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/error-no-search-results_2353c5.png"
                      alt="Search Not Found"
                    />
                    <h1 className="relative z-10 text-2xl font-medium text-gray-900">
                      Sorry, no results found!
                    </h1>
                    <p className="relative z-10 text-center text-xl text-primary-grey">
                      Please check the spelling or try searching for something
                      else
                    </p>
                  </div>
                </div>
              )}

              {loading ? (
                <Loader />
              ) : (
                <div className="flex w-full flex-col items-center justify-center gap-2 overflow-hidden bg-white pb-4">
                  <div className="grid w-full grid-cols-1 place-content-start overflow-hidden border-b pb-4 sm:grid-cols-4">
                    {products?.map((product) => (
                      <Product {...product} key={product._id} />
                    ))}
                  </div>
                  {filteredProductsCount > resultPerPage && (
                    <Pagination
                      count={Number(
                        ((filteredProductsCount + 6) / resultPerPage).toFixed()
                      )}
                      page={currentPage}
                      onChange={(e, val) => setCurrentPage(val)}
                      color="primary"
                    />
                  )}
                </div>
              )}
            </div>
            {/* <!-- search column --> */}
          </div>
        </div>
        {/* <!-- row --> */}
      </main>
    </>
  )
}

export default Products
