import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { addItemsToCart } from '../../actions/cartAction'
import { removeFromSaveForLater } from '../../actions/saveForLaterAction'
import { getDiscount } from '../../utils/functions'

const SaveForLaterItem = ({
  product,
  name,
  seller,
  price,
  cuttedPrice,
  image,
  stock,
  quantity,
}) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const removeFromSaveForLaterHandler = (id) => {
    dispatch(removeFromSaveForLater(id))
    enqueueSnackbar('Removed From Save For Later', { variant: 'success' })
  }

  const moveToCartHandler = (id, quantity) => {
    dispatch(addItemsToCart(id, quantity))
    removeFromSaveForLaterHandler(id)
    enqueueSnackbar('Product Added To Cart', { variant: 'success' })
  }

  return (
    <div
      className="relative z-10 flex flex-col gap-3 border-b py-5 pl-2 sm:pl-6"
      key={product}
    >
      <div
        className="flex w-full flex-col items-stretch gap-5 sm:flex-row"
        href="#"
      >
        {/* <!-- product image --> */}
        <div className="h-28 w-full flex-shrink-0 sm:w-1/6">
          <img
            draggable="false"
            className="h-full w-full object-contain"
            src={image}
            alt={name}
          />
        </div>
        {/* <!-- product image --> */}

        {/* <!-- description --> */}
        <div className="flex w-full flex-col gap-1 p-1 pr-6 sm:gap-5">
          {/* <!-- product title --> */}
          <div className="flex items-start justify-between pr-5">
            <div className="flex w-11/12 flex-col gap-0.5 sm:w-full">
              <p>{name.length > 50 ? `${name.substring(0, 50)}...` : name}</p>
              <span className="text-sm text-gray-500">Seller: {seller}</span>
            </div>
          </div>
          {/* <!-- product title --> */}

          {/* <!-- price desc --> */}
          <div className="flex items-baseline gap-2 text-xl font-medium">
            <span>KSh{(price * quantity).toLocaleString()}</span>
            <span className="text-sm font-normal text-gray-500 line-through">
              KSh{(cuttedPrice * quantity).toLocaleString()}
            </span>
            <span className="text-sm text-primary-green">
              {getDiscount(price, cuttedPrice)}%&nbsp;off
            </span>
          </div>
          {/* <!-- price desc --> */}
        </div>
        {/* <!-- description --> */}
      </div>

      {/* <!-- move to cart --> */}
      <div className="ml-10 flex justify-evenly sm:justify-start sm:gap-6">
        {/* <!-- quantity --> */}
        <div className="flex items-center gap-1">
          <span className="flex h-7 w-7 cursor-not-allowed items-center justify-center rounded-full border bg-gray-50 text-3xl font-light">
            <p>-</p>
          </span>
          <input
            className="w-11 rounded-sm border py-0.5 text-center text-sm font-medium text-gray-700 outline-none"
            value={quantity}
            disabled
          />
          <span className="flex h-7 w-7 cursor-not-allowed items-center justify-center rounded-full border bg-gray-50 text-xl font-light">
            +
          </span>
        </div>
        {/* <!-- quantity --> */}
        <div className=" relative z-10 rounded-lg p-6">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <button
            onClick={() => moveToCartHandler(product, quantity)}
            className="relative z-10 font-medium hover:text-primary-blue sm:ml-4"
          >
            MOVE TO CART
          </button>
        </div>
        <div className=" relative z-10 rounded-lg p-6">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <button
            onClick={() => removeFromSaveForLaterHandler(product)}
            className="relative z-10 font-medium hover:text-red-600"
          >
            REMOVE
          </button>
        </div>
      </div>
      {/* <!-- move to cart --> */}
    </div>
  )
}

export default SaveForLaterItem
