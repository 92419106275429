import React from 'react'

const StoryCard = ({ story }) => {
  // Function to encode job title for URL
  return (
    <div className="overflow-hidden rounded-lg bg-white shadow-md">
      <img
        src={story.image}
        alt={story.title}
        className="h-48 w-full object-cover object-center"
      />
      <div className="p-6">
        <h3 className="mb-2 text-xl font-semibold">{story.title}</h3>
        <p className="mb-4 text-gray-600">{story.description}</p>
        <a
          href={`/stories/${story.slug}`}
          className="font-semibold text-yellow-600 hover:text-yellow-700"
        >
          Read More
        </a>
      </div>
    </div>
  )
}

export default StoryCard
