import React from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ChatIcon from '@mui/icons-material/Chat'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { logoutUser } from '../../../actions/userAction'

const PrimaryDropDownMenu = ({ setTogglePrimaryDropDown, user }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { wishlistItems } = useSelector((state) => state.wishlist)

  const handleLogout = () => {
    dispatch(logoutUser())
    navigate('/login')
    enqueueSnackbar('Logout Successfully', { variant: 'success' })
    setTogglePrimaryDropDown(false)
  }

  const navs = [
    // {
    //   title: 'Supercoin Zone',
    //   icon: <OfflineBoltIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />,
    //   redirect: '/',
    // },
    // {
    //   title: 'Kibanda4Devs Plus Zone',
    //   icon: <AddCircleIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />,
    //   redirect: '/',
    // },
    {
      title: 'Orders',
      icon: <ShoppingBagIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />,
      redirect: '/orders',
    },
    {
      title: 'Wishlist',
      icon: <FavoriteIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />,
      redirect: '/wishlist',
    },
    // {
    //   title: 'My Chats',
    //   icon: <ChatIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />,
    //   redirect: '/',
    // },
    // {
    //   title: 'Coupons',
    //   icon: (
    //     <ConfirmationNumberIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />
    //   ),
    //   redirect: '/',
    // },
    // {
    //   title: 'Gift Cards',
    //   icon: (
    //     <AccountBalanceWalletIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />
    //   ),
    //   redirect: '/',
    // },
    // {
    //   title: 'Notifications',
    //   icon: <NotificationsIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />,
    //   redirect: '/',
    // },
  ]

  return (
    <div className="absolute -left-24 top-9 ml-2 w-60 flex-col rounded bg-white text-sm shadow-2xl ">
      <div className=" relative z-10 mx-auto rounded-lg p-4">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}
        {user.role === 'admin' && (
          <Link
            className="hover:bg-black-50 relative z-10 flex items-center gap-3 rounded-t border-b py-3.5 pl-3"
            to="/admin/dashboard"
          >
            <span className="text-[#00B6D0]">
              <DashboardIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />
            </span>
            Admin Dashboard
          </Link>
        )}

        <Link
          className="hover:bg-black-50 relative z-10 flex items-center gap-3 rounded-t border-b py-3.5 pl-3"
          to="/account"
        >
          <span className="text-[#00B6D0]">
            <AccountCircleIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />
          </span>
          My Profile
        </Link>

        {navs.map((item, i) => {
          const { title, icon, redirect } = item

          return (
            <>
              {title === 'Wishlist' ? (
                <Link
                  className="hover:bg-black-50 relative z-10 flex items-center gap-3 border-b py-3.5 pl-3"
                  to={redirect}
                  key={i}
                >
                  <span className="text-[#00B6D0]">{icon}</span>
                  {title}
                  <span
                    className="bg-black-100 text-black-600 relative z-10 ml-auto mr-3 rounded p-0.5 px-2"
                    style={{ backgroundColor: '#FFA500' }}
                  >
                    {wishlistItems.length}
                  </span>
                </Link>
              ) : (
                <Link
                  className="hover:bg-black-50 relative z-10 flex items-center gap-3 border-b py-3.5 pl-3"
                  to={redirect}
                  key={i}
                >
                  <span className="text-[#00B6D0]">{icon}</span>
                  {title}
                </Link>
              )}
            </>
          )
        })}

        <div
          className="hover:bg-black-50 relative z-10 flex cursor-pointer items-center gap-3 rounded-b py-3.5 pl-3"
          onClick={handleLogout}
        >
          <span className="text-[#00B6D0]">
            <PowerSettingsNewIcon sx={{ fontSize: '18px', color: 'red' }} />
          </span>
          Logout
        </div>

        <div className="absolute -top-2.5 right-1/2 z-10">
          <div className="arrow_down"></div>
        </div>
      </div>
    </div>
  )
}

export default PrimaryDropDownMenu
