import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const CookiesModal = ({ onClose, onAgree }) => {
  const [isChecked, setChecked] = useState(false)
  const navigate = useNavigate()

  const handleAgree = () => {
    if (isChecked) {
      onAgree()
      navigate('/') // Redirect to home page on agreement
    }
  }

  const handleClose = () => {
    onClose()
    navigate('/') // Redirect to home page on close
  }

  return (
    <div className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-opacity-80 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 py-2.5">
      <div className="relative z-10 mx-auto rounded-lg p-8">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}
        <div className="relative z-10 max-w-screen-lg overflow-hidden p-8">
          <div className="max-h-[500px] overflow-y-auto">
            <h2 className="mb-8 text-center text-2xl font-bold">
              Cookies Policy
              <span
                className="relative left-1/2 mt-1 block h-1 w-20 -translate-x-1/2 transform rounded-md"
                style={{ backgroundColor: '#DB7A03' }}
              ></span>
            </h2>
            <ul className="text-left">
              <li className="mb-4" style={{ textAlign: 'center' }}>
                <strong style={{ fontWeight: 'bold' }}>
                  KIBANDALABS TECHNOLOGIES/KIBANDA4DEVS COOKIES POLICY
                </strong>
              </li>
              <li>
                Welcome to kibanda4devs.com! This Cookie Policy explains how we
                use cookies and similar technologies on our website and mobile
                application. By using kibanda4devs, you consent to the use of
                cookies as described in this policy.
              </li>
              <li>
                <strong>1. What Are Cookies?</strong>
              </li>
              <li>
                Cookies are small text files that are stored on your device
                (computer, smartphone, tablet) when you visit a website. They
                help us recognize your device and improve your experience on
                kibanda4devs.
              </li>
              <li>
                <strong>2. How We Use Cookies</strong>
              </li>
              <ul>
                <li>
                  <strong>a) Essential Cookies:</strong> These cookies are
                  necessary for the basic functionality of kibanda4devs. They
                  enable you to navigate the site, use essential features, and
                  access secure areas.
                </li>
                <li>
                  <strong>b) Performance Cookies:</strong> We use performance
                  cookies to collect information about how visitors use
                  kibanda4devs. This helps us improve the site's performance,
                  analyze trends, and fix issues.
                </li>
                <li>
                  <strong>c) Functionality Cookies:</strong> These cookies allow
                  us to remember your preferences, such as language or region
                  selection, to enhance your user experience.
                </li>
                <li>
                  <strong>d) Marketing Cookies:</strong> We and our third-party
                  partners may use marketing cookies to deliver personalized
                  advertisements based on your interests and online activities.
                </li>
              </ul>
              <li>
                <strong>3. Third-Party Cookies</strong>
              </li>
              <li>
                We may allow third-party service providers to use cookies on
                kibanda4devs for the purposes outlined in section 2. These
                providers may include analytics services, advertising networks,
                and social media platforms.
              </li>
              <li>
                <strong>4. Cookie Management</strong>
              </li>
              <ul>
                <li>
                  <strong>a) Cookie Preferences:</strong> You can manage your
                  cookie preferences through the cookie banner on kibanda4devs.
                  You can also adjust your browser settings to refuse or delete
                  cookies.
                </li>
                <li>
                  <strong>b) Browser Settings:</strong> Most web browsers allow
                  you to control cookies through their settings. However,
                  blocking all cookies may affect the functionality of
                  kibanda4devs.
                </li>
              </ul>
              <li>
                <strong>5. Changes to the Cookie Policy</strong>
              </li>
              <li>
                We may update this Cookie Policy to reflect changes in our
                practices or for legal reasons. The effective date at the top
                indicates the latest revision. Your continued use of
                kibanda4devs after any changes constitutes acceptance of the
                updated Cookie Policy.
              </li>
              <li>
                <strong>6. Contact Information</strong>
              </li>
              <li>
                If you have questions or concerns about this Cookie Policy,
                please contact us at{' '}
                <span style={{ textDecoration: 'underline', color: '#DB7A03' }}>
                  info@kibanda4devs.com
                </span>
                .
              </li>

              <li className="mb-4 mt-4" style={{ textAlign: 'center' }}>
                Thank you for understanding our use of cookies on kibanda4devs!
              </li>
            </ul>
          </div>
          <div className="sticky bottom-0 p-4">
            <label className="mt-4 flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                checked={isChecked}
                onChange={() => setChecked(!isChecked)}
              />
              I have read and agree to the Cookies Policy
            </label>
            <div className="flex justify-end">
              <button
                className="btn btn-danger-gradiant mr-2 border-0 bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 text-white"
                onClick={handleAgree}
              >
                Agree
              </button>
              <button
                className="bg-gray-500 px-4 py-2 text-white"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookiesModal
