import CircularProgress from '@mui/material/CircularProgress'

const Loader = () => {
  return (
    <div className="flex min-h-screen min-w-full items-center justify-center">
      <CircularProgress />
    </div>
  )
}

export default Loader
