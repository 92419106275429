import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom' // Import useNavigate for redirection

const Modal = ({ onClose, onAgree }) => {
  const [isChecked, setChecked] = useState(false)
  const navigate = useNavigate() // Initialize useNavigate hook

  const handleAgree = () => {
    if (isChecked) {
      onAgree()
      navigate('/') // Redirect to home page on agreement
    }
  }

  const handleClose = () => {
    onClose()
    navigate('/') // Redirect to home page on close
  }

  return (
    <div className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-opacity-80 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 py-2.5">
      <div className=" relative z-10 mx-auto rounded-lg p-8">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}
        <div className=" relative z-10 max-w-screen-lg overflow-hidden p-8">
          <div className="max-h-[500px] overflow-y-auto">
            <h2 className="mb-8 text-center text-2xl font-bold">
              Terms and Conditions
              <span
                className=" relative left-1/2 mt-1 block h-1 w-20 -translate-x-1/2 transform rounded-md"
                style={{ backgroundColor: '#DB7A03' }}
              ></span>
            </h2>
            <ul>
              <li className="mb-4" style={{ textAlign: 'center' }}>
                <strong style={{ fontWeight: 'bold' }}>
                  PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY!
                </strong>
              </li>

              <li>
                Welcome to www.kibanda4devs.com! These Terms of Use describe the
                terms and conditions applicable to your access and use of the
                websites, mobile sites, mobile applications and other portals
                owned, operated, branded or made available by Kibandalabs
                Technologies (defined below) from time to time which relate to
                the Kibandalabs e-commerce platform including but not limited to
                the web and mobile-optimized versions of the websites identified
                by the uniform resource locator “www.kibanda4devs.com” and the
                mobile applications of the Kibanda4devs ecommerce platform (the
                “Sites"). This document is a legally binding agreement between
                you as the user(s) of the Sites (referred to as “you, “your” or
                “user” hereinafter) and the Kibandalabs Technologies (referred
                to as “we”,“our”or“Kibandalabs” hereinafter).
              </li>
              <li>
                <strong>1. Application and Acceptance of the Terms</strong>
                <ul>
                  <li>
                    1.1 Your access to and use of the Sites and Kibandalabs
                    services, software and products through the Sites, which
                    shall include the platforms described in clause 7.1 of the
                    Terms (such services, software and products collectively
                    referred to as the “Services” hereinafter) is subject to the
                    terms and conditions contained in this document as well as
                    the Privacy Policy, and any other rules and policies of the
                    Sites that Kibandalabs may publish from time to time. This
                    document and such other rules and policies of the Sites are
                    collectively referred to below as the “Terms”. By accessing
                    and use of the Sites and Services, you agree to accept and
                    be bound by the Terms. Please do not access or use the
                    Services or the Sites if you do not accept all of the Terms.
                  </li>
                  <li>
                    1.2 You may not access or use the Services or the Sites and
                    may not accept the Terms if (a) you are not of legal age,
                    for example, under 18 years old if you are in Africa, the
                    European Union (EU) and the European Economic Area (EEA), to
                    form a binding contract with Kibandalabs, or (b) you are not
                    permitted to receive any Services under the laws of other
                    countries/regions including the country/region in which you
                    are resident or from which you access and use the Services
                    and the Sites.
                  </li>
                  <li>
                    1.3 Kibandalabs may amend any Terms at any time by posting
                    the relevant amended and restated Terms on the Sites.
                    Kibandalabs will inform you of any material change to the
                    Terms by posting the relevant changes on the Sites. By
                    continuing to access or use the Services or the Sites, you
                    agree that the amended and restated Terms will apply to you.
                  </li>
                  <li>
                    1.4 If Kibandalabs has posted or provided a translation of
                    the English language version of the Terms, you agree that
                    the translation is provided for convenience only and that
                    the English language version will govern your access to and
                    use of the Services or the Sites.
                  </li>
                  <li>
                    1.5 You may be required to enter into separate agreement(s),
                    whether online or offline, with Kibandalabs or our affiliate
                    for any Service (or features within the Services) (each
                    an“Additional Agreement”). If there is any conflict or
                    inconsistency between the Terms and an Additional Agreement,
                    the Additional Agreement shall take precedence over the
                    Terms only in relation to that Service (or feature within
                    the Service) concerned.
                  </li>
                  <li>
                    1.6 The Terms may not otherwise be modified except in
                    writing by an authorized officer of Kibandalabs.
                  </li>
                </ul>
              </li>

              <li>
                <strong>2. Provision of Services</strong>
                <ul>
                  <li>
                    2.1 You must register as a member on the Sites in order to
                    access and use some Services. Further, Kibandalabs reserves
                    the right, without prior notice, to restrict access to or
                    use of certain Services (or any features within the
                    Services) to paying users, or subject to other conditions
                    that Kibandalabs may impose at our discretion.
                  </li>
                  <li>
                    2.2 Services (or any features within the Services) may vary
                    for different regions and countries. No warranty or
                    representation is given that a particular Service or feature
                    or function thereof or the same type and extent of the
                    Service or features and functions thereof will be available
                    for users. Kibandalabs may at our sole discretion limit,
                    deny or create different levels of access to and use of any
                    Services (or any features within the Services) with respect
                    to different users.
                  </li>
                  <li>
                    2.4 Kibandalabs may launch, change, upgrade, impose
                    conditions to, suspend, or stop any Services (or any
                    features within the Services) without prior notice except
                    that in case of a fee-based Service, such changes will not
                    substantially adversely affect the ability of such paying
                    users to enjoy that Service.
                  </li>
                  <li>
                    2.5 Some Services (or part thereof) may be provided by
                    Kibandalabs’ affiliates on behalf of Kibandalabs.
                  </li>
                  <li>
                    2.6 Promotion Services provided by Kibandalabs pursuant to
                    the terms hereunder shall include: 1) Top Ranking and
                    Sponsored Listing; 2) services that benefit, promote,
                    support, whether directly or indirectly, the business of
                    user, the user’s brand, product, store, logo, trademark,
                    service mark, user Content (as defined in clause 3.7 of the
                    Terms) and/or derivative work using the user Content on
                    software, applications, tools, browser extensions and/or
                    platforms operated and/or controlled by Kibandalabs
                    (“kibandalabs Channels”) and/or on software, applications,
                    channels, platforms, websites and/or any other forms of
                    media that are operated and/or controlled by third parties
                    (“Third Party Channels”); and 3) any such other services as
                    may be announced by Kibandalabs from time to time.
                    <br />
                    Kibandalabs shall be entitled to, at its sole discretion,
                    determine whether the Promotion Services or any part thereof
                    will be available to you.
                  </li>
                </ul>
              </li>
              <li>
                <strong>3. Users Generally</strong>
                <ul>
                  <li>
                    3.1 As a condition of your access to and use of the Sites or
                    Services, you agree that you will comply with all applicable
                    laws and regulations when accessing or using the Sites or
                    Services.
                  </li>
                  <li>
                    3.2 You agree that (a) you will not copy, reproduce,
                    download, re-publish, sell, distribute or resell any
                    Services or any information, text, images, graphics, video
                    clips, sound, directories, files, databases or listings, etc
                    available on or through the Sites (the “Site Content”), and
                    (b) you will not copy, reproduce, download, compile or
                    otherwise use any Site Content for the purposes of operating
                    a business that competes with Kibandalabs, or otherwise
                    commercially exploiting the Site Content. Systematic
                    retrieval of Site Content from the Sites to create or
                    compile, directly or indirectly, a collection, compilation,
                    database or directory (whether through robots, spiders,
                    automatic devices or manual processes) without written
                    permission from Kibandalabs is prohibited. Use of any
                    content or materials on the Sites for any purpose not
                    expressly permitted in the Terms is prohibited.
                  </li>
                  <li>
                    3.3 To know how we and our affiliates use and protect your
                    personal information when using the relevant Site, please
                    review our privacy policy at the following link:{' '}
                    <a
                      href="https://www.kibanda4devs.com/pages/privacypolicy"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: 'orange' }}
                    >
                      Privacy Policy
                    </a>
                    .
                  </li>

                  <li>
                    3.4 Kibandalabs may allow users to access to content,
                    products or services offered by third parties through
                    hyperlinks (in the form of word link, banners, channels or
                    otherwise), API or otherwise to such third parties'
                    websites. You are cautioned to read such websites' terms and
                    conditions and/or privacy policies before using the Sites.
                    You acknowledge that Kibandalabs has no control over such
                    third parties' websites, does not monitor such websites, and
                    shall not be responsible or liable to anyone for such
                    websites, or any content, products or services made
                    available on or through such websites.
                  </li>
                  <li>
                    3.5 You agree not to undertake any action to undermine the
                    integrity of the computer systems or networks of Kibandalabs
                    and/or any other user nor to gain unauthorized access to
                    such computer systems or networks.
                  </li>
                  <li>
                    3.6 You agree not to undertake any action which may
                    undermine the integrity of Kibandalabs’ feedback system,
                    such as leaving positive feedback for yourself using
                    secondary Member IDs or through third parties or by leaving
                    unsubstantiated negative feedback for another user.
                  </li>
                  <li>
                    3.7 By posting or displaying any logos, trademarks, service
                    marks, brands, description/information in the product
                    listings, and any other information, content or material on
                    the Sites (any of such information, content or material,
                    collectively referred to as“user Content”) or providing any
                    user Content to Kibandalabs and/or its affiliates and their
                    respective representative(s), and to the extent permitted
                    under applicable laws, you grant an irrevocable, perpetual,
                    worldwide, royalty-free, and sub-licensable (through
                    multiple tiers) license to Kibandalabs and/or its affiliates
                    and their respective representative(s) to display, transmit,
                    distribute, reproduce, publish, duplicate, adapt, modify,
                    edit, translate, create derivative works using the user
                    Content, remove any part of it (including, without
                    limitation, the watermark or mark the user Content bears),
                    and otherwise use any or all of the user Content in any
                    form, media, or technology now known or not currently known
                    in any manner, on the Sites, Kibandalabs Channels and/or
                    Third Party Channels and for any purpose which may be
                    beneficial, whether directly or indirectly, to Kibandalabs,
                    the operation of the Sites, the provision of any Services
                    and Promotion Services and/or the business of the user. You
                    confirm and warrant to Kibandalabs that you have all the
                    rights, power and authority necessary to grant the above
                    license and the user Content and use of such user Content
                    (including derivative works) by Kibandalabs and/or its
                    affiliates under such license is free from any infringement
                    or violation of any Third Party Rights (as defined in clause
                    5.4 of the Terms). To the maximum extent permitted by law,
                    you waive your right to enforce your Intellectual Property
                    Rights in the user Content against Kibandalabs and/or its
                    affiliates, assignees or sub-licensees in connection with
                    use of such user Content in connection with the Services.
                    Information that is protected under data protection laws
                    will only be used and kept in compliance with those laws.
                  </li>
                </ul>
              </li>

              <li>
                <strong>4. Member Accounts</strong>
                <ul>
                  <li>
                    4.1 User must be registered on the Sites to access or use
                    some Services (a registered user is also referred to as a
                    “Member” below). Except with Kibandalabs’ approval, one user
                    may only register one member account on the Sites.
                    Kibandalabs may cancel or terminate a user's member account
                    if Kibandalabs has reasons to suspect that the user has
                    concurrently registered or is in control of two or more
                    member accounts. Further, Kibandalabs may reject the user's
                    application for registration for any reason.
                  </li>
                  <li>
                    4.2 Upon registration on the Sites, Kibandalabs shall assign
                    an account and issue a member ID and password (the latter
                    shall be chosen by a registered user during registration) to
                    each registered user. An account may have a web-based email
                    account with limited storage space for the Member to send or
                    receive emails.
                  </li>
                  <li>
                    4.3 A set of Member ID and password is unique to a single
                    account. Each Member shall be solely responsible for
                    maintaining the confidentiality and security of your Member
                    ID and password and for all use of and activities that occur
                    under your account (whether such use or activities are
                    authorized or not). No Member may share, assign, or permit
                    the use of your Member account, ID or password by another
                    person, even to other individuals within the Member's own
                    business entity (where applicable). Each Member agrees to
                    notify Kibandalabs immediately if you become aware of any
                    unauthorized use of your password or your account or any
                    other breach of security of your account.
                  </li>
                  <li>
                    4.4 Each Member agrees that all use of the Sites and
                    Services, and all activities that occur under your account
                    (including without limitation, posting any company or
                    product information, clicking to accept any Additional
                    Agreements or rules, subscribing to or making any payment
                    for any services, sending emails using the email account or
                    sending SMS) will be deemed to have been authorized by the
                    Member.
                  </li>
                  <li>
                    4.5 Each Member acknowledges that sharing of your account
                    with other persons, or allowing multiple users outside of
                    your business entity to use your account (collectively,
                    "multiple use"), may cause irreparable harm to Kibandalabs
                    or other users of the Sites. Each Member shall indemnify
                    Kibandalabs, our affiliates, directors, employees, agents
                    and representatives against any loss or damages (including
                    but not limited to loss of profits) suffered as a result of
                    the multiple use of their account. Each Member also agrees
                    that in case of the multiple use of your account or Member's
                    failure to maintain the security of your account,
                    Kibandalabs shall not be liable for any loss or damages
                    arising from such a breach and shall have the right to
                    suspend or terminate the Member's account without liability
                    to the Member.
                  </li>
                </ul>
              </li>

              <li>
                <strong>5. Member's Responsibilities</strong>
                <ul>
                  <li>
                    5.1 Each Member represents, warrants and agrees that (a) you
                    have full power and authority to accept the Terms, to grant
                    the license and authorization and to perform the obligations
                    hereunder; (b) your access and use the Sites and Services
                    will be for business purposes only; and (c) for Members who
                    are business entities, the address you provide when
                    registering is the principal place of business of your
                    business entity. For purposes of this provision, a branch or
                    liaison office will not be considered a separate entity and
                    your principal place of business will be deemed to be that
                    of your head office.
                  </li>
                  <li>
                    5.2 Each Member will be required to provide information or
                    material about your entity, business or products/services as
                    part of the registration process on the Sites for your
                    access to and use of any Service or the member account. Each
                    Member represents, warrants and agrees that (a) such
                    information and material whether submitted during the
                    registration process or thereafter throughout the
                    continuation of the use of the Sites or Service is true,
                    accurate, current and complete, and (b) you will maintain
                    and promptly amend all information and material to keep it
                    true, accurate, current and complete.
                  </li>
                  <li>
                    5.3 Upon becoming a Member, you consent to the inclusion of
                    the contact information about you in our database and
                    authorize Kibandalabs and our affiliates to share the
                    contact information with other users or otherwise use your
                    personal information in accordance with the Privacy Policy.
                  </li>
                  <li>
                    5.4 Each Member represents, warrants and agrees that (a) you
                    shall be solely responsible for obtaining all necessary
                    third party licenses and permissions regarding any user
                    Content that you submit, post or display; (b) any user
                    Content that you submit, post or display does not infringe
                    or violate any of the copyright, patent, trademark, trade
                    name, trade secrets or any other personal or proprietary
                    rights of any third party (“Third Party Rights”); and (c)
                    you have the right and authority to sell, trade, distribute
                    or export or offer to sell, trade, distribute or export the
                    products or services described in the user Content and such
                    sale, trade, distribution or export or offer does not
                    violate any Third Party Right；and (d) neither the Member
                    nor any of its related persons, have been banned or removed
                    from any major e-commerce platforms, or otherwise implicated
                    in selling counterfeit or pirated products online.
                  </li>
                  <li>
                    5.5 Each Member further represents, warrants and agrees that
                    the user Content that you submit, post or display shall: a)
                    be true, accurate, complete and lawful; b) not be false,
                    misleading or deceptive; c) not contain information that is
                    defamatory, libelous, threatening or harassing, obscene,
                    objectionable, offensive, sexually explicit or harmful to
                    minors; d) not contain information that is discriminatory or
                    promotes discrimination based on race, sex, religion,
                    nationality, disability, sexual orientation or age; e) not
                    violate other Terms or any applicable Additional Agreements;
                    f) not violate any applicable laws and regulations
                    (including without limitation those governing export
                    control, consumer protection, unfair competition, price
                    gouging or false advertising) or promote any activities
                    which may violate any applicable laws and regulations; g)
                    not contain any link directly or indirectly to any other web
                    Sites which includes any content that may violate the Terms.
                  </li>
                  <li>
                    5.6 Each Member further represents, warrants and agrees that
                    you shall/are: a) carry on your activities on the Sites in
                    compliance with any applicable laws and regulations; b)
                    conduct your business transactions with other users of the
                    Sites in good faith; c) carry on your activities in
                    accordance with the Terms and any applicable Additional
                    Agreements; d) not use the Services or Sites to defraud any
                    person or entity (including without limitation sale of
                    stolen items, use of stolen credit/debit cards); e) not
                    impersonate any person or entity, misrepresent yourself or
                    your affiliation with any person or entity; f) not engage in
                    spamming or phishing; g) not engage in any other unlawful
                    activities (including without limitation those which would
                    constitute a criminal offence, give rise to civil liability,
                    etc) or encourage or abet any unlawful activities; h) not
                    involve attempts to copy, reproduce, exploit or expropriate
                    Kibandalabs’s various proprietary directories, databases and
                    listings; i) not involve any computer viruses or other
                    destructive devices and codes that have the effect of
                    damaging, interfering with, intercepting or expropriating
                    any software or hardware system, data or personal
                    information; j) not involve any scheme to undermine the
                    integrity of the data, systems or networks used by
                    Kibandalabs and/or any user of the Sites or gain
                    unauthorized access to such data, systems or networks; k)
                    not, and your director(s), officer(s), controlling
                    party/ies, affiliates and legal jurisdiction in which any of
                    the foregoing persons or entities is organized or has
                    operations are not, persons or entities that are subject to
                    any economic or fraud sanctions of any governmental,
                    international or regulatory entities; and l) not engage in
                    any activities that would otherwise create any liability for
                    Kibandalabs or our affiliates.
                  </li>
                  <li>
                    5.7 Members may not use the Sites, Services or member
                    account to engage in activities which are identical or
                    similar to Kibandalabs E-commerce marketplace business.
                  </li>
                  <li>
                    5.8 If a Member provides a business referee, the Member
                    represents, warrants and agrees that you have obtained all
                    necessary consents, approvals and waivers from such referee
                    to (a) act as your business referee; (b) post and publish
                    their contact details and information, reference letters and
                    comments on their behalf; and (c) that third parties may
                    contact such business referees to support claims or
                    statements made about you. You further warrant and agree
                    that all reference letters and comments are true and
                    accurate and third parties may contact the business referees
                    without the need to obtain your consent.
                  </li>
                  <li>
                    5.9 Each Member agrees to provide all necessary information,
                    materials and approval, and render all reasonable assistance
                    and cooperation necessary for Kibandalabs’s provision of the
                    Services, evaluating whether the Member has breached the
                    Terms and/or handling any complaint against the Member. If a
                    Member's failure to do so results in delay in, or suspension
                    or termination of, the provision of any Service, Kibandalabs
                    shall not be obliged to extend the relevant service period
                    nor be liable for any loss or damages arising out of or in
                    connection with such delay, suspension or termination.
                  </li>
                  <li>
                    5.10 Each Member acknowledges and agrees that Kibandalabs
                    reserves the right to, but shall not be required to actively
                    monitor or exercise any editorial control whatsoever over
                    the content of any message or material or information
                    (including user Content) created, obtained or accessible
                    through the Services or Sites. Kibandalabs does not endorse,
                    verify or otherwise certify the contents of any comments or
                    other material or information (including user Content)
                    created, submitted, posted, displayed or otherwise made by
                    any Member. Each Member is solely responsible for the
                    contents of their communications and may be held legally
                    liable or accountable for the content of their comments or
                    other material or information.
                  </li>
                  <li>
                    5.11 Member acknowledges and agrees that the Sites and
                    Services may only be used by businesses and their
                    representatives for business use and not for individual
                    consumers or for personal use.
                  </li>
                  <li>
                    5.12 Member acknowledges and agrees that each Member is
                    solely responsible for observing applicable laws and
                    regulations in its respective jurisdictions to ensure that
                    all access and use of the Site and Services are in
                    compliance with the same.
                  </li>
                  <li>
                    5.13 Each Member represents, warrants and agrees that (a)
                    you and your affiliates shall comply with applicable laws
                    and regulations in conducting your respective business
                    (including without limitation applicable laws and
                    regulations with respect to product safety, intellectual
                    property rights, data privacy, consumer protection, product
                    or regulatory certification, import and export control,
                    unfair competition, price gouging, false advertising, labor,
                    environment, health and safety, anti-bribery and anti-money
                    laundering); (b) you and your affiliates are not the subject
                    of any trade restrictions, sanctions or other legal
                    restrictions enacted or proposed to be enacted by any
                    country, international organization or jurisdiction
                    ("Relevant Subjects"); (c) you and your affiliates are not
                    offering products or services that would likely trigger any
                    international trade restrictions, sanctions or other legal
                    restrictions by any Relevant Subjects; (d) neither you, or
                    any of your parent companies or affiliates, directly or
                    indirectly, deals with, or provides any funds, goods or
                    services to Relevant Subjects; (e) you will at all times
                    comply with all applicable export control and sanctions laws
                    and regulations with regard to products, services, software
                    and technologies in using the Services, including sanctions
                    resolutions, laws and regulations enacted and enforced by
                    the Republic of Kenya, and any other country; (g) You and
                    your affiliates are responsible for your products'
                    compliance with the laws and regulations of the local
                    country/region, the country/region of destination and other
                    applicable international conventions, including but not
                    limited to labour laws and standards, such as the Employment
                    Act and the Work Injury and Benefits Act of Kenya. If a
                    product is not permitted to be possessed, sold, purchased,
                    exported, imported or manufactured in one or more
                    country/regions, Kibandalabs reserves the right to remove
                    the listing of such product from, OR STOP THE SALE OF ANY
                    SUCH PRODUCT IN, those country/regions. If, at any time, you
                    fail to meet any of the above requirements, you should stop
                    using the Services immediately. If Kibandalabs reasonably
                    believes that any of your conduct violates or threatens to
                    violate any applicable laws and regulations, Kibandalabs
                    may, at its sole discretion, at any time take action as it
                    may deem appropriate in light of the circumstances,
                    including, but not limited to, terminating the provision of
                    Services to you, closing relevant purchase orders, and
                    terminating your accounts, while reserving all rights it may
                    have regarding any non-compliant actions or conduct of its
                    members.
                  </li>
                </ul>
              </li>

              <li>
                <strong>6. Breaches by Members</strong>
                <ul>
                  <li>
                    6.1 Kibandalabs reserves the right in our sole discretion to
                    remove, modify or reject any user Content (in whole or in
                    part) that you submit to, post or display on the Sites which
                    we reasonably believe (i) violates any applicable laws and
                    regulations, (ii) violates the Terms hereunder, (iii) could
                    subject Kibandalabs or our affiliates to liability, (iv)
                    infringes any Third Party Rights, (v) could harm the
                    interests of our Members, third party or Kibandalabs or our
                    affiliates, or (vi) is otherwise found inappropriate in
                    Kibandalab’s sole discretion.
                  </li>
                  <li>
                    6.2 If any Member breaches any Terms, or if Kibandalabs has
                    reasonable grounds to believe that a Member is in breach of
                    any Terms, Kibandalabs shall have the right to take such
                    disciplinary actions as it deems appropriate, including
                    without limitation: (i) suspending or terminating the
                    Member’s account and any and all accounts determined to be
                    related to such account by Kibandalabs in its sole
                    discretion without liability for any losses or damages
                    arising out of or in connection with such suspension or
                    termination; (ii) restricting, downgrading, suspending or
                    terminating the subscription of, access to, or current or
                    future use of any Service; (iii) removing any product
                    listings or other user Content that the Member has
                    submitted, posted or displayed, or imposing restrictions on
                    the number of product listings or user Content that the
                    Member may post or display; (iv) imposing other restrictions
                    on the Member’s use of any features or functions of any
                    Service as Kibandalabs may consider appropriate in its sole
                    discretion; and (v) any other corrective actions, discipline
                    or penalties as Kibandalabs may deem necessary or
                    appropriate in its sole discretion.
                  </li>
                  <li>
                    6.3 Without limiting the generality of the provisions of the
                    Terms, a Member would be considered as being in breach of
                    the Terms in any of the following circumstances: a) upon
                    complaint or claim from any third party, Kibandalabs has
                    reasonable grounds to believe that such Member has willfully
                    or materially failed to perform your contract with such
                    third party including without limitation where a Member who
                    supplies products or services using the Sites and Services
                    has failed to deliver any items ordered by such third party
                    after receipt of the purchase price, or where the items such
                    Member has delivered materially fail to meet the terms and
                    descriptions outlined in your contract with such third
                    party, b) Kibandalabs has reasonable grounds to suspect that
                    such Member has used a stolen credit card or other false or
                    misleading information in any transaction with a counter
                    party, c) Kibandalabs has reasonable grounds to suspect that
                    any information provided by the Member is not current or
                    complete or is untrue, inaccurate, or misleading, or d)
                    Kibandalabs believes that the Member’s actions may cause
                    financial loss or legal liability to Kibandalabs or our
                    affiliates or any other users.
                  </li>
                  <li>
                    6.4 Kibandalabs reserves the right to cooperate fully with
                    governmental or regulatory authorities, law enforcement
                    bodies, private investigators and/or injured third parties
                    in the investigation of any suspected criminal or civil
                    wrongdoing. Further, to the extent permitted by applicable
                    laws and policies, Kibandalabs may disclose the Member's
                    identity, contact information and/or information regarding
                    the Member's account(s), transactions or activities carried
                    out on or via the Site, if requested by a government,
                    regulatory or law enforcement body or an injured third
                    party, or as a result of a subpoena or other legal action.
                    Kibandalabs shall not be liable for damages or results
                    arising from such disclosure, and the Member agrees not to
                    bring any action or claim against Kibandalabs for such
                    disclosure.
                  </li>
                  <li>
                    6.5 Kibandalabs may, at any time and in our reasonable
                    discretion, impose limitations on, suspend or terminate the
                    Members use of any Service or the Sites without being liable
                    to the Member if Kibandalabs has received notice that the
                    Member is in breach of any agreement or undertaking with any
                    affiliate of Kibandalabs and such breach involves or is
                    reasonably suspected to involve dishonest or fraudulent
                    activities. Kibandalabs reserves the right to, but shall not
                    be required to investigate such breach or request
                    confirmation from the Member.
                  </li>
                  <li>
                    6.6 Each Member agrees to indemnify Kibandalabs, our
                    affiliates, directors, employees, agents and representatives
                    and to hold them harmless, from any and all damages, losses,
                    claims and liabilities (including legal costs on a full
                    indemnity basis) which may arise from your submission,
                    posting or display of any user Content, from your access to
                    or use of the Sites or Services, or from your breach of the
                    Terms or any Additional Agreements.
                  </li>
                  <li>
                    6.7 Each Member further agrees that Kibandalabs is not
                    responsible, and shall have no liability to you or anyone
                    else for any user Content or other material transmitted
                    through the Sites or Services, including fraudulent, untrue,
                    misleading, inaccurate, defamatory, offensive or illicit
                    material and that the risk of damage from such user Content
                    or other material rests entirely with the Member.
                    Kibandalabs reserves the right, at our own expense, to
                    assume the exclusive defense and control of any matter
                    otherwise subject to indemnification by the Member, in which
                    event the Member shall cooperate with Kibandalabs in
                    asserting any available defenses.
                  </li>
                </ul>
              </li>
              <li>
                <strong>7. Transactions Between Buyers and Sellers</strong>
                <ul>
                  <li>
                    7.1 Through the Sites, Kibandalabs provides electronic
                    web-based platforms for exchanging information between
                    buyers and sellers of products and services. Kibandalabs
                    additionally provides electronic web-based transaction
                    platforms for Members to place, accept, conclude, manage and
                    fulfill orders for the provision of products and services
                    online. However, for any Services, Kibandalabs does not
                    represent either the seller or the buyer in specific
                    transactions. Kibandalabs does not control and is not liable
                    or responsible for the quality, safety, lawfulness or
                    availability of the products or services offered for sale on
                    the Sites, the ability of the sellers to complete a sale or
                    the ability of buyers to complete a purchase.
                  </li>
                  <li>
                    7.2 Users are hereby made aware that there may be risks of
                    dealing with people acting under false pretenses.
                    Kibandalabs uses several techniques to verify the accuracy
                    of certain information our paying users provide us when they
                    register for a paying membership service on the Sites.
                    However, because user verification on the Internet is
                    difficult, Kibandalabs cannot and does not confirm each
                    user's purported identity (including, without limitation,
                    paying Members). We encourage you to use various means, as
                    well as common sense, to evaluate with whom you are dealing.
                  </li>
                  <li>
                    7.3 Buyers and sellers accessing or using the Sites or
                    Services shall assume the risks of conducting any purchase
                    and sale transactions in connection with or through the
                    Sites or Services. Buyer and sellers accessing or using the
                    Site or Services shall also fully assume all risks of
                    liability or harm of any kind arising out of or in
                    connection with any subsequent activity relating to the
                    products or services that are the subject of the
                    transactions on the Sites. Examples of such risks shall
                    include, but are not limited to, mis-representation of
                    products and services, fraudulent schemes, unsatisfactory
                    product quality, failure to meet specifications, defective
                    or dangerous products, unlawful products, delay or default
                    in delivery or payment, cost mis-calculations, breach of
                    warranty, breach of contract, transportation accidents, the
                    risk that the manufacture, importation, export,
                    distribution, offer, display, purchase, sale and/or use of
                    products or services offered or displayed on the Sites may
                    violate or may be asserted to violate Third Party Rights,
                    and the risk that users may incur costs of defense or other
                    costs in connection with third parties’ assertion of Third
                    Party Rights, or in connection with any claims by any party
                    that they are entitled to defense or indemnification in
                    relation to the assertion of rights, demands or claims by
                    claimants of Third Party Rights. Examples of such risks also
                    include the risk of claims from consumers, other purchasers,
                    end-users of products or other third parties that they have
                    suffered injuries or harm from their use of the products
                    obtained through the Sites or Services. All of the foregoing
                    risks are referred to as "Transaction Risks". Kibandalabs is
                    not liable or responsible for any damages, claims,
                    liabilities, costs, harm, inconveniences, business
                    disruptions or expenditures of any kind that may arise a
                    result of or in connection with any Transaction Risks.
                  </li>
                  <li>
                    7.4 Buyers and sellers on the Sites are solely responsible
                    for setting out and performance of the terms and conditions
                    of the transactions conducted on, through or as a result of
                    use of the Sites or Services, including, without limitation,
                    terms regarding payment, returns, warranties, shipping,
                    insurance, fees, taxes, title, licenses, fines, permits,
                    handling, transportation and storage.
                  </li>
                  <li>
                    7.5 Each user agrees to provide all information and
                    materials as may be reasonably required by Kibandalabs in
                    connection with your transactions conducted on, through or
                    as a result of use of the Sites or Services. Kibandalabs has
                    the right to suspend or terminate any user's account if the
                    user fails to provide the required information and materials
                    without liability for any losses or damages arising out of
                    or in connection with such suspension or termination.
                  </li>
                  <li>
                    7.6 In the event that any user has a dispute with any party
                    to a transaction, such user agrees to release and indemnify
                    Kibandalabs (and our agents, affiliates, directors, officers
                    and employees) from all claims, demands, actions,
                    proceedings, costs, expenses and damages (including without
                    limitation any actual, special, incidental or consequential
                    damages) arising out of or in connection with such dispute
                    or the transaction.
                  </li>
                  <li>
                    7.7 The sellers declare, and the buyers acknowledge, that
                    all the contents the sellers provide contain commercial
                    communications.
                  </li>
                </ul>
              </li>

              <li>
                <strong>8. Kibandalabs Guarantee</strong>
                <ul>
                  <li>
                    8.1 Kibandalabs Guarantee is a service provided by
                    Kibandalabs or its authorised agent to protect purchases. To
                    protect against the risk of liability, payment for purchases
                    made to Seller using the Services will be held by
                    Kibandalabs or its authorised agent (“Kibandalabs Guarantee
                    Account”). Seller will not receive interest or other
                    earnings from the sum you have paid into Kibandalabs
                    Guarantee Account.
                  </li>
                  <li>
                    8.2 After Buyer makes payment for his/her order (“Buyer’s
                    Purchase Monies”), Buyer’s Purchase Monies will be held in
                    Kibandalabs Guarantee Account until:
                    <ul>
                      <li>
                        (a) Buyer sends confirmation to Kibandalabs that Buyer
                        has received his/her goods, in which case, unless 8.2(d)
                        applies, Kibandalabs will release Buyer’s Purchase
                        Monies (less the Seller’s proportion of the Shipping fee
                        (if applicable), the Transaction Fee, Kibandalabs
                        Commission Fee and Tax Amount (defined below), and (if
                        applicable) the Cross Border Fee (defined below)) in
                        Kibandalabs Guarantee Account to Seller;
                      </li>
                      <li>
                        (b) Kibandalabs Guarantee Period (15 days) (or any
                        approved extension under 8.3) expires, in which case,
                        unless 8.2(c) or 8.2(d) applies, Kibandalabs will
                        release Buyer’s Purchase Monies (less the Seller’s
                        proportion of the Shipping Fee (if applicable), the
                        Transaction Fee, Kibandalabs Commission Fee and Tax
                        Amount (defined below), and (if applicable) the Cross
                        Border Fee (defined below)) in Kibandalabs Guarantee
                        Account to Seller;
                      </li>
                      <li>
                        (c) Kibandalabs determines that Buyer’s application for
                        a return of goods and/or refund is successful, in which
                        case, unless 8.2(d) applies, Kibandalabs will provide a
                        refund to Buyer, subject to and in accordance with the
                        Refunds and Return Policy;
                      </li>
                      <li>
                        (d) such other time as Kibandalabs reasonably determines
                        that a distribution of Buyer’s Purchase Monies (less the
                        Seller’s proportion of the Shipping Fee (if applicable),
                        the Transaction Fee, Kibandalabs Commission Fee and Tax
                        Amount (defined below), and (if applicable) the Cross
                        Border Fee (defined below)) is appropriate, including,
                        without limitation, where it deems reasonably necessary
                        to comply with applicable law or a court order or to
                        enforce these Terms of Service.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Kibandalabs Guarantee is only offered to Buyers who have
                    made payment through the channels provided by Kibandalabs
                    into Kibandalabs Guarantee Account. Offline arrangements
                    between Buyer and Seller will not be covered under
                    Kibandalabs Guarantee.
                  </li>
                  <li>
                    8.3 Payments made through Kibandalabs channels will be held
                    in the Kibandalabs Guarantee Account for a specified period
                    of time being 15 days from the delivery date (the
                    “Kibandalabs Guarantee Period”). Buyer may apply for a
                    one-time extension of Kibandalabs Guarantee Period prior to
                    the expiry of the applicable Kibandalabs Guarantee Period,
                    subject to and in accordance with the Refunds and Return
                    Policy. Upon Buyer’s application, Kibandalabs Guarantee
                    Period may be extended for a maximum period of three (3)
                    days unless Kibandalabs in its sole discretion determines
                    that a longer extension is appropriate or required.
                  </li>
                  <li>
                    8.4 If, for any reason, the Seller's bank account cannot be
                    credited and/or the Seller cannot be contacted, Kibandalabs
                    will use reasonable endeavours to contact the Seller using
                    the contact details provided by him/her. In the event that
                    the Seller cannot be contacted for more than twelve (12)
                    months after the balance of the Buyer’s Purchase Monies
                    becomes due to the Seller and such money remains unclaimed,
                    we reserve our right to handle or process such money in
                    accordance with the applicable law, including lodging such
                    money with the Registrar in accordance with the requirements
                    under the Unclaimed Financial Assets Act of 2011. In such
                    instance, you may recover your unclaimed money directly from
                    the Registrar less any fees or charges that may be imposed,
                    with no further recourse to us.
                  </li>
                  <li>
                    8.5 Seller/Buyer must be the beneficial owner of the Account
                    and conduct transaction on the Site only on behalf of him or
                    herself. Kibandalabs may require Seller or Buyer to provide
                    his or her personal data such as recent identity photograph,
                    bank account details and/or any other such documentation
                    necessary, for verification purposes, including verification
                    required by third party payment processing and logistic
                    service providers. Seller/Buyer hereby grants Kibandalabs
                    his/her consent to use or provide to third party his/her
                    personal data to facilitate his/her use of the Site.
                    Further, Seller/Buyer authorises Kibandalabs to use his/her
                    personal data to make any inquires we consider necessary to
                    validate his/her identity with the appropriate entity such
                    as his/her bank. For more information in relation to how
                    Kibandalabs handles your personal information, please visit
                    our{' '}
                    <span style={{ color: '#DB7A03' }}> privacy policy </span>{' '}
                    page.
                  </li>
                  <li>
                    8.6 The Kibandalabs Guarantee is in addition and without
                    limitation to Buyer’s and Seller’s obligations under
                    applicable law, which may go above and beyond what is
                    provided for by the Kibandalabs Guarantee. The Kibandalabs
                    Guarantee is neither intended nor designed to assist Buyer
                    or Seller in complying with its own legal obligations, for
                    which each party will remain solely responsible, and
                    Kibandalabs accepts no liability in connection with the
                    same. Without limitation, the Kibandalabs Guarantee does not
                    constitute a product warranty.
                  </li>
                  <li>
                    8.7 Buyer and Seller acknowledge and agree that
                    Kibandalabs’s decision (including any appeals) in respect of
                    and relating to any issues concerning the Kibandalabs
                    Guarantee is final.
                  </li>
                  <li>
                    8.8 For the avoidance of doubt, any transactions not
                    conducted on the Site will not qualify for the protection
                    offered by Kibandalabs Guarantee.
                  </li>
                </ul>
              </li>
              <li>
                <strong>9. Limitation of Liability</strong>
                <ul>
                  <li>
                    9.1 TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICES
                    PROVIDED BY Kibandalabs ON OR THROUGH THE SITES ARE PROVIDED
                    "AS IS", "AS AVAILABLE" AND “WITH ALL FAULTS”, AND
                    Kibandalabs HEREBY EXPRESSLY DISCLAIMS ANY AND ALL
                    WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
                    TO, ANY WARRANTIES OF CONDITION, QUALITY, DURABILITY,
                    PERFORMANCE, ACCURACY, RELIABILITY, MERCHANTABILITY OR
                    FITNESS FOR A PARTICULAR PURPOSE. ALL SUCH WARRANTIES,
                    REPRESENTATIONS, CONDITIONS, AND UNDERTAKINGS ARE HEREBY
                    EXCLUDED.
                  </li>
                  <li>
                    9.2 TO THE MAXIMUM EXTENT PERMITTED BY LAW, Kibandalabs
                    MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE VALIDITY,
                    ACCURACY, CORRECTNESS, RELIABILITY, QUALITY, STABILITY,
                    COMPLETENESS OR CURRENTNESS OF ANY INFORMATION PROVIDED ON
                    OR THROUGH THE SITES; Kibandalabs DOES NOT REPRESENT OR
                    WARRANT THAT THE MANUFACTURE, IMPORTATION, EXPORT,
                    DISTRIBUTION, OFFER, DISPLAY, PURCHASE, SALE AND/OR USE OF
                    PRODUCTS OR SERVICES OFFERED OR DISPLAYED ON THE SITES DOES
                    NOT VIOLATE ANY THIRD PARTY RIGHTS; AND Kibandalabs MAKES NO
                    REPRESENTATIONS OR WARRANTIES OF ANY KIND CONCERNING ANY
                    PRODUCT OR SERVICE OFFERED OR DISPLAYED ON THE SITES.
                  </li>
                  <li>
                    9.3 Any material downloaded or otherwise obtained through
                    the Sites or Services is done at each user's sole discretion
                    and risk and each user is solely responsible for any damage
                    to its own or to Kibandalabs’ computer system(s) or any loss
                    of data that may result from the download of any such
                    material. No advice or information, whether oral or written,
                    obtained by any user from Kibandalabs or through or from the
                    Sites shall create any warranty not expressly stated herein.
                  </li>
                  <li>
                    9.4 The Sites may make available to user services or
                    products provided by independent third parties. No warranty
                    or representation is made with regard to such services or
                    products. In no event shall Kibandalabs or our affiliates be
                    held liable for any such services or products.
                  </li>
                  <li>
                    9.5 Each user hereby agrees to indemnify and hold
                    Kibandalabs, our affiliates, directors, officers and
                    employees harmless, from any and all losses, claims,
                    liabilities (including legal costs on a full indemnity
                    basis) which may arise from such user's access to or use of
                    the Sites or Services (including but not limited to the
                    submission, posting or display of such user's information
                    and user Content on the Sites, Kibandalabs’ Channels or
                    Third Party Channels under Promotion Services) or from your
                    breach of any of the representations, warranties hereunder
                    and the terms and conditions of the Terms. Each user hereby
                    further agrees to indemnify and hold Kibandalabs, our
                    affiliates, directors, officers and employees harmless, from
                    any and all losses, damages, claims, liabilities (including
                    legal costs on a full indemnity basis) which may arise from
                    user's breach of any representations and warranties made by
                    user to Kibandalabs, including but not limited to those set
                    forth in clause 5 hereunder.
                  </li>
                  <li>
                    9.6 Each user hereby further agrees to indemnify and save
                    Kibandalabs, our affiliates, directors, officers and
                    employees harmless, from any and all losses, damages,
                    claims, liabilities (including legal costs on a full
                    indemnity basis) which may arise, directly or indirectly, as
                    a result of any claims asserted by Third Party Rights
                    claimants or other third parties relating to the user
                    Content, information and/or products offered or displayed on
                    the Sites. Each user hereby further agrees that Kibandalabs
                    is not responsible and shall have no liability to you, for
                    any material posted by others, including defamatory,
                    offensive or illicit material and that the risk of damages
                    from such material rests entirely with each user.
                    Kibandalabs reserves the right, at our own expense, to
                    assume the exclusive defense and control of any matter
                    otherwise subject to indemnification by you, in which event
                    you shall cooperate with Kibandalabs in asserting any
                    available defenses.
                  </li>
                  <li>
                    9.7 Kibandalabs shall not be liable for any special, direct,
                    indirect, punitive, incidental or consequential damages or
                    any damages whatsoever (including but not limited to damages
                    for loss of profits or savings, business interruption, loss
                    of information), whether in contract, negligence, tort,
                    equity or otherwise or any other damages resulting from any
                    of the following:
                    <ul>
                      <li>
                        a) the use or the inability to use the Sites or
                        Services;
                      </li>
                      <li>
                        b) any defect in goods, samples, data, information or
                        services purchased or obtained from a user or any other
                        third party through the Sites or Services;
                      </li>
                      <li>
                        c) violation of Third Party Rights or claims or demands
                        that user's manufacture, importation, exportation,
                        distribution, offer, display, purchase, sale and/or use
                        of products or services offered or displayed on the
                        Sites or through the Services may violate or may be
                        asserted to violate Third Party Rights; or claims by any
                        party that they are entitled to defense or
                        indemnification in relation to assertions of rights,
                        demands or claims by Third Party Rights claimants;
                      </li>
                      <li>
                        d) unauthorized access by third parties to data or
                        private information of any user;
                      </li>
                      <li>
                        e) statements or conduct of any user of the Sites or
                        Services; or
                      </li>
                      <li>
                        f) any matters relating to the Sites or Services,
                        however arising, including negligence.
                      </li>
                    </ul>
                  </li>
                  <li>
                    9.8 Notwithstanding any of the foregoing provisions, the
                    aggregate liability of Kibandalabs, our employees, agents,
                    affiliates, representatives or anyone acting on our behalf
                    with respect to each user for all claims arising from the
                    access to or use of the Sites or Services during any
                    calendar year shall be limited to the greater of (a) the
                    amount of fees the user has paid to Kibandalabs in exchange
                    for the access to or use of the Site or Services during the
                    calendar year and (b) the maximum amount permitted under the
                    applicable law. The preceding sentence shall not preclude
                    the requirement by the user to prove actual damages. All
                    claims arising from the use of the Sites or Services must be
                    filed within one (1) year from the date the cause of action
                    arose or such longer period as prescribed under the
                    applicable law governing the Terms.
                  </li>
                  <li>
                    9.9 The limitations and exclusions of liability to you under
                    the Terms shall apply to the maximum extent permitted by law
                    and shall apply whether or not Kibandalabs has been advised
                    of or should have been aware of the possibility of any such
                    losses arising.
                  </li>
                </ul>
              </li>

              <li>
                <strong>10. Force Majeure</strong>
                <ul>
                  <li>
                    10.1 Kibandalabs shall not be liable for or required to pay
                    compensation of any nature whatsoever for any loss arising
                    from the unavailability, inconvenience, or failures of the
                    services or systems due to the following reasons:
                    <ul>
                      <li>(i) system shut-down for maintenance;</li>
                      <li>
                        (ii) inability to transmit data due to failures in
                        communications terminals or telecommunications
                        equipment;
                      </li>
                      <li>
                        (iii) systems failure and inability to perform its
                        functions due to force majeure events including but not
                        limited to typhoons, earthquakes, tsunamis, floods,
                        power failure, fires, storms, war, political unrest,
                        labour strikes, shortage of labor or materials, riots,
                        insurrections, civil disturbances, terrorist attack,
                        explosions, acts of God, governmental actions, orders of
                        domestic or foreign courts or tribunals, non-performance
                        of third parties; or
                      </li>
                      <li>
                        (iv) suspension or delay of services or systems failure
                        due to reasons beyond the reasonable control of
                        Kibandalabs such as hacker or cyber attacks, technical
                        adjustments or failure of the telecommunications
                        department, website upgrades, third party problems or
                        any suspension or disruption of transportation or
                        business operation (including but not limited to delays
                        or disruption of the resumption of work or operation
                        ordered by any government agency) in the event of a
                        national or regional spread of epidemic or pandemic.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <strong>11. Intellectual Property Rights</strong>
                <ul>
                  <li>
                    11.1 Kibandalabs is the sole owner or lawful licensee of all
                    the rights and interests in the Sites and the Site Content.
                    The Sites and Site Content embody trade secrets and other
                    intellectual property rights protected under worldwide
                    copyright and other laws. All title, ownership, and
                    intellectual property rights in the Sites and Site Content
                    shall remain with Kibandalabs, our affiliates or licensors,
                    as the case may be. All rights not otherwise claimed under
                    the Terms or by Kibandalabs are hereby reserved.
                  </li>
                  <li>
                    11.2 "kibandalabs", "Kibandalabs" and related icons and
                    logos are registered trademarks or trademarks or service
                    marks of kibandalabs Group Holding Limited and “GOLD
                    SUPPLIER”, "TRUSTPASS" and related icons and logos are
                    registered trademarks or trademarks or service marks of
                    Kibandalabs Limited and its affiliates, in various
                    jurisdictions and are protected under applicable copyright,
                    trademark and other proprietary rights laws. The
                    unauthorized copying, modification, use or publication of
                    these marks is strictly prohibited.
                  </li>
                  <li>
                    11.3 Kibandalabs may have independent third parties involved
                    in the provision of the Kibandalabs Sites or Services (e.g.,
                    the authentication and verification service providers). You
                    may not use any trademark, service mark or logo of such
                    independent third parties without prior written approval
                    from such parties.
                  </li>
                  <li>
                    11.4 To the largest extent permissible under applicable law,
                    all rights, title, and interest to all derivative work
                    created by Kibandalabs and/or its affiliates using user
                    Content pursuant to the Terms shall belong to Kibandalabs,
                    which may be freely assignable, licensable or grantable by
                    Kibandalabs to any third party or its affiliates.
                  </li>
                </ul>
              </li>

              <li>
                <strong>12. Notices</strong>
                <ul>
                  <li>
                    <strong>12.1 Legal Notices</strong>
                    <ul>
                      <li>
                        12.1.1 All legal notices or demands to or upon
                        Kibandalabs shall be made in writing and sent to
                        Kibandalabs personally, by courier or certified mail to
                        the following entity and address: Kibandalabs
                        Technologies:{' '}
                        <span style={{ color: '#DB7A03' }}>
                          {' '}
                          info@kibanda4devs.com.
                        </span>
                      </li>
                      <li>
                        12.1.2 All legal notices or demands to or upon a user
                        shall be effective if either delivered personally, sent
                        by courier, certified mail, by facsimile or email to the
                        last-known correspondence, fax or email address provided
                        by the user to Kibandalabs, or by posting such notice or
                        demand on an area of the Sites that is publicly
                        accessible without a charge. Notice to a user shall be
                        deemed to be received by such user if and when:
                        <ul>
                          <li>
                            a) Kibandalabs is able to demonstrate that
                            communication, whether in physical or electronic
                            form, has been sent to such user, or
                          </li>
                          <li>
                            b) immediately upon Kibandalabs posting such notice
                            on an area of the Sites that is publicly accessible
                            without charge.
                          </li>
                        </ul>
                      </li>
                      <li>
                        12.1.3 You agree that all agreements, notices, demands,
                        disclosures and other communications that Kibandalabs
                        sends to you electronically will satisfy any legal
                        requirement that such communication should be in
                        writing.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <strong>13. Notices of illicit content</strong>
                <ul>
                  <li>
                    <strong>13.2 Notice mechanism</strong>
                    <ul>
                      <li>
                        13.2.1 You may notify us of specific pieces of
                        information that you consider to be illegal content at{' '}
                        <span style={{ color: '#DB7A03' }}>
                          “Report Abuse”{' '}
                        </span>{' '}
                        through product listing page or by contacting our
                        customer service through{' '}
                        <span style={{ color: '#DB7A03' }}>“Help Center”.</span>{' '}
                        Your notice should include the following:
                        <ul>
                          <li>
                            (a) a sufficiently substantiated explanation of the
                            reasons why you allege the information in question
                            to be illegal content;
                          </li>
                          <li>
                            (b) a clear indication of the exact electronic
                            location of that information (such as the exact URL
                            or URLs, and, where necessary, additional
                            information enabling the identification of the
                            illegal content);
                          </li>
                          <li>
                            (c) your name and email address, except in the case
                            of information considered to involve one of the
                            offences referred to in the Sexual Offences Act and
                            the Children's Act of Kenya, or relating to offences
                            relating to Children;
                          </li>
                          <li>
                            (d) a statement confirming your bona fide belief
                            that the information and allegations contained
                            therein are accurate and complete.
                          </li>
                        </ul>
                      </li>
                      <li>
                        13.2.2 Actions taken on notices. Where your notice
                        contains electronic contact information, Kibandalabs
                        will send a confirmation of receipt of the notice. After
                        reviewing your notice, Kibandalabs will notify you of
                        its decision in respect of the information to which the
                        notice relates and provide you with information on the
                        possibilities for redress.
                      </li>
                      <li>
                        13.2.3 Complaint-handling system. If you have submitted
                        a notice, you may lodge a complaint within 6 months
                        after you have been informed of Kibandalabs’ decision
                        following your notice. You may lodge your complaint,
                        using the form accessible at the “Help Center”.
                        Kibandalabs will inform you of its decision in respect
                        of the information to which the complaint related and of
                        the possibility of out-of-court dispute settlement or
                        other redress mechanisms. YOU ARE INFORMED THAT IF YOU
                        FREQUENTLY SUBMIT NOTICES OR COMPLAINTS THAT ARE
                        MANIFESTLY UNFOUNDED, Kibandalabs MAY SUSPEND, AFTER
                        ISSUING A PRIOR WARNING, THE PROCESSING OF YOUR NOTICES
                        AND COMPLAINTS.
                      </li>
                      <li>
                        13.2.4 Certified out-of-court dispute settlement. Where
                        you have submitted a notice or lodged a complaint, you
                        may select any certified out-of-court dispute settlement
                        body in order to resolve the dispute relating to
                        Kibandalabs decisions following a notice or a complaint.
                        In such case, you and Kibandalabs will engage, in good
                        faith, with the selected certified out-of-court dispute
                        settlement body with a view to resolving the dispute.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <strong>14. General Provisions</strong>
                <ul>
                  <li>
                    14.1 Subject to any Additional Agreements, the Terms
                    constitute the entire agreement between you and Kibandalabs
                    with respect to and govern your use of the Sites and
                    Services, superseding any prior written or oral agreements
                    in relation to the same subject matter herein.
                  </li>
                  <li>
                    14.2 Kibandalabs and you are independent contractors, and no
                    agency, partnership, joint venture, employee-employer or
                    franchiser-franchisee relationship is intended or created by
                    the Terms.
                  </li>
                  <li>
                    14.3 If any provision of the Terms is held to be invalid or
                    unenforceable, such provision shall be deleted and the
                    remaining provisions shall remain valid and be enforced.
                  </li>
                  <li>
                    14.4 Headings are for reference purposes only and in no way
                    define, limit, construe or describe the scope or extent of
                    such clause.
                  </li>
                  <li>
                    14.5 Kibandalabs’ failure to enforce any right or failure to
                    act with respect to any breach by you under the Terms will
                    not constitute a waiver of that right nor a waiver of
                    Kibandalabs’ right to act with respect to subsequent or
                    similar breaches.
                  </li>
                  <li>
                    14.6 Kibandalabs shall have the right to assign the Terms
                    (including all of our rights, titles, benefits, interests,
                    and obligations and duties in the Terms） to any person or
                    entity (including any affiliates of Kibandalabs). You may
                    not assign, in whole or part, the Terms to any person or
                    entity.
                  </li>
                  <li>
                    14.7 THIS AGREEMENT SHALL BE GOVERNED BY THE LAWS OF THE
                    REPUBLIC OF KENYA, AND THE PARTIES TO THIS AGREEMENT HEREBY
                    SUBMIT TO THE EXCLUSIVE JURISDICTION OF THE REPUBLIC OF
                    KENYA.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="sticky bottom-0 p-4">
            <label className="mt-4 flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                checked={isChecked}
                onChange={() => setChecked(!isChecked)}
              />
              I agree to the terms and conditions
            </label>
            <div className="flex justify-end">
              <button
                className="btn btn-danger-gradiant mr-2 border-0  bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 text-white"
                onClick={handleAgree}
              >
                Agree
              </button>
              <button
                className="bg-gray-500 px-4 py-2 text-white"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
