import React from 'react'

function Banner() {
  return (
    <div className="banner-area-three">
      <div className="container">
        <div className="banner-content-three">
          <h1>Elevate your coding experience</h1>
          <p>
            Kibanda4Devs is your dedicated partner in customizing developer
            workspaces, empowering you to create with ease and efficiency.
          </p>
          <div className="banner-btn">
            <a href="/browse-setups" className="default-btn border-radius-5">
              Browse Setups
            </a>
            <a
              href="/connect-wallet"
              className="default-btn two border-radius-5"
            >
              Connect Wallet
            </a>
            <a
              href="/customize-workspace"
              className="default-btn two border-radius-5"
            >
              Customize Workspace
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
