//src/components/Cart/Payment.jsx
// src/components/Cart/Payment.jsx

import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PriceSidebar from './PriceSidebar'
import Stepper from './Stepper'
import { clearErrors } from '../../actions/orderAction'
import { useSnackbar } from 'notistack'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import MetaData from '../Layouts/MetaData'
import { useNavigate } from 'react-router-dom'
import { emptyCart } from '../../actions/cartAction'

const Payment = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [payDisable, setPayDisable] = useState(false)
  const { shippingInfo, cartItems } = useSelector((state) => state.cart)
  const { user } = useSelector((state) => state.user)
  const { error } = useSelector((state) => state.newOrder)

  const totalPrice = cartItems?.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  )

  const paymentData = {
    amount: Math.round(totalPrice).toString(),
    email: user.email,
    phoneNo: shippingInfo.phoneNo,
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    setPayDisable(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        '/api/v1/payment/payment/process',
        paymentData,
        config
      )

      window.location.assign(data.authorization_url)

      // After successful payment, empty the cart
      dispatch(emptyCart()) // Dispatch the emptyCart action

      // Optionally, you can navigate to a success page or handle other logic here
      navigate('/order/success')
    } catch (error) {
      setPayDisable(false)
      enqueueSnackbar(error, { variant: 'error' })
    }
  }

  useEffect(() => {
    if (error) {
      dispatch(clearErrors())
      enqueueSnackbar(error, { variant: 'error' })
    }
  }, [dispatch, error, enqueueSnackbar])

  return (
    <>
      <MetaData title="Kibanda4Devs: Secure Payment | Paystack" />
      <main className="mt-20 w-full bg-gray-200 py-8">
        <div className="m-auto mt-0 flex w-full flex-col gap-3.5 sm:mb-7 sm:mt-4 sm:w-11/12 sm:flex-row">
          <div className="flex-1">
            <Stepper activeStep={3}>
              <div className=" relative z-10 rounded-lg">
                {/* Curved outward shadow effect */}
                <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                {/* End of curved outward shadow effect */}
                <div className="relative z-10 w-full">
                  <form
                    onSubmit={(e) => submitHandler(e)}
                    autoComplete="off"
                    className="mx-8 my-4 flex w-full flex-col justify-start gap-2 overflow-hidden"
                  >
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="payment-radio-group"
                        defaultValue="paytm"
                        name="payment-radio-button"
                      >
                        <FormControlLabel
                          value="paytm"
                          control={<Radio />}
                          label={
                            <div className="relative z-10 flex items-center gap-4 ">
                              <img
                                draggable="false"
                                className="h-8 w-8 rounded-md object-contain"
                                src="https://assets.paystack.com/assets/img/hero/paystack-opengraph.png"
                                alt="Paystack Logo"
                              />
                              <span>Pay Using Mpesa or Card</span>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </FormControl>

                    <input
                      type="submit"
                      value={`Pay KSh${totalPrice.toLocaleString()}`}
                      disabled={payDisable}
                      className={`${
                        payDisable
                          ? 'cursor-not-allowed bg-primary-grey'
                          : 'cursor-pointer bg-primary-orange'
                      } my-2 w-1/2 rounded-sm py-3 font-medium uppercase text-white shadow outline-none hover:shadow-lg sm:w-1/4`}
                    />
                  </form>
                </div>
              </div>
            </Stepper>
          </div>
          <PriceSidebar cartItems={cartItems} />
        </div>
      </main>
    </>
  )
}

export default Payment

// import axios from 'axios'
// import { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import PriceSidebar from './PriceSidebar'
// import Stepper from './Stepper'
// // import {
// //     CardNumberElement,
// //     CardCvcElement,
// //     CardExpiryElement,
// //     useStripe,
// //     useElements,
// // } from '@stripe/react-stripe-js';
// import { clearErrors } from '../../actions/orderAction'
// import { useSnackbar } from 'notistack'
// import { post } from '../../utils/paytmForm'
// import FormControl from '@mui/material/FormControl'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Radio from '@mui/material/Radio'
// import RadioGroup from '@mui/material/RadioGroup'
// import MetaData from '../Layouts/MetaData'
// import { useNavigate } from 'react-router-dom'

// const Payment = () => {
//   const dispatch = useDispatch()
//   const navigate = useNavigate();
//   const { enqueueSnackbar } = useSnackbar()
//   // const stripe = useStripe();
//   // const elements = useElements();
//   // const paymentBtn = useRef(null);

//   const [payDisable, setPayDisable] = useState(false)

//   const { shippingInfo, cartItems } = useSelector((state) => state.cart)
//   const { user } = useSelector((state) => state.user)
//   const { error } = useSelector((state) => state.newOrder)

//   const totalPrice = cartItems.reduce(
//     (sum, item) => sum + item.price * item.quantity,
//     0
//   )

//   const paymentData = {
//     amount: Math.round(totalPrice).toString(),
//     email: user.email,
//     phoneNo: shippingInfo.phoneNo,
//   }
// console.log(paymentData)
//   // const order = {
//   //     shippingInfo,
//   //     orderItems: cartItems,
//   //     totalPrice,
//   // }

//   const submitHandler = async (e) => {
//     e.preventDefault()

//     // paymentBtn.current.disabled = true;
//     setPayDisable(true)

//     try {
//       const config = {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       }

//       const { data } = await axios.post(
//         '/api/v1/payment/payment/process',
//         paymentData,
//         {email: user.email},
//         config
//       )
//        window.open(data.authorization_url)

//       // let info = {
//       //   action: 'https://securegw-stage.paytm.in/order/process',
//       //   params: data.paytmParams,
//       // }

//       // post(info)

//       // if (!stripe || !elements) return;

//       // const result = await stripe.confirmCardPayment(client_secret, {
//       //     payment_method: {
//       //         card: elements.getElement(CardNumberElement),
//       //         billing_details: {
//       //             name: user.name,
//       //             email: user.email,
//       //             address: {
//       //                 line1: shippingInfo.address,
//       //                 city: shippingInfo.city,
//       //                 country: shippingInfo.country,
//       //                 state: shippingInfo.state,
//       //                 postal_code: shippingInfo.pincode,
//       //             },
//       //         },
//       //     },
//       // });

//       // if (result.error) {
//       //     paymentBtn.current.disabled = false;
//       //     enqueueSnackbar(result.error.message, { variant: "error" });
//       // } else {
//       //     if (result.paymentIntent.status === "succeeded") {

//       //         order.paymentInfo = {
//       //             id: result.paymentIntent.id,
//       //             status: result.paymentIntent.status,
//       //         };

//       //         dispatch(newOrder(order));
//       //         dispatch(emptyCart());

//       //         navigate("/order/success");
//       //     } else {
//       //         enqueueSnackbar("Processing Payment Failed!", { variant: "error" });
//       //     }
//       // }
//     } catch (error) {
//       // paymentBtn.current.disabled = false;
//       setPayDisable(false)
//       enqueueSnackbar(error, { variant: 'error' })
//     }
//   }

//   useEffect(() => {
//     if (error) {
//       dispatch(clearErrors())
//       enqueueSnackbar(error, { variant: 'error' })
//     }
//   }, [dispatch, error, enqueueSnackbar])

//   return (
//     <>
//       <MetaData title="Kibanda4Devs: Secure Payment | Paystack" />

//       <main className="mt-20 w-full">
//         {/* <!-- row --> */}
//         <div className="m-auto mt-0 flex w-full flex-col gap-3.5 sm:mb-7 sm:mt-4 sm:w-11/12 sm:flex-row">
//           {/* <!-- cart column --> */}
//           <div className="flex-1">
//             <Stepper activeStep={3}>
//               <div className="w-full bg-white">
//                 <form
//                   onSubmit={(e) => submitHandler(e)}
//                   autoComplete="off"
//                   className="mx-8 my-4 flex w-full flex-col justify-start gap-2 overflow-hidden"
//                 >
//                   <FormControl>
//                     <RadioGroup
//                       aria-labelledby="payment-radio-group"
//                       defaultValue="paytm"
//                       name="payment-radio-button"
//                     >
//                       <FormControlLabel
//                         value="paytm"
//                         control={<Radio />}
//                         label={
//                           <div className="flex items-center gap-4">
//                             <img
//                               draggable="false"
//                               className="h-6 w-6 object-contain"
//                               src="https://assets.paystack.com/assets/img/hero/paystack-opengraph.png"
//                               alt="Paystack Logo"
//                             />
//                             <span>PayStack</span>
//                           </div>
//                         }
//                       />
//                     </RadioGroup>
//                   </FormControl>

//                   <input
//                     type="submit"
//                     value={`Pay $${totalPrice.toLocaleString()}`}
//                     disabled={payDisable ? true : false}
//                     className={`${payDisable ? 'cursor-not-allowed bg-primary-grey' : 'cursor-pointer bg-primary-orange'} my-2 w-1/2 rounded-sm py-3 font-medium uppercase text-white shadow outline-none hover:shadow-lg sm:w-1/4`}
//                   />
//                 </form>

//                 {/* stripe form */}
//                 {/* <form onSubmit={(e) => submitHandler(e)} autoComplete="off" className="flex flex-col justify-start gap-3 w-full sm:w-3/4 mx-8 my-4">
//                                 <div>
//                                     <CardNumberElement />
//                                 </div>
//                                 <div>
//                                     <CardExpiryElement />
//                                 </div>
//                                 <div>
//                                     <CardCvcElement />
//                                 </div>
//                                 <input ref={paymentBtn} type="submit" value="Pay" className="bg-primary-orange w-full sm:w-1/3 my-2 py-3.5 text-sm font-medium text-white shadow hover:shadow-lg rounded-sm uppercase outline-none cursor-pointer" />
//                             </form> */}
//                 {/* stripe form */}
//               </div>
//             </Stepper>
//           </div>

//           <PriceSidebar cartItems={cartItems} />
//         </div>
//       </main>
//     </>
//   )
// }

// export default Payment.
