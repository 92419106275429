import React from 'react'
import { Link, useParams } from 'react-router-dom' // Import useParams to access URL parameters

const JobInfoPage = () => {
  const { jobTitle } = useParams() // Access the jobTitle parameter from the URL

  const jobResponsibilities = ` Developing high-quality software solutions to meet business requirements.
    Designing and implementing scalable and reliable code for various projects.
    Collaborating with cross-functional teams including designers, product managers, and other engineers to define, design, and ship new features.
    Participating in code reviews to ensure code quality and standards are met.
    Investigating and resolving software defects and technical issues.
    Keeping up-to-date with the latest technologies and industry trends to continuously improve skills and knowledge.`

  // Function to encode job title for URL
  const encodeJobTitle = (title) => title.replace(/\s+/g, '-')

  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-200">
      <div className="container relative z-10 mx-auto">
        <h1
          className="relative z-10 mb-2 text-center font-sans text-3xl font-bold"
          style={{ marginBottom: '20px' }}
        >
          Job Information
          <span
            className="absolute relative left-1/2 z-10 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
            style={{ backgroundColor: '#DB7A03' }}
          ></span>
        </h1>

        <div className=" relative z-10 mx-auto rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <div className="relative z-10 mb-8 border-b border-gray-200 pb-4">
            <h2 className="mb-2 text-xl font-semibold">{jobTitle}</h2>
            {/* For jobLocation, you can fetch it from an API or pass it as a URL parameter */}
            {/* Example: <p className="text-gray-600 text-sm">{jobLocation}</p> */}
          </div>
          <div className="relative z-10 mb-8">
            <h3 className="mb-2 text-lg font-semibold">Description</h3>
            <p className="text-gray-600">
              We are seeking a skilled and experienced {jobTitle} to join our
              team. In this role, you will be responsible for:
              <br />
              <ul className="list-disc pl-4">
                {jobResponsibilities
                  .split('\n')
                  .map((responsibility, index) => (
                    <li className="text-gray-600" key={index}>
                      {responsibility.trim()}
                    </li>
                  ))}
              </ul>
            </p>
          </div>
        </div>

        <div className=" relative z-10 mx-auto mt-8 rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <div className="relative mb-8 mt-8">
            <h3 className="mb-2 text-lg font-semibold">Requirements</h3>
            <ul className="list-disc pl-4">
              <li className="text-gray-600">
                Bachelor's degree in Computer Science or related field.
              </li>
              <li className="text-gray-600">
                Minimum of 3 years of experience in software development.
              </li>
              <li className="text-gray-600">
                Strong proficiency in JavaScript, React, Node.js, SQL.
              </li>
            </ul>
          </div>
        </div>
        <div className=" relative z-10 mx-auto mb-8 mt-8 rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <div className="relative mb-8 mt-8">
            <h3 className="mb-2 text-lg font-semibold">Benefits</h3>
            <ul className="list-disc pl-4">
              <li className="text-gray-600">Competitive salary.</li>
              <li className="text-gray-600">
                Health insurance and retirement plans.
              </li>
              <li className="text-gray-600">Flexible working hours.</li>
            </ul>
          </div>
        </div>
        <div className="text-center">
          {/* Use encoded job title in the URL */}
          <Link
            to={`/careers/apply/${encodeJobTitle(jobTitle)}/apply-form`}
            className="mb-8 ml-8 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            Apply Now
          </Link>
        </div>
      </div>
    </div>
  )
}

export default JobInfoPage
