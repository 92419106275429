import React, { useState } from 'react'
import MissionImage from '../../../assets/images/blog1.jpeg'
import MissionImage2 from '../../../assets/images/blog2.jpeg'
import MissionImage3 from '../../../assets/images/Blog3.jpeg'

const initialBlogPosts = [
  {
    id: 1,
    title:
      "Why We Need Starlink (and Alternatives) After East Africa's Internet Meltdown (For a Connected Dev Workspace)",
    date: 'May 13, 2024',
    author: 'Kibanda4Devs',
    excerpt:
      "Isn't it time we explored alternative internet solutions, especially for a seamless smart desk experience?",
    image: MissionImage,
    url: 'https://www.linkedin.com/pulse/why-we-need-starlink-alternatives-after-east-africas-internet-bpucf/?trackingId=d9xtKT5P8WVX7isAdJohbA%3D%3D',
  },
  {
    id: 2,
    title: 'Make Your Wednesday Count: Coding Tips and Tricks',
    date: 'April 17, 2024',
    author: 'Kibanda4Devs',
    excerpt:
      "Whether you're seasoned or just starting out, there's always something new to learn or a smoother way to approach those code challenges.",
    image: MissionImage2,
    url: 'https://www.linkedin.com/pulse/make-your-wednesday-count-coding-tips-tricks-kibanda4devs-lzlee/',
  },
  {
    id: 3,
    title:
      "Stop Battling Your Laptop: The Developer's Guide to Choosing the Right Machine",
    date: 'February 20, 2024',
    author: 'Kibanda4Devs',
    excerpt:
      "At Kibanda4devs, we understand the struggle of coding on an underpowered machine – that's why we're passionate about helping developers find the perfect tools for the job.",
    image: MissionImage3,
    url: 'https://www.linkedin.com/pulse/stop-battling-your-laptop-developers-guide-choosing-right-jdpif/',
  },
  // {
  //   id: 4,
  //   title: 'The Power of Flexbox in Web Design',
  //   date: 'February 10, 2024',
  //   author: 'Bob Williams',
  //   excerpt:
  //     'Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.',
  //   image: MissionImage,
  //   url: '#'
  // },
  // {
  //   id: 5,
  //   title: 'Responsive Web Design Techniques',
  //   date: 'January 5, 2024',
  //   author: 'Emily Davis',
  //   excerpt:
  //     'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.',
  //   image: MissionImage,
  //   url: '#'
  // },
  // {
  //   id: 6,
  //   title: 'SEO Tips for Improving Website Ranking',
  //   date: 'December 20, 2023',
  //   author: 'Mike Johnson',
  //   excerpt:
  //     'Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.',
  //   image: MissionImage,
  //   url: '#'
  // },
  // {
  //   id: 7,
  //   title: 'Understanding Node.js Fundamentals',
  //   date: 'November 15, 2023',
  //   author: 'Sarah Brown',
  //   excerpt:
  //     'Sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
  //   image: MissionImage,
  //   url: '#'
  // },
  // {
  //   id: 8,
  //   title: 'The Importance of UI/UX Design in Apps',
  //   date: 'October 10, 2023',
  //   author: 'Chris Wilson',
  //   excerpt:
  //     'Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.',
  //   image: MissionImage,
  //   url: '#'
  // },
  // {
  //   id: 9,
  //   title: 'Creating Interactive Web Experiences with JavaScript',
  //   date: 'September 5, 2023',
  //   author: 'Laura Taylor',
  //   excerpt:
  //     'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi.',
  //   image: MissionImage,
  //   url: '#'
  // },
]

function Blog() {
  const [blogPosts, setBlogPosts] = useState(initialBlogPosts.slice(0, 3))
  const [showAll, setShowAll] = useState(false)

  const toggleShowAll = () => {
    setShowAll(!showAll)
    if (!showAll) {
      setBlogPosts(initialBlogPosts)
    } else {
      setBlogPosts(initialBlogPosts.slice(0, 3))
    }
  }

  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-200">
      <div className="container relative z-10 mx-auto">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}

        <div className="relative z-10 mx-auto rounded-lg p-8">
          <h1 className="text-orange-600 relative z-10 mb-6 mt-3 text-center font-sans text-3xl font-bold">
            Latest Blog Posts
            <span
              className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            ></span>
          </h1>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {blogPosts.map((post) => (
              <div
                key={post.id}
                className="rounded-lg bg-gray-100 p-6 shadow-md"
              >
                <img
                  src={post.image}
                  alt={post.title}
                  className="mb-4 w-full rounded-lg"
                />
                <h2 className="mb-2 text-xl font-semibold">{post.title}</h2>
                <p className="mb-2 text-gray-600">
                  {post.date} | By {post.author}
                </p>
                <p className="text-gray-800">{post.excerpt}</p>
                <a
                  href={post.url}
                  className="font-semibold text-yellow-600 hover:text-yellow-700"
                >
                  Read More
                </a>
              </div>
            ))}
          </div>
          <div className="mt-8 text-center">
            {showAll ? (
              <button
                onClick={toggleShowAll}
                className="ml-8 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
              >
                View Less
              </button>
            ) : (
              <button
                onClick={toggleShowAll}
                className="ml-8 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
              >
                View More
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog
