const counties = [
  {
    code: 'BGM',
    name: 'Baringo',
  },
  {
    code: 'BGN',
    name: 'Bungoma',
  },
  {
    code: 'BKI',
    name: 'Bomet',
  },
  {
    code: 'BSS',
    name: 'Busia',
  },
  {
    code: 'EBU',
    name: 'Embu',
  },
  {
    code: 'GSA',
    name: 'Garissa',
  },
  {
    code: 'HMA',
    name: 'Homa Bay',
  },
  {
    code: 'ISI',
    name: 'Isiolo',
  },
  {
    code: 'KAJ',
    name: 'Kajiado',
  },
  {
    code: 'KAK',
    name: 'Kakamega',
  },
  {
    code: 'KCO',
    name: 'Kericho',
  },
  {
    code: 'KBU',
    name: 'Kiambu',
  },
  {
    code: 'KIL',
    name: 'Kilifi',
  },
  {
    code: 'KIR',
    name: 'Kirinyaga',
  },
  {
    code: 'KIS',
    name: 'Kisii',
  },
  {
    code: 'KII',
    name: 'Kisumu',
  },
  {
    code: 'KIT',
    name: 'Kitui',
  },
  {
    code: 'KWA',
    name: 'Kwale',
  },
  {
    code: 'LDP',
    name: 'Laikipia',
  },
  {
    code: 'LMA',
    name: 'Lamu',
  },
  {
    code: 'MAC',
    name: 'Machakos',
  },
  {
    code: 'MAK',
    name: 'Makueni',
  },
  {
    code: 'MAL',
    name: 'Mandera',
  },
  {
    code: 'MER',
    name: 'Meru',
  },
  {
    code: 'MIG',
    name: 'Migori',
  },
  {
    code: 'MRS',
    name: 'Marsabit',
  },
  {
    code: 'MBA',
    name: 'Mombasa',
  },
  {
    code: 'MUR',
    name: "Murang'a",
  },
  {
    code: 'NBO',
    name: 'Nairobi',
  },
  {
    code: 'NKU',
    name: 'Nakuru',
  },
  {
    code: 'NAN',
    name: 'Nandi',
  },
  {
    code: 'NAR',
    name: 'Narok',
  },
  {
    code: 'NTH',
    name: 'Nyeri',
  },
  {
    code: 'TTR',
    name: 'Taita Taveta',
  },
  {
    code: 'TAN',
    name: 'Tana River',
  },
  {
    code: 'TUR',
    name: 'Tharaka Nithi',
  },
  {
    code: 'TRN',
    name: 'Trans Nzoia',
  },
  {
    code: 'UAS',
    name: 'Uasin Gishu',
  },
  {
    code: 'VIH',
    name: 'Vihiga',
  },
  {
    code: 'WJR',
    name: 'Wajir',
  },
  {
    code: 'GBK',
    name: 'West Pokot',
  },
]

export default counties
