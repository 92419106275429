import AuctionsArea from './AuctionsArea'
import AuthorArea from './AuthorArea'
import Banner from './Banner'
import BlogArea from './BlogArea'
import CollectionsArea from './CollectionsArea'
import CopyRightArea from './CopyRightArea'
import FeaturedArea from './FeaturedArea'
import FooterArea from './FooterArea'
import NavBar from './NavBar'
import PreLoader from './PreLoader'
import SearchModal from './SearchModal'
import TestimonialArea from './TestimonialArea'
import TopSellers from './TopSellers'
import TrendingArea from './TrendingArea'

function GuestMode() {
  return (
    <div className="App">
      <PreLoader />
      <NavBar />
      <Banner />
      <AuctionsArea />
      <FeaturedArea />
      {/* <CollectionsArea/> */}
      <TrendingArea />
      {/* <TopSellers/> */}
      <AuthorArea />
      <BlogArea />

      <TestimonialArea />
      <FooterArea />

      {/* <SearchModal/> */}
      <CopyRightArea />
    </div>
  )
}

export default GuestMode
