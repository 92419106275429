import React from 'react'

function PreLoader() {
  return (
    <div className="preloader">
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
    </div>
  )
}

export default PreLoader
