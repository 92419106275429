// Categories Used In Entire App
export const categories = [
  'Electronics',
  'Chairs',
  'Art',
  'Desks',
  'Lighting',
  'Desk Plants',
  'Merchandise',
  "Developer's Health",
  "Developer's Books",
  // 'Acessories',
]

// Product Sliders Offers
export const offerProducts = [
  {
    image:
      'https://rukminim1.flixcart.com/image/150/150/jqgy3rk0/computer-table/j/2/v/particle-board-dkl-comp-tb-09-delite-kom-brown-original-imafcheqbgers37z.jpeg',
    name: 'Office Study Tables',
    offer: 'From KSh10,000',
    tag: 'Made In Kenya Brands',
  },
  {
    image:
      'https://rukminim1.flixcart.com/image/150/150/jqgy3rk0/computer-table/j/2/v/particle-board-dkl-comp-tb-09-delite-kom-brown-original-imafcheqbgers37z.jpeg',
    name: 'Developer Books',
    offer: 'From KSh2500',
    tag: 'New reads',
  },
  {
    image:
      'https://i5.walmartimages.com/seo/USB-Streaming-Podcast-Microphone-Kit-Professional-192KHZ-24Bit-Studio-Cardioid-Condenser-Computer-PC-Mic-Kit-Scissor-Arm-Shock-Mount-Stand-Pop-Filter_3c2e2d72-8479-4851-a71e-a98dc60e89b2.98ec1ac546fbe264720bbb9f3581991e.png',
    name: 'Podcast Equipment',
    offer: 'From KSh1800',
    tag: 'Start a podcast',
  },
  {
    image:
      'https://www.pimpmyoffice.com.au/cdn/shop/products/HUSH-MEET-Mikomax_1400x.jpg?v=1581489571',
    name: 'Hush Office Pods Meet',
    offer: 'In Focus Now',
    tag: 'Buy Now!',
  },
  {
    image:
      'https://www.static-src.com/wcsstore/Indraprastha/images/catalog/full/MTA-7600247/sony_sony-ps4-grand-theft-auto-v-premium-online-edition-gta-5--r3----efootball-pes-2020-game-ps4--r2--dvd-game_full03.jpg',
    name: 'Console Games',
    offer: 'Min 70% Off',
    tag: 'Buy Now!',
  },
  {
    image:
      'https://www.americangamingsupplies.com/Images/Flash/Large/GO-2286H-BK-RLB-GG.jpg',
    name: 'Office Study Chairs',
    offer: 'Min 40% Off',
    tag: 'Grab Now!',
  },
  {
    image:
      'https://rukminim1.flixcart.com/image/150/150/ky0g58w0/smartwatch/v/w/p/33-android-ios-id116-plus-fitpro-yes-original-imagacdfj58fqtgf.jpeg',
    name: 'Smart Watches',
    offer: 'Min 40% Off',
    tag: 'Discover Now!',
  },
  {
    image:
      'https://cdn.vox-cdn.com/thumbor/ICuRY7bEqlQBMGY5nIYQ0nCmhPE=/0x0:5120x2880/1400x1400/filters:focal(2560x1440:2561x1441)/cdn.vox-cdn.com/uploads/chorus_asset/file/24951122/Ray_Ban_Meta_Smart_Glasses___Transparent_Group_Shot.jpg',
    name: 'Ray-Ban Meta Smart Glasses',
    offer: 'Min 20% Off',
    tag: 'Great Savings!',
  },
  {
    image:
      'https://www.jbhifi.com.au/cdn/shop/products/527435-Product-1-I-637606392502122880_1024x1024.jpg',
    name: 'Bluetooth Speakers',
    offer: 'Upto 40% Off',
    tag: 'Buy Now!',
  },
  {
    image:
      'https://resource.logitech.com/content/dam/gaming/en/products/g240/g240-gallery-1-new.png',
    name: 'Mouse Pads',
    offer: 'Upto 10% Off',
    tag: 'Great Savings!',
  },
  {
    image: 'https://m.media-amazon.com/images/I/71A4MiB1FXL.jpg',
    name: 'Headphones',
    offer: 'Min 60% Off',
    tag: 'Grab Now!',
  },
  {
    image:
      'https://www.starlinkhardware.com/wp-content/uploads/2023/11/Starlink_Standard_500x500.png',
    name: 'Starlink Hardware',
    offer: 'Upto 30% Off',
    tag: 'Discover Now!',
  },
  {
    image:
      'https://goodsph.files.wordpress.com/2015/08/transcend-storejet-25m3.jpg',
    name: 'Storage Devices',
    offer: 'Min 20% Off',
    tag: 'Shop Now!',
  },
  {
    image:
      'https://applescoop.org/image/story/apple-vr-headset-february-2021-2.jpg',
    name: 'VR Headsets',
    offer: 'Upto 15% Off',
    tag: 'Grab Now!',
  },
  {
    image:
      'https://www.brighttech.co.ke/wp-content/uploads/2018/02/Tplink-TL-WA850RE-300Mbps-Universal-Wi-Fi-Range-Extender.jpg',
    name: 'WI-FI Range Extenders',
    offer: 'Min 10% Off',
    tag: 'Explore Now!',
  },
  {
    image:
      'https://c4.wallpaperflare.com/wallpaper/241/804/22/paintings-silence-traditional-art-scans-abstract-wallpaper-preview.jpg',
    name: 'ArtWorks',
    offer: 'Min 20% Off',
    tag: 'Great Savings!',
  },
  {
    image:
      'https://rukminim1.flixcart.com/image/150/150/k6mibgw0/datacard/r/h/g/jiofi-jmr-541-original-imafpfhandhkptwc.jpeg',
    name: 'Data Cards',
    offer: 'Upto 40% Off',
    tag: 'Buy Now!',
  },
  {
    image:
      'https://i5.walmartimages.com/asr/d2cb983f-aaeb-447a-aa1f-fd16972c387f.6888e2cc4b00bfd78d5af2d86cb83bc5.jpeg?odnHeight=768&odnWidth=768&odnBg=FFFFFF',
    name: 'Led Crystal Night Light,',
    offer: 'Upto 10% Off',
    tag: 'Grab Now!',
  },
  {
    image:
      'https://milan.milanovic.org/post/the-greatest-software-development-books-of-all-time/featured_huac570fc56800933dc6719718917798ef_526772_680x500_fill_q90_lanczos_smart1_2.png',
    name: 'Software Engineering Books',
    offer: 'Upto 55% Off',
    tag: 'Top Rated',
  },
  {
    image:
      'https://i3.cloudfable.net/styles/735x735/128.133/Black/code-ninja-programmer-coder-computer-programming-coding-coffee-mug-20231026014514-shr1rjbj.jpg',
    name: "Devs' Merchandise",
    offer: 'Min 60% Off',
    tag: 'Great Savings!',
  },
  {
    image:
      'https://territories.co.ke/wp-content/uploads/2023/03/Laptop-Stand-for-Desk-Ultra-Thin-Light-Weight-Aluminum-Adjustable-Laptop-Holder-1.png',
    name: 'Foldable Laptop Stands',
    offer: 'Upto 25% Off',
    tag: 'Discover Now!',
  },
  {
    image:
      'https://www.asokayoga.co.za/wp-content/uploads/2019/10/emerald-green-navy-1.jpg',
    name: 'Yoga Mats',
    offer: 'From $20',
    tag: 'Relax & Work',
  },
  {
    image: 'https://m.media-amazon.com/images/I/718Wm2k-kML.jpg',
    name: 'Electric Standing Tables',
    offer: 'Min 50% Off',
    tag: 'Explore Now!',
  },
  {
    image:
      'https://hightech.com.gr/wp-content/uploads/2023/10/278-86-ADSP3BK_1.jpg',
    name: 'Fabric Gaming Chairs',
    offer: 'Upto 20% Off',
    tag: 'Shop Now!',
  },
  {
    image:
      'https://m.media-amazon.com/images/I/51P1s7MZB+L._AC_UF894,1000_QL80_.jpg',
    name: 'Laptop Chargers',
    offer: 'Upto 60% Off',
    tag: 'Great Savings!',
  },
  {
    image:
      'https://i5.walmartimages.com/asr/39e7b1df-7d76-4519-8f21-dea328ff6461.589f6277f0db4377c338fdc8f559bd44.jpeg',
    name: 'Laptops',
    offer: 'Min 20% Off',
    tag: 'Great Savings!',
  },
  {
    image: 'https://m.media-amazon.com/images/I/61ZV8lbPX5S.jpg',
    name: 'Desk Plants',
    offer: 'Min 5% Off',
    tag: 'Grab Now!',
  },
  {
    image:
      'https://m.media-amazon.com/images/I/5183aMmrVdL._AC_UF894,1000_QL80_.jpg',
    name: 'Playstation 5',
    offer: 'Upto 10% Off',
    tag: 'Buy Now!',
  },
]
