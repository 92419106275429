import Backdrop from '@mui/material/Backdrop'
// import CircularProgress from '@mui/material/CircularProgress'

// const BackdropLoader = () => {
//   return (
//     <Backdrop sx={{ color: '#fff', zIndex: 1500 }} open={true}>
//       <CircularProgress color="inherit" />
//     </Backdrop>
//   )
// }

// export default BackdropLoader

import React from 'react'
import { quantum } from 'ldrs'

quantum.register()

const BackdropLoader = () => {
  return (
    <Backdrop sx={{ color: '#DB7904', zIndex: 1500 }} open={true}>
      <l-quantum size="45" speed="1.75" color="#DB7904"></l-quantum>
    </Backdrop>
  )
}

export default BackdropLoader
