import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { addItemsToCart, removeItemsFromCart } from '../../actions/cartAction'
import { getDeliveryDate, getDiscount } from '../../utils/functions'
import { saveForLater } from '../../actions/saveForLaterAction'
import { Link } from 'react-router-dom'

const CartItem = ({
  product,
  name,
  seller,
  price,
  cuttedPrice,
  image,
  stock,
  quantity,
  inCart,
}) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const increaseQuantity = (id, quantity, stock) => {
    if (quantity >= stock) {
      enqueueSnackbar('Maximum Order Quantity', { variant: 'warning' })
      return
    }
    const newQty = quantity + 1 // Update quantity before dispatch
    dispatch(addItemsToCart(id, newQty))
  }

  const decreaseQuantity = (id, quantity) => {
    const newQty = quantity - 1
    if (quantity <= 1) return
    dispatch(addItemsToCart(id, newQty))
  }

  const removeCartItem = (id) => {
    dispatch(removeItemsFromCart(id))
    enqueueSnackbar('Product Removed From Cart', { variant: 'success' })
  }

  const saveForLaterHandler = (id) => {
    dispatch(saveForLater(id))
    removeCartItem(id)
    enqueueSnackbar('Saved For Later', { variant: 'success' })
  }

  return (
    <div
      className="relative z-10 flex flex-col gap-3 overflow-hidden border-b bg-gray-200 py-5 pl-2 sm:pl-6"
      key={product}
    >
      <Link
        to={`/product/${product}`}
        className="group flex w-full flex-col items-stretch gap-5 sm:flex-row"
      >
        {/* <!-- product image --> */}
        <div className="h-28 w-full flex-shrink-0 sm:w-1/6">
          <img
            draggable="false"
            className="h-full w-full object-contain"
            src={image}
            alt={name}
          />
        </div>
        {/* <!-- product image --> */}

        {/* <!-- description --> */}
        <div className="flex w-full flex-col pr-6 sm:gap-5">
          {/* <!-- product title --> */}
          <div className="flex flex-col items-start justify-between gap-1 pr-5 sm:flex-row sm:gap-0">
            <div className="flex flex-col gap-0.5 sm:w-3/5">
              <p className="group-hover:text-primary-blue">
                {name.length > 42 ? `${name.substring(0, 42)}...` : name}
              </p>
              <span className="text-sm text-gray-500">Seller: {seller}</span>
            </div>

            <div className="flex flex-col sm:gap-2">
              <p className="text-sm">
                Delivery by {getDeliveryDate()} |{' '}
                <span className="text-primary-green">Free</span>{' '}
                <span className="line-through">Ksh{quantity * 40}</span>
              </p>
              <span className="text-xs text-gray-500">
                7 Days Replacement Policy
              </span>
            </div>
          </div>
          {/* <!-- product title --> */}

          {/* <!-- price desc --> */}
          <div className="flex items-baseline gap-2 text-xl font-medium">
            <span>KSh{(price * quantity).toLocaleString()}</span>
            <span className="text-sm font-normal text-gray-500 line-through">
              KSh{(cuttedPrice * quantity).toLocaleString()}
            </span>
            <span className="text-sm text-primary-green">
              {getDiscount(price, cuttedPrice)}%&nbsp;off
            </span>
          </div>
          {/* <!-- price desc --> */}
        </div>
        {/* <!-- description --> */}
      </Link>

      {/* <!-- save for later --> */}
      <div className="flex justify-between pr-4 sm:justify-start sm:gap-6 sm:pr-0">
        {/* <!-- quantity --> */}
        <div className="ml-10 flex items-center gap-1 ">
          <span
            onClick={() => decreaseQuantity(product, quantity)}
            className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border bg-gray-50 text-3xl font-light"
          >
            <p>-</p>
          </span>
          <input
            className="qtyInput w-11 rounded-sm border py-0.5 text-center text-sm font-medium text-gray-700 outline-none"
            value={quantity}
            disabled
          />
          <span
            onClick={() => increaseQuantity(product, quantity, stock)}
            className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border bg-gray-50 text-xl font-light"
          >
            +
          </span>
        </div>
        {/* <!-- quantity --> */}
        {inCart && (
  <div className="flex flex-col gap-4 p-4 sm:p-6">
    <div className="relative z-10 rounded-lg p-4 sm:p-6">
      {/* Curved outward shadow effect */}
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
      {/* End of curved outward shadow effect */}
      <button
        onClick={() => saveForLaterHandler(product)}
        className="relative z-10 font-medium hover:text-primary-blue w-full text-center"
      >
        SAVE FOR LATER
      </button>
    </div>

    <div className="relative z-10 rounded-lg p-4 sm:p-6">
      {/* Curved outward shadow effect */}
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
      {/* End of curved outward shadow effect */}
      <button
        onClick={() => removeCartItem(product)}
        className="relative z-10 font-medium hover:text-red-600 w-full text-center"
      >
        REMOVE
      </button>
    </div>
  </div>
)}

      </div>
      {/* <!-- save for later --> */}
    </div>
  )
}

export default CartItem
