import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearErrors, loginUser } from '../../actions/userAction'
import { useSnackbar } from 'notistack'
import BackdropLoader from '../Layouts/BackdropLoader'
import MetaData from '../Layouts/MetaData'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()

  const { loading, isAuthenticated, error } = useSelector((state) => state.user)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(loginUser(email, password))
  }

  const redirect = location.search ? location.search?.split('=')[1] : 'account'

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' })
      dispatch(clearErrors())
    }
    if (isAuthenticated) {
      navigate(`/${redirect}`)
    }
  }, [dispatch, error, isAuthenticated, redirect, navigate, enqueueSnackbar])

  return (
    <>
      <MetaData title="Login | Kibanda4Devs" />

      {loading && <BackdropLoader />}
      <main className="mt-12 flex min-h-screen w-full items-center justify-center  sm:mt-0 sm:pt-20">
        {/* <!-- row --> */}
        <div className="m-auto mb-7 flex bg-gray-200 shadow-lg sm:mt-4 sm:w-4/6">
          {/* <!-- sidebar column  --> */}
          <div
            className="loginSidebar hidden w-2/5 flex-col gap-4  p-10 pr-12 sm:flex"
            style={{
              backgroundColor: '#00c9b7',
              boxShadow: '0px 0px 10px 0px #00c9b7',
            }}
          >
            <h1 className="text-3xl font-medium text-white">Login</h1>
            <p className="text-lg text-gray-200">
              Get access to your Orders, Wishlist and Recommendations
            </p>
          </div>
          {/* <!-- sidebar column  --> */}

          {/* <!-- login column --> */}

          <div className="flex-1 overflow-hidden">
            {/* <!-- edit info container --> */}

            <div className="px-4 py-10 text-center sm:px-14">
              {/* <!-- input container --> */}
              <form onSubmit={handleLogin}>
                <div className="flex w-full flex-col gap-4">
                  <TextField
                    fullWidth
                    id="email"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <TextField
                    fullWidth
                    id="password"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  {/* <span className="text-xxs text-red-500 font-medium text-left mt-0.5">Please enter valid Email ID/Mobile number</span> */}

                  {/* <!-- button container --> */}
                  <div className="mb-32 mt-2 flex flex-col gap-2.5">
                    <p className="text-left text-xs text-primary-grey">
                      By continuing, you agree to Kibanda4Devs's{' '}
                      <a
                        href="https://www.Kibanda4Devs.com/pages/terms"
                        className="text-primary-blue"
                      >
                        {' '}
                        Terms of Use
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://www.Kibanda4Devs.com/pages/privacypolicy"
                        className="text-primary-blue"
                      >
                        {' '}
                        Privacy Policy.
                      </a>
                    </p>
                    <button
                      type="submit"
                      className="w-full rounded-sm bg-[#DB7904] py-3 font-medium text-white shadow hover:shadow-lg"
                    >
                      Login
                    </button>
                    <Link
                      to="/password/forgot"
                      className="w-full rounded-sm border py-3 text-center font-medium text-primary-blue shadow hover:bg-gray-50"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  {/* <!-- button container --> */}
                </div>
              </form>
              {/* <!-- input container --> */}

              <Link
                to="/register"
                className="text-sm font-medium text-primary-blue"
              >
                New to Kibanda4Devs? Create an account
              </Link>
            </div>
            {/* <!-- edit info container --> */}
          </div>
          {/* <!-- login column --> */}
        </div>
        {/* <!-- row --> */}
      </main>
    </>
  )
}

export default Login
