import React from 'react'
import { Link } from 'react-router-dom'
import MissionImage from '../../../assets/images/mission.avif'
import Step1 from '../../../assets/images/step1.jpeg'
import Step2 from '../../../assets/images/step2.jpeg'
import Step3 from '../../../assets/images/step3.jpeg'
import Step4 from '../../../assets/images/step4.jpeg'
import CreditCard from '../../../assets/images/credittt.jpeg'
import Mpesa from '../../../assets/images/mpesalogo.png'
import SellOn from '../../../assets/images/sellon.jpeg'
import Affiliate from '../../../assets/images/affiliate2.jpeg'

function SellInfo() {
  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-200 py-8">
      <div className="container relative z-10 mx-auto">
        {/* Header Section */}
        <div className="relative z-10 mb-12 text-center">
          <h1
            className="relative mx-auto mb-4 text-center font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            Sell with Us
            <span
              className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            />
          </h1>
        </div>
        <div className="relative z-10 mx-auto mb-12 rounded-lg p-8 text-center  ">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <p className="relative z-10 mb-4 ml-8 text-gray-600 ">
            Are you a business looking to expand your reach and sell your
            products online? Join Kibanda4devs today and get the chance to show
            your products to millions of customers all around the globe.
          </p>
          {/* Start Selling Button */}
          <Link
            to="/business-registration-form"
            className="relative z-10 ml-8 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            Start Now
          </Link>
        </div>

        <div className=" relative z-10 mx-auto rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          {/* Section 1: Step 1 */}
          <section className="relative z-10 mb-12 flex flex-col items-center md:flex-row">
            <div className="md:w-1/2 md:pr-4">
              <h2
                className="mb-4 ml-8 text-2xl font-semibold"
                style={{ color: '#DB7A03' }}
              >
                Step 1: Create Your Account
              </h2>
              <p className="mb-4 ml-8 text-gray-600">
                Sign up for a seller account on Kibanda4devs. It's quick and
                easy!
              </p>
            </div>
            <div className="ml-4 md:w-1/2">
              <img src={Step1} alt="Step 1" className="rounded-lg shadow-md" />
            </div>
          </section>
        </div>

        {/* Section 2: Step 2 */}

        <div className=" relative z-10 mx-auto mb-8 rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <section className="relative z-10 mb-12 flex flex-col items-center md:flex-row">
            <div className="ml-4 md:w-1/2">
              <img src={Step2} alt="Step 2" className="rounded-lg shadow-md" />
            </div>
            <div className="md:w-1/2 md:pl-4">
              <h2
                className="mb-4 ml-8 text-2xl font-semibold"
                style={{ color: '#DB7A03' }}
              >
                Step 2: List Your Products
              </h2>
              <p className="mb-4 ml-8 text-gray-600">
                Upload images and descriptions of your products to create
                listings.
              </p>
            </div>
          </section>
        </div>

        {/* Section 3: Step 3 */}

        <div className=" relative z-10 mx-auto mb-8 rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <section className="relative z-10 mb-12 flex flex-col items-center md:flex-row">
            <div className="md:w-1/2 md:pr-4">
              <h2
                className="mb-4 ml-8 text-2xl font-semibold"
                style={{ color: '#DB7A03' }}
              >
                Step 3: Set Prices and Inventory
              </h2>
              <p className="mb-4 ml-8 text-gray-600">
                Determine the prices for your products and manage your
                inventory.
              </p>
            </div>
            <div className="ml-4 md:w-1/2">
              <img src={Step3} alt="Step 3" className="rounded-lg shadow-md" />
            </div>
          </section>
        </div>

        {/* Section 4: Step 4 */}

        <div className=" relative z-10 mx-auto mb-8 rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <section className="relative z-10 mb-12 flex flex-col items-center md:flex-row">
            <div className="ml-4 md:w-1/2">
              <img src={Step4} alt="Step 4" className="rounded-lg shadow-md" />
            </div>
            <div className="md:w-1/2 md:pl-4">
              <div>
                <h2
                  className="mb-4 ml-8 text-2xl font-semibold"
                  style={{ color: '#DB7A03' }}
                >
                  Step 4: Start Selling!
                </h2>
                <p className="mb-4 ml-8 text-gray-600">
                  Once your listings are live, customers can start purchasing
                  your products.
                </p>
              </div>
              {/* Start Selling Button */}
              <div className="mt-8 text-center">
                {' '}
                {/* Move the button to the bottom */}
                <Link
                  to="/business-registration-form"
                  className="inline-block rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
                >
                  Start Now
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default SellInfo
