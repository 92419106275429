import { useDispatch, useSelector } from 'react-redux'
import FolderIcon from '@mui/icons-material/Folder'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import PersonIcon from '@mui/icons-material/Person'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import ChatIcon from '@mui/icons-material/Chat'
import FolderSharedIcon from '@mui/icons-material/FolderShared'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import { Link, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { logoutUser } from '../../actions/userAction'

const Sidebar = ({ activeTab }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { user } = useSelector((state) => state.user)

  const handleLogout = () => {
    dispatch(logoutUser())
    enqueueSnackbar('Logout Successfully', { variant: 'success' })
    navigate('/login')
  }

  return (
    <div className="hidden w-1/4 flex-col gap-4 bg-gray-200 px-1 sm:flex">
      {/* <!-- profile card --> */}
      <div className="mb-6 mt-8 flex items-center gap-4 rounded-sm bg-gray-200 p-3 shadow">
        {/* <!-- user icon --> */}
        <div className="h-12 w-12 rounded-full">
          <img
            draggable="false"
            className="h-full w-full rounded-full object-cover"
            src={user?.avatar?.url}
            alt="Avatar"
          />
        </div>
        {/* <!-- user icon --> */}
        <div className="flex flex-col gap-1">
          <p className="text-xs">Hello,</p>
          <h2
            className="rounded-md px-2 py-1 font-medium"
            style={{ backgroundColor: '#FFA500' }}
          >
            {user?.name}
          </h2>
        </div>
      </div>
      {/* <!-- profile card --> */}

      {/* <!-- nav tiles --> */}
      <div className=" relative z-10 p-8">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}
        <div className="relative z-10 flex flex-col rounded-sm bg-gray-200 shadow">
          {/* <!-- my orders tab --> */}
          <div className="flex items-center gap-5 border-b px-4 py-4">
            <span style={{ color: '#00c9b7' }}>
              <FolderIcon />
            </span>

            <Link
              className="flex w-full justify-between font-medium text-gray-500 hover:text-primary-blue"
              to="/orders"
            >
              MY ORDERS
              <span style={{ color: '#00c9b7' }}>
                <ChevronRightIcon />
              </span>
            </Link>
          </div>
          {/* <!-- my orders tab --> */}

          {/* <!-- account settings tab --> */}
          <div className="flex items-center gap-5 px-4 py-4">
            <span style={{ color: '#00c9b7' }}>
              <PersonIcon />
            </span>
            <p className="flex w-full justify-between font-medium text-gray-500">
              ACCOUNT SETTINGS
            </p>
          </div>
          <div className="flex flex-col border-b pb-3 text-sm">
            <Link
              to="/account"
              className={`${activeTab === 'profile' ? 'bg-blue-50 font-medium text-primary-blue' : 'hover:bg-blue-50 hover:text-primary-blue'} p-3 pl-14`}
              style={{
                color: '#00c9b7',
                transition: 'color 0.3s ease',
                textDecoration: 'none',
                position: 'relative',
                zIndex: '1',
              }}
            >
              Profile Information
              <span
                className="absolute inset-0 rounded-lg bg-blue-500 opacity-0 transition-opacity duration-300"
                style={{
                  zIndex: '-1',
                  boxShadow: '0px 0px 10px 0px #00c9b7',
                }}
              ></span>
            </Link>
            <Link
              className="p-3 pl-14 hover:bg-blue-50 hover:text-primary-blue"
              to="/"
              style={{
                color: '#00c9b7',
                transition: 'color 0.3s ease',
                textDecoration: 'none',
              }}
            >
              Manage Addresses
            </Link>
            <Link
              className="p-3 pl-14 hover:bg-blue-50 hover:text-primary-blue"
              to="/"
              style={{
                color: '#00c9b7',
                transition: 'color 0.3s ease',
                textDecoration: 'none',
              }}
            >
              PAN Card Information
            </Link>
          </div>

          {/* <!-- account settings tab --> */}

          {/* <!-- payments tab --> */}
          <div className="flex items-center gap-5 px-4 py-4">
            <span style={{ color: '#00c9b7' }}>
              <AccountBalanceWalletIcon />
            </span>
            <p className="flex w-full justify-between font-medium text-gray-500">
              PAYMENTS
            </p>
          </div>
          <div className="flex flex-col border-b pb-3 text-sm">
            <Link
              className="flex justify-between p-3 pl-14 pr-6 hover:bg-blue-50 hover:text-[#00c9b7]"
              to="/"
            >
              Gift Cards{' '}
              <span
                className="rounded-md px-2 py-1 font-medium"
                style={{ backgroundColor: '#FFA500' }}
              >
                Ksh0
              </span>
            </Link>
            <Link
              className="p-3 pl-14 hover:bg-blue-50 hover:text-[#00c9b7]"
              to="/"
            >
              Saved UPI
            </Link>
            <Link
              className="p-3 pl-14 hover:bg-blue-50 hover:text-[#00c9b7]"
              to="/"
            >
              Saved Cards
            </Link>
          </div>
          {/* <!-- payments tab --> */}

          {/* <!-- my chats tab --> */}
          <div className="flex items-center gap-5 border-b px-4 py-4">
            <span style={{ color: '#00c9b7' }}>
              <ChatIcon />
            </span>
            <Link
              className="flex w-full justify-between font-medium text-gray-500 hover:text-[#00c9b7]"
              to="/"
            >
              MY CHATS
              <span>
                <ChevronRightIcon />
              </span>
            </Link>
          </div>
          {/* <!-- my chats tab --> */}

          {/* <!-- my stuff tab --> */}
          <div className="flex items-center gap-5 px-4 py-4">
            <span style={{ color: '#00c9b7' }}>
              <FolderSharedIcon />
            </span>
            <p className="flex w-full justify-between font-medium text-gray-500">
              MY STUFF
            </p>
          </div>
          <div className="flex flex-col border-b pb-3 text-sm">
            <Link
              className="p-3 pl-14 hover:bg-blue-50 hover:text-[#00c9b7]"
              to="/"
            >
              My Coupons
            </Link>
            <Link
              className="p-3 pl-14 hover:bg-blue-50 hover:text-[#00c9b7]"
              to="/"
            >
              My Reviews & Ratings
            </Link>
            <Link
              className="p-3 pl-14 hover:bg-blue-50 hover:text-[#00c9b7]"
              to="/"
            >
              All Notifications
            </Link>
            <Link
              to="/wishlist"
              className={`${activeTab === 'wishlist' ? 'bg-blue-50 font-medium text-primary-blue' : 'hover:bg-blue-50 hover:text-[#00c9b7]'} p-3 pl-14`}
            >
              My Wishlist
            </Link>
          </div>
          {/* <!-- my stuff tab --> */}

          {/* <!-- logout tab --> */}
          <div className="flex items-center gap-5 border-b px-4 py-4">
            <span style={{ color: '#FF0000' }}>
              <PowerSettingsNewIcon />
            </span>

            <div
              className="flex w-full cursor-pointer justify-between font-medium text-gray-500 hover:text-primary-blue"
              onClick={handleLogout}
            >
              Logout
              <span style={{ color: '#00c9b7' }}>
                <ChevronRightIcon />
              </span>
            </div>
          </div>
          {/* <!-- logout tab --> */}
        </div>
      </div>
      {/* <!-- nav tiles --> */}

      {/* <!-- frequenty visited tab --> */}
      <div className=" relative z-10 p-8">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}
        <div className="relative z-10 flex flex-col items-start gap-2 rounded-sm bg-gray-200 p-4 shadow">
          <span className="text-xs font-medium">Frequently Visited:</span>
          <div className="flex gap-2.5 text-xs text-gray-500">
            <Link to="/password/update">Change Password</Link>
            <Link to="/orders">Track Order</Link>
            <Link to="/">Help Center</Link>
          </div>
        </div>
      </div>
      {/* <!-- frequenty visited tab --> */}
    </div>
  )
}

export default Sidebar
