import React from 'react'
import Modal from './termsAndConditionsModal'
import { useState, useEffect } from 'react'

function termsAndConditionsPage() {
  const [showModal, setShowModal] = useState(true)
  const [isAgreed, setIsAgreed] = useState(false)

  const handleAgree = () => {
    setIsAgreed(true)
    setShowModal(false)
  }
  return (
    <div className=" bg-gray-200">
      {!isAgreed && showModal && (
        <Modal onClose={() => setShowModal(false)} onAgree={handleAgree} />
      )}
    </div>
  )
}

export default termsAndConditionsPage
