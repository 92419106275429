// import React from 'react'
// import ReactDOM from 'react-dom'
// import './index.css'
// import './viewDetails.css'
// import App from './App'
// import { BrowserRouter as Router } from 'react-router-dom'
// import { Provider } from 'react-redux'
// import { store, persistor } from './store' // Import named exports
// import { SnackbarProvider } from 'notistack'
// import { PersistGate } from 'redux-persist/integration/react' // Import PersistGate

// const root = ReactDOM.createRoot(document.getElementById('root'))
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         {' '}
//         {/* Wrap your App component with PersistGate */}
//         <SnackbarProvider
//           maxSnack={2}
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'center',
//           }}
//         >
//           <Router>
//             <App />
//           </Router>
//         </SnackbarProvider>
//       </PersistGate>
//     </Provider>
//   </React.StrictMode>
// )

//src/index.js
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './viewDetails.css'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import { SnackbarProvider } from 'notistack'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Router>
          <App />
        </Router>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>
)
