// src/actions/wishlistActions.js
import axios from 'axios'
import {
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
} from '../constants/wishlistConstants'

// Add To Wishlist
export const addToWishlist = (id) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/v1/product/product/${id}`)

  const wishlistItem = {
    product: data.product._id,
    name: data.product.name,
    price: data.product.price,
    cuttedPrice: data.product.cuttedPrice,
    image: data.product.images[0].url,
    ratings: data.product.ratings,
    reviews: data.product.numOfReviews,
  }

  // Dispatch the action to add to wishlist
  dispatch({
    type: ADD_TO_WISHLIST,
    payload: wishlistItem,
  })

  // Save to local storage
  localStorage.setItem(
    'wishlistItems',
    JSON.stringify(getState().wishlist.wishlistItems)
  )

  // Make a POST request to add the item to the wishlist in the database
  await axios.post('/api/v1/wishlist/add', { productId: wishlistItem.product })
}

// Remove From Wishlist
export const removeFromWishlist = (id) => async (dispatch, getState) => {
  // Dispatch action to remove from wishlist locally
  dispatch({
    type: REMOVE_FROM_WISHLIST,
    payload: id,
  })

  // Update local storage after removing item
  localStorage.setItem(
    'wishlistItems',
    JSON.stringify(getState().wishlist.wishlistItems)
  )

  // Make a DELETE request to remove the item from the wishlist in the database
  await axios.delete('/api/v1/wishlist/remove', {
    data: { id },
  })
}
