import React from 'react'

function AuthorArea() {
  return (
    <div className="author-area-two author-area-bg3 pt-100 pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-md-8">
            <div className="section-title">
              <h2>Top Blogs</h2>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="author-btn text-end">
              <a href="authors.html" className="default-btn border-radius-5">
                Explore More
              </a>
            </div>
          </div>
        </div>

        <div className="row pt-45 justify-content-center">
          {[...Array(4)].map((_, index) => (
            <div key={index} className="col-lg-3 col-6">
              <div className="author-card">
                <a href="author-profile.html">
                  <img
                    src={`assets/images/author/author-img${index + 1}.jpg`}
                    alt="Images"
                  />
                </a>
                <div className="content">
                  <div className="content-follow">
                    Follow
                    <ul className="author-social">
                      <li>
                        <a
                          href="https://www.facebook.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="ri-facebook-fill"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="ri-instagram-fill"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="ri-twitter-fill"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="author-user">
                    <img
                      src={`assets/images/author/author-user${index + 1}.jpg`}
                      alt="Images"
                    />
                    <i className="ri-check-line"></i>
                  </div>
                  <h3>
                    <a href="author-profile.html">Author Name</a>
                  </h3>
                  <span>@Username</span>
                  <p>Lorem ipsum dolor sit amet, elit Velit egestas mattis.</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="author-area-shape-two">
        <img src="assets/images/author/author-shape2.png" alt="Images" />
      </div>
    </div>
  )
}

export default AuthorArea
