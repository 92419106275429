const PriceSidebar = ({ cartItems }) => {
  return (
    <div className="sticky top-16 flex flex-col sm:h-screen sm:w-4/12 sm:px-1 ">
      {/* <!-- nav tiles --> */}
      <div className="relative z-10 flex flex-col rounded-sm bg-gray-200">
        <div className=" relative z-10 mx-auto rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <h1 className="relative z-10 border-b px-6 py-3 font-medium text-gray-500">
            PRICE DETAILS
          </h1>

          <div className="relative z-10 flex flex-col gap-4 p-6 pb-3">
            <p className="flex justify-between">
              Price ({cartItems?.length} item){' '}
              <span>
                KSh
                {cartItems
                  ?.reduce(
                    (sum, item) => sum + item.cuttedPrice * item.quantity,
                    0
                  )
                  .toLocaleString()}
              </span>
            </p>
            <p className="flex justify-between">
              Discount{' '}
              <span className="text-primary-green">
                - KSh
                {cartItems
                  ?.reduce(
                    (sum, item) =>
                      sum +
                      (item.cuttedPrice * item.quantity -
                        item.price * item.quantity),
                    0
                  )
                  .toLocaleString()}
              </span>
            </p>
            <p className="flex justify-between">
              Delivery Charges <span className="text-primary-green">FREE</span>
            </p>

            <div className="border border-dashed"></div>
            <p className="flex justify-between text-lg font-medium">
              Total Amount{' '}
              <span>
                KSh
                {cartItems
                  ?.reduce((sum, item) => sum + item.price * item.quantity, 0)
                  .toLocaleString()}
              </span>
            </p>
            <div className="border border-dashed"></div>

            <p className="font-medium text-primary-green">
              You will save KSh
              {cartItems
                ?.reduce(
                  (sum, item) =>
                    sum +
                    (item.cuttedPrice * item.quantity -
                      item.price * item.quantity),
                  0
                )
                .toLocaleString()}{' '}
              on this order
            </p>
          </div>
        </div>
        {/* <!-- nav tiles --> */}
      </div>
    </div>
  )
}

export default PriceSidebar
