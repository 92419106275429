//src/components/Admin/Sidebar/Sidebar.jsx
import { Link, useNavigate } from 'react-router-dom'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import InventoryIcon from '@mui/icons-material/Inventory'
import GroupIcon from '@mui/icons-material/Group'
import ReviewsIcon from '@mui/icons-material/Reviews'
import AddBoxIcon from '@mui/icons-material/AddBox'
import LogoutIcon from '@mui/icons-material/Logout'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import CloseIcon from '@mui/icons-material/Close'
import Avatar from '@mui/material/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import './Sidebar.css'
import { useSnackbar } from 'notistack'
import { logoutUser } from '../../../actions/userAction'

const navMenu = [
  {
    icon: <EqualizerIcon />,
    label: 'Dashboard',
    ref: '/admin/statistics',
  },
  {
    icon: <ShoppingBagIcon />,
    label: 'Orders',
    ref: '/admin/orders',
  },
  {
    icon: <InventoryIcon />,
    label: 'Products',
    ref: '/admin/products',
  },
  {
    icon: <AddBoxIcon />,
    label: 'Add Product',
    ref: '/admin/new_product',
  },
  {
    icon: <GroupIcon />,
    label: 'Users',
    ref: '/admin/users',
  },
  {
    icon: <ReviewsIcon />,
    label: 'Reviews',
    ref: '/admin/reviews',
  },
  {
    icon: <AccountBoxIcon />,
    label: 'My Profile',
    ref: '/account',
  },
  {
    icon: <LogoutIcon />,
    label: 'Logout',
  },
]

const Sidebar = ({ activeTab, setToggleSidebar }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { user } = useSelector((state) => state.user)

  const handleLogout = () => {
    dispatch(logoutUser())
    enqueueSnackbar('Logout Successfully', { variant: 'success' })
    navigate('/login')
  }

  return (
    <aside className="sidebar fixed left-0 z-10 block max-h-screen min-h-screen w-3/4 overflow-x-hidden border-r bg-gray-800 pb-14 text-white sm:z-0 sm:w-1/5">
      <div className="mx-3.5 my-4 flex items-center gap-3 rounded-lg bg-gray-700 p-2 shadow-lg">
        <Avatar alt="Avatar" src={user?.avatar?.url} />
        <div className="flex flex-col gap-0">
          <span className="text-lg font-medium">{user?.name}</span>
          <span className="text-sm text-gray-300">{user?.email}</span>
        </div>
        <button
          onClick={() => setToggleSidebar(false)}
          className="ml-auto flex h-10 w-10 items-center justify-center rounded-full bg-gray-800 sm:hidden"
        >
          <CloseIcon />
        </button>
      </div>

      <div className="my-8 flex w-full flex-col gap-0">
        {navMenu.map((item, index) => {
          const { icon, label, ref } = item
          return (
            <>
              {label === 'Logout' ? (
                <button
                  onClick={handleLogout}
                  className="flex items-center gap-3 px-4 py-3 font-medium hover:bg-gray-700"
                >
                  <span>{icon}</span>
                  <span>{label}</span>
                </button>
              ) : (
                <Link
                  to={ref}
                  className={`${activeTab === index ? 'bg-gray-700' : 'hover:bg-gray-700'} flex items-center gap-3 px-4 py-3 font-medium`}
                >
                  <span>{icon}</span>
                  <span>{label}</span>
                </Link>
              )}
            </>
          )
        })}
      </div>

      <div className="mx-3.5 mb-6 mt-28 flex flex-col gap-1 overflow-hidden rounded-lg bg-gray-700 p-3 shadow-lg">
        <h5>Developed with ❤️ by:</h5>
        <div className="flex flex-col gap-0">
          <a
            href="https://www.linkedin.com/in/brunokiptoo/"
            target="_blank"
            rel="noreferrer"
            className="text-lg font-medium hover:text-blue-500"
          >
            Bruno Zamani
          </a>
          <a
            href="mailto:jigarsable21@gmail.com"
            className="text-sm text-gray-300 hover:text-blue-500"
          >
            brunokiptoo@gzoltraafrika.com
          </a>
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
