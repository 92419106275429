import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import MetaData from '../Layouts/MetaData'
import CartItem from './CartItem'
import EmptyCart from './EmptyCart'
import PriceSidebar from './PriceSidebar'
import SaveForLaterItem from './SaveForLaterItem'

const Cart = () => {
  const navigate = useNavigate()
  const { cartItems } = useSelector((state) => state.cart)
  const { saveForLaterItems } = useSelector((state) => state.saveForLater)

  const placeOrderHandler = () => {
    navigate('/login?redirect=shipping')
  }

  return (
    <>
      <MetaData title="Shopping Cart | Kibanda4Devs" />
      <main className="mt-20 w-full bg-gray-200 py-8">
        {/* <!-- row --> */}
        <div className="m-auto mt-0 flex w-full flex-col gap-3.5 sm:mb-7 sm:mt-4 sm:w-11/12 sm:flex-row">
          {/* <!-- cart column --> */}
          <div className="flex-1">
            {/* <!-- cart items container --> */}
            <div className="relative z-10 flex flex-col bg-gray-200 ">
              {/* Curved outward shadow effect */}
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              {/* End of curved outward shadow effect */}
              <span className="relative z-10 border-b px-2 py-4 text-lg font-medium sm:px-8">
                My Cart ({cartItems.length})
              </span>

              {cartItems && cartItems.length === 0 && <EmptyCart />}

              {cartItems &&
                cartItems.map((item, i) => (
                  <CartItem {...item} inCart={true} key={i} />
                ))}

              {/* <!-- place order btn --> */}
              <div className="relative z-10 flex justify-end">
                <button
                  onClick={placeOrderHandler}
                  disabled={cartItems.length < 1 ? true : false}
                  className={`${cartItems.length < 1 ? 'cursor-not-allowed bg-primary-grey' : 'bg-primary-orange'} mx-2 my-4 w-full rounded-sm py-3 font-medium text-white shadow hover:shadow-lg sm:mx-6 sm:w-1/3`}
                >
                  PLACE ORDER
                </button>
              </div>
              {/* <!-- place order btn --> */}
            </div>

            {/* <!-- cart items container --> */}

            {/* <!-- saved for later items container --> */}
            <div className="relative z-10 mt-5 flex flex-col rounded-lg bg-white shadow">
              {/* Curved outward shadow effect */}
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
              <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
              {/* End of curved outward shadow effect */}

              <span className="relative z-10 border-b px-2 py-4 text-lg font-medium sm:px-8">
                Saved For Later ({saveForLaterItems.length})
              </span>
              {saveForLaterItems &&
                saveForLaterItems.map((item, i) => (
                  <SaveForLaterItem {...item} key={i} />
                ))}
            </div>
            {/* <!-- saved for later container --> */}
          </div>

          {/* <!-- cart column --> */}

          <PriceSidebar cartItems={cartItems} />
        </div>
        {/* <!-- row --> */}
      </main>
    </>
  )
}

export default Cart
