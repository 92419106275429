import React from 'react'

function FeaturedArea() {
  return (
    <div className="featured-area pt-100 pb-70">
      <div className="container">
        <div className="tab featured-tab-area">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-4">
              <div className="section-title">
                <h2>Featured Assets</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-8">
              <ul className="tabs flex space-x-4">
                <li>
                  <a href="/all" className="inline-block">
                    All
                  </a>
                </li>
                <li>
                  <a href="/browse-art" className="inline-block">
                    Art
                  </a>
                </li>
                <li>
                  <a href="/browse-desks" className="inline-block">
                    Desks
                  </a>
                </li>
                <li>
                  <a href="/browse-seats" className="inline-block">
                    Seats
                  </a>
                </li>
                <li>
                  <a href="/browse-lighting" className="inline-block">
                    Lighting
                  </a>
                </li>
                <li>
                  <a href="/browse-electronics" className="inline-block">
                    Electronics
                  </a>
                </li>
                <li>
                  <a href="/browse-merch" className="inline-block">
                    Merch
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab_content current active pt-45">
            <div className="tabs_item current">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img1.jpeg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 122
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html"> Inspiring Artworks</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <a
                            href="/browse-art"
                            className="inline-block rounded bg-pink-900 px-4 py-2 font-bold text-white hover:bg-purple-500"
                          >
                            Browse Page
                          </a>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="mr-2 h-6 w-6 cursor-pointer text-red-500"
                          onClick={() => {
                            // Handle thumbs up click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-6 w-6 cursor-pointer text-green-500"
                          onClick={() => {
                            // Handle thumbs down click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img2.webp"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 192
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Study Desks</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <a
                            href="/browse-desks"
                            className="inline-block rounded bg-pink-900 px-4 py-2 font-bold text-white hover:bg-purple-500"
                          >
                            Browse Page
                          </a>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="mr-2 h-6 w-6 cursor-pointer text-red-500"
                          onClick={() => {
                            // Handle thumbs up click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-6 w-6 cursor-pointer text-green-500"
                          onClick={() => {
                            // Handle thumbs down click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img3.jpeg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 192
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Ergonomic Chairs</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <a
                            href="/browse-seats"
                            className="inline-block rounded bg-pink-900 px-4 py-2 font-bold text-white hover:bg-purple-500"
                          >
                            Browse Page
                          </a>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="mr-2 h-6 w-6 cursor-pointer text-red-500"
                          onClick={() => {
                            // Handle thumbs up click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-6 w-6 cursor-pointer text-green-500"
                          onClick={() => {
                            // Handle thumbs down click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img4.jpeg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 192
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Customized Lighting</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <a
                            href="/browse-lighting"
                            className="inline-block rounded bg-pink-900 px-4 py-2 font-bold text-white hover:bg-purple-500"
                          >
                            Browse Page
                          </a>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="mr-2 h-6 w-6 cursor-pointer text-red-500"
                          onClick={() => {
                            // Handle thumbs up click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-6 w-6 cursor-pointer text-green-500"
                          onClick={() => {
                            // Handle thumbs down click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img5.webp"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 142
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Dev's Electronics</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <a
                            href="/browse-electronics"
                            className="inline-block rounded bg-pink-900 px-4 py-2 font-bold text-white hover:bg-purple-500"
                          >
                            Browse Page
                          </a>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="mr-2 h-6 w-6 cursor-pointer text-red-500"
                          onClick={() => {
                            // Handle thumbs up click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-6 w-6 cursor-pointer text-green-500"
                          onClick={() => {
                            // Handle thumbs down click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img6.jpeg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 172
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">K4D Merchandise</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <a
                            href="/browse-merch"
                            className="inline-block rounded bg-pink-900 px-4 py-2 font-bold text-white hover:bg-purple-500"
                          >
                            Browse Page
                          </a>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="mr-2 h-6 w-6 cursor-pointer text-red-500"
                          onClick={() => {
                            // Handle thumbs up click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-6 w-6 cursor-pointer text-green-500"
                          onClick={() => {
                            // Handle thumbs down click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img7.jpeg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 182
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Developer Books</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <a
                            href="/browse-developer-books"
                            className="inline-block rounded bg-pink-900 px-4 py-2 font-bold text-white hover:bg-purple-500"
                          >
                            Browse Page
                          </a>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="mr-2 h-6 w-6 cursor-pointer text-red-500"
                          onClick={() => {
                            // Handle thumbs up click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-6 w-6 cursor-pointer text-green-500"
                          onClick={() => {
                            // Handle thumbs down click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img9.jpeg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 188
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Hush Pods</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <a
                            href="/browse-hushed-spaces"
                            className="inline-block rounded bg-pink-900 px-4 py-2 font-bold text-white hover:bg-purple-500"
                          >
                            Browse Page
                          </a>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="mr-2 h-6 w-6 cursor-pointer text-red-500"
                          onClick={() => {
                            // Handle thumbs up click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-6 w-6 cursor-pointer text-green-500"
                          onClick={() => {
                            // Handle thumbs down click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img8.jpeg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 182
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Desk Plants</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <a
                            href="/browse-desk-plants"
                            className="inline-block rounded bg-pink-900 px-4 py-2 font-bold text-white hover:bg-purple-500"
                          >
                            Browse Page
                          </a>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="mr-2 h-6 w-6 cursor-pointer text-red-500"
                          onClick={() => {
                            // Handle thumbs up click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-6 w-6 cursor-pointer text-green-500"
                          onClick={() => {
                            // Handle thumbs down click event
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs_item">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img9.jpeg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user5.jpg"
                            alt="Images"
                          />
                          <span>Created by @Daniel</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Walking On Air</a>
                      </h3>
                      <div className="content-in">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="mr-2 h-6 w-6 cursor-pointer text-red-500"
                            onClick={() => {
                              // Handle thumbs up click event
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-6 w-6 cursor-pointer text-green-500"
                            onClick={() => {
                              // Handle thumbs down click event
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user6.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user7.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 112
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img6.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user6.jpg"
                            alt="Images"
                          />
                          <span>Created by @Samuel</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Supper Nuemorphism</a>
                      </h3>
                      <div className="content-in">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="mr-2 h-6 w-6 cursor-pointer text-red-500"
                            onClick={() => {
                              // Handle thumbs up click event
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-6 w-6 cursor-pointer text-green-500"
                            onClick={() => {
                              // Handle thumbs down click event
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user7.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user8.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 162
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img7.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user7.jpg"
                            alt="Images"
                          />
                          <span>Created by @Martina</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Dark-light Angel</a>
                      </h3>
                      <div className="content-in">
                        <span>110 ETH 12/14</span>
                        <h4>Bid 80 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user8.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user1.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 162
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img8.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user8.jpg"
                            alt="Images"
                          />
                          <span>Created by @Henry</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Exe Dream Hight</a>
                      </h3>
                      <div className="content-in">
                        <span>160 ETH 12/14</span>
                        <h4>Bid 90 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user1.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user3.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 182
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img1.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user1.jpg"
                            alt="Images"
                          />
                          <span>Created by @Farnil</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Industrial Revolution</a>
                      </h3>
                      <div className="content-in">
                        <span>100 ETH 12/14</span>
                        <h4>Bid 80 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user1.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user2.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 122
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img2.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user2.jpg"
                            alt="Images"
                          />
                          <span>Created by @Adison</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Love In The Air</a>
                      </h3>
                      <div className="content-in">
                        <span>110 ETH 12/14</span>
                        <h4>Bid 70 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user3.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user2.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 112
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img3.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user3.jpg"
                            alt="Images"
                          />
                          <span>Created by @Jekob</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Become One With Nature</a>
                      </h3>
                      <div className="content-in">
                        <span>120 ETH 12/14</span>
                        <h4>Bid 90 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user4.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user5.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 132
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img4.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user4.jpg"
                            alt="Images"
                          />
                          <span>Created by @Jack</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Twilight Fracture City</a>
                      </h3>
                      <div className="content-in">
                        <span>130 ETH 12/14</span>
                        <h4>Bid 80 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user5.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user6.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 142
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs_item">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img1.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 122
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Industrial Revolution</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>100 ETH 12/14</span>
                          <h4>Bid 80 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user1.jpg"
                          alt="Images"
                        />
                        <span>Created by @Adison</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img2.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 142
                      </p>
                      <div
                        className="featured-card-clock"
                        data-countdown="2022/10/10"
                      ></div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">I Love In The Air</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>110 ETH 12/14</span>
                          <h4>Bid 70 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user2.jpg"
                          alt="Images"
                        />
                        <span>Created by @Maicel</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img3.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 162
                      </p>
                      <div
                        className="featured-card-clock"
                        data-countdown="2022/09/09"
                      ></div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Become One With Nature</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>120 ETH 12/14</span>
                          <h4>Bid 80 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user3.jpg"
                          alt="Images"
                        />
                        <span>Created by @Jekob</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img4.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 192
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Twilight Fracture City</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>110 ETH 12/14</span>
                          <h4>Bid 90 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user4.jpg"
                          alt="Images"
                        />
                        <span>Created by @Jack</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img5.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 142
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Walking On Air</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>130 ETH 12/14</span>
                          <h4>Bid 80 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user5.jpg"
                          alt="Images"
                        />
                        <span>Created by @Daniel</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img6.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 172
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Supper Nuemorphism</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>140 ETH 12/14</span>
                          <h4>Bid 90 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user6.jpg"
                          alt="Images"
                        />
                        <span>Created by @Samuel</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img7.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 182
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Dark-light Angel</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>160 ETH 12/14</span>
                          <h4>Bid 100 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user7.jpg"
                          alt="Images"
                        />
                        <span>Created by @Martina</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img8.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 142
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Exe Dream Hight</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>170 ETH 12/14</span>
                          <h4>Bid 90 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user8.jpg"
                          alt="Images"
                        />
                        <span>Created by @Henry</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs_item">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img5.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user5.jpg"
                            alt="Images"
                          />
                          <span>Created by @Daniel</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Walking On Air</a>
                      </h3>
                      <div className="content-in">
                        <span>110 ETH 12/14</span>
                        <h4>Bid 90 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user6.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user7.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 112
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img6.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user6.jpg"
                            alt="Images"
                          />
                          <span>Created by @Samuel</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Supper Nuemorphism</a>
                      </h3>
                      <div className="content-in">
                        <span>130 ETH 12/14</span>
                        <h4>Bid 70 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user7.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user8.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 162
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img7.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user7.jpg"
                            alt="Images"
                          />
                          <span>Created by @Martina</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Dark-light Angel</a>
                      </h3>
                      <div className="content-in">
                        <span>110 ETH 12/14</span>
                        <h4>Bid 80 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user8.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user1.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 162
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img8.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user8.jpg"
                            alt="Images"
                          />
                          <span>Created by @Henry</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Exe Dream Hight</a>
                      </h3>
                      <div className="content-in">
                        <span>160 ETH 12/14</span>
                        <h4>Bid 90 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user1.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user3.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 182
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img1.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user1.jpg"
                            alt="Images"
                          />
                          <span>Created by @Farnil</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Industrial Revolution</a>
                      </h3>
                      <div className="content-in">
                        <span>100 ETH 12/14</span>
                        <h4>Bid 80 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user1.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user2.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 122
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img2.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user2.jpg"
                            alt="Images"
                          />
                          <span>Created by @Adison</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Love In The Air</a>
                      </h3>
                      <div className="content-in">
                        <span>110 ETH 12/14</span>
                        <h4>Bid 70 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user3.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user2.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 112
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img3.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user3.jpg"
                            alt="Images"
                          />
                          <span>Created by @Jekob</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Become One With Nature</a>
                      </h3>
                      <div className="content-in">
                        <span>120 ETH 12/14</span>
                        <h4>Bid 90 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user4.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user5.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 132
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-item">
                    <div className="featured-item-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img4.jpg"
                          alt="Images"
                        />
                      </a>
                      <div className="featured-user">
                        <a
                          href="author-profile.html"
                          className="featured-user-option"
                        >
                          <img
                            src="assets/images/featured/featured-user4.jpg"
                            alt="Images"
                          />
                          <span>Created by @Jack</span>
                        </a>
                      </div>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="author-profile.html">Twilight Fracture City</a>
                      </h3>
                      <div className="content-in">
                        <span>130 ETH 12/14</span>
                        <h4>Bid 80 ETH </h4>
                      </div>
                      <div className="featured-content-list">
                        <ul>
                          <li>
                            <img
                              src="assets/images/featured/featured-user5.jpg"
                              alt="Images"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/images/featured/featured-user6.jpg"
                              alt="Images"
                            />
                          </li>
                          <li className="title">10+ People Placed Bid</li>
                        </ul>
                        <p>
                          <i className="ri-heart-line"></i> 142
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs_item">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img1.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 122
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Industrial Revolution</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>100 ETH 12/14</span>
                          <h4>Bid 80 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user1.jpg"
                          alt="Images"
                        />
                        <span>Created by @Adison</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img2.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 142
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                      <div
                        className="featured-card-clock"
                        data-countdown="2022/10/10"
                      ></div>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">I Love In The Air</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>110 ETH 12/14</span>
                          <h4>Bid 70 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user2.jpg"
                          alt="Images"
                        />
                        <span>Created by @Maicel</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img3.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 162
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                      <div
                        className="featured-card-clock"
                        data-countdown="2022/09/09"
                      ></div>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Become One With Nature</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>120 ETH 12/14</span>
                          <h4>Bid 80 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user3.jpg"
                          alt="Images"
                        />
                        <span>Created by @Jekob</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img4.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 192
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Twilight Fracture City</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>110 ETH 12/14</span>
                          <h4>Bid 90 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user4.jpg"
                          alt="Images"
                        />
                        <span>Created by @Jack</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img5.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 142
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Walking On Air</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>130 ETH 12/14</span>
                          <h4>Bid 80 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user5.jpg"
                          alt="Images"
                        />
                        <span>Created by @Daniel</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img6.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 172
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Supper Nuemorphism</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>140 ETH 12/14</span>
                          <h4>Bid 90 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user6.jpg"
                          alt="Images"
                        />
                        <span>Created by @Samuel</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img7.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 182
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Dark-light Angel</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>160 ETH 12/14</span>
                          <h4>Bid 100 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user7.jpg"
                          alt="Images"
                        />
                        <span>Created by @Martina</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="featured-card">
                    <div className="featured-card-img">
                      <a href="item-details.html">
                        <img
                          src="assets/images/featured/featured-img8.jpg"
                          alt="Images"
                        />
                      </a>
                      <p>
                        <i className="ri-heart-line"></i> 142
                      </p>
                      <button
                        type="button"
                        className="default-btn border-radius-5"
                      >
                        View Details
                      </button>
                    </div>
                    <div className="content">
                      <h3>
                        <a href="item-details.html">Exe Dream Hight</a>
                      </h3>
                      <div className="content-in">
                        <div className="featured-card-left">
                          <span>170 ETH 12/14</span>
                          <h4>Bid 90 ETH </h4>
                        </div>
                        <a
                          href="item-details.html"
                          className="featured-content-btn"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                      <a
                        href="author-profile.html"
                        className="featured-user-option"
                      >
                        <img
                          src="assets/images/featured/featured-user8.jpg"
                          alt="Images"
                        />
                        <span>Created by @Henry</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedArea
