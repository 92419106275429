import React from 'react'

const PaymentsPage = () => {
  return (
    <div className="payment-page relative flex min-h-screen items-center justify-center bg-gray-100">
      <div className="container relative z-10 mx-auto">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}
        <div className="relative z-10 mx-auto max-w-4xl rounded-lg p-8">
          <h1 className="text-orange-600 relative z-10 mb-6 mt-3 text-center font-sans text-3xl font-bold">
            Payment Options
            <span
              className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            ></span>
          </h1>

          <p className="mb-4 text-lg">
            At Kibanda4Devs, we offer various secure payment options to ensure a
            seamless shopping experience. Choose from the following payment
            methods:
          </p>
          <ul className="mb-4 list-disc pl-6">
            <li className="mb-2">Credit or Debit Card</li>
            <li className="mb-2">PayPal</li>
            <li className="mb-2">Mpesa</li>
            <li className="mb-2">Bank Transfer</li>
            <li className="mb-2">Cash on Delivery (COD)</li>
            {/* Add more payment methods as needed */}
          </ul>
          <p className="text-lg">
            Rest assured, all transactions are encrypted and securely processed
            to protect your financial information.
          </p>
        </div>
      </div>
    </div>
  )
}

export default PaymentsPage
