import axios from 'axios'
import {
  SUBMIT_CONTACT_FORM_REQUEST,
  SUBMIT_CONTACT_FORM_SUCCESS,
  SUBMIT_CONTACT_FORM_FAIL,
  SUBMIT_REGISTRATION_FORM_REQUEST,
  SUBMIT_REGISTRATION_FORM_SUCCESS,
  SUBMIT_REGISTRATION_FORM_FAIL,
  SUBMIT_ADVERT_FORM_REQUEST,
  SUBMIT_ADVERT_FORM_SUCCESS,
  SUBMIT_ADVERT_FORM_FAIL,
  SUBMIT_AFFILIATE_FORM_REQUEST,
  SUBMIT_AFFILIATE_FORM_SUCCESS,
  SUBMIT_AFFILIATE_FORM_FAIL,
  SUBMIT_WHOLESALE_FORM_REQUEST,
  SUBMIT_WHOLESALE_FORM_SUCCESS,
  SUBMIT_WHOLESALE_FORM_FAIL,
} from '../constants/formsConstants'

export const submitContactForm = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SUBMIT_CONTACT_FORM_REQUEST })
    const { data } = await axios.post('/api/v1/auth/contact_us', formData)
    dispatch({ type: SUBMIT_CONTACT_FORM_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: SUBMIT_CONTACT_FORM_FAIL, payload: error.message })
  }
}

export const submitRegistrationForm = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SUBMIT_REGISTRATION_FORM_REQUEST })
    const { data } = await axios.post(
      '/api/v1/auth/business_registration',
      formData
    )
    dispatch({ type: SUBMIT_REGISTRATION_FORM_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: SUBMIT_REGISTRATION_FORM_FAIL, payload: error.message })
  }
}

export const submitAdvertForm = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SUBMIT_ADVERT_FORM_REQUEST })
    const { data } = await axios.post('/api/v1/auth/advertise_form', formData)
    dispatch({ type: SUBMIT_ADVERT_FORM_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: SUBMIT_ADVERT_FORM_FAIL, payload: error.message })
  }
}

export const submitAffiliateForm = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SUBMIT_AFFILIATE_FORM_REQUEST })
    const { data } = await axios.post(
      '/api/v1/auth/affiliate_registration_form',
      formData
    )
    dispatch({ type: SUBMIT_AFFILIATE_FORM_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: SUBMIT_AFFILIATE_FORM_FAIL, payload: error.message })
  }
}

export const submitWholesaleForm = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SUBMIT_WHOLESALE_FORM_REQUEST })
    const { data } = await axios.post('/api/v1/auth/wholesale_form', formData)
    dispatch({ type: SUBMIT_WHOLESALE_FORM_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: SUBMIT_WHOLESALE_FORM_FAIL, payload: error.message })
  }
}
