import React from 'react'
import { useState, useEffect } from 'react'
import PrivacyModal from './privacyModal'

function PrivacyPage() {
  const [showModal, setShowModal] = useState(true)
  const [isAgreed, setIsAgreed] = useState(false)

  const handleAgree = () => {
    setIsAgreed(true)
    setShowModal(false)
  }
  return (
    <div className=" bg-gray-200">
      {!isAgreed && showModal && (
        <PrivacyModal
          onClose={() => setShowModal(false)}
          onAgree={handleAgree}
        />
      )}
    </div>
  )
}

export default PrivacyPage
