import NotificationsIcon from '@mui/icons-material/Notifications'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import DownloadIcon from '@mui/icons-material/Download'

const SecondaryDropDownMenu = () => {
  const navs = [
    {
      title: 'Notification Preferences',
      icon: <NotificationsIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />,
      redirect: 'https://www.Kibanda4Devs.com/communication-preferences/push',
    },
    {
      title: 'Sell on Kibanda4Devs',
      icon: <BusinessCenterIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />,
      redirect: 'https://seller.Kibanda4Devs.com/sell-online',
    },
    {
      title: '24x7 Customer Care',
      icon: <LiveHelpIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />,
      redirect: 'https://www.Kibanda4Devs.com/helpcentre',
    },
    {
      title: 'Advertise',
      icon: <TrendingUpIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />,
      redirect: 'https://advertising.Kibanda4Devs.com',
    },
    // {
    //   title: 'Download App',
    //   icon: <DownloadIcon sx={{ fontSize: '18px', color: '#00B6D0' }} />,
    //   redirect: 'https://www.Kibanda4Devs.com/mobile-apps',
    // },
  ]

  return (
    <div className="absolute -right-2 top-9 w-60 flex-col rounded bg-white text-sm shadow-2xl">
      <div className=" relative z-10 mx-auto rounded-lg p-4">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}
        {navs.map((item, i) => {
          const { title, icon, redirect } = item

          return (
            <a
              className="relative z-10 flex items-center gap-3 rounded-t border-b py-3.5 pl-3 hover:bg-gray-50"
              href={redirect}
              key={i}
            >
              <span className="text-[#00B6D0]">{icon}</span>
              {title}
            </a>
          )
        })}

        <div className="absolute -top-2.5 right-1/2 z-10">
          <div className="arrow_down"></div>
        </div>
      </div>
    </div>
  )
}

export default SecondaryDropDownMenu
