import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { getRandomProducts } from '../../../utils/functions'
import { settings } from '../DealSlider/DealSlider'
import Product from './Product'

const ProductSlider = ({ title, tagline }) => {
  const { loading, products } = useSelector((state) => state.products)

  return (
    <section className="w-full overflow-hidden bg-[#F4F4F4] shadow">
      {/* <!-- header --> */}
      <div className="flex items-center justify-between px-6 py-4 sm:px-6">
        <div className="title flex flex-col gap-0.5">
          <h1 className="text-lg font-medium sm:text-xl">{title}</h1>
          <p className="text-sm text-gray-400">{tagline}</p>
        </div>
        <Link
          to="/products"
          className="rounded-sm bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 text-xs font-medium text-white shadow-lg sm:px-5 sm:py-2.5"
        >
          VIEW ALL
        </Link>
      </div>
      <hr />
      {loading ? null : (
        <Slider {...settings} className="flex items-center justify-between p-1">
          {products &&
            getRandomProducts(products, 12).map((product) => (
              <Product {...product} key={product._id} />
            ))}
        </Slider>
      )}
    </section>
  )
}

export default ProductSlider
