import { Link } from 'react-router-dom'

const EmptyCart = () => {
  return (
    <div className="relative z-10 m-6 flex flex-col items-center gap-2">
      <div className="h-44 w-52">
        <img
          draggable="false"
          className="h-full w-full object-contain "
          src="https://rukminim1.flixcart.com/www/800/800/promos/16/05/2019/d438a32e-765a-4d8b-b4a6-520b560971e8.png"
          alt="Empty Cart"
        />
      </div>
      <span className="text-lg">Your cart is empty!</span>
      <p className="text-xs">Add items to it now.</p>
      <Link
        to="/products"
        className="mt-3 rounded-sm bg-primary-blue px-12 py-2 text-sm text-white shadow"
      >
        Shop Now
      </Link>
    </div>
  )
}

export default EmptyCart
