import React, { useState } from 'react'

const FAQPage = () => {
  const [isOpen, setIsOpen] = useState([])

  const handleClick = (index) => {
    setIsOpen((prevState) => {
      const newState = [...prevState]
      newState[index] = !prevState[index]
      return newState
    })
  }

  return (
    <div className="faq-page relative flex min-h-screen items-center justify-center bg-gray-100">
      <div className="container relative z-10 mx-auto">
        <h1 className="text-orange-600 relative z-10 mb-6 mt-3 text-center font-sans text-3xl font-bold">
          Frequently Asked Questions (FAQ)
          <span
            className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
            style={{ backgroundColor: '#DB7A03' }}
          ></span>
        </h1>
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}

        <div className="relative z-10 mx-auto max-w-4xl overflow-hidden rounded-lg p-8">
          {FAQ_DATA.map((faq, index) => (
            <div key={index} className="mb-6">
              <div className="from-orange-600 via-orange-400 mb-2 border-b-2 bg-gradient-to-r to-transparent"></div>{' '}
              {/* Gradient line separating questions */}
              <div>
                <h2
                  className={`text-orange-600 mb-2 cursor-pointer text-lg font-semibold`}
                  onClick={() => handleClick(index)}
                  style={{ color: '#E88C09' }}
                >
                  {faq.question}
                </h2>
                <div
                  className={`transition-height overflow-hidden duration-500 ease-in-out ${isOpen[index] ? 'h-auto' : 'h-0'}`}
                >
                  <p className="leading-loose text-gray-800">
                    {faq.answer.includes('info@kibanda4devs.com') ? (
                      <>
                        {faq.answer.split('info@kibanda4devs.com')[0]}
                        <span style={{ color: '#E88C09' }}>
                          info@kibanda4devs.com
                        </span>
                        {faq.answer.split('info@kibanda4devs.com')[1]}
                      </>
                    ) : (
                      faq.answer
                    )}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const FAQ_DATA = [
  {
    question: 'Question 1: Why Choose Kibanda4Devs?',
    answer:
      'We are a growing startup that focuses on creating a seamless and personalized shopping experience to enhance your developer setup.',
  },
  {
    question:
      'Question 2: Where can I find more information about your products?',
    answer:
      'You can find detailed information about our products on the product pages of our website and our social media pages. If you have any specific questions, feel free to contact our customer support team at info@kibanda4devs.com.',
  },
  {
    question: 'Question 3: How can I track my order?',
    answer:
      'After your order has shipped, you can track its status in your profile by selecting your order on our website.',
  },
]

export default FAQPage
