import { useSelector } from 'react-redux'
import CartItem from './CartItem'
import PriceSidebar from './PriceSidebar'
import Stepper from './Stepper'
import { useNavigate } from 'react-router-dom'
import MetaData from '../Layouts/MetaData'
import OrderStatus from './OrderStatus'

const OrderConfirm = () => {
  const navigate = useNavigate()
  const { cartItems } = useSelector((state) => state.cart)
  const { user } = useSelector((state) => state.user)

  return (
    <>
      <MetaData title="Kibanda4Devs: Order Confirmation" />

      <main className="mt-20 w-full bg-gray-200 py-8 ">
        {/* <!-- row --> */}
        <div className="m-auto mt-0 flex w-full flex-col gap-3.5 sm:mb-7 sm:mt-4 sm:w-11/12 sm:flex-row ">
          {/* <!-- cart column --> */}
          <div className="flex-1">
            <Stepper activeStep={2}>
              {/* <OrderStatus/> */}
              <div className="w-full">
                {cartItems?.map((item, i) => (
                  <CartItem {...item} inCart={false} key={i} />
                ))}
              </div>
              <div className="relative z-10 mt-4 flex items-center justify-between  rounded-b-sm bg-white px-6 py-3">
                {/* Curved outward shadow effect */}
                <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                {/* End of curved outward shadow effect */}
                <div className=" relative z-10 rounded-lg p-4">
                  {/* Curved outward shadow effect */}
                  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
                  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
                  <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
                  {/* End of curved outward shadow effect */}
                  <p className="relative z-10 text-sm">
                    Order confirmation email will be sent to{' '}
                    <span
                      className="relative z-10 rounded-md px-2 py-2 font-medium"
                      style={{ backgroundColor: '#FFA500' }}
                    >
                      {user.email}
                    </span>
                  </p>
                </div>
                <button
                  onClick={() => {
                    navigate('/process/payment')
                  }}
                  className="relative z-10 rounded-sm bg-primary-orange px-6 py-2 font-medium uppercase text-white shadow hover:shadow-lg"
                >
                  continue
                </button>
              </div>
            </Stepper>
          </div>

          <PriceSidebar cartItems={cartItems} />
        </div>
      </main>
    </>
  )
}

export default OrderConfirm
