// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  width: 47px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  background: #fcfcfc;
  color: #000;
  z-index: 1;
}
.slick-prev {
  left: 0;
  border-radius: 0 5px 5px 0;
}
.slick-next {
  right: 0;
  border-radius: 5px 0 0 5px;
}
.slick-prev::before,
.slick-next::before {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/Banner/Banner.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yCAAyC;EACzC,mBAAmB;EACnB,WAAW;EACX,UAAU;AACZ;AACA;EACE,OAAO;EACP,0BAA0B;AAC5B;AACA;EACE,QAAQ;EACR,0BAA0B;AAC5B;AACA;;EAEE,aAAa;AACf","sourcesContent":[".slick-arrow.slick-prev,\n.slick-arrow.slick-next {\n  width: 47px;\n  height: 104px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);\n  background: #fcfcfc;\n  color: #000;\n  z-index: 1;\n}\n.slick-prev {\n  left: 0;\n  border-radius: 0 5px 5px 0;\n}\n.slick-next {\n  right: 0;\n  border-radius: 5px 0 0 5px;\n}\n.slick-prev::before,\n.slick-next::before {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
