import React from 'react'

const ShippingPage = () => {
  const whatsappNumber = '0113116933'

  const handleWhatsAppClick = () => {
    window.open(
      `https://wa.me/${whatsappNumber}?text=Hello%20from%20your%20website!`,
      '_blank'
    )
  }

  return (
    <div className="shipping-page relative flex min-h-screen items-center justify-center bg-gray-100">
      <div className="container relative z-10 mx-auto">
        {/* Curved outward shadow effect */}
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
        {/* End of curved outward shadow effect */}

        <div className="relative z-10 mx-auto max-w-4xl rounded-lg p-8">
          <h1 className="text-orange-600 relative z-10 mb-6 mt-3 text-center font-sans text-3xl font-bold">
            Shipping Information
            <span
              className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            ></span>
          </h1>
          <div className="relative z-10 mx-auto max-w-4xl rounded-lg p-8">
            <p className="mb-6 text-lg text-gray-800">
              We're committed to delivering your orders with care and
              efficiency. Here's what you need to know about our shipping
              process:
            </p>
            <ul className="mb-6 list-disc pl-6 text-gray-800">
              <li className="mb-2">
                Orders are typically shipped within 1-3 business days of
                purchase.
              </li>
              <li className="mb-2">
                Shipping costs are calculated based on destination and package
                weight.
              </li>
              <li className="mb-2">
                You'll receive a unique tracking code to monitor your shipment's
                progress.
              </li>
              <li className="mb-2">
                We offer both standard and expedited shipping options to suit
                your needs.
              </li>
              {/* Add more shipping details as needed */}
            </ul>
            <p className="text-lg text-gray-800">
              To ensure smooth delivery, please provide accurate shipping
              information at checkout.
            </p>
            <div className="mt-8 flex items-center justify-center">
              <p className="mr-2 text-lg text-gray-800">
                Need assistance with your shipment?
              </p>
              <button
                onClick={handleWhatsAppClick}
                className="ml-2 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-2 py-2 font-bold text-white hover:bg-blue-700"
              >
                Contact Us on WhatsApp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShippingPage
