//src/components/Layouts/Footer/businessRegistrationForm.jsx
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { submitRegistrationForm } from '../../../actions/formsAction'

const BusinessRegistrationForm = () => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    businessName: '',
    email: '',
    contactNumber: '',
    businessType: '',
    address: '',
    yearsInOperation: '',
    website: '',
    description: '',
    message: '',
  })

  const [submittedBusinessName, setSubmittedBusinessName] = useState('')
  const { loading, success, error } = useSelector(
    (state) => state.businessregistrationForm
  )

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmittedBusinessName(formData.businessName)
    dispatch(submitRegistrationForm(formData))
  }

  useEffect(() => {
    if (success) {
      setFormData({
        businessName: '',
        email: '',
        contactNumber: '',
        businessType: '',
        address: '',
        yearsInOperation: '',
        website: '',
        description: '',
        message: '',
      })
    }
  }, [success])

  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-200">
      <div className="container relative z-10 mx-auto mb-8 mt-8">
        <div className="relative z-10 mx-auto rounded-lg p-8">
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <h1
            className="relative z-10 mb-2 text-center font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            Business Registration
            <span
              className="absolute relative left-1/2 z-10 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            ></span>
          </h1>
          <form className="relative z-10" onSubmit={handleSubmit}>
            <div className="relative z-10 mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="businessName"
              >
                Business Name
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="businessName"
                type="text"
                placeholder="Business Name"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="email"
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="contactNumber"
              >
                Contact Number
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="contactNumber"
                type="text"
                placeholder="Contact Number"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="address"
              >
                Business Address
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="address"
                type="text"
                placeholder="Business Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="businessType"
              >
                Business Type
              </label>
              <select
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="businessType"
                name="businessType"
                value={formData.businessType}
                onChange={handleChange}
                required
              >
                <option value="">Select Business Type</option>
                <option value="Art">Art</option>
                <option value="Desks">Desks</option>
                <option value="Seats">Seats</option>
                <option value="Lighting">Lighting</option>
                <option value="Electronics">Electronics</option>
                <option value="Merchandise">Merchandise</option>
                <option value="Developer Books">Developer Books</option>
                <option value="Desk Plants">Desk Plants</option>
                <option value="Offer Zone">Offer Zone</option>
                <option value="Hush Meets">Hush Meets</option>
              </select>
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="yearsInOperation"
              >
                Years in Operation
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="yearsInOperation"
                type="number"
                placeholder="Years in Operation"
                name="yearsInOperation"
                value={formData.yearsInOperation}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="website"
              >
                Website URL
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="website"
                type="url"
                placeholder="Website URL (optional)"
                name="website"
                value={formData.website}
                onChange={handleChange}
              />
            </div>
            <div className="mb-6">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="description"
              >
                Business Description
              </label>
              <textarea
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="description"
                rows="5"
                placeholder="Describe your business..."
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="mb-6">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="message"
                rows="5"
                placeholder="Your message..."
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="flex items-center justify-center">
              <button
                className="focus:shadow-outline rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
            {success && (
              <div className="alert alert-success mt-4">
                Thank you, {submittedBusinessName}! Your registration has been
                successfully received. Our team will be in touch with you
                shortly to assist you further.
              </div>
            )}
            {error && (
              <div className="alert alert-danger mt-4">
                An error occurred. Please try again later.
              </div>
            )}
            {''}
          </form>
        </div>
      </div>
    </div>
  )
}

export default BusinessRegistrationForm
