import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CircleIcon from '@mui/icons-material/Circle'
import { Link } from 'react-router-dom'
import { formatDate } from '../../utils/functions'

const OrderItem = (props) => {
  const {
    orderId,
    name,
    image,
    price,
    quantity,
    createdAt,
    deliveredAt,
    orderStatus,
  } = props

  return (
    <div className=" relative z-10">
      {/* Curved outward shadow effect */}
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
      <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
      {/* End of curved outward shadow effect */}
      <Link
        to={`/order_details/${orderId}`}
        className="relative z-10 flex items-start gap-2  rounded border p-4 hover:shadow-lg sm:gap-0"
      >
        {/* <!-- image container --> */}
        <div className="h-20 w-full sm:w-32">
          <img
            draggable="false"
            className="h-full w-full object-contain"
            src={image}
            alt={name}
          />
        </div>
        {/* <!-- image container --> */}

        {/* <!-- order desc container --> */}
        <div className="flex w-full flex-col justify-between sm:flex-row">
          <div className="flex flex-col gap-1 overflow-hidden">
            <p className="text-sm">
              {name.length > 40 ? `${name.substring(0, 40)}...` : name}
            </p>
            <p className="mt-2 text-xs text-gray-500">Quantity: {quantity}</p>
            <p
              className="rounded-md px-2 py-1 text-xs"
              style={{ backgroundColor: '#FFA500' }}
            >
              Total: Ksh{(quantity * price).toLocaleString()}
            </p>
          </div>

          <div className="mt-1 flex flex-col gap-2 sm:mt-0 sm:w-1/2 sm:flex-row sm:gap-20">
            <p className="text-sm">Ksh{price.toLocaleString()}</p>

            <div className="flex flex-col gap-1.5">
              <p className="flex items-center gap-1 text-sm font-medium">
                {orderStatus === 'Shipped' ? (
                  <>
                    <span className="pb-0.5 text-primary-orange">
                      <CircleIcon sx={{ fontSize: '14px' }} />
                    </span>
                    Shipped
                  </>
                ) : orderStatus === 'Delivered' ? (
                  <>
                    <span className="pb-0.5 text-primary-green">
                      <CircleIcon sx={{ fontSize: '14px' }} />
                    </span>
                    Delivered on {formatDate(deliveredAt)}
                  </>
                ) : (
                  <>
                    <span className="pb-0.5 text-primary-green">
                      <RadioButtonUncheckedIcon sx={{ fontSize: '14px' }} />
                    </span>
                    Ordered on {formatDate(createdAt)}
                  </>
                )}
              </p>
              {orderStatus === 'Delivered' ? (
                <p className="ml-1 text-xs">Your item has been {orderStatus}</p>
              ) : orderStatus === 'Shipped' ? (
                <p className="ml-1 text-xs">Your item has been {orderStatus}</p>
              ) : (
                <p className="ml-1 text-xs">Seller has processed your order</p>
              )}
            </div>
          </div>
        </div>
        {/* <!-- order desc container --> */}
      </Link>
    </div>
  )
}

export default OrderItem
