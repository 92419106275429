import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import MissionImage from '../../../assets/images/workenv2.jpeg'

const CareersPage = () => {
  // Sample job data
  const jobPositions = [
    {
      title: 'Software Engineer',
      location: 'Full-Time - Nairobi, Kenya',
      slug: 'software-engineer',
    },
    {
      title: 'Frontend Developer',
      location: 'Full-Time - Remote',
      slug: 'frontend-developer',
    },
    // Add more job positions as needed
  ]

  // Function to encode job title for URL
  const encodeJobTitle = (title) => title.replace(/\s+/g, '-')

  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-200">
      <div className="container relative z-10 mx-auto">
        {/* Header Section */}
        <div className="mb-12 mt-4">
          <h1
            className="relative mx-auto mb-4 text-center font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            Join Our Team
            <span
              className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            />
          </h1>
        </div>

        <div className=" relative z-10 mx-auto rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}

          {/* Why Choose Us Section */}
          <section className="relative z-10 mb-12 flex flex-col items-center md:flex-row">
            <div className="relative z-10 md:w-1/2 md:pr-8">
              <h1
                className="font-semi-bold mb-4 font-sans text-2xl"
                style={{ color: '#DB7A03' }}
              >
                Why Choose Kibanda4Devs?
              </h1>
              <p className="mb-4 font-sans text-gray-600">
                At Kibanda4Devs, we foster a culture of collaboration and
                innovation. We believe in continuous learning and professional
                growth, offering ample opportunities for career advancement.
                Join us and be a part of a dynamic startup environment where
                your contributions truly matter.
              </p>
              <ul className="mt-4 list-disc pl-4">
                <li className="mb-2 font-sans text-gray-600">
                  Collaborative and Innovative Environment
                </li>
                <li className="mb-2 font-sans text-gray-600">
                  Opportunities for Professional Growth
                </li>
                <li className="mb-2 font-sans text-gray-600">
                  Competitive Benefits Package
                </li>
              </ul>
            </div>
            <div className="md:w-1/2">
              {/* Replace with an image showcasing your work environment */}

              <img
                src={MissionImage}
                alt="Work Environment Image"
                className="rounded-lg shadow-md"
              />
            </div>
          </section>
        </div>

        {/* List of Open Positions */}
        <div className="mb-12 mt-4">
          <h1
            className="relative mx-auto mb-4 text-center font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            Open Positions
            <span
              className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            />
          </h1>
        </div>

        <div className=" relative z-10 mx-auto rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          {/* List of Open Positions */}
          <section>
            <ul className="relative z-10 space-y-8">
              {jobPositions.map((position, index) => (
                <li
                  key={index}
                  className="flex justify-between border-b border-gray-200 pb-4"
                >
                  <div>
                    <h3 className="mb-2 font-sans text-xl font-semibold">
                      {position.title}
                    </h3>
                    <p className="font-sans text-sm text-gray-600">
                      {position.location}
                    </p>
                  </div>
                  <Link
                    to={`/careers/apply/${encodeJobTitle(position.title)}`}
                    className="flex items-center font-sans text-gray-600 hover:text-gray-300"
                  >
                    Learn More
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                  </Link>
                </li>
              ))}
            </ul>
          </section>
        </div>

        <div className=" relative z-10 mx-auto mb-6 mt-12 rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          {/* How to Apply Section */}
          <section className="relative z-10 mt-6 text-center">
            <h2 className="mb-4 font-sans text-2xl font-bold ">
              Ready to Join Us?
            </h2>
            <p className="mb-4 font-sans text-gray-600">
              We are always looking for talented individuals to join our team.
              If you are interested in applying for a position, please submit
              your resume and cover letter following the instructions.
            </p>
            <p className="mb-4 font-sans text-gray-600">
              To apply, please send your resume and cover letter to
              careers@kibanda4devs.com. In the subject line of your email,
              please specify the position you are applying for.
            </p>
          </section>
        </div>
      </div>
    </div>
  )
}

export default CareersPage
