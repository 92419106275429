import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { submitAdvertForm } from '../../../actions/formsAction'

function AdvertRegistrationForm() {
  const [formData, setFormData] = useState({
    businessName: '',
    email: '',
    contactNumber: '',
    businessType: '',
    advertisementType: '',
    advertisementDuration: '',
    advertisementDetails: '',
    message: '',
  })

  const [submittedBusinessName, setSubmittedBusinessName] = useState('')

  const dispatch = useDispatch()
  const { loading, success, error } = useSelector(
    (state) => state.advertRegistrationForm
  )

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmittedBusinessName(formData.businessName)
    dispatch(submitAdvertForm(formData))
  }

  useEffect(() => {
    if (success) {
      setFormData({
        businessName: '',
        email: '',
        contactNumber: '',
        businessType: '',
        advertisementType: '',
        advertisementDuration: '',
        advertisementDetails: '',
        message: '',
      })
    }
  }, [success])

  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-200">
      <div className="container relative z-10 mx-auto mb-8 mt-8">
        <div className="relative z-10 mx-auto rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <h1
            className="relative z-10 mb-2 text-center font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            Advertise with Us
            <span
              className="absolute relative left-1/2 z-10 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            ></span>
          </h1>
          <form className="relative z-10" onSubmit={handleSubmit}>
            <div className="relative z-10 mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="businessName"
              >
                Business Name
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="businessName"
                type="text"
                placeholder="Business Name"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="email"
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="contactNumber"
              >
                Contact Number
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="contactNumber"
                type="text"
                placeholder="Contact Number"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="businessType"
              >
                Business Type
              </label>
              <select
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="businessType"
                name="businessType"
                value={formData.businessType}
                onChange={handleChange}
                required
              >
                <option value="">Select Business Type</option>
                <option value="Art">Art</option>
                <option value="Desks">Desks</option>
                <option value="Seats">Seats</option>
                <option value="Lighting">Lighting</option>
                <option value="Electronics">Electronics</option>
                <option value="Merchandise">Merchandise</option>
                <option value="Developer Books">Developer Books</option>
                <option value="Desk Plants">Desk Plants</option>
                <option value="Offer Zone">Offer Zone</option>
                <option value="Hush Meets">Hush Meets</option>
              </select>
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="advertisementType"
              >
                Advertisement Type
              </label>
              <select
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="advertisementType"
                name="advertisementType"
                value={formData.advertisementType}
                onChange={handleChange}
                required
              >
                <option value="">Select Advertisement Type</option>
                <option value="Banner">Banner</option>
                <option value="Pop-up">Pop-up</option>
                <option value="Sponsored Content">Sponsored Content</option>
              </select>
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="advertisementDuration"
              >
                Advertisement Duration (in days)
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="advertisementDuration"
                type="number"
                placeholder="Advertisement Duration"
                name="advertisementDuration"
                value={formData.advertisementDuration}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-6">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="advertisementDetails"
              >
                Advertisement Details
              </label>
              <textarea
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="advertisementDetails"
                rows="5"
                placeholder="Enter advertisement details..."
                name="advertisementDetails"
                value={formData.advertisementDetails}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="mb-6">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="message"
                rows="5"
                placeholder="Your message..."
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="flex items-center justify-center">
              <button
                className="focus:shadow-outline rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
                type="submit"
              >
                Submit
              </button>
            </div>
            {success && (
              <div className="alert alert-success mt-4">
                Thank you, {submittedBusinessName}! Your advertisement request
                has been successfully received. Our team will be in touch with
                you shortly to provide further assistance.
              </div>
            )}

            {error && (
              <div className="alert alert-danger mt-4">
                There was an error submitting your request. Please try again
                later.
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default AdvertRegistrationForm
