import React from 'react'
import AffiliateImage from '../../../assets/images/affiliate6.png'

function Affiliate() {
  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-200 py-8">
      <div className="relative z-10 mx-auto max-w-4xl px-6">
        <h1 className="relative mx-auto mb-8 text-center text-4xl font-bold">
          Join Our Affiliate Program
          <span
            className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
            style={{ backgroundColor: '#DB7A03' }}
          />
        </h1>
        <div className="relative z-10 mx-auto mb-12 rounded-lg p-8 text-center  ">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <div className="relative z-10 mb-12 flex flex-col items-center md:flex-row">
            <div className="md:w-1/2">
              <img
                src={AffiliateImage}
                alt="Affiliate"
                className="rounded-lg"
              />
            </div>
            <div className="md:w-1/2 md:pl-8">
              <p className="mb-4 text-lg text-gray-700">
                Earn money by promoting products from Kibanda4devs as an
                affiliate! Here's how to get started:
              </p>
              <ol className="mb-4 list-decimal pl-6 text-lg text-gray-700">
                <li className="mb-2">Sign up for an affiliate account.</li>
                <li className="mb-2">Receive your unique affiliate link.</li>
                <li className="mb-2">
                  Promote products using your affiliate link.
                </li>
                <li className="mb-2">
                  Earn commissions on sales generated through your link!
                </li>
              </ol>
              <a
                href="/affiliate-registration-form"
                className="ml-8 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Affiliate
