import React from 'react'
import { Link } from 'react-router-dom'
import MissionImage from '../../../assets/images/mission.avif'
import CreditCard from '../../../assets/images/credittt.jpeg'
import Mpesa from '../../../assets/images/mpesalogo.png'
import SellOn from '../../../assets/images/sellon.jpeg'
import Affiliate from '../../../assets/images/affiliate2.jpeg'
import Advertise from '../../../assets/images/advertise.jpeg'

function BusinessServicePage() {
  return (
    <div className="cancellation-returns-page relative flex min-h-screen items-center justify-center bg-gray-200 py-8">
      <div className="container relative z-10 mx-auto">
        {/* Header Section */}
        <div className="mb-12 text-center">
          <h1
            className="relative mx-auto mb-4 text-center font-sans text-3xl font-bold"
            style={{ marginBottom: '20px' }}
          >
            Simplify Your Business Solutions with Us
            <span
              className="absolute left-1/2 mt-2 block h-1 w-20 -translate-x-1/2 transform rounded-md"
              style={{ backgroundColor: '#DB7A03' }}
            />
          </h1>
        </div>

        <div className=" relative z-10 mx-auto mb-8 rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}

          {/* Consumer Services Section */}
          <section className="relative  z-10 mb-12 flex  flex-col items-center md:flex-row">
            <div className="md:w-1/2 md:pr-4">
              {' '}
              {/* Reduced right padding */}
              <h2
                className="mb-4 ml-8 text-2xl font-semibold"
                style={{ color: '#DB7A03' }}
              >
                Sell Your Products on Kibanda4devs
              </h2>
              <p className="mb-4 ml-8 text-gray-600">
                Are you a business looking to expand your reach and sell your
                products online? Join Kibanda4devs today and get the chance to
                show your products to millions of customers all around the
                globe.
              </p>
              {/* Start Selling Button */}
              <Link
                to="/about-us/business-services/sell"
                className="ml-8 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
              >
                Start Selling
              </Link>
            </div>
            <div className="ml-4 md:w-1/2">
              {' '}
              {/* Reduced left margin */}
              <img
                src={SellOn}
                alt="Welcome Image"
                className="rounded-lg shadow-md"
              />
            </div>
          </section>
        </div>

        {/* Advertising Section */}
        <div className=" relative z-10 mx-auto mb-8 rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}

          <section className="relative z-10 mb-12 flex flex-col items-center md:flex-row">
            <div className="ml-4 md:w-1/2">
              {' '}
              {/* Reduced left margin */}
              <img
                src={Advertise}
                alt="Advertise Your Business"
                className="rounded-lg shadow-md"
              />
            </div>
            <div className="md:w-1/2 md:pl-4">
              {' '}
              {/* Reduced left padding */}
              <h2
                className="mb-4 ml-8 text-2xl font-semibold"
                style={{ color: '#DB7A03' }}
              >
                Advertise Your Business on Kibanda4devs
              </h2>
              <p className="mb-4 ml-8 text-gray-600">
                Looking to increase your brand visibility and reach a wider
                audience? Kibanda4devs offers advertising opportunities to
                showcase your products and services to our diverse user base.
                With our platform, you can connect with potential customers,
                enhance your brand recognition, and drive more traffic to your
                business. Join us today and take advantage of our targeted
                advertising solutions to achieve your marketing goals.
              </p>
              {/* Learn More Button */}
              <Link
                to="/about-us/business-services/advertise"
                className="ml-8 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
              >
                Learn More
              </Link>
            </div>
          </section>
        </div>
        {/* Affiliate Section */}

        {/* Advertising Section */}
        <div className=" relative z-10 mx-auto mb-8 rounded-lg p-8">
          {/* Curved outward shadow effect */}
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-gray-200 via-white to-transparent"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-transparent via-gray-200 to-white"></div>
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-br from-white via-gray-200 to-transparent"></div>
          {/* End of curved outward shadow effect */}
          <section className="relative z-10 mb-12 flex flex-col items-center md:flex-row">
            <div className="md:w-1/2 md:pr-4">
              {' '}
              {/* Reduced right padding */}
              <h2
                className="mb-4 ml-8 text-2xl font-semibold"
                style={{ color: '#DB7A03' }}
              >
                Become an Affiliate or Influencer for Kibanda4devs
              </h2>
              <p className="mb-4 ml-8 text-gray-600">
                Are you passionate about promoting developer products and
                earning commissions? Join our affiliate program and become a
                part of our network of influencers! As an affiliate, you'll have
                the opportunity to share cutting-edge developer tools and
                services with your audience while earning competitive
                commissions. Sign up today and start monetizing your influence
                in the developer community.
              </p>
              {/* Learn More Button */}
              <Link
                to="/about-us/business-services/affiliate"
                className="ml-8 rounded bg-gradient-to-l from-yellow-400 via-yellow-500 to-yellow-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
              >
                Learn More
              </Link>
            </div>
            <div className="ml-4 md:w-1/2">
              {' '}
              {/* Reduced left margin */}
              <img
                src={Affiliate}
                alt="Become an Affiliate or Influencer"
                className="rounded-lg shadow-md"
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default BusinessServicePage
